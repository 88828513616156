.boxLeftx {
  width: 50%;
  height: 100%;
  position: relative;
  padding: 0 2rem 0 0;
}

.boxRightx {
  width: 50%;
  height: 100%;
  position: relative;
  padding: 1rem 0 0 2rem;
}

@media only screen and (max-width: 768px) {
  .boxLeftx {
    width: 100%;
    padding: 0;
  }
  .boxRightx {
    width: 100%;
    padding: 0;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }
  .dflex {
    flex-direction: column;
  }
}
