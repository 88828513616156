.modalTitle {
    text-align: left;
    font: 18px/32px Helvetica;
    color: #000000;
    margin-right: .5rem;
}

.modalContent {
    padding: 1rem;
}

.boxContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
}

.boxContent:not(:first-child) {
    margin-top: 1rem;
}

.titleContent {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #B0B0B0;
}

.inputModal {
    border: 0;
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000000;
    margin-top: .5rem;
}

.inputModal:focus {
    border: none;
    outline: unset;
}

.modalAction {
    margin-top: 1rem;
    display: flex;
    float: right;
}

.modalAction span {
    text-align: center;
    font: 16px/32px Helvetica;
    color: #DDDDDD;
    margin: auto;
}

.btnFullAction {
    width: 100%;
    height: 49px;
    background: #D4D4D4 0% 0% no-repeat padding-box;
    border-radius: 3px;
    display: flex;
}

.contentBtn {
    margin: auto;
    display: flex;
}

.circle {
    width: 16px;
    height: 16px;
    background: #223771 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin: auto 1rem auto 0;
    display: flex;
}

.circle img {
    height: 60%;
    width: 60%;
    margin: auto;
}

.contentBtn span {
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000000;
}

.option {
    text-align: left;
    font: 12px/16px Helvetica;
    color: #DDDDDD;
    margin: auto;
}

.note {
    text-align: left;
    font: 12px/16px Helvetica;
    color: #000000;
}

.cancel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 16px;
    padding: 0 .5rem;
    margin-right: 1rem;
    width: 110px;
    display: flex;
    cursor: pointer;
}

.save {
    background: #00599D 0% 0% no-repeat padding-box;
    border: 1px solid #03428E;
    border-radius: 16px;
    padding: 0 .5rem;
    width: 110px;
    display: flex;
    cursor: pointer;
}

.content {
    height: 100%;
    width: 100%;
}

.boxSkill1 {
    width: 100%;
    height: 224px;
    border: 1px solid #D4D4D4;
    border-radius: 3px;
    padding: 1rem;
    margin-bottom: .5rem;

}

.boxSearchSkill {
    display: flex;
    border-bottom: 1px solid #b0b0b0;
    height: 24px;
    width: 100%;
}

.boxSearchSkill input {
    border: none;
    margin-left: .5rem;
}

.boxSkill2 {
    padding: 1rem;
    width: 100%;
    min-height: 112px;
    height: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 3px;
    margin-top: .5rem;
    display: flex;
}

.boxSkill {
    width: auto;
    height: 32px;
    background: #00599D 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0 1rem;
    display: flex;
    border-radius: 16px;
    margin-right: 1rem;
    margin-top: 1rem;
}

.default {
    background: #DDDDDD 0% 0% no-repeat padding-box;
}

.boxSkill span {
    margin: auto 0;
    font: 16px/32px Helvetica;
    color: white;
}

.contentUpload {
    margin-bottom: 1rem;
}

.boxFile {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: .5rem;
    border: 1px solid #b0b0b0;
}

.boxFile:not(:first-child) {
    margin-top: .5rem;
}

.boxFile input {
    margin-right: .5rem;
}

.iconClose {
    margin: auto 0 auto auto;
}