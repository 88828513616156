.careerSection {
    height: 100%;
    width: 100%;
    background-color: #F9F9F9;
}

.customContainer {
    padding-top: calc(2rem + 56px);
}

.titleContent {
    text-align: left;
    font: bold 20px/32px Helvetica;
    color: #000000;
    margin-top: 1rem;
}

.box1 {
    display: flex;
}

.boxProfile {
    height: 100%;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* margin: 0 2rem 0 0; */
    padding: 1rem 2rem;
}

.boxProgress {
    height: 100%;
    width: 20%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 1.5rem;
}

.lineProgress {
    width: 100%;
    height: 5px;
    position: relative;
    background-color: #f1f1f1;
    border-radius: 5px;
}

.lineProgressActive {
    width: 20%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #223771;
    border-radius: 5px;
}

.progress {
    margin-top: 2rem;
}

.contentProgress {
    display: flex;
    height: 100%;
    width: 100%;
    margin-bottom: 1rem;
}

.contentProgress span{
    margin: auto 0 auto 1rem;
}

.checkedProgress {
    width: 16px;
    height: 16px;
    background: #F26522 0% 0% no-repeat padding-box;
    margin: auto 0;
    border-radius: 50%;
}

.uncheckedProgress {
    width: 16px;
    height: 16px;
    background: #DDDDDD 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: auto 0;
    border-radius: 50%;
}

@media only screen and (max-width: 1400px){
    .customContainer {
        padding-top: calc(2rem + 56px);
        max-width: 82vw;
    }
}

@media only screen and (max-width: 480px){
    .customContainer {
        padding-top: calc(2rem + 82px);
        /* max-width: 82vw; */
    }
}
