.accountProfile {
    margin-top: 1rem;
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
}

.contentProfile {
    display: flex;
    flex-direction: column;
}

.boxLeft {
    height: 100%;
    width: 100%;
}

.boxRight {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.boxContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f1f1f1;
}

.titleContent {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #B0B0B0;
}

.errorVisible {
    display: block !important;
}

.contentError {
    text-align: left;
    font: 12px Helvetica;
    color: #980000;
    display: none;
}

.inputContent {
    border: 0;
    text-align: left;
    font: 14px/32px Helvetica;
    color: #000000;
    margin-top: .5rem;
}

.inputContent:focus {
    border: none;
    outline: unset;
}

.boxAction {
    width: auto;
    height: 32px;
    background: #00599D 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 16px;
    margin: 2rem 0 auto auto;
    cursor: pointer;
}

.boxAction span {
    font: 14px/8px Helvetica;
    color: #ffffff;
    margin: auto;
    cursor: pointer;

}