.box1 {
    width: 65%;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .link > .title {
        padding: 0 1rem 0 1rem;
    }

    .thumbC {
        width: auto;
    }

    .thumbImg {
        height: 500px;
        width: 100%;
        object-fit: cover;
    }

    .box3 {
        margin-top: 1rem;
        padding: 2rem 0.5rem 1rem 0.5rem;
        display: flex;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.03);
        height: 100%;

        .smallContentBox {
            display: flex;
            align-items: baseline;
            width: 48%;
        }
    }
}

.box2 {
    width: 35%;
    background: rgba(0, 0, 0, 0.03);
    padding: 0 1rem 1rem 1rem;

    .link {
        padding-top: 1rem;
        transform: translateY(-2.8rem);

        // &:not(:first-child) {
        //     margin-top: 2rem;
        // }
    }

    .thumbC {
        width: auto;
    }

    .thumbImg {
        height: 325px;
        width: 100%;
        object-fit: cover;
    }

}

.slider {
    display: block;
    position: relative;
}

.title {
    margin-top: 1rem;
    margin-bottom: 0;
    font: bold 16px Helvetica;
    line-height: 1.8;
    text-align: justify;
}

.summary {
    padding-top: 1rem;
    font: normal 16px Helvetica;
    line-height: 1.8;
    text-align: justify;
    margin-bottom: 0;
}

.link {
    color: black;
    &:hover {
        text-decoration: none;
    }
}

.customLink {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    font: normal 16px Helvetica;
    color: black;
}

.customLink:hover .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    /* background-color: rgb(45, 57, 69, 0.5); */
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.readMore, .readMore:hover {
    font: normal 16px Helvetica;
    color: black;
    text-decoration: none;
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1600px) {

}

@media only screen and (max-width: 1440px) {
    .box1 {
        .thumbImg {
            height: 360px;
        }
    }

    .box2 {
        .link {
            transform: translateY(-2.8rem);
        }
        .thumbImg {
            height: 200px;
        }
    }
    .summary {
        font: normal 14px/1.8 Helvetica;
    }
    .readMore, .readMore:hover {
        font: normal 14px Helvetica;
        text-decoration: none;
    }
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {

}