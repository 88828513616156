.experience {
  padding-bottom: 5rem;
}

.experience hr {
  margin-top: 0;
}

.firstBox {
  display: flex;
  justify-content: space-between;
}

.boxExp {
  width: 49%;
  height: auto;
  position: relative;
  background-color: #ffffff;
  padding: 2rem;
}

.boxTitle {
  display: flex;
}

.boxAction {
  margin-left: auto;
  width: 20px;
  height: 20px;
  background: #223771 0% 0% no-repeat padding-box;
  border-radius: 16px;
  display: flex;
}

.boxAction img {
  height: 70%;
  width: 70%;
  object-fit: contain;
  margin: auto;
}

.contentTitle {
  text-align: left;
  font: 16px/32px Helvetica;
  color: #000000;
}

.boxResume {
  display: flex;
}

.boxResume:not(:first-child) {
  margin-top: 1rem;
}

.boxPreview {
  width: 64px;
  height: 64px;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border: 4px solid #ffffff;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
}

.boxPreview img {
  height: 100%;
  width: 100%;
}

.boxPreview div {
  margin: auto;
}

.boxPreview span {
  margin: auto;
  font: 14px Helvetica;
}

.boxPreview .customLink {
  margin: auto;
  color: #000000;
  font: 14px Helvetica;
}

.boxPreview .customLink>div>img {
  object-fit: cover;
}

.boxDetailPreview {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.boxDetailPreview div {
  margin: auto 0 0 0;
}

/* .boxDetailPreview div span {
  text-align: left;
  font: 12px/24px Helvetica;
  color: #223771;
  margin-right: 0.5rem;

} */


.wrap_teks {
  overflow-wrap: break-word;
  max-width: 200px;
  color: black !important;
  font: 14p Helvetica;
}

.wrap_teks2 {
  color: #223771;
  margin-right: 0.5rem;
  font: 14p Helvetica;
}


.boxPortofolio {
  display: flex;
  flex-wrap: wrap;
}

.boxWorking {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #b0b0b0;
}

.boxWorking:not(:first-child) {
  margin-top: 1rem;
}

.boxWorkingContent {
  display: flex;
  flex-direction: column;
}

.boxWorkingContent span {
  margin-bottom: 0.5rem;
}

.boxWorkingAction {
  margin-left: auto;
}

.workTitle {
  font: 14px Helvetica;
}

.workCompany {
  font: bold 14px Helvetica;
  text-transform: uppercase;
}

.yearRange {
  font: 14px Helvetica;
}

.iconEditW {
  margin: auto 0.5rem;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  position: relative;
  display: flex;
}

.iconEditW img {
  margin: auto;
  height: 65%;
  width: 65%;
  object-fit: cover;
}

.secondBox,
.thirdBox {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.boxSkills {
  width: auto;
  height: 32px;
  background: #00599d 0% 0% no-repeat padding-box;
  border-radius: 16px;
  margin-right: 1rem;
  margin-top: 1rem;
  display: flex;
}

.boxSkills span {
  text-align: center;
  font: 14px/32px Helvetica;
  color: #ffffff;
  margin: auto;
  padding: 0 1rem;
}

.boxSkills:nth-child(4n) {
  /* margin-top: 1rem; */
}

.customLink:hover,
.customLink:focus {
  text-decoration: none;
}

.boxRemove {
  margin: auto 0 auto auto;
  height: 24px;
  width: 24px;
  border: 1px solid #b0b0b0;
  border-radius: 50%;
  display: flex;
}

.boxRemove svg {
  margin: auto;
}

.boxProfile {
  display: flex;
  margin-bottom: 0.5rem;
}

.content {
  text-align: left;
  font: 14px/32px Helvetica;
  color: #000000;
  margin: 0;
}

.iconEdit {
  margin: auto 2rem auto 2rem;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  position: relative;
  display: flex;
}

.iconEdit img {
  margin: auto;
  height: 65%;
  width: 65%;
  object-fit: cover;
}

.boxRemovePortofolio {
  position: absolute;
  top: -20%;
  right: -15%;
}

@media only screen and (max-width: 768px) {

  .firstBox,
  .secondBox {
    flex-direction: column;
  }

  .boxExp {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .boxExp {
    padding: 1rem 2rem;
  }

  .boxResume {
    width: 100%;
  }

  .boxPreview {
    width: 20%;
  }

  .boxDetailPreview {
    width: 70%;
    margin-right: 1rem;
    overflow: hidden;
  }

}

@media only screen and (max-width: 900px) and (orientation:landscape) {
  .boxExp {
    margin-bottom: 2rem;
  }

  .firstBox,
  .secondBox {
    justify-content: unset;
  }

  .secondBox {
    margin-top: 0;
  }
}