.modalHeader {
    padding: 2rem 2rem 0 2rem;
    border-bottom: 0;
}
.modalContent {
    display: flex;
    padding: 1rem 2rem 2rem 2rem;
}
.boxImageIconShare {
    margin-right: .5rem;
}

.boxImageIconShare:focus {
    outline: none;
}
