.customContainer {
    height: 100vh;
    width: 100%;
    display: flex;
}
.content {
    height: auto;
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #B0B0B0;
    padding: 2rem;
    border-radius: 1%;
}

.content form {
    display: flex;
    flex-direction: column;
}

.customContainer > .content span {
    color: rgb(34, 55, 113);
}

.boxContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f1f1f1;
}

.boxContent:not(:first-child) {
    margin-top: 1rem;
}

.titleContent {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #B0B0B0;
}

.errorVisible {
    display: block !important;
}

.contentError {
    text-align: left;
    font: 12px Helvetica;
    color: #980000;
    display: none;
}

.inputModal {
    border: 0;
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000000;
    margin-top: .5rem;
}

.inputModal:focus {
    border: none;
    outline: unset;
}

.contentAction {
    display: flex;
    margin-top: 2rem;
    margin-left: auto;
}

.cancel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 16px;
    padding: 0 .5rem;
    margin-right: 1rem;
    width: 110px;
    display: flex;
    cursor: pointer;
}

.cancel span {
    font: 16px/8px Helvetica;
    color: #000000;
    margin: auto;
    cursor: pointer;
}

.boxAction {
    width: 100%;
    height: 32px;
    background: #00599d 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 16px;
    margin: auto 0 auto 0.5rem;
    cursor: pointer;
}

.boxAction span {
    font: 16px/8px Helvetica;
    color: #ffffff !important;
    margin: auto;
    cursor: pointer;
}

@media only screen and (max-width: 480px) {
    .content {
        width: 100%;
    }
    .contentAction span {
        font: 12px Helvetica;
    }
    .boxAction {
        width: 50%;
    }
}