.customLink {
  letter-spacing: unset !important;
}

.iconExpand {
  height: 20px;
  width: 20px;
  position: relative;
  display: flex;
  text-align: center;
  justify-items: center;
  /* margin: -1.2rem 1rem; */
  margin: auto 1rem !important;
  
}


.iconExpandSafari {
  text-align: center;
  justify-items: center;
  margin: -1.27rem 1rem;
  height: 20px;
  width: 20px;
}

.iconLink {
  margin: auto 0 auto 2rem;
  padding: 0 0 8px 0;
}

.iconLink .icon {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 20px;
  cursor: pointer;
}

p.contentLinkMenu {
  font: 26px/32px Helvetica;
  color: white;
  margin: auto 0 auto 0;
}

.contentItem {
  position: relative;
  display: flex;
  color: white;
  font-family: "Helvetica";
  margin-bottom: 2.5rem;
}

.contentItem:hover {
  color: #f16b26;
}

.contentItem:hover>div.expandList {
  background-color: #223771 !important;
}

.contentItem:hover>div.iconExpand>button.expandList:before {
  transform: rotate(90deg);
  background: white;
}

.contentItem:hover>div.iconExpand>button.expandList:after {
  transform: rotate(180deg);
  background: white;
}

.contentItem:hover>div.iconLink>div>div.arrow,
.contentItem:hover>div.iconLink>div>div.arrow:before,
.contentItem:hover>div.iconLink>div>div.arrow:after {
  background-color: #fff;
}

@media only screen and (max-width: 1680px) {
  p.contentLinkMenu {
    font: 22px Helvetica !important;
  }

  .iconExpand {
    margin: -1.5rem 1rem;
  }
}

@media only screen and (max-width: 1440px) {
  p.contentLinkMenu {
    font: 20px Helvetica !important;
    /* margin-bottom: 1rem; */
  }



  .iconLink .icon {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 10px;
    cursor: pointer;
  }

  .iconExpand {
    margin: -1.1rem 1rem;
  }
}

@media only screen and (max-width: 1366px) {
  .iconLink .icon {
    width: 15px;
    height: 10px;
  }
  .contentItem {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 420px) {
  p.contentLinkMenu {
    font: 14px/32px Helvetica;
  }

  .iconExpand {
    margin: -0.3rem 1rem;
    height: 5px;
    width: 5px;
  }

  .iconLink .icon {
    width: 25px;
    height: 15px;
  }
}

@media only screen and (max-width: 360px) {
  p.contentLinkMenu {
    font: 14px/32px Helvetica;
  }

  .iconExpand {
    margin: -0.3rem 1rem;
    height: 5px;
    width: 5px;
  }

  .iconLink .icon {
    width: 25px;
    height: 15px;
  }
}