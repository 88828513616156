/* GENERAL */

:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
}

.contentSection2 {
  padding: 5rem 0;
  background-color: #f1f1f1;
  width: 100vw;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  font: Bold 36px Helvetica;
  /* padding-top: 2rem; */
  margin-bottom: 2rem;
}

.customContainer2 {
  max-width: 100vw !important;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-bottom: 3.5rem;
}

.customContainer3 {
  max-width: 100vw !important;
  margin: 0;
  padding: 0;
}

.ctBtmHalf {
  width: 100%;
  height: 100%;
  background: #f1f1f1;
}

.subTitle {
  font: 20px/50px Helvetica;
}

/* .viewMore p {
  font: normal 16px Helvetica !important;
  color: white !important;
  cursor: pointer;
} */

.boxLinks a {
  color: white !important;
}

.boxLinks p {
  font: normal 16px Helvetica !important;
}

.boxLinks:hover .viewMore {
  margin-left: 0.5rem;
  font-weight: bold;
}

.contentTitle {
  font: bold 26px/48px Helvetica;
}

.contentReadmore {
  font: 14px/32px Helvetica;
}

.box:hover .boxImg {
  width: 540px !important;
  position: absolute;
  z-index: 1;
}

.box:hover .boxVertical {
  position: absolute;
  z-index: 3;
  width: 540px !important;
}

.box:hover .contentBack {
  opacity: 1;
  z-index: 2;
  width: 540px;
}

.box:hover .circle {
  opacity: 0;
}

.box:hover .innerCircle {
  opacity: 0;
}

.box:hover .outerCircle {
  opacity: 1;
}

.box:hover .boxTitle {
  /* padding-top: 0.5rem; */
  /* margin: auto auto auto 1.5rem; */
  opacity: 0;
}

.box:hover .boxImg {
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
}

.box {
  width: 440px !important;
  height: 568px !important;
  object-fit: cover;
  margin: 0 2rem 0 0;
  position: relative;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.boxImg {
  width: 440px !important;
  height: 568px !important;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.01s ease-in-out;
  /* margin: 0 2rem 0 0;
    transition: width 1s ; */
}

.boxImgDefault {
  width: 440px !important;
  height: 568px !important;
  object-fit: contain;
}

figure.contentFigure {
  width: 440px !important;
  height: 568px !important;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.box img {
  background-repeat: no-repeat;
  background-size: contain;
}

.boxVertical {
  /* width: 72px; */
  /* height: 568px; */
  /* width: 100%; */
  width: 440px;
  height: 72px;
  background: rgb(128, 128, 128, 0.5) 0% 0% no-repeat padding-box;
  position: absolute;
  /* top: 0; */
  bottom: 0;
  display: flex;
  transition: width 0.01s ease-in-out;
}

.contentBoxTitle {
  display: flex;
  margin: auto 2rem;
}

.contentBoxTitle a {
  text-decoration: none;
  display: flex;
}

.boxTitle {
  /* writing-mode: vertical-rl; */
  /* text-orientation: mixed; */
  /* transform: rotate(180deg); */
  margin: auto auto auto 1rem;
  color: white;
  text-align: left;
  font: bold 20px Helvetica;
}

.readMore {
  cursor: pointer;
  color: white;
  font: normal 16px Helvetica;
}

.boxLinks {
  position: relative;
  margin: auto 0;
  display: flex;
  text-align: left;
  margin-top: 1rem;
}

.boxLinks2 {
  position: relative;
  text-align: left;
}

.boxLinks2:hover .outerCircleBot {
  opacity: 1;
}

.boxLinks2:hover .innerCircleBot {
  opacity: 1;
}

.boxLinks2:hover .readMore {
  margin-left: 0;
  font-weight: bold;
}

.boxLinks:hover .outerCircleBot {
  opacity: 1;
}

.boxLinks:hover .innerCircleBot {
  opacity: 1;
}

.boxLinks:hover .readMore {
  margin-left: 0;
  /*font-weight: bold;*/
}

.outerCircle {
  padding: 1px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  position: absolute;
  /* top: 1.64rem;
    left: 1.1rem; */
  /* position: absolute;
    bottom: -1rem;
    left: 0rem; */
  opacity: 1;
}

.circle {
  content: "";
  /* position: absolute;
    bottom: -1rem;
    left: 0.4rem; */
  position: absolute;
  top: 2rem;
  /* margin: auto 0.5rem auto auto; relative */
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.outerCircleBot {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  /* position: absolute; */
  /* margin: 0 1rem 0 auto; */
  margin: 0 0.5rem auto auto;
  align-self: center;
  /* bottom: -1rem;
    left: 0.1rem; */
  opacity: 0;
}

.circle,
.innerCircle {
  content: "";
  background: #f16624;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

/* MAC */

.innerCircle>svg>#Group_3096 {
  mix-blend-mode: normal !important;
}

/* END MAC */

.innerCircleBot {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.boxSmall {
  width: 392px !important;
  height: 241px;
  margin: 0 2rem 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.boxSmall:hover .contentBottomTitle {
  opacity: 1;
}

/* .contentTop {
  margin-left: 13rem;
} */

.contentBottom {
  margin: 5rem 0 0 0;
}

.havingMargin {
  margin-top: 2rem;
}

.leftMargin {
  margin-left: 10.8vw;
}

.contentBack {
  opacity: 0;
  width: 440px;
  height: 568px;
  background-color: rgb(34, 55, 113, 0.8);
  padding: 2rem 2rem 2rem 6rem;
  position: absolute;
  top: 0;
  display: flex;
  transition: width 0.01s ease-in-out;
}

.contentBack p {
  color: white;
}

.contentInBox {
  position: relative;
  display: flex;
  width: 392px;
  height: 241px;
  background: transparent;
  /* background: transparent;
  backdrop-filter: blur(2px); */
}

.cardCover {
  background-color: tomato;
  background: transparent;
  backdrop-filter: blur(2px);
}

.txtMid {
  font-weight: 400;
  font-size: 26px;
  color: white;
  font-weight: bold;
  /* text-transform: uppercase !important; */
  font-family: Helvetica;
  text-align: right;
  width: 15vw;
  margin: 0 3rem 0 auto;
  align-self: center;
  position: relative;
  line-height: normal;
}

.img_box {
  width: 488px;
  height: 288px;
  object-fit: cover;
}

.textmidle4 {
  font: bold 20px Helvetica;
  color: white;
  /* text-transform: uppercase; */
  text-align: right;
  width: 15vw;
  margin: 0 1rem 0 auto;
  align-self: center;
  position: relative;
}

._box:hover {
  opacity: 0.8;
}

._content {
  position: relative;
  display: flex;
  width: 488px !important;
  height: 288px !important;
  background: transparent;
  /* backdrop-filter: blur(2px); */
}

._absolute {
  top: 0;
  position: absolute;
}

._absolute p {
  font: bold 22px Helvetica;
  color: white;
  /* text-transform: uppercase; */
}

.bgColor {
  background-color: violet;
}

.box_1 {
  width: 392px;
  height: 241px;
}

/* .box_hover:hover .contentInBox {
  display: none;
} */

.imgImg2 {
  width: 392px;
  height: 241px;
  object-fit: cover;
}

.box_hover {
  width: 392px;
  height: 241px;
}

.box_hover:hover .boxLinks {
  opacity: 1;
}

.box_hover:hover ._overlay {
  opacity: 1;
}

.box_hover:hover .contentInBox {
  backdrop-filter: blur(0px);
}

.contentBottomTitle:hover .outerCircle2 {
  opacity: 1;
}

.contentBottomTitle:hover .readMore2 {
  margin-left: 2rem;
  font-weight: bold;
}

._overlay {
  background: transparent;
  opacity: 1;
  width: 392px;
  height: 241px;
}

.imgZoom {
  background: white;
  overflow: hidden;
  padding: inherit;
}

/* .imgZoom:hover .cardCover {
  filter: blur(2px);
} */

.box_hover:hover .blue,
.box_hover:hover .grey {
  opacity: 1;
}

.blue {
  background: #6969dd 0 0 no-repeat padding-box;
  opacity: 0.5;
}

.grey {
  background: gray 0 0 no-repeat padding-box;
  opacity: 0.5;
}

.contentAbsolute {
  position: absolute;
  padding: 0;
  /* background-color: rgb(128 128 128 / 25%); */
}

.boxOver {
  position: absolute;
  top: 0;
  background-color: transparent;
  filter: blur(2px);
  opacity: 0;
}

.contentInBox span {
  font: 12px/24px Helvetica;
}

.contentBottomTitle {
  position: relative;
  display: flex;
  opacity: 0;
}

.contentBottomTitle span {
  color: white;
}

.customLink:hover {
  text-decoration: none;
}

.content p {
  font: 16px/32px Helvetica;
  line-height: 1.8rem !important;
  font-style: italic !important;
}

/* HD+ */

@media only screen and (min-width: 2560px) {
  .customContainer {
    padding: 0;
    max-width: 2000px !important;
  }

  .customContainer2 {
    margin-bottom: 5rem;
  }

  .contentSection2 {
    padding: 5rem 0;
  }

  .title {
    padding-top: 0;
    margin-bottom: 2rem;
  }

  /* .contentTop {
    margin-left: 17.5rem;
  } */
}


@media only screen and (min-width: 2048px) and (max-height: 1536px) {
  .customContainer2 {
    margin-bottom: 9vh;
  }

  .contentSection2 {
    padding: 9vh 0;
  }

  .leftMargin {
    margin-left: 13.2vw;
  }
}

/* LAPTOP/ NOTEBOOK*/

@media only screen and (max-width: 1680px) {
  .customContainer {
    max-width: 1300px !important;
    padding-left: 1.5rem;
  }


}

@media only screen and (max-width: 1440px) {
  .customContainer {
    max-width: 1062px !important;
    padding-left: 1.5rem;
  }

  .leftMargin {
    margin-left: 13.5vw;
  }

  .title {
    font: bold 28px Helvetica;
    margin-bottom: 1.5rem;
  }

  /* .contentTop {
    margin-left: 11.2rem;
  } */
  .subTitle {
    font: 14px Helvetica;
    margin-left: 132px;
  }

  figure.contentFigure {
    width: 325px !important;
    height: 420px !important;
  }

  .boxImg {
    width: 325px !important;
    height: 420px !important;
  }

  .box {
    width: 350px !important;
    height: 420px !important;
  }

  .contentBack {
    width: 325px;
    height: 420px;
  }

  .boxVertical {
    width: 325px;
    height: 60px;
  }

  .box:hover .contentBack {
    width: 425px;
  }

  .box:hover .boxVertical {
    width: 425px !important;
  }

  .box:hover .boxImg {
    width: 425px !important;
  }

  .contentTitle {
    font: bold 18px Helvetica;
  }

  .boxTitle {
    font: bold 16px Helvetica;
  }

  .innerCircle {
    width: 40px;
    height: 40px;
  }

  .content p,
  .content strong,
  .content span,
  .content li,
  .content i {
    font: bold 14px Helvetica !important;
    line-height: 1.8 !important;
    font-style: italic !important;
  }

  .contentInBox span {
    font: 14px Helvetica !important;
  }

  .boxLinks p {
    font: normal 14px Helvetica !important;
  }

  .readMore {
    font: normal 14px Helvetica;
  }

  .txtMid {
    font-size: 18px;
  }

  .outerCircle2 {
    top: 22px;
  }
}

/*ipad pro*/

@media only screen and (max-width: 1024px) {
  .customContainer {
    max-width: 88vw !important;
    padding-left: 0 !important;
  }

  .leftMargin {
    margin-left: 6vw;
  }

  .customContainer2 {
    margin-bottom: 2.5rem;
  }

  .boxLinks .outerCircleBot {
    opacity: 1;
  }

  .boxLinks .innerCircleBot {
    opacity: 1;
  }

  .boxLinks .readMore {
    margin-left: 0;
  }
}

/*table view*/

@media only screen and (max-width: 768px) {
  .customContainer {
    max-width: 672px !important;
    padding: 0;
    margin: 0 6.25%;
  }

  .title {
    font: Bold 18px Helvetica;
  }

  .boxTitle {
    font: bold 16px Helvetica;
  }

  .innerCircle {
    width: 35px;
    height: 35px;
  }

  /* .contentTop {
    margin-left: 3rem;
  } */
  .contentSection2 {
    padding: 3.5rem 0;
  }

  .box {
    width: 224px !important;
    height: 256px !important;
  }

  /* .box:first-child {
    margin-left: 6vw;
  } */
  .havingMargin {
    margin-top: 2rem;
  }

  figure.contentFigure {
    width: 224px !important;
    height: 256px !important;
  }

  .boxImg {
    width: 224px !important;
    height: 256px !important;
  }

  .leftMargin {
    margin-left: 6vw;
  }

  .contentBack {
    width: 224px;
    height: 256px;
    padding: 1rem;
  }

  .boxVertical {
    width: 224px;
    height: 56px;
  }

  .box:hover .contentBack {
    width: 224px;
  }

  .box:hover .boxVertical {
    width: 224px !important;
  }

  .box:hover .boxImg {
    width: 224px !important;
  }

  .boxSmall,
  .contentInBox {
    width: 232px !important;
    height: 152px;
  }

  .contentTitle {
    font: bold 18px Helvetica;
  }

  .content p,
  .content strong,
  .content span,
  .content li,
  .content i {
    font: 14px Helvetica !important;
    line-height: 22px !important;
    font-style: italic !important;
  }

  .txtMid {
    font: bold 18px Helvetica;
    margin: 0 1rem 0 auto;
  }
}