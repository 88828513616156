.contSec5 {
  width: 100vw;
  height: 100vh;
  /* margin-top: 20px; */
}

.cardImageSec5 {
  font-size: medium;
}

.tl {
  margin-bottom: 0 !important;
}

.textMidle {
  position: relative;
  transform: translate(-50%, -50%);
  width: 586px;
  z-index: 1;
  font-size: small;
  background-color: rgba(255, 255, 255, 0.445);
  padding: 4rem;
  height: 523px;
  border-color: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: unset;
}

.footerSec4 {
  /* display: inline-flex; */
  background-color: transparent;
  border: none;
  font-size: 14px;
  /* position: absolute; */
  /* bottom: -22%; */
  padding-top: 10px;
  padding: unset;
  position: relative;
  display: flex;
}

.textMidle2 {
  position: absolute;
  top: 30%;
  /* left: 80%; */
  right: 10%;
  /* transform: translate(-50%, -50%); */
  width: 50%;
  font-size: small;
  /* background-color: rgba(255, 255, 255, 0.445); */
  background-color: transparent;
  border: none;
}

.textInsideOverlay p {
  font-size: 36px;
  color: black;
  font-family: Helvetica;
  text-align: left;
  /* padding-right: 70px; */
  /* margin: 20px; */
  font-weight: bold;
}

.cardGroupSec5 {
  width: 100%;
  height: 100%;
  border-color: transparent;
  display: flex;
  flex-direction: row;
}

/* .imgSec5 img {
    opacity: 50%;
} */

.colSec5 {
  max-height: 800;
  padding: inherit;
}

.imgSec5 {
  display: flex;
  max-width: 480px;
  height: auto;
  overflow-x: hidden;
}

.cardSec51 {
  width: 50%;
  height: 100vh;
  object-fit: cover;
  position: relative;
}
.cardSec5 {
  width: 50%;
  height: 100vh;
  object-fit: cover;
}

.boxContentRight {
  height: 700px;
  background: #283164a9 0 0 no-repeat padding-box;
  /* opacity: 1.5; */
  /* margin: 0 100px 500px 0; */
}

.overlaySec5 {
  background: #ffffffd0 0 0 no-repeat padding-box;
  opacity: 0.79;
  border-color: transparent;
}

.imgOverlay {
  background: #283164a9 0 0 no-repeat padding-box;
  opacity: 0.6;
  cursor: url("./assets/drag.svg") 39 39, auto;
}

.text_title {
  font: bold 26px Helvetica !important;
  color: black !important;
  margin-bottom: 1rem;
}

span.title {
  font: bold 46px/48px Helvetica;
  color: black;
}

.btn_card_ripple {
  width: 256px;
  height: 72px;
  /* position: absolute;
    right: 8%;
    bottom: 5%; */
  float: right;
}

.btn_card {
  background-color: #f16b26;
  border: none;
  width: 192px !important;
  height: 57px !important;
  border-radius: 50px;
  margin: auto;
}

.btn_card p {
  font: bold 26px Helvetica;
  color: white;
  margin-bottom: 0 !important;
}

.btn_card:focus {
  outline: none;
  border: 0;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot1 {
  height: 20px;
  width: 20px;
  background-color: #f16b26;
  border-radius: 50%;
  display: inline-block;
}

.contentSpan {
  margin: auto auto auto 0;
}

.contentRelatif {
  position: relative;
  display: flex;
}

.contentImage {
  opacity: 1.3;
  height: inherit;
  object-fit: cover;
}

.contentImageD {
  opacity: 0.3;
  height: inherit;
  /* object-fit: contain; */
}

.contentRight {
  margin-left: 10.5vw;
}

.contentImageAbsolute {
  position: absolute;
  padding: 0;
}

.textInsideImage {
  font: 46px/48px Helvetica;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.contentAbsolute {
  position: absolute;
  top: 2rem;
  left: 2rem;
  /* top: 259px; */
  right: 276px;
  /* bottom: 0; */
  /* left: 0; */
  padding: 0;
}

.gradientOverlay {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    345deg,
    rgba(0, 0, 0, 0.20818749863226538) 0%,
    rgba(0, 0, 0, 0) 61%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
}

.contentInBox {
  position: relative;
  display: flex;
  width: 317px !important;
  height: 181px !important;
}

.contentInBox p {
  font: 36px Helvetica;
  font-weight: bold;
}

.innerCircleBot {
  content: "";
  background: #fff;
  width: 8px;
  height: 8px;
}

.circle,
.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.innerCircleBot {
  content: "";
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  position: absolute;
  top: 1.64rem;
  left: 1.1rem;
  /* position: absolute;
    bottom: -1rem;
    left: 0rem; */
  opacity: 1;
}

.circle {
  content: "";
  /* position: absolute;
    bottom: -1rem;
    left: 0.4rem; */
  position: absolute;
  top: 2rem;
  /* margin: auto 0.5rem auto auto; relative */
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.outerCircleBot {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
  /* position: absolute; */
  margin: 0 1rem 0 auto;
  align-self: center;
  /* bottom: -1rem;
    left: 0.1rem; */
  opacity: 1;
}

.boxContentImage {
  background-image: cover;
}

.text_content {
  font: normal 16px Helvetica;
  text-align: justify;
  margin-bottom: 5rem;
  line-height: 1.8rem;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 528px;
}

.boxTop {
  width: auto;
  height: 200px;
  background-color: white;
}

.imageDefault {
  object-fit: contain;
  width: 100%;
  height: 528px;
}

.infoCareer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
}

.subCarReal,
.contentCarReal {
  width: auto;
}

.contentCarReal {
  margin-bottom: 1rem;
  text-align: justify;
}

.contentCarReal span {
  font: 16px Helvetica;
  color: black !important;
  line-height: 1.8rem;
  text-align: justify;
}

.infoCarReal {
  width: 500px;
  height: auto;
  position: relative;
  background-color: #ffffff8f;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 4rem;
}

.btnCarReal {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.overlayInfo {
  width: 100%;
  height: 100%;
  background-color: #ffffff80;
  position: absolute;
  top: 0;
}

@media only screen and (min-width: 2560px) {
  .textMidle {
    width: 839px;
    height: 450px;
    left: 54.7%;
  }

  .text_content {
    margin-bottom: 3rem;
  }

  .text_title p {
    font: Bold 26px Helvetica !important;
  }

  .infoCarReal {
    width: 900px;
  }
}

@media only screen and (max-width: 1440px) {
  .text_title p {
    font: Bold 18px Helvetica !important;
    margin-bottom: 1rem;
  }
  .text_content span {
    font: normal 14px Helvetica !important;
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
  }

  .infoCarReal {
    width: 400px;
  }

  .text_title p {
    font: bold 18px Helvetica;
  }

  .contentCarReal span {
    font: 14px Helvetica;
    line-height: 1.5rem;
  }

  .contentInBox p {
    font: Bold 28px Helvetica;
  }

  .btn_card {
    width: 136px;
    height: 40px;
    font: bold 18px Helvetica !important;
  }

  .btn_card p {
    font: bold 18px Helvetica;
  }

  .infoCarReal {
    padding: 2rem;
  }

  .footerSec4 span {
    font-size: 14px;
  }

  .textInsideOverlay p {
    font-size: 28px;
  }

  .text_content {
    margin-bottom: 0.7rem;
  }
}

/*ipad pro*/
@media only screen and (max-width: 1024px) {
  .cardGroupSec5 {
    flex-direction: column;
  }

  .contentCarReal {
    margin-bottom: 2.5rem;
  }

  .contentAbsolute {
    top: 3.5rem;
    left: 48px;
  }

  .cardSec51,
  .cardSec5 {
    width: 100%;
  }

  .btn_card {
    width: 163px !important;
    height: 50px !important;
  }

  .infoCarReal {
    width: 100%;
    margin: auto 20vw;
    padding: 3.5rem;
  }

  .infoCareer,
  .gradientOverlay {
    height: 50vh;
  }

  .gradientOverlay {
    background: linear-gradient(
      271deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 0%) 1%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .textInsideOverlay p {
    font: bold 18px Helvetica;
  }

  .text_title {
    font: bold 18px Helvetica !important;
    margin-bottom: 2.5rem;
  }

  .cardSec51 {
    height: 50vh !important;
  }
  .cardSec5 {
    height: 50vh !important;
  }

  .contSec5 {
    height: 100vh;
  }

  .contentInBox span {
    font: bold 18px Helvetica;
    color: black;
    text-decoration: none;
  }
}

/*table view*/
@media only screen and (max-width: 768px) {
  .cardGroupSec5 {
    flex-direction: column;
  }

  .contentCarReal {
    margin-bottom: 2.5rem;
  }

  .contentAbsolute {
    top: 3.5rem;
    left: 48px;
  }

  .cardSec51,
  .cardSec5 {
    width: 100%;
  }

  .infoCarReal {
    width: 100%;
    margin: auto 20vw;
    padding: 3.5rem;
  }

  .infoCareer,
  .gradientOverlay {
    height: 50vh;
  }

  .gradientOverlay {
    background: linear-gradient(
      271deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 0%) 1%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .textInsideOverlay p {
    font: bold 18px Helvetica;
  }

  .text_title {
    font: bold 18px Helvetica !important;
    margin-bottom: 2.5rem;
  }

  .cardSec51 {
    height: 50vh !important;
  }
  .cardSec5 {
    height: 50vh !important;
  }

  .contSec5 {
    height: 100vh;
  }

  .contentInBox span {
    font: bold 18px Helvetica;
    color: black;
    text-decoration: none;
  }

  .btn_card {
    width: 163px !important;
    height: 50px !important;
  }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
  .cardSec5 {
    height: 60vh;
    margin: 0 !important;
  }

  .imgContent {
    height: 60vh !important;
  }

  .textMidle {
    width: 296px;
    height: 280px;
    padding: 1rem;
    left: 51%;
  }

  .footerSec4 {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
  }

  .footerSec4 .contentSpan {
    margin: 0 auto 0 1rem;
  }

  .footerSec4 .btn_card_ripple {
    margin: 0 auto;
    width: 100%;
  }

  .btn_card_ripple .btn_card {
    width: 100%;
  }

  .text_content {
    margin-bottom: 2rem;
    height: 110px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .imgSlider {
    height: 40vh !important;
  }

  .contentCard {
    position: relative;
  }

  .textInsideOverlay p {
    font: bold 22px Helvetica;
  }
}

@media only screen and (max-width: 360px) {
  .cardSec5 {
    height: 60vh;
    margin: 0 !important;
  }

  .imgContent {
    height: 60vh !important;
  }

  .textMidle {
    width: 296px;
    height: 280px;
    padding: 1rem;
    left: 51%;
  }

  .footerSec4 {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
  }

  .footerSec4 .contentSpan {
    margin: 0 auto 0 1rem;
  }

  .footerSec4 .btn_card_ripple {
    margin: 0 auto;
    width: 100%;
  }

  .btn_card_ripple .btn_card {
    width: 100%;
  }

  .text_content {
    margin-bottom: 2rem;
    height: 110px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .imgSlider {
    height: 40vh !important;
  }

  .contentCard {
    position: relative;
  }

  .textInsideOverlay p {
    font: bold 22px Helvetica;
  }
}
