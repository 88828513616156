.top {
  display: float;
  /* height: 96px; */
  margin-top: 45px;
  margin-bottom: 17px;
}

.title {
  text-align: left;
  font: normal normal 900 28px/40px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: inline-block;
  margin: 0;
  margin-right: 20px;
}

.subtitle {
  text-align: left;
  font: normal normal medium 16px/40px Helvetica;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  display: inline-block;
  margin: 0;
}

.blackCircleBtn {
  height: 24px;
  width: 24px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
}

.purpleCircleBtn {
  height: 24px;
  width: 24px;
  background: #223771 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
}

.blackCircleBtn,
.purpleCircleBtn i {
  color: white;
  font-size: 20px;
}

.allBrands {
  text-align: left;
  font: normal normal bold 18px/40px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: inline-block;
}

.bottom {
  background-color: black;
}

.bottomTitle {
  text-align: left;
  font: normal normal bold 20px/40px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 21px;
  margin-bottom: 19px;
  display: inline-block;
}

.cardContent {
  padding: 1.5rem;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1;
}

.cardStatus {
  margin-bottom: 25px;
}

.box {
  width: 304px !important;
  height: 272px;
  background-color: #223771;
  border-radius: 0;
  margin-right: 16px;
  margin-top: 2rem;
}

.box .text {
  font-family: Helvetica;
  letter-spacing: -0.18px;
  color: #f0f0f0;
  opacity: 1;
  margin: 0;
  text-align: left;
}

.boxTop {
  width: 100%;
  height: 212px;
}

.boxBottom {
  width: 100%;
  height: 60px;
}

.font_16 {
  font-size: 16px;
}

.font_18 {
  font-size: 18px;
}

.titleCard {
  color: #f0f0f0;
  opacity: 1;
  font: bold 16px Helvetica;
  text-align: left;
  /* margin-bottom: 83px; */
}

.expiredDate {
  position: absolute;
  right: 32px;
  bottom: 44px;
}

.bottomContent {
  padding-bottom: 5rem;
}

.bottomContent a {
  text-decoration: none;
}

.margcustom {
  margin-top: 2rem;
}

.cusT {
  max-width: 1520px;
}

.selected {
  background: black !important;
}

@media only screen and (min-width: 2560px) {
  .cusT {
    max-width: 2090px !important;
    padding: 0 1rem;
  }

  .pad0 {
    padding: 0;
  }

  .box {
    width: 472px !important;
    height: 360px;
  }

  .cardContentHover {
    width: 472px !important;
    height: 360px;
  }
}

@media only screen and (max-width: 1400px) {
  .cusT {
    max-width: 1062px;
  }
  .bottomContent {
    padding-bottom: 3.5rem;
  }

  .title {
    font: bold 18px Helvetica;
  }

  .bottomTitle {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
    font: bold 18px Helvetica;
  }

  .subtitle,
  .titleDate {
    font: normal 14px Helvetica;
  }

  .titleCard2,
  .titleCard {
    font: bold 14px Helvetica;
  }

  .btnApply p {
    font: normal 14px Helvetica;
  }

  .box {
    width: 272px !important;
    height: 243px;
  }

  .cardContentHover {
    width: 272px;
    height: 243px;
  }

  .font_18,
  .font_16 {
    font-size: 14px;
  }
}

/*tablet only*/
@media only screen and (max-width: 768px) {
  .cusT {
    max-width: 672px;
    padding: 0;
    margin: 0 48px;
  }

  .box {
    margin-right: 0;
  }
}
