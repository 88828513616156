/* GENERAL */
:root {
  --card-width-hd: 230px;
  --card-height-hd: 324px;
  --container-hd: 1062px;
}

/* SPECIFIC */
.contentWrap {
  position: relative;
  display: flex;
  justify-content: center;
  /* margin: 2rem 0; */
}

p.title {
  font: 18px/32px Helvetica;
  color: black;
}

p.contentTitle {
  font: bold 24px/24x Helvetica;
  color: black;
}

p.contentDesc {
  font: 16px/24px Helvetica;
  color: black;
}

.titleBoxEvent {
  color: #223771;
  padding: 1rem 3rem 0;
  text-transform: uppercase;
}

.isNull {
  display: none !important;
}

.titleBoxNews {
  color: #f16b26;
  padding: 1rem 3rem 0;
  text-transform: uppercase;
}

.titleBoxUpcoming {
  color: #223771;
  padding: 1rem 3rem 0;
  text-transform: uppercase;
}

.customNavbar1 {
  padding-left: 2.2rem;
  margin-top: 4rem;
}

.boxContent1 {
  width: 320px;
  height: 451.2px;
  background-color: #f1f1f1;
  text-decoration: none;
  color: black;
  margin: 0 12px 0 0;
  position: relative;
  -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}

@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}

.boxflex {
  width: 472px;
}

.boxContent:hover {
  text-decoration: none;
  box-shadow: 0 3px 6px #00000029;
}

.contentBox {
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: black;
}

.boxImage {
  width: 472px;
  height: 256px;

  overflow: hidden;
  position: relative;
}

.newsColor {
  background-color: #f1f1f1;
}

.newsColor {
  background-color: #f1f1f1;
}

.eventColor {
  background-color: #223771;
}

.title_content1 {
  word-break: break-word;
  font: bold 18px Helvetica;
  color: white;
  opacity: 1;
  position: relative;
  margin: auto;
}

.textContent {
  width: 100%;
  margin: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  height: 80px;
  display: flex;
  align-items: center;
}

.txt_content {
  padding: 1rem 3rem 0;
  font: 16px/25px Helvetica;
  height: 75px;
  overflow: hidden;
  color: black;
}

.txt_content p {
  font: 16px/30px Helvetica;
  margin-bottom: 0;
}

.txt_content1 {
  padding: 1rem 2rem;
  font: 16px Helvetica;
  height: 125px;
  overflow: hidden;
}

.foot_content {
  position: relative;
  display: flex;
  padding: 1rem 3rem;
  font: 16px Helvetica;
  color: black;
}

.foot_content1 {
  position: relative;
  display: flex;
  padding: 0.5rem 2rem;
  font: 16px Helvetica;
  color: black;
}

.date_right {
  position: absolute;
  right: 7%;
}

.date_right1 {
  position: absolute;
  right: 10%;
}

.box_image {
  width: 320px;
  height: 451.2px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.div_readmore {
  position: relative;
  background-color: #f1f1f1;
  width: 451.2px;
  height: 156px;
}

.readmore_btn {
  position: absolute;
  background-color: #223771;
  width: 192px;
  height: 48px;
  color: white;
  border-radius: 40px;
  text-align: center;
  padding: 0.75rem;
  top: 30%;
  left: 25%;
}

.box_behind {
  position: absolute;
  top: 0;
  opacity: 1;
}

.boxContent1:hover .box_behind {
  opacity: 1;
}

.textContainer {
  position: absolute;
  width: 100%;
  bottom: 0;
  cursor: pointer;
}

.readmore_btn1 {
  position: absolute;
  background-color: #223771;
  width: 192px;
  height: 48px;
  color: white;
  border-radius: 40px;
  text-align: center;
  padding: 0.75rem;
  top: 35%;
  left: 25%;
}

.box_content_image {
  position: relative;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.box_content_image:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transition: 1s ease-in-out;
}

.box_content_image img {
  cursor: url("../assets/drag.svg") 20 20, auto;
}

figure.wrapp_image {
  width: 880px;
  height: 424px;
  overflow: hidden;
}

.img_content {
  width: 472px;
  height: 256px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.inside_image {
  width: 472px;
  height: 256px;
  margin: auto;
  position: absolute;

  top: 0;
  left: 0;
  padding: 15%;
  text-align: center;
  background-color: rgba(234, 236, 236, 0.945);
  opacity: 0;
  color: black;
}

.icon_share {
  width: 50px;
  height: 50px;
  margin-bottom: 7%;
}

figure.wrapp_image:hover .inside_image {
  opacity: 1;
}

figure.wrapp_image:hover .img_content {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transition: 2s ease-in-out;
}

.icon {
  margin: 5px;
  top: 50%;
}

.img_content:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transition: 2s ease-in-out;
}

.background_blur {
  z-index: 8;
  height: 75px;

  width: 100vw;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: transparent;
}

.link {
  text-decoration: none !important;
  cursor: pointer;
}

.component {
  margin-top: 6rem;
}

.fadeInBottom {
  -webkit-animation: fade-in-bottom 0.5s ease-in both;
  animation: fade-in-bottom 0.5s ease-in both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.images_slide {
  width: 100%;
  height: 1080px;
  background-color: tomato;
}

.older {
  width: 416px;
  height: 64px;
  background-color: #f16b26;
  margin: 50px auto;
  box-shadow: 3px -1px 6px #00000061;
  border-radius: 50px;
}

.older:hover {
  background-color: #223771;
}

.older p {
  font: 20px Helvetica;
  color: white;
  text-align: center;
  padding: 1.3rem;
  margin: none !important;
  cursor: pointer;
}

.box_grid {
  position: relative;
  display: flex;
  margin: 1rem auto;
  justify-content: center;
}

.customSelect {
  border: none;
  background-color: transparent;
  margin: 15px 0 0 20px;
  display: flex;
  height: 100%;
  width: 10%;
  position: relative;
  font: 16px Helvetica;
  border-radius: 1rem;
}

.customSelect option {
  background-color: white;
  border-radius: 10px;
  width: 200px;
}

.customSelect option:hover {
  background-color: #223771;
}

@media only screen and (min-width: 2560px) {
  .boxContent1 {
    width: 366px;
    height: 516.1px;
    margin-left: 0;
    margin-right: 1.5rem;
  }

  .box_image {
    width: 366px;
    height: 516.1px;
  }
}

@media only screen and (max-width: 1400px) {
  .component {
    margin-top: 8rem;
  }

  .newNavbar {
    margin-left: 209px;
  }

  .box_grid {
    width: 80%;
    margin-top: 7rem;
  }

  .input_search {
    width: 245px;
    font-size: 14px;
  }

  .content {
    margin-top: -2rem;
  }

  .img_grid {
    width: 15px;
  }

  .img_list {
    width: 15px;
  }

  .box_search {
    right: 33%;
    width: 285px;
  }

  .box_search p {
    font: 16px Helvetica;
  }

  .wrap_option {
    left: 35%;
  }

  .div_readmore {
    width: var(--card-width-hd);
    height: 90px;
  }

  .div_readmore1 {
    width: var(--card-width-hd);
    height: 70px;
  }

  .boxContent1 {
    width: var(--card-width-hd);
    height: var(--card-height-hd);
  }

  .box_image {
    width: var(--card-width-hd);
    height: var(--card-height-hd);
  }

  .title_content {
    padding: 10px 25px;
    font: bold 17px/26px Helvetica;
    height: 60px;
  }

  .textContent {
    height: 50px;
  }

  .contentBox {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .titleBoxEvent {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .titleBoxNews {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .titleBoxUpcoming {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .txt_content {
    padding: 4px 25px;
    font: 13px/15px Helvetica;
    height: 45px;
  }

  .txt_content p {
    font: 13px/15px Helvetica;
    height: 45px;
  }

  .foot_content {
    font: 13px/15px Helvetica;
    padding: 0 25px;
    height: 50px;
  }

  .img_content {
    /* object-fit: cover; */
    width: 318px;
    height: 200px;
  }

  .readmore_btn {
    top: 25%;
    left: 17%;
  }

  .readmore_btn1 {
    top: 25%;
    left: 17%;
  }

  .title_content1 {
    font: bold 14px Helvetica;
  }

  .txt_content1 {
    font-size: 14px;
    height: 70px;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  .foot_content1 {
    font: 12px Helvetica;
    padding: 0 1.5rem;
  }

  .contentWrap1 {
    width: 918px;
  }

  .inside_image {
    width: 318px;
    height: 200px;
  }

  .icon_share {
    height: 38px;
    width: 38px;
    margin-bottom: 5%;
  }

  .customSelect {
    margin: 17px 0 0 -185px;
    font: normal 13px Helvetica;
  }
}

@media only screen and (max-width: 768px) and (max-width: 1023px) {

  .title_content1 p {
    word-wrap: break-word;
    width: 90%;
    height: 100%;
    margin: auto;

  }

  .boxContent1 {
    width: 220px;
  }

  .box_image {
    transform: none !important;
  }
}

@media only screen and (max-width: 480px) {
  .boxContent1 {
    margin: 0 12px 0 0;
  }

  .boxContent1:first-child {
    margin-left: 6vw !important;
  }

  .title_content1 {
    font: 14px Helvetica;
  }
}

@media only screen and (max-width: 1024px) {
  .marginLeft {
    margin-left: 6vw;
  }
}