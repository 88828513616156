.customAccordion {
    margin-top: 7vh;
    height: 100%;
}

.content {
    font: 12px Helvetica;
    color: white;
    text-align: left;
    letter-spacing: 0;
}

.contentDetail {
    height: 100%;
    width: 80%;
    padding: 0 0 1rem 2rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid white;
}

.customLink {
    text-decoration: none;
    color: #fff;
}


.signInText {
    font: bold 12px Helvetica;
    color: white;
}


.isHide {
    display: none;
}

@media only screen and (min-width: 600px) {
    .customAccordion {
        margin-left: 10vh;
        width: 70vw;
    }

    .content {
        font: 14px Helvetica;
    }
}

@media only screen and (max-width: 599px) {
    .customAccordion {
        width: 100vw;
    }

}
