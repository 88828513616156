.tableColumn {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;
    margin-right: 1rem;
}

.tableColumn span{
    font: bold 14px Helvetica !important;
    margin: 0 auto;
}

.contentColumn span{
    /* font-weight: bold; */
}

.inputColumn {
    margin-top: .5rem;
    width: 100%;
    height: 30px;
    background-color: #fff;
    position: relative;
    padding: .2rem;
    border: 1px solid black;
}

.inputColumn input {
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
}

.inputColumn input:focus {
    outline: none;
}

.dataTable span{
    font: 14px Helvetica;
    color: black;
}
.customLink {
    text-decoration: none;
    color: #f16624;
}

.customLink p {
    font: 14px Helvetica;
    margin-bottom: 0;
}

.customLink:hover {
    text-decoration: none;
    color: #f16624;
}

.customLink:hover p{
    font: 15px Helvetica;
}

.contentNoData {
    height: 100%;
    width: 100%;
    padding: .5rem;
    display: flex;
    background: #f1f1f1;
}

.contentNoData span {
    margin: auto;
}

.contentExpand {
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.expand {
    display: flex;
    margin: .5rem 0;
}

.firstExp span {
    font-weight: bold;
}

.firstExp {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.secondExp {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: .5rem;
}