.top {
  display: float;
  /* height: 96px; */
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .topInside {
} */

.title {
  text-align: left;
  font: normal normal 900 36px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: inline-block;
  margin: 0;
  margin-right: 20px;
}

.subtitle {
  text-align: left;
  font: normal normal medium 16px/40px Helvetica;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  display: inline-block;
  margin: 0;
}

.blackCircleBtn {
  height: 24px;
  width: 24px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
}

.purpleCircleBtn {
  height: 24px;
  width: 24px;
  background: #223771 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
}

.blackCircleBtn,
.purpleCircleBtn i {
  color: white;
  font-size: 20px;
}

.allBrands {
  text-align: left;
  font: normal normal bold 16px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: inline-block;
}

.bottom {
  background-color: #f1f1f1;
}

.bottomTitle {
  text-align: left;
  font: normal normal bold 26px Helvetica;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin-top: 5rem;
  margin-bottom: 2rem;
  display: inline-block;
}

.cardContent {
  padding: 2rem;
  background-color: #223771;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: width 1s;
}

.cardContentHover {
  width: 304px;
  height: 272px;
  position: absolute;
  top: 0;
  overflow: hidden;
  transition: width 3s;
  /* background: white; */
  opacity: 0;
  display: flex;
  flex-direction: column;
}

.boxText {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: auto;
  padding: auto;
  width: 100%;
  height: 164px;
  padding: 2rem 2rem 0 2rem;
  background: white;
  transition: width 1s;
}

.boxTrans {
  width: 100%;
  height: 109px;
  background: #f1f1f199;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: auto;
  padding: auto;

  /* filter: blur(5px); */
}

.btnApply {
  width: 150px;
  height: 5vh;
  border-radius: 30px;
  background-color: #223771;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
  transition: width 1s;
}

.btnApply p {
  font: bold 16px Helvetica;
  color: white;
  margin: auto;
}

.customLink {
  text-decoration: none !important;
  display: flex;
}

.box:hover .cardContentHover {
  width: 304px;
  /* transition: inherit; */
  transition: all 0.5s ease-out;
  opacity: 1;
}

/* .box:hover .cardContent{
  background-color: #ffffff73;
  filter: blur(2px);
  opacity: 1;
} */

.box:hover .cardContent {
  background-color: white;
  opacity: 1;
}

.box:hover .boxTrans {
  backdrop-filter: blur(2px);
}

.box:hover .boxText {
  width: 70%;
  transition: all 0.5s ease-out;
}

.box:hover .cardContent p {
  color: black !important;
}

.box:hover .btnApply {
  width: 140px;
  transition: all 0.5s ease-out;
}

.box:hover p.box {
  color: black !important;
}

.titleExp,
.titleDate {
  font: normal 16px Helvetica;
  color: white;
  margin-bottom: 0;
  margin-top: 3rem;
}

.cardStatus {
  margin-bottom: 25px;
}

.box {
  width: 304px !important;
  height: 272px;
  /* background-color: #223771; */
  border-radius: 0;
  margin-right: 29px;
  /* margin-right: 16px; */
  cursor: url("../assets/drag.svg") 20 20, auto;
}

.box .text {
  font-family: Helvetica;
  letter-spacing: -0.18px;
  color: #f0f0f0;
  opacity: 1;
  margin: 0;
  text-align: left;
}

.font_16 {
  font-size: 16px;
}

.font_18 {
  font-size: 16px;
}

.titleCard {
  color: #f0f0f0;
  font: bold 16px Helvetica;
  text-align: left;
  /* margin-bottom: 83px; */
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.titleCard2 {
  color: black;
  font: bold 16px Helvetica;
  text-align: left;
  /* margin-bottom: 83px; */
  text-decoration: none;
}

.company {
  margin-top: 83px;
  margin-bottom: 30px;
}

.expiredDate {
  position: absolute;
  right: 32px;
  bottom: 44px;
}

.bottomContent {
  padding-bottom: 5rem;
}

.bottomContent a {
  text-decoration: none;
}

.cusT {
  max-width: 1520px;
}

.bottomContent {
  margin-left: 13.5rem;
}

.pad0 {
  padding: 0 !important;
}

@media only screen and (min-width: 2560px) {
  .cusT {
    max-width: 2090px !important;
  }

  .btnApply {
    height: 3vh;
  }

  .bottomContent {
    margin-left: 15.5rem;
  }

  .pad0 {
    padding: 0;
  }

  .box {
    width: 472px !important;
    height: 360px;
    margin-right: 107px;
  }

  .cardContentHover {
    width: 472px !important;
    height: 360px;
  }
}

@media only screen and (max-width: 1400px) {
  .cusT {
    max-width: 1062px;
  }
  .bottomContent {
    margin-left: 10.5rem;
    padding-bottom: 3.5rem;
  }

  .title {
    font: bold 18px Helvetica;
  }

  .bottomTitle {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
    font: bold 18px Helvetica;
  }

  .subtitle,
  .titleDate {
    font: normal 14px Helvetica;
  }

  .titleCard2,
  .titleCard {
    font: bold 14px Helvetica;
  }

  .btnApply p {
    font: normal 14px Helvetica;
  }

  .box {
    width: 272px !important;
    height: 243px;
  }

  .cardContentHover {
    width: 272px;
    height: 243px;
  }

  .boxText {
    width: auto;
    height: 60%;
  }
  .boxTrans {
    width: 272px;
    height: 40%;
  }
}

/*tablet only*/
@media only screen and (max-width: 768px) {
  .cusT {
    max-width: 672px;
    padding: 0;
  }

  .bottomContent {
    margin-left: 48px;
  }
}
