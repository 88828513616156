.customSearch {
	background-color: #fff;
	padding: 5px 5px 5px 25px !important;
	width: 20rem;
	font-size: 15px;
	border-radius: 20px;
	margin-bottom: 3rem;
}

.customSearch input[type='text'] {
	font-weight: bold;
	min-height: 30px;
	width: 100%;
	border: none;
	padding: 0px 0px 0px 0px;
	outline: none;
	border-radius: 20px;
}

.faCustom {
	position: absolute;
	top: 0.8rem;
	right: 1rem;
	color: darkgrey;
}

.frCkstm {
    background-color: rgba(0, 0, 0, .9) !important;
}

.footerSocialMedia {
    display: flex;
	position: absolute;
    /* right: -100px; */
	left: 41rem;
	margin-top: 3rem;
}
.footerSocialMedia a {
    font-size: unset !important;
    margin-right: 1rem;
    color: unset !important;
}
.footerSocialMedia svg {
    transform: scale(1.4);
}

@media only screen and (max-width: 1680px) {
	.itemList {
		max-width: 30%;
	}
	.footerSocialMedia {
		left: 31rem;
	}
}

@media only screen and (max-width: 1440px) {
	.itemList {
		max-width: 35%;
	}
	.footerSocialMedia {
		left: 26rem;
	}
}

@media only screen and (max-width: 1400px) {
	.footerSocialMedia {
	  left: 25rem;
	}
  }

@media only screen and (max-width: 1376px) {
	.customSearch {
		margin-bottom: 2rem;
	}

	.customSearch input[type='text'] {
		font-weight: bold;
		min-height: 20px;
		width: 100%;
		border: none;
		padding: 0px 0px 0px 0px;
		outline: none;
		border-radius: 10px;
	}

	.itemList {
		max-width: 30%;
	}
}

@media only screen and (max-width: 1024px) {
	.itemList {
		max-width: 80%;
	}
}

@media only screen and (max-width: 420px) {
	.customSearch {
		width: 15rem;
	}

	.customSearch input[type='text'] {
		min-height: 10px;
	}

	.itemList {
		max-width: 100%;
	}

	.customSearch:focus {
		position: sticky;
		top: 10%;
	}
}

@media only screen and (max-width: 360px) {
	.customSearch {
		width: 15rem;
	}

	.customSearch input[type='text'] {
		min-height: 10px;
	}

	.itemList {
		max-width: 100%;
	}
}
