.filterList {
    display: flex;
}

.filterContent {
    width: auto;
    height: 32px;
    background: #707070 0% 0% no-repeat padding-box;
    border-radius: 16px;
    display: flex !important;
    padding: 0 1rem;
    margin-right: .5rem;
    cursor: pointer;
}

.filterActive {
    background: #000000 0% 0% no-repeat padding-box;
}

.filterContent span {
    color: white;
    text-align: center;
    font: 14px/32px Helvetica;
    margin: auto;
}