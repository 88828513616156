.elementWithImage {
  width: "100vw";
  height: "100vh";
  background-image: url("./assets/img1.png");
  background-size: "cover";
}

.section-template {
  padding: 0 0 0 0;
  margin-left: 190px;
  /* width: 100vw; */
  /* height: 100vh; */
  display: block;
  /* background: rgb(194, 188, 188); */
  /* backdrop-filter: blur(10px);
    z-index: 99; */
}

.section-blur {
  height: auto;
  width: 100vw;
  backdrop-filter: blur(17px);
}

.card-template-front,
.card-template-front-side {
  width: 416px;
  height: 350px;
  background: transparent 0 no-repeat padding-box;
  /* opacity: 1; */
  display: flex !important;
}

.card-body-d {
  flex: 1 1 auto;
  padding: 2.6rem 2.6rem 0 2.6rem;
}

.card-footer-d {
  padding: 0 2.6rem 2.6rem 2.6rem !important;
}

.content-wrap {
  position: relative;
  width: 416px;
  height: 350px;
  background: rgba(34, 55, 113, 0.6);
  margin: 0 10px 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.card-template-back {
  width: 416px;
  height: 350px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(29, 106, 154, 0.72); */
  background: white;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  border: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.card-template-side {
  /* width: 35vw; */
  width: 416px;
  height: 350px;
  /* margin-left: 470px; */
  /* margin-left: 26.6rem; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(29, 106, 154, 0.72); */
  background: white;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  border: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.card-template-front span {
  color: white;
}

/* .content-wrap:hover .card-template-front-side {
    background: white;
} */

/* .content-wrap:hover .card-template-front-side p {
    color: black;
} */

.content-wrap:hover .card-template-back {
  visibility: visible;
  opacity: 1;
}

.content-wrap:hover .card-template-side {
  z-index: 1;
  visibility: visible;
  opacity: 1;
}

.card-title,
.card-title p {
  color: white;
  font-family: Helvetica;
  font-size: 18px;
  /* font-weight: bold; */
  text-transform: uppercase;
  opacity: 1 !important;
}

/* .card-footer p {
    color: white;
    font: 16px Helvetica;

} */

.card-content p {
  color: white;
  font-family: Helvetica;
  font-size: 18px;
  padding-top: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 12ch;
  text-align: justify;
}

.card-footer-back {
  position: relative;
  border: none;
  margin: auto;
  background: none;
}

.card-footer {
  position: relative;
  background-color: transparent !important;
  border: none;
}

.card-footer .left {
  float: left;
}

.card-footer .right {
  float: right;
}

/* .slick-slide {
    margin-left: 5px;
}
*/

/* .section-template .slick-list {
    margin-right: 6vw;
} */

.slick-track {
  display: flex;
}

.slick-arrow {
  display: none !important;
}

.btn-phenikaa {
  font: bold 12px/24px Helvetica;
  background-color: #223771;
  color: white;
  border: none;
  height: 2.4rem;
  width: 7rem;
  border-radius: 50px;
  margin: auto;
  padding: auto;
  text-transform: inherit;
}

.btn-phenikaa:hover {
  background-color: #f16624;
}

.section-sub-title {
  color: white;
}

.upcoming-event {
  background-color: white;
}

.event {
  background-color: #f16624;
}

@media (min-width: 1200px) {
  .container {
    /*max-width: 1540px;*/
  }
}

#section5 div.slick-slider {
  background: #e3e3e3;
}

[data-aos] {
  visibility: hidden;
}

[data-aos].animated {
  visibility: visible;
}

.padding-box {
  padding: 4rem 0 0 0;
}

/* .card-title-d {
    margin: 0 0 2rem 0;
} */

@media only screen and (min-width: 2560px) {
  .section-template {
    margin-left: 15rem;
  }
}

@media only screen and (min-width: 1920px) {
  .section-template {
    margin-left: 12rem;
  }
}

@media only screen and (max-width: 1440px) {
  .card-template-front,
  .card-template-front-side {
    width: 316px;
    height: 256px;
    background: #223870 0 0 no-repeat padding-box;
    opacity: 0.5;
    display: flex !important;
  }

  .content-wrap {
    width: 316px;
    height: 256px;
  }
  .card-template-back {
    width: 316px;
    height: 256px;
  }

  .card-template-side {
    height: 316px;
    width: 256px;
  }

  .card-content p {
    font-size: 16px;
  }

  .card-footer p {
    font-size: 14px;
  }
  .card-title {
    font-size: 16px;
  }
  .padding-box {
    padding: 1rem 0 0 0;
  }
}


