/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
}

/* SPECIFIC */
.sectionTitle {
  color: black;
  font: bold 26px Helvetica;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.no_src {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 2rem;
}

.sectionContentTitle {
  /* color: white; */
  font: bold 26px Helvetica;
  margin: 0 0 1rem 0;
}

.contentDetail p {
  font: 14px/24px Helvetica;
  margin-bottom: 0;
}

.contentImage {
  height: 200px;
}

.coverBlue {
  width: 416px;
  height: 350px;
  background: #223870 0 0 no-repeat padding-box;
  opacity: 0.5;
  position: absolute;
}

.contentImageD {
  width: 480px;
  height: 288px;
  object-fit: contain;
}

.imgOnBox {
  object-fit: cover;
  height: 276px;
  width: 100%;
}

.imgOnBoxD {
  object-fit: contain;
  height: 276px;
  width: 100%;
}

.imageContent {
  width: auto;
  height: 376px;
  object-fit: cover;
}

.imageContentD {
  width: auto;
  height: 376px;
  object-fit: contain;
}

.paddingBox {
  padding-bottom: 5rem;
}

.imageBack {
  width: 100vw;
  height: auto;
  background-size: cover;
}

/* .customLink:hover {
  text-decoration: none;
} */

.bxf1r {
  position: relative;
  width: 304px !important;
  height: 272px;
  /* background: #223771; */
  background: transparent;
  margin: 0 16px 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.ContentWrapp {
  position: relative;
  width: 304px;
  height: 272px;
  background: transparent;
  margin: 0 16px 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.shadowHover {
  width: 304px;
  height: 272px;
  box-shadow: 0px 16px 20px 7px #2b407b;
  opacity: 0;
}

.cardtemplateback {
  width: 304px;
  height: 272px;
  position: relative;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* background: rgba(29, 106, 154, 0.72); */
  background: #f1f1f1;
  color: #fff;
  /* visibility: hidden; */
  margin-right: 20px;
  opacity: 1;
  border: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.outerCircle2 {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 0rem 0 auto;
  align-self: left;
  opacity: 0;
  /*position: absolute;*/
  /* top: 1px; */
  /* left: 42%; */
}

.innerCircle2 {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.shdw0 {
  width: 304px;
  height: 272px;
  position: absolute;
  top: 0;
  opacity: 1;
  z-index: 1;
  background: hsl(224 54% 29% / 0.5);
  display: flex;
}

/* .ContentWrapp:hover .cardtemplateback {
  visibility: visible;
  opacity: 1;
} */

/* .ContentWrapp:hover .shadowHover {
  opacity: 1;
} */

.contentWrapping {
  padding: 0;
}

.customPadding {
  padding: 2rem 0 0 0;
}

/* .bxf1r:hover .shdw0 {
  opacity: 0;
  z-index: -1;
} */

.readMore {
  font-size: 14px Helvetica;
  color: white;
}

.date {
  font: 16px Helvetica;
  color: white;
  text-align: right;
}

.kpos {
  font: bold 16px Helvetica;
  line-height: 1.5rem;
  color: white;
  text-align: right;
  padding-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: right;
}

.cardFd1 {
  height: 50%;
  padding: 1.5rem;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* @media only screen and (max-width: 1400px) {
  .date {
    font: 14px Helvetica;
    color: white;
  }
} */

.cardFd1 {
  padding: 2rem;
}

.cardfooterback {
  position: relative;
  border: none;
  margin: auto;
  background: none;
}

.btnRead {
  background-color: #223771;
  height: 2.4rem;
  width: 7rem;
  border-radius: 50px;
  align-self: center;
}

.btnRead p {
  color: white;
  font: bold 12px Helvetica;
  padding: 0.8rem 0;
  margin: auto;
  text-align: center;
}

/* .btnRead:hover {
  background-color: #f16624;
} */

.cardfront,
.cardfrontside {
  position: absolute;
  width: 304px;
  height: 272px;
  background: transparent 0 no-repeat padding-box;
  /* opacity: 1; */
  display: flex !important;
  top: 0;
}

.bxf1r:hover .is_src {
  filter: blur(2px);
}

.bxf1r:hover .outerCircle2 {
  opacity: 1;
}

.cardcontent a {
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
}

/* .cardcontent p {
  color: white;
  font-family: Helvetica;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 12ch;
  line-height: 1.8rem;
  text-align: left;
  font-weight: bold;
} */

.isMarginLeft {
  margin-left: 10vw !important;
}

.is_src {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgOnBox {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.rdMoreP {
  font: 16px Helvetica;
  color: white;
  margin: 0;
  text-align: right;
}

.css01 {
  height: 50%;
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.css01 a {
  text-decoration: none;
}

@media only screen and (min-width: 1921px) and (max-width: 2560px) {
  .joinC {
    max-width: 2000px !important;
    margin-left: 235px;
  }

  .isMarginLeft {
    margin-left: 9vw !important;
  }

  .joinT {
    max-width: 2000px !important;
    margin-left: 235px;
  }



  /* .bxf1r,
  .shdw0,
  .cardtemplateback {
    width: 472px;
    height: 360px;
  } */

  .cardFd1 {
    padding: 3rem;
  }

  .cardFd {
    padding: 0 3rem 3rem 3rem;
  }

  .cardfooterback {
    margin: 5rem auto auto auto;
  }

  .imgOnBox {
    height: 245px;
  }

  .shadowHover {
    width: 472px;
    height: 360px;
  }
}


@media only screen and (max-width: 1680px) {
  .isMarginLeft {
    margin-left: 11vw !important;
  }

  .rdMoreP {
    margin-left: 1rem;
  }

  .kpos {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1440px) {
  .isMarginLeft {
    margin-left: 11vw !important;
  }

  .sectionTitle {
    font: var(--title-hd);
    margin-left: 169px;
    margin-left: 169px;
    font: bold 18px Helvetica;
  }

  .kpos {
    font: bold 14px Helvetica;
  }

  .rdMoreP {
    font: 14px Helvetica;
  }

  /* .cardcontent p {
    font-size: 14px;
    line-height: 1.5rem;
    padding-top: 0;
    margin-bottom: 0;
  } */

  .joinT {
    max-width: 1062px;
    margin-left: 169px;
  }

  .sectionContentTitle {
    font: 18px/32px Helvetica;
    margin: 0 0 0.5rem 190px;
  }

  .imgOnBox {
    height: 156px;
  }

  .contentImage {
    height: 156px;
  }

  .imageContent {
    height: 276px;
    width: 316px;
  }

  .shdw0 {
    height: 243px;
  }

  .rdMoreP {
    font: 14px Helvetica;
  }

  /* .shdw0,
  .cardtemplateback {
    width: 272px;
    height: 243px;
  } */

  .bxf1r {
    width: 272px;
    height: 243px;
    margin: 0 19px 0 0;
  }

  .cardFd1 {
    padding: 1.5rem;
  }

  .cardFd {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  .shadowHover {
    width: 272px;
    height: 243px;
  }
}

/*ipad pro only*/
@media only screen and (max-width: 1024px) {
  .isMarginLeft {
    margin-left: 6vw !important;
  }

  .kpos {
    font: bold 14px Helvetica;
  }

  .rdMoreP {
    font: 14px Helvetica;
  }

  .joinC {
    max-width: 100vw !important;
    margin: 0;
  }

  .sectionTitle {
    margin-left: 48px;
  }

  .bxf1r {
    margin: 0 2rem 0 0;
  }

  .joinT {
    margin: 0 0 0 48px;
    justify-content: space-between;
  }

  .cardFd {
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  .css01 {
    justify-content: flex-end;
    height: 100%;
  }

  .cardFd a {
    text-decoration: none;
    /* justify-content: flex-end; */
    display: flex;
    /* margin: auto; */
    width: 50%;
    text-align: left;
    color: white;
  }

  .rdM01 {
    margin: auto auto 0 0;
  }

  .date {
    justify-content: end;
    display: flex;
    margin: auto auto 0 auto;
  }

  .cardFd1 {
    height: 50%;
  }

  .ct0 {
    width: 50%;
  }

  .date {
    font: 14px Helvetica;
    color: white;
    width: 50%;
    text-align: right !important;
    margin: auto 0 auto auto;
  }
}

/*tablet only*/
@media only screen and (max-width: 768px) {
  .isMarginLeft {
    margin-left: 6vw !important;
  }

  .joinC {
    max-width: 672px !important;
    margin: 0;
  }

  .kpos {
    font: bold 14px Helvetica;
  }

  .rdMoreP {
    font: 14px Helvetica;
  }

  .ct0 {
    width: 50%;
  }

  .sectionTitle {
    margin-left: 48px;
  }

  .bxf1r {
    margin: 0 2rem 0 0;
  }

  .joinT {
    margin: 0 0 0 48px;
    justify-content: space-between;
  }

  .cardFd {
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  .rdM01 {
    margin: auto auto 0 0;
  }

  .date {
    justify-content: end;
    display: flex;
    margin: auto 0 0 auto;
  }

  .cardFd a {
    text-decoration: none;
    /* justify-content: flex-end; */
    display: flex;
    /* margin: auto; */
    width: 50%;
    text-align: left;
    color: white;
  }

  .date {
    font: 14px Helvetica;
    color: white;
    width: 50%;
    text-align: right !important;
    margin: auto 0 auto auto;
  }

  .cardFd1 {
    height: 50%;
  }

  .css01 {
    justify-content: flex-end;
    height: 100%;
  }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
  .sectionContentTitle {
    margin-left: 1rem;
  }

  .kpos {
    font: bold 14px Helvetica;
  }

  .rdMoreP {
    font: 14px Helvetica;
  }

  .sectionTitle {
    font: Bold 26px Helvetica;
    margin: 0 0 0 1rem;
    text-align: left;
  }

  .sectionContentTitle {
    font: Bold 14px Helvetica;
  }
}

@media only screen and (max-width: 360px) {
  .sectionContentTitle {
    margin-left: 1rem;
  }

  .sectionTitle {
    font: Bold 26px Helvetica;
    margin: 0 0 0 1rem;
    text-align: left;
  }

  .sectionContentTitle {
    font: Bold 14px Helvetica;
  }
}