.mainDev {
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  z-index: 1;
}

.flexContent {
  width: 1440px;
  height: 440px;
  margin: auto;
  top: 40%;
}

p.title, h2.title {
  font: 36px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  margin-bottom: 2rem;
}

.contentInBox {
  position: relative;
  top: -35.4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.contentInBox p, .contentInBox h1 {
  font: 48px Helvetica;
  font-weight: bold;
  letter-spacing: 0;
}

.textInside p , .textInside h1{
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font: bold 46px Helvetica;
  text-align: center;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: header-anim;
}

@keyframes header-anim {

  0%,
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }

  30%,
  70% {
    transform: translateX(0);
    opacity: 1;
  }

  70%,
  99% {
    opacity: 1;
  }

  100%,
  99% {
    opacity: 0;
  }
}

.boxImage {
  width: 100vw;
  height: 65vh;
  position: relative;
}

.imgContent {
  width: 100%;
  height: 65vh;
  object-fit: cover;
}

.offsideBox {
  width: 704px;
  height: 600px;
  background-color: #223771;
  position: absolute;
  top: 40%;
  left: 50%;
}

.offsideBox2 {
  width: 704px;
  height: 600px;
  background-color: #f1f1f1;
  position: relative;
  right: 2rem;
  bottom: 2rem;
}

.mainBox {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0;
}

.insideBox2 {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.textSub {
  font: bold 18px Helvetica !important;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.texSub ul>li {
  margin-bottom: 1.5rem;
  text-align: justify;
}

.text {
  text-align: justify;
  font: normal 16px/1.8rem Helvetica;
  color: #000000;
}

p.text>ul {
  margin-top: 1rem;
  font: normal 16px/1.8rem Helvetica;
  text-align: justify;
}

p.mainTitle {
  font: normal normal bold 20px/56px Helvetica;
  color: #000000;
  text-transform: uppercase;
}

.padding {
  padding-top: 5rem;
}

.chart {
  width: 50%;
  height: 100%;
  position: relative;
}

.boxChart {
  width: 100%;
  height: 100%;
  background-color: white;
}

.titleChart {
  text-align: left;
  font: bold 18px/1.8px Helvetica;
  color: black;
  margin-bottom: 2rem;
}

.insideBox {
  line-height: 20px;
}

.p_0_underline {
  display: block;
  height: 2px;
  width: 400px;
  margin-left: auto;
  margin-right: 38%;
  background-color: black;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-iteration-count: alternate;
  animation-name: underline-anim;
}

@keyframes underline-anim {

  0%,
  10% {
    width: 0;
    transform: translateX(-500px);
  }

  10%,
  20% {
    width: 500px;
    transform: translateX(0);
  }

  100%,
  30% {
    transform: translateX(0);
    width: 0;
  }
}

.navBack {
  position: relative;
  padding: 2rem 0 5rem;
  width: 30%;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.textBack {
  font: normal 16px/32px Helvetica;
  color: black;
  margin: 0;
}

.customMargin {
  margin: auto 1rem auto auto;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.customLink {
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

.wrapGallery {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  justify-content: center;
}

.boxCulture {
  width: 300px;
  height: 360px;
  position: relative;
  justify-content: center;
}

.boxCulture img {
  position: relative;
  width: 100%;
  height: 360px;
  object-fit: cover;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.boxCulture figure {
  overflow: hidden;
  height: 100%;
}

.boxCulture .boxMask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 360px;
}

.boxCulture:hover .boxMask {
  opacity: 0;
}

.boxCulture:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.boxC {
  margin: 5rem 12.5rem;
  padding-bottom: 5rem;
}

@keyframes animate {
  0% {
    transform: translateX(500);
  }

  100% {
    transform: translateX(-300%);
  }
}

.boxGallery {
  width: 100px;
  height: 374px;
  transition: width 2s;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
}

.boxGallery figure {
  margin: 0;
  overflow: hidden;
}

.boxGallery figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.activeImg {
  width: 720px;
}

.boxGallery:first-child {
  border-left: 7px solid rgba(214, 214, 214, 0.6);
}

.boxGallery:last-child {
  border-right: 7px solid rgba(214, 214, 214, 0.6);
}

.boxGallery:not(:first-child),
.boxGallery:not(:last-child) {
  border-bottom: 7px solid rgba(214, 214, 214, 0.6);
  border-top: 7px solid rgba(214, 214, 214, 0.6);
  border-left: 1px solid rgba(214, 214, 214, 0.6);
}


.dflex {
  display: flex;
  flex-direction: row;
}

.boxRightx {
  width: 50%;
  height: 100%;
  position: relative;
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2000px !important;
    margin: 5rem 17.5rem;
  }

  p.text {
    line-height: 2rem;
  }

  .textInside p , .textInside h1{
    font: bold 70px Helvetica;
    color: black;
  }

  .p_0_underline {
    background-color: black;
  }

  @keyframes underline-anim {

    0%,
    10% {
      width: 0;
      transform: translateX(-800px);
    }

    10%,
    20% {
      width: 800px;
      transform: translateX(0);
    }

    100%,
    30% {
      transform: translateX(0);
      width: 0;
    }
  }
}

@media only screen and (max-width: 1680px) {
  .mainDev {
    width: 100vw;
  }

  .boxImage {
    width: 100%;
  }

  p.title, h2.title {
    font: bold 28px Helvetica;
  }

  .contentInBox p, .contentInBox h1 {
    font: bold 36px Helvetica;
    -webkit-text-stroke: 0.8px rgb(5 54 144 / 70%);
  }

  .p_0_underline {
    display: block;
    height: 2px;
    width: 430px;
    margin-right: 34%;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-anim2;
  }

  @keyframes underline-anim2 {

    0%,
    10% {
      width: 0;
      transform: translateX(-350px);
    }

    10%,
    20% {
      width: 430px;
      transform: translateX(0);
    }

    100%,
    30% {
      transform: translateX(0);
      width: 0;
    }
  }

  .titleChart {
    padding: 0;
    font-size: bold 18px Helvetica;
  }

  .boxLeftx {
    width: 50%;
    height: 100%;
    position: relative;
  }

  .boxRightx {
    width: 50%;
    height: 100%;
    position: relative;
  }

  .boxChart {
    width: 470px;
    height: 300px;
  }

  .offsideBox {
    width: 50%;
    height: 100%;
  }

  .offsideBox2 {
    width: 513px;
    height: 458px;
  }

  p.text {
    line-height: 18px;
  }

  p.mainTitle {
    font: bold 18px Helvetica;
  }

  .insideBox p {
    font: 16px Helvetica;
    line-height: 1.8rem;
  }

  .boxC {
    max-width: 78vw !important;
    margin: 3.5rem auto;
    padding-bottom: 3.5rem;
  }

  .textSub {
    font: normal 16px Helvetica !important;
    line-height: 1.8rem;
  }

  .boxCulture {
    width: 213px;
    height: 260px;
  }

  .boxCulture img {
    width: 213px;
    height: 260px;
  }

  p.text>ul {
    margin-top: 1rem;
    font: normal 16px Helvetica;
    line-height: 1.8rem;
    text-align: justify;
  }
}

@media only screen and (max-width: 1440px) {
  .mainDev {
    width: 100vw;
  }

  .boxImage {
    width: 100%;
  }

  p.title, h2.title {
    font: bold 28px Helvetica;
  }

  .contentInBox p, .contentInBox h1 {
    font: bold 36px Helvetica;
    -webkit-text-stroke: 0.8px rgb(5 54 144 / 70%);
  }

  .p_0_underline {
    display: block;
    height: 2px;
    width: 430px;
    margin-right: 34%;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-anim2;
  }

  @keyframes underline-anim2 {

    0%,
    10% {
      width: 0;
      transform: translateX(-350px);
    }

    10%,
    20% {
      width: 430px;
      transform: translateX(0);
    }

    100%,
    30% {
      transform: translateX(0);
      width: 0;
    }
  }

  .titleChart {
    padding: 0;
    font-size: bold 18px Helvetica;
  }

  .boxLeftx {
    width: 50%;
    height: 100%;
    position: relative;
  }

  .boxRightx {
    width: 50%;
    height: 100%;
    position: relative;
  }

  .boxChart {
    width: 470px;
    height: 300px;
  }

  .offsideBox {
    width: 50%;
    height: 100%;
  }

  .offsideBox2 {
    width: 513px;
    height: 458px;
  }

  p.text {
    line-height: 18px;
  }

  p.mainTitle {
    font: bold 18px Helvetica;
  }

  .insideBox p {
    font: 14px Helvetica;
  }

  .boxC {
    max-width: 1200px !important;
    margin: 3.5rem auto;
    padding-bottom: 3.5rem;
  }

  .textSub {
    font: normal 14px Helvetica !important;
  }

  .boxCulture {
    width: 213px;
    height: 260px;
  }

  .boxCulture img {
    width: 213px;
    height: 260px;
  }

  p.text>ul {
    margin-top: 1rem;
    font: normal 14px/1.8rem Helvetica;
    text-align: justify;
  }

    .textBack {
        font: normal 14px/32px Helvetica;
        color: black;
        margin: 0;
    }

}

@media only screen and (max-width: 1366px) {
  .boxC {
    max-width: 1062px !important;
    margin: 3.5rem auto;
    padding-bottom: 3.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .boxC {
    max-width: 88% !important;
    margin: 3.5rem 48px 0 48px;
    padding-bottom: 3.5rem;
  }

  .boxGallery figure {
    width: 100% !important;
  }




  .boxLeftx {
    width: 100%;
    padding: 0;
  }

  p.title, h2.title {
    font: bold 18px Helvetica;
  }

  .boxRightx {
    width: 100%;
    padding-left: 0em;
  }

  .text {
    font: normal 14px Helvetica;
    line-height: 28px;
  }

  p.text>ul {
    font: normal 14px Helvetica;
    line-height: 28px;
  }

  .titleChart {
    font: bold 14px/1.8px Helvetica;
    margin-bottom: 1rem;
    text-align: center;
  }

  .contentInBox p, .contentInBox h1 {
    font: 28px Helvetica;
    font-weight: bold;
  }

  .p_0_underline {
    margin-right: 25%;
  }
}

@media only screen and (max-width: 768px) {
  .boxC {
    max-width: 88% !important;
    margin: 3.5rem 6% 0 6%;
    padding: 0 0 3.5rem 0;
  }

  .boxGallery figure {
    width: 100% !important;
  }


  .dflex {
    display: flex;
    flex-direction: column !important;
  }

  .boxLeftx {
    width: 100%;
    padding: 0;
  }

  p.title, h2.title {
    font: bold 18px Helvetica;
  }

  .boxRightx {
    width: 100%;
  }

  .text {
    font: normal 14px Helvetica;
    line-height: 28px;
  }

  p.text>ul {
    font: normal 14px Helvetica;
    line-height: 28px;
  }

  .titleChart {
    font: bold 14px/1.8px Helvetica;
    margin-bottom: 1rem;
    text-align: center;
  }

  .contentInBox p, .contentInBox h1 {
    font: 28px Helvetica;
    font-weight: bold;
  }

  .p_0_underline {
    margin-right: 25%;
  }

  .boxChart {
    width: 400px;
    height: 300px;
    padding-left: 1rem;
  }

}