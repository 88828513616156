#mobile-industry>.slick-slider>.slick-dots {
    text-align: center;
    margin-bottom: -2vh;
}

#mobile-industry>.slick-slider>.slick-dots>ul {
    padding: 0;
}

#mobile-industry>.slick-slider>.slick-dots>ul>li {
    width: 10px;
    margin: 0 3px;
}

#mobile-industry>.slick-slider>.slick-dots>ul>li.slick-active div {
    background-color: #f16b26;
}