/* GENERAL */
:root {
    --title-hd: bold 28px Helvetica;
    --subtitle-hd: bold 18px Helvetica;
    --p-hd: normal 14px Helvetica;
}

/* SPECIFIC */
.customContainer {
    padding-top: 5rem;
}

.Section4 {
    width: auto;
    /* height: 880px; */
    height: 100%;
    background-color: #f1f1f1;
    position: relative;
    /* padding-bottom: 5rem; */
    /* padding-top: 5rem; */
}

.boxTitle {
    align-self: center;
    top: 14%;
}

p.title,
h2.title {
    font: 36px Helvetica;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 2rem;
}

.textContainer {
    padding-top: 5rem;
}

.boxC,
.textContainer {
    max-width: 1540px;
}

.boxText {
    align-self: center;
    top: 25%;
}

p.text {
    font: 16px Helvetica;
    line-height: 1.8;
    margin-bottom: 2rem;
}

.flexContent {
    margin-top: 8%;
}

.miniBox {
    height: 203px;
    width: 480px;
    padding: 2rem 0 2rem 4rem;
}

p.read {
    font: 28px/24px Helvetica;
    font-weight: bold;
    letter-spacing: 0;
}

p.contentLink {
    font: 16px/24px Helvetica;
    margin: auto 0;

    color: black;
    cursor: pointer;
}

.outerCircle {
    padding: 5px;

    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0;
    opacity: 1;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    opacity: 1;
}

p.mainRead {
    font: 30px Helvetica;
    font-weight: bold;
    color: #f16624;
}

.link {
    color: transparent !important;
    cursor: pointer;
}

.boxBottom {
    width: 100%;
    height: 45vh;

    display: flex;
    flex-direction: row;
}

.boxOne {
    width: 40%;
    height: auto;

}

.boxTwo {
    width: 60%;
    height: auto;

}

.imgImg {
    width: 100%;
    height: 45vh;
    object-fit: cover;
}

.ptitle {
    font: bold 18px Helvetica;
    color: black;
    text-align: left;
    padding: 2rem;
}

.boxLinks {
    position: relative;
    margin: auto 0;
    text-align: left;
}

.outerCircle2 {
    padding: 5px;

    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    margin: 0 1rem 0 auto;
    align-self: left;
    opacity: 0;
    position: absolute;

}

.contentLink {
    cursor: pointer;
    color: white;
    font: normal 16px Helvetica;
}

.innerCircle2 {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.boxLinks:hover .outerCircle2 {
    opacity: 1;
}

.boxLinks:hover .contentLink {
    margin-left: 1.5rem;
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2090px !important;
    }

    .textContainer {
        max-width: 2090px !important;
    }

    .boxC {
        min-width: 2090px;
    }

    .flexContent {
        margin-top: 2%;
    }

    p.title,
    h2.title {
        font: bold 36px Helvetica;
    }
}

@media only screen and (max-width: 1680px) {

    p.title,
    h2.title {
        font: bold 28px Helvetica;
    }

    p.text {
        font: normal 14px Helvetica;
        line-height: 1.8;
        text-align: justify;
    }

    .miniBox {
        height: 268px;
        width: 400px;
    }

    p.read {
        font: bold 23px/24px Helvetica;
    }

    .textContainer {
        max-width: 76%;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }

    p.contentLink {
        font: normal 14px Helvetica;
    }
}

/*
@media only screen and (max-width: 1680px) {
    p.text {
        font: normal 14px Helvetica;
        line-height: 1.8;
    }

    p.contentLink {
        font: normal 14px Helvetica;
    }
}
*/

@media only screen and (max-width: 1024px) {
    .boxC {
        max-width: 88vw;
    }

    .textContainer {
        max-width: 88vw;
        padding-left: 0;
        padding-right: 0;
    }

    .boxTitle,
    .boxText,
    .link {
        max-width: 88vw;
    }

    .boxLinks .outerCircle2 {
        opacity: 1;
    }

    .boxLinks .contentLink {
        margin-left: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {

    p.title,
    h2.title {
        font: bold 18px Helvetica !important;
    }

    .boxC {
        max-width: 672px !important;
        height: 50vh;
    }
}

@media only screen and (max-width: 480px) {


    .Section4 {
        padding-top: 3.5rem;
    }

    .boxC {
        max-width: 88vw !important;
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .boxTop,
    .boxBottom {
        position: relative;
        /* height: 100%; */
        width: 100%;
    }

    .boxTop {
        margin-bottom: 2vh;
    }

    .boxLinks .outerCircle2 {
        opacity: 1;
        position: relative;
        margin: 0 .5rem 0 auto;
    }


    .boxLinks {
        display: flex;
        flex-direction: row;
    }

    .boxLinks .contentLink {
        margin-left: 0;
    }

    .contentLink {
        display: flex;
        align-items: center;
    }

}