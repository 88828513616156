.modalTitle {
  text-align: left;
  font: 18px/32px Helvetica;
  color: #000000;
  margin-right: 0.5rem;
}

.modalContent {
  padding: 1rem;
}

.content h2 {
  color: #980000;
  font: 12px Helvetica;
}

.mB0x {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.errorActive {
  text-align: left;
  opacity: 1 !important;
}

.boxContent {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

.boxContent2 {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

.wrap1 p {
  color: #980000;
  font: 12px Helvetica;
}

.wrap2 p {
  color: #980000;
  font: 12px Helvetica;
}

.boxContent2 p {
  color: #980000;
  font: 12px Helvetica;
}

.boxContent:not(:first-child) {
  margin-top: 1rem;
}

.titleContent {
  text-align: left;
  font: 12px/24px Helvetica;
  color: #00599d;
}

.inputModal {
  border: 0;
  text-align: left;
  font: 16px/32px Helvetica;
  color: #000000;
  margin-top: 0.5rem;
}

.inputModal:focus {
  border: none;
  outline: unset;
}

.option {
  text-align: left;
  font: 12px/16px Helvetica;
  color: #00599d;
  margin: auto 0.2rem auto 0;
}

.optionTerms {
  text-align: left;
  font: 12px Helvetica;
  color: #00599d;
  margin: auto 0.2rem auto 0;
  line-height: 1.5rem;
}

.optionTitle {
  margin: 1rem 0 0 0;
}

.note {
  text-align: left;
  font: 12px/16px Helvetica;
  color: #000000;
}

.contentLink {
  text-align: left;
  font: 12px/24px Helvetica;
  color: #00599d;
  margin: auto 0;
  cursor: pointer;
  text-decoration: underline;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.validError {
  opacity: 0;
}

.contentAction {
  display: flex;
  margin-top: 1rem;
  margin-left: auto;
}

.boxAction {
  width: auto;
  height: 32px;
  background: #00599d 0% 0% no-repeat padding-box;
  padding: 0 1rem;
  display: flex;
  border-radius: 16px;
  margin: auto 0 auto 0.5rem;
  cursor: pointer;
}

.boxRight {
  margin: auto 0 auto auto;
}

.boxAction span {
  font: 16px/8px Helvetica;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
}

.or {
  text-align: center;
  display: flex;
  width: 100%;
  height: 20px;
}

.or span {
  margin: 0 0.2rem;
  text-align: left;
  font: 16px/24px Helvetica;
  color: #696969;
}

.orLine {
  height: 1px;
  width: 100%;
  border-top: 1px solid #f1f1f1;
  margin-top: 10px;
}

.default {
  background: #dddddd 0% 0% no-repeat padding-box;
}

.modalAction {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.btnFull {
  width: 100%;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 24px;
  display: flex;
}

.btnFull span {
  margin: auto;
  text-align: center;
  font: 16px/8px Helvetica;
  color: #000000;
}

.btnDefault {
  background: #00599d 0% 0% no-repeat padding-box;
}

.btnDefault span {
  color: #ffffff;
}

.btnFull:not(:first-child) {
  margin-top: 0.5rem;
}

/* register */

.contentWrapp {
  display: flex;
}

.contentWrapp .boxContent {
  margin-top: 1rem;
  width: 100%;
}

.contentDate {
  display: flex;
  justify-content: space-between;
  width: 55%;
}

.contentDate .boxContent:first-child {
  width: 20%;
}

.contentDate .boxContent:nth-child(2) {
  width: 25%;
  margin-top: 0;
}

.contentDate .boxContent:nth-child(3) {
  width: 20%;
  margin-top: 0;
}

.contentDate .boxContent:last-child {
  width: 20%;
  margin-top: 0;
  margin-left: auto;
}

.contentDate .boxContent:nth-child(2n) {
  padding: 0 1rem;
}

.modalSelect {
  margin-top: 0.8rem;
  border: 0;
}

.modalSelect:focus {
  border: none;
  outline: none;
}

/* custom select */
.contentDropdown {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}

.contentDropdown>ul>li>ul {
  max-height: 20vh;
  overflow: scroll;
}

.contentDropdown ul {
  margin: auto !important;
}

.dropDownLang {
  position: relative;
}

.dropDownBtn {
  color: #707070;
  font-size: 16px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: 0 0;
}

.dropdownMenu {
  position: absolute;
  width: 100%;
  min-width: 100%;
  background: #f8f8f8;
  top: 25%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: visible;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, 0.08);
  box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, 0.08);

  width: 16rem;
  left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.slYear {
  display: inline;
  position: absolute;
  bottom: 0px;
  right: 2vw;
  background: transparent;
  /* box-shadow: 0px 3px 6px #00000029; */
  opacity: 1;
  border-radius: 50%;
}

.wrap1 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.wrap2 {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 1rem;
}

.isEmpty {
  opacity: 1;
}

.slYear ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.slYear li {
  cursor: pointer;
  /* padding-bottom: 10px; */
}

.slYear li ul {
  display: none;
}

.slYear li:hover ul {
  position: absolute;
  top: 29px;
  right: -15px;
  display: block;
  background: #fff;
  width: 100px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slYear li:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}

.slYear li:hover .triangle:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slYear li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #000;
  display: flex;
  flex-direction: column;
}

.slYear li ul li:last-of-type {
  padding-bottom: 15px;
}

.slYear li ul li span {
  padding-left: 5px;
}

.slYear li ul li span:hover,
.slYear li ul li span.active {
  color: #f16b26;
}

@media only screen and (max-width: 480px) {
  .contentDate {
    width: unset;
  }
}