.AuSec5 .slick-dots {
    bottom: 10px;
}

.AuSec5 .slick-dots li.slick-active button:before {
    background-color: #f16b26;
    opacity: 1;
}

.AuSec5 li button {
    width: 10px;
    height: 10px;
}

.AuSec5 li button:before {
    width: 10px;
    height: 10px;
    font-size: 0;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid rgb(214, 214, 214);
    opacity: 1;
}

.AuSec5 li:last-of-type {
    width: 10px;
}