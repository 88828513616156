li.slick-active div {
  background-color: white;
}

.section-slider {
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: transparent;
}

.section-fullview {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  display: flex;
}

.slider-box {
  position: absolute;
  z-index: 1;
  top: 25%;
}

.content-slider {
  align-self: center;
  position: absolute;
  top: 160px;
  margin-left: 12.7rem;
}

.content-slider p,
.content-slider h1 {
  font-family: "Helvetica";
  font-weight: bold;
  color: white;
}

.title-slider {
  font: bold 80px/74px Helvetica;
}

.title-slider-desc {
  font: bold 30px/36px Helvetica;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: right;
  right: 4rem;
}

.slick-dots li {
  width: 10px;
}

@media only screen and (max-width: 2560px) {
  .content-slider {
    top: 300px;
    margin-left: 17rem;
  }
}

@media only screen and (max-width: 1400px) {
  .content-slider {
    margin-left: 11rem;
    top: 143px !important;
  }

  .title-slider {
    font: bold 50px Helvetica;
    margin-bottom: 0;
  }

  .title-slider-desc {
    font: bold 19px Helvetica;
  }
}

@media only screen and (max-width: 420px) {
  .title-slider {
    font: bold 38px Helvetica;
  }

  .title-slider-desc {
    font: bold 12px Helvetica;
  }
  .content-slider {
    padding: 2rem;
  }
  .slick-dots {
    /* display: none; */
  }
  .slider-box {
    top: 40%;
  }
}

@media only screen and (max-width: 360px) {
  .title-slider {
    font: bold 38px Helvetica;
  }

  .title-slider-desc {
    font: bold 12px Helvetica;
  }
  .content-slider {
    padding: 2rem;
  }
  /* .slick-dots {
    display: none;
  } */
  .slider-box {
    top: 40%;
  }
}
