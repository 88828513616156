.titleAccordion {
    font: 16px/32px Helvetica;
    color: white;
    text-transform: uppercase;
    margin: auto 0;
}

@media only screen and (min-width: 600px) {
    .titleAccordion {
        font: 18px/32px Helvetica;
        color: white;
        text-transform: uppercase;
        margin: auto 0;
    }
    .mobileMenu {
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 480px) {
    .mobileMenu {
        margin-bottom: .5rem;
    }
}