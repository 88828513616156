.modalTitle {
    text-align: left;
    font: 18px/32px Helvetica;
    color: #000000;
    margin-right: .5rem;
}

.modalContent {
    padding: 1rem;
}

.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contentWrapp {
    display: flex;
}

.contentWrapp .boxContent {
    margin: 1rem .5rem 0 .5rem;
    width: 50%;
}

.boxContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
    position: relative;
}

.boxContent:not(:first-child) {
    margin-top: 1rem;
}

.titleContent {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #B0B0B0;
}

.circleLink {
    width: 16px;
    height: 16px;
    background: #223771 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin: auto;
    right: 0;
    display: flex;
    position: absolute;
}

.circleLink img {
    height: 60%;
    width: 60%;
    margin: auto;
}

.circle {
    width: 16px;
    height: 16px;
    background: #223771 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin: auto 1rem auto 0;
    display: flex;
}

.circle img {
    height: 60%;
    width: 60%;
    margin: auto;
}

.btnFullAction {
    width: 100%;
    height: 49px;
    background: #D4D4D4 0% 0% no-repeat padding-box;
    border-radius: 3px;
    display: flex;
}

.contentBtn {
    margin: auto;
    display: flex;
}

.inputModal {
    border: 0;
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000000;
    margin-top: .5rem;
}

.inputModal:focus {
    border: none;
    outline: unset;
}

.contentLink,
.contentFile {
    width: 100%;
    padding: 1rem 0;
}

.boxLink {
    display: flex;
    position: relative;
}

.boxLink:not(:first-child) {
    margin-top: .5rem;
}

.boxLink input {
    border: none;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #b0b0b0;
}

.boxLink input:focus {
    outline: none;
}

.modalAction {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.boxAction {
    width: auto;
    height: 32px;
    background: #00599D 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 16px;
    margin: auto 0 auto .5rem;
    cursor: pointer;
}

.boxRight {
    margin: auto 0 auto auto;
}

.boxAction span {
    font: 16px/8px Helvetica;
    color: #ffffff;
    margin: auto;
    cursor: pointer;
}

.option {
    text-align: left;
    font: 12px/16px Helvetica;
    color: #DDDDDD;
    margin: auto .2rem auto 0;
}

.note {
    text-align: left;
    font: 12px/16px Helvetica;
    color: #000000;
}

.contentWrapp .boxContent {
    margin-top: 1rem;
    width: 50%;
}

.boxResume {
    display: flex;
}

.boxResume:not(:first-child) {
    margin-top: 1rem;
}

.boxPreview {
    width: 64px;
    height: 64px;
    background: #E5E5E5 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 4px solid #FFFFFF;
    border-radius: 3px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
}

.boxPreview img {
    height: 100%;
    width: 100%;
}

.boxPreview div {
    margin: auto;
}

.boxPreview span {
    margin: auto;
    font: 14px Helvetica;
}

.boxPreview .customLink {
    margin: auto;
    color: #000000;
    font: 14px Helvetica;
}

.boxPreview .customLink>div>img {
    object-fit: cover;
}

.boxDetailPreview {
    width: auto;
    height: 64px;
    display: flex;
    flex-direction: column;
}

.boxDetailPreview div {
    margin: auto 0 0 0;
}

.boxDetailPreview div span {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #223771;
    margin-right: .5rem;
}

.boxRemovePortofolio {
    position: absolute;
    top: -20%;
    right: -15%;
}

.boxPortofolio {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 480px) {
    .contentWrapp {
      flex-direction: column;
    }

    .contentWrapp .boxContent {
        width: unset;
    }
  }
  