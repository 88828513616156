/* GENERAL */
:root {
    --title-hd: bold 28px Helvetica;
    --subtitle-hd: bold 18px Helvetica;
    --p-hd: normal 14px Helvetica;
    --container-hd: 1062px !important;
    --padding-section-hd: 3.5rem 0;

    --title-fhd: bold 36px Helvetica;
    --subtitle-fhd: bold 26px Helvetica;
    --p-fhd: normal 16px Helvetica;
    --container-fhd: 1540px !important;
    --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */
.customContainer {
    padding-left: 0;
    padding-right: 0;
}

.topContainer {
    margin-bottom: 2rem;
    padding-top: 5rem;
}

.topTitle {
    text-align: left;
    font: var(--title-fhd);
    color: #000000;
    opacity: 1;
    padding-right: 25px;
    text-transform: uppercase;
}

.topHorizontalLine {
    margin-top: 2rem;
    display: flex;
    height: 2px;
    width: 100%;
    background-color: black;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.posRelative {
    background-color: white;
    /* padding-bottom: 120px; */
}

.boxPreview {
    margin: 0 0 2rem 0;
    position: relative;
    background-color: #d4d4d4;
    padding: 1rem;
    width: 100%;
    height: 100%;
    /* transition: height 2s; */
    animation: transitionHeight 1s ease-in-out;
}

/* .boxPreview .active {
    animation: transitionHeight 1s;
} */

.boxPreviews .inActive {
    animation: transitionHeightRev 1s;
}

@-webkit-keyframes transitionHeight {
    0% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        height: 0;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        height: 100%;
        opacity: 1;
    }
}

@keyframes transitionHeight {
    0% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        height: 0;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        height: 100%;
        opacity: 1;
    }
}

@-webkit-keyframes transitionHeightRev {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        height: 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        height: 0;
        opacity: 0;
    }
}

@keyframes transitionHeightRev {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        height: 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        height: 0;
        opacity: 0;
    }
}

.boxPreview p {
    font: var(--p-fhd);
    line-height: 1.8;
    margin-bottom: 1.5rem;
}

.boxPreview ul>li {
    font: var(--p-fhd);
    line-height: 1.8;
    margin-bottom: 0;
}

.navBack {
    position: relative;
    /* margin-left: -200px; */
    /* margin-top: -45px; */
    padding: 2rem 0 5rem 0;
    width: 30%;
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.textBack {
    font: normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.wrapGallery {
    display: flex;
    padding: 2rem 0;
}

.boxCulture {
    width: 300px;
    height: 360px;
    position: relative;
    justify-content: center;
}

.boxCulture img {
    position: relative;
    width: 100%;
    height: 360px;
    object-fit: cover;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    /* filter: blur(5px); */
}

.boxCulture figure {
    overflow: hidden;
}

.boxCulture .boxMask {
    /* background-color: rgb(0, 0, 0, .4); */
    position: absolute;
    top: 0;
    width: 100%;
    height: 360px;
}

.boxCulture:hover .boxMask {
    opacity: 0;
}

.boxCulture:hover img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.customLink {
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2000px !important;
    }
}

@media only screen and (max-width: 1650px) {
    .customContainer {
        max-width: 1300px !important;
    }
}


@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: var(--container-hd) !important;
    }

    .topContainer {
        padding-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    .textBack {
        font: normal 14px/28px Helvetica;
        color: black;
        margin: 0;
    }

    .topTitle {
        font: var(--title-hd);
    }

    .topHorizontalLine {
        margin-top: 1.5rem;
    }

    .navBack {
        padding: 1.5rem 0 3.5rem 0;
    }

    .boxPreview p {
        font: var(--p-hd);
        line-height: 1.8;
        margin-bottom: 1rem;
        text-align: justify;
    }

    .boxPreview ul>li {
        font: var(--p-hd);
        line-height: 1.8;
    }
}

@media only screen and (max-width: 1024px) {
    .customContainer {
        max-width: 88vw !important;
    }
}

@media only screen and (max-width: 768px) {
    .customContainer {
        max-width: 672px !important;
    }

    .topTitle {
        font: bold 18px/32px Helvetica;
    }

    .boxPreview {
        padding: 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .customBorder {
        border-top: 2px solid #b0b0b0;
        padding: 8vh 0 5vh 0;
    }
    .customBorderDefault {
        margin-bottom: 8vh;
    }
    .flexContainer {
        justify-content: center;
    }
    .customContainer {
        max-width: calc(100vw - 12vw) !important;
    }

    .posRelative {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .topContainer {
        padding-top: 0;
    }

    .boxPreview {
        border-top: 1px solid black;
        margin-top: 5vh;
    }

    .boxPreview p, .boxPreview ul > li{
        font: 14px/24px Helvetica;
    }

    .navBack {
        padding: 3rem 0 0 0;
    }
}