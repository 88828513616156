#c_dt_tbl {
    padding-bottom: 4rem;
}

#c_dt_tbl > .data-table-extensions  {
    padding-right: 0;
}

#c_dt_tbl > .data-table-extensions > .data-table-extensions-filter {
    position: relative;
    float: right;
}

#c_dt_tbl > .data-table-extensions > .data-table-extensions-filter > label {
    top: 50%;
    background-image: url('../../assets/search.svg');
    right: 0;
}

#c_dt_tbl > .data-table-extensions > .data-table-extensions-filter > input {
    margin-left: .5rem;
}

#c_dt_tbl > div:nth-child(3n) > nav {
    background-color: transparent;
}

@media only screen and (max-width: 1440px) {
    #c_dt_tbl {
        padding-bottom: 3.5rem;
    }
}

@media only screen and (max-width: 1024px) {
    #c_dt_tbl {
        padding-bottom: 3rem;
    }
}
