.title {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    padding: 4rem 0 0 0;
}

.navBackTitle {
    /*d edit this*/
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 0 0;

}

.customContainer {
    height: 100vh;
    width: 100%;
    margin: auto;
    justify-content: center;
    display: inline-block;
    position: relative;
    background-color: #f6f6f6;
    padding-top: 9rem;
}

.chart {
    display: flex;
    justify-content: center;

}

.chartL {
    width: 50%;
    height: 100%;
    position: relative;

}

.chartR {
    width: 50%;
    height: 100%;
    position: relative;

}

.contentTitle {
    font: bold 32px/48px Helvetica;
    margin: 0;
}

.customLink {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.customLink:hover {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.backgroundChart {
    position: absolute;
    top: -5rem;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
}

.backgroundChart img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.16);
    opacity: .3;
}

.navBack {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2rem 0 5rem 0;

}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.textBack {
    font: normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.customLink {
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.charts {

    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #f6f6f6;

}

.customContainerC {
    position: relative;
    display: block;
}

@media only screen and (min-width: 2560px) {
    .customContainerC {
        margin: -20vh auto 0 auto !important;
    }
}

@media only screen and (min-width: 1921px) {
    .charts {
        height: 100vh;
    }

    .customContainerC {
        margin: -15vh auto 0 auto;
    }
}


@media only screen and (max-width: 1680px) and (max-height: 900px) {


    .customContainerC {
        max-width: 78vw;
        margin-top: 10vh !important;
    }


}

@media only screen and (max-width: 1440px) {
    .customContainer {
        padding-top: 5.5rem;
    }

    .chart {
        transform: scale(.85);
    }

    .customContainerC {
        max-width: 1062px !important;

    }

    .textBack {
        font: normal 14px/28px Helvetica;
    }

    .customContentRight {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 2rem;
    }
}

@media only screen and (max-width: 1440px) and (max-height: 650px) {
    .customContainerC {
        margin-top: 20vh;
    }
}

@media only screen and (max-width: 1024px) {
    .customContainer {
        padding-top: 20vh;
    }

    .chart {
        transform: scale(.85);
    }

    .chartL,
    .chartR {
        width: 100%;
    }

    .customContainerC {
        max-width: 88vw !important;
    }

    .navBack {
        margin-top: -10vh;
    }
}


@media only screen and (max-width: 768px) {
    .charts {
        height: 100vh;
    }

    .customContainer {
        padding-top: 20%;
    }

    .chart {
        transform: scale(.8);
    }

    .customContainerC {
        margin-top: -20%;
        max-width: 672px !important;
        margin-bottom: 0;
    }
}