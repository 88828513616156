.wrapper {
  width: 100vw;
}

.main {
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 6px #00000029;
}

.images_slide {
  width: 960px !important;
  height: 1080px;
  object-fit: cover;
}

.box {
  position: relative;
  display: flex;
}

.boxContent {
  width: 50%;
  height: 100vh;
}

.boxContent2 {
  width: 50%;
  height: 100vh;
  background-color: #f1f1f1;
}

._img {
  width: 50vw;
  height: 50vh;
  object-fit: cover;
}

.pad {
  margin: 2rem auto;
}

.txt2 {
  font: normal 16px Helvetica;
  color: black;
  text-align: center;
}

._boxmain1 {
  width: auto;

  padding: auto;

  display: flex;
  flex-direction: column;
}

._img1 {
  width: 50vw;
  height: 50vh;
  object-fit: contain;
}

._boxmain {
  width: auto;
  padding: auto;
  display: flex;
  flex-direction: column;
}

._img_slide {
  width: 222px;
  height: 129px;
  object-fit: cover;
  cursor: url("./assets/drag.svg") 39 39, auto;
}

._box3 {
  width: auto;
  height: 20vh;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
}

._box2 {
  width: 222px;
  height: 129px;
  margin: auto 0.3rem;
}

.padd2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: center;
  margin: auto;
}

._abs {
  position: relative;
  padding: auto 2rem;
}

.news_title {
  position: relative;
  display: flex;
  padding: 1rem 5rem 1rem 12rem;
  text-transform: uppercase;
  align-self: flex-end;
}

.news {
  padding: 5rem 7rem 1rem 10rem;
  font: 18px Helvetica;
}

.judul {
  padding: 9rem 5rem 1rem 12rem;
  font: bold 24px Helvetica;
}

._next {
  width: auto;
  height: 7vh;

  text-align: right;
  margin-right: 2rem;
}

p._next {
  color: black;
  font: normal 22px Helvetica;
}

._btnBtm {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

._marg {
  margin: auto;
}

.isi {
  padding: 1rem 5rem 1rem 12rem;
  text-align: justify;
}

.wrappbtnback2 {
  width: 50vw;
  height: 6vh;
  background-color: #f1f1f1;
  position: relative;
  padding: 1rem 5rem 1rem 12rem;
}

.box_scroll {
  height: auto;
}

.box_togle {
  display: flex;
  padding: 2rem 7rem 1rem 10rem;
}

.images_goback {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  bottom: 2%;
  background-color: white;
}

.images_goback2 {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  bottom: 2%;
  background-color: #f1f1f1;
}

.share {
  position: absolute;
  display: flex;
  width: 200px;
  right: 8%;
  font-family: Helvetica;
  bottom: 10%;
}

.icon {
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .images_slide {
    width: 720px !important;
    height: 760px;
  }
}

.wrapper {
  width: 100vw;
}

.main {
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 6px #00000029;
}

.images_slide {
  width: 960px !important;
  height: 1080px;
  object-fit: cover;
}

.box {
  position: relative;
  display: flex;
}

/* .boxContent {
    width: 50%;
    height: 1080px;
}
.boxContent2 {
    width: 50%;
    height: 1080px;
    background-color: #f1f1f1;
} */

.boxImage {
  width: 100%;
  /* margin-top: 1rem; */
}

._img {
  width: 50vw;
  height: 50vh;
  object-fit: cover;
}

.pad {
  margin: 2rem auto;
}

.txt2 {
  font: normal 16px Helvetica;
  color: black;
  text-align: center;
}

._boxmain1 {
  width: auto;
  padding: auto;
  display: flex;
  flex-direction: column;
}

._img1 {
  width: 50vw;
  height: 50vh;
  object-fit: contain;
}

._boxmain {
  width: auto;
  padding: auto;
  display: flex;
  flex-direction: column;
}

._img_slide {
  width: 222px;
  height: 129px;
  object-fit: cover;
  cursor: url("./assets/drag.svg") 39 39, auto;
}

._box3 {
  width: auto;
  height: 20vh;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
}

._box2 {
  width: 222px;
  height: 129px;
  margin: auto 0.3rem;
}

/* .padd2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
} */

._abs {
  position: relative;
  padding: auto 2rem;
}

.news_title {
  position: relative;
  display: flex;
  padding: 1rem 5rem 1rem 12rem;
  text-transform: uppercase;
  align-self: flex-end;
}

.news {
  padding: 5rem 7rem 1rem 10rem;
  font: 18px Helvetica;
}

.link {
  cursor: pointer;
  color: black;
}

.link:hover {
  text-decoration: none;
}

.tgl {
  padding: 1rem 5rem 1rem 12rem;
}

._next {
  width: auto;
  height: 7vh;
  text-align: right;
  margin-right: 2rem;
}

p._next {
  color: black;
  font: normal 22px Helvetica;
}

._btnBtm {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

._marg {
  margin: auto;
}

/* .isi {
    padding: 1rem 5rem 1rem 12rem;
    text-align: justify;
} */

.img_box {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.wrappbtnback {
  width: 50vw;
  height: 15vh;
  background-color: white;
  position: relative;
}

.wrappbtnback a {
  text-decoration: none;
  color: black;
}

.wrappbtnback2 {
  width: 50vw;
  height: 15vh;
  background-color: #f1f1f1;
  position: relative;
}

/* .box_scroll {
  height: 880px;
} */

.scroll_content {
  height: 606px;
  overflow: auto;
  cursor: url("./assets/scrolldark.svg") 39 39, auto;
}

.box_togle {
  display: flex;
  padding: 2rem 7rem 1rem 10rem;
}

.images_goback {
  padding: 2rem 2rem 4rem 10vw;
  display: flex;
  margin-top: -3%;
  position: absolute;
  bottom: 2%;
  background-color: white;
}

.images_goback2 {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  bottom: 2%;
  background-color: #f1f1f1;
}

.share {
  position: absolute;
  display: flex;
  width: 200px;
  right: 8%;
  font-family: Helvetica;
  bottom: 10%;
}

@media only screen and (min-width: 2560px) {
  .scroll_content {
    height: 100%;
  }

  .judul {
    padding: 15rem 5rem 1rem 12rem;
  }
}

@media only screen and (max-width: 1400px) {
  .images_goback {
    padding: 0 2rem 4rem 14vw;
  }

  .scroll_content {
    height: 478px;
  }

  .isi {
    padding: 1rem 3rem 1rem 12rem;
  }

  .judul {
    font: bold 18px Helvetica;
    padding: 6rem 3rem 1rem 12rem;
  }

  .images_slide {
    width: 720px !important;
    height: 760px;
  }

  .wrappbtnback2 {
    height: 6vh;
    padding: 6rem 5rem 0 12rem;
  }

  .boxImage {
    margin-top: 0.3rem;
  }

  .pad {
    margin: 1rem auto;
  }

  .padd2 {
    margin-top: 3rem;
  }

  .boxContent2 {
    width: 50%;
    padding: 3.5rem 2rem 2rem 2rem;
    background-color: #f1f1f1;
  }
}

@media only screen and (max-width: 768px) {
  .judul {
    padding-left: 4rem !important;
  }

  .tgl {
    padding-left: 4rem !important;
  }

  .isi {
    padding-left: 4rem !important;
  }

  ._img_slide {
    width: 168px !important;
  }

  ._img {
    width: 50vw;
  }
}