#h5_sliderR > div > div.slick-dots{
  text-align: left;
  left: 0rem;
}
.img {
  width: 100%;
  height: 100vh;
  /* filter: blur(3px); */
  object-fit: cover;
}

.imgD {
  width: 100%;
  height: 100vh;
  filter: blur(3px);
  object-fit: contain;
}

.section-blur {
  height: auto;
  width: 100vw;
  backdrop-filter: blur(17px);
}

.c-sec2 {
  margin-top: 500px;
}
.overlaysec2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #1b1b1bda;
}
.image-sec2:hover .overlaysec2 {
  opacity: 1;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Helvetica;
}
.content-sec2 {
  margin-bottom: 50px;
}

.image-zoom-sec1 {
  background: white;
  overflow: hidden;
  padding: inherit;
  height: 220px;
  object-fit: cover;
}

.image-zoom-sec1 img {
  max-width: none;
  width: -webkit-calc(100% + 30px);
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-20px, 0, 0);
  /* -webkit-backface-visibility: hidden; */
  /* backface-visibility: hidden; */
  object-fit: cover;
}
.image-zoom-sec1:hover img {
  /* #1 */
  opacity: 0.5;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.cv-color3 {
  background: #000000a9 0 0 no-repeat padding-box;
  opacity: 0.79;
}

.text-midle3 {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-size: x-large;
  color: white !important;
  text-transform: uppercase;
  font-family: Helvetica;
  text-align: right;
}
.text-midle3:after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1.5em;
  width: 100%;
  border-bottom: 2px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  font-family: Helvetica;
}
.text-midle3:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.text-midle4 {
  /* position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%); */
  font-weight: 400;
  font-size: 30px !important;
  color: white;
  font-weight: bold;
  text-transform: uppercase !important;
  font-family: Helvetica;
  text-align: right;
  width: 15vw;
  margin: 0 1rem 0 auto;
  align-self: center;
  position: relative;
  line-height: normal;
}
/* .text-midle4:after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1.5em;
    width: 100%;
    border-bottom: 2px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}
.text-midle4:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
} */

.img-thumb-sec2 {
  /* display: flex; */
  max-width: 947px;
  /* height: auto;
    overflow-x: hidden;
    position: relative */
}
.img-thumb-sec2:hover img {
  transition: transform 1s, filter 2s ease-in-out;
  filter: blur(2px);
  transform: scale(1.1);
}
.img-thumbs-sec2:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(0.9);
}

/* .cv-color-sec2-blue {
  background: #223870 0 0 no-repeat padding-box;
  opacity: 0.5;
  padding: none;
}
.cv-color-orange-sec2 {
  background: #f16b26 0 0 no-repeat padding-box;
  opacity: 0.5;
} */

.image-zoom-sec2 {
  background: white;
  overflow: hidden;
  padding: inherit;
}

.image-zoom-sec2 img {
  max-width: none;
  width: -webkit-calc(100% + 30px);
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-20px, 0, 0);
  /* -webkit-backface-visibility: hidden; */
  /* backface-visibility: hidden; */
  height: 304px;
  object-fit: cover;
}
.image-zoom-sec2:hover img {
  /* #1 */
  /* opacity: 0.2; */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.image-zoom-sec2:hover .cv-color-sec2-blue,
.image-zoom-sec2:hover .cv-color-orange-sec2 {
  opacity: 0.2;
}

.card-sec2 {
  border: none !important;
  height: 280px;
}

.card-image-zoom {
  border: none;
}

.title_co_hoi_ngep {
  font: bold 46px/48px Helvetica;
  color: black !important;
}

.content_co_hoi_ngep {
  font-family: Helvetica;
  font-size: 18px;
}

/*ipad pro*/
@media only screen and (max-width: 1024px) {
  .img {
    height: 50vh;
  }
}

@media only screen and (max-width: 768px) {
  .img {
    height: 50vh;
  }
}

.HSec5 .slick-dots {
  bottom: 10px;
}

.HSec5 .slick-dots li.slick-active button:before {
  background-color: #f16b26;
  opacity: 1;
}

.HSec5 li button {
  width: 10px;
  height: 10px;
}

.HSec5 li button:before {
  width: 10px;
  height: 10px;
  font-size: 0;
  background-color: transparent;
  opacity: 1;
  border-radius: 50%;
  border: 1px solid rgb(214, 214, 214);
}

.HSec5 li:last-of-type {
  width: 10px;
}