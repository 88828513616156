div.scrollMenu {
    overflow: auto;
    white-space: nowrap;
}

div.scrollMenu a {
    display: inline-block;
    color: white;
    text-align: center;
    margin: 14px;
    text-decoration: none;
}