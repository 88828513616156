.imgNav {
  height: 60px;
  width: 112px;
  color: white;
  fill: white;
  z-index: 100;
}

.navText {
  color: white !important;
  font-weight: bold;
  z-index: 100;
}

.navLogo {
  z-index: 100;
}

.imgLogo {
  height: 100%;
  width: 100%;
  color: white;
  fill: white;
  z-index: 100;
  margin: -0.5rem 0 0 1rem;
}

@media only screen and (max-width: 1400px) and (orientation: portrait) {
  .imgNav {
    width: 75px;
  }
}

@media only screen and (max-width: 420px) {
  .imgLogo {
    width: 40px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 360px) {
  .imgLogo {
    width: 40px !important;
    height: 40px !important;
  }

  /* .navMobile {
    background-color: rgb(0, 0, 0, .5);
  } */
}


/**landscape view*/
@media only screen and (orientation: landscape) and (max-width: 897px) {

  .imgLogo {
    width: 50px;
    height: 50px;
    margin: .5rem 0 0 .5rem;
  }

}


@media only screen and (orientation: landscape) and (max-width: 568px) {
  .imgLogo {
    width: 38px;
    height: 38px;
    margin: .5rem 0 0 0;
  }
}