.section {
    padding: 0;
}

.customContainer {
    padding: 0;
    max-width: 88vw;
    margin: 0 auto;
}

.content1 {
    margin-bottom: 2rem;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.textBack {
    font: normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.content1 p {
    font: normal 14px Helvetica;
    line-height: 1.5rem;
    text-align: justify;
}

.content2 {
    background-color: #f1f1f1;
    color: black;
    padding: 2rem;
}

.content2 p {
    font: normal 18px Helvetica;
    line-height: 2.5rem;
    padding: 1rem 2rem;
    text-align: justify;
}

.content3 {
    /* background-color: #d6d6d6; */
    color: black;
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem 2rem 0;
}

.backIcon {
    color: #F26522;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}

.content2 ul {
    padding: 0;
}

.content2 li {
    text-align: justify;
}