/* GENERAL */
:root {
    --left-fixed: 224px;
    --left-fixed-tablet: 420px;
    --left-fixed-ipad-pro: 305px;
}

/* SPECIFIC */
.backgroundchart {

    width: 100%;
    margin-top: -7.4rem;
    transform: scale(0.9);

}

.wrappContent {
    margin-top: 0;
    width: 100%;
    height: 567px;
    position: relative;

}

.title {
    font: bold 18px Helvetica !important;
}

.circleFake {
    position: absolute;

    border: 2px solid #ddd;
    width: 356px;
    height: 360px;

    align-items: center;
    border-radius: 50%;
    top: 8px;
    left: 577px;
    z-index: 0;
}

.circle {
    margin: 18rem auto auto;
    border: 20px dotted #ddd;
    width: 375px;
    height: 375px;
    display: flex;
    align-items: center;
    border-radius: 50%;

}

.circleInner {
    margin: auto;
    width: 300px;

    display: flex;
    text-align: center;
    background-color: #ececec;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
}

.textInnerCircle {
    font: bold 20px Helvetica;
    margin: auto;
    text-transform: uppercase;
}

.chart {
    position: relative;
}

.boxRound {
    position: absolute;
    z-index: 4;
    display: flex;
    align-items: center;
    margin: auto;

    background-color: #eead34;
    width: 300px;
    height: 60px;
    border-radius: 40px;
    justify-content: flex-end;
    padding-right: 5px;
    right: 0;
    bottom: 0;
    animation: move 5s;
}

@keyframes move {
    from {
        bottom: 22%;
    }

    to {
        bottom: 0;
    }
}

@-webkit-keyframes move {
    from {
        bottom: 22%;
    }

    to {
        bottom: 0;
    }
}

.textInBox {
    font: normal 16px Helvetica;
}

.textInboxRound {
    font: normal 18px Helvetica;
    color: white;
    margin: auto;
    text-align: center;
    width: 190px;
}

.Line {
    position: absolute;
    width: 70px;
    height: 5px;
    background-color: #eead34;
    z-index: 2;
    transform: rotate(90deg);
    right: -1.5%;
    bottom: 36%;
}

.circleLine {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    padding: 5px;
    border: solid 5px #eead34;
}

.circleLineIn {
    margin: auto;
    width: 20px;
    height: 20px;

    border-radius: 15px;
    border: solid 5px #eead34;
    text-align: center;
    align-items: center;
}

.circleLine2 {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    padding: 5px;
    border: solid 5px #eead34;
    position: absolute;
    right: 6px;
    top: 34%;

}

.circleLineIn2 {
    margin: auto;
    width: 20px;
    height: 20px;

    border-radius: 15px;
    border: solid 5px #eead34;
    text-align: center;
    align-items: center;
}

.wrappContentBox {
    position: absolute;
    width: 600px;

    height: 250px;
    left: 55px;
    top: 57%;
    z-index: 1;
}

.wrappContentBox:nth-child(2) {
    transform: translate(-232px, -290px);
    top: 76%;
    left: 123px;
}

.wrappContentBox:nth-child(2) .Line {
    transform: translate(83px, 78px) rotate(180deg);
    background-color: #f19924;
    right: 2.5%;
    bottom: 42%;
}

.wrappContentBox:nth-child(2) .circleLine2 {
    transform: translate(163px, 154px);
    border: solid 5px #ffaa3b;
    right: 55px;
    top: 19%;
}

.wrappContentBox:nth-child(2) .circleLineIn2 {
    border: solid 5px #f19924;
}

.wrappContentBox:nth-child(2) .boxRound {
    background-color: #f19924;
    right: 0;
    animation: move2 5s infinite;
    -webkit-animation: move2 5s;
}

@keyframes move2 {
    from {
        right: -18%;
    }

    to {
        right: 0;
    }
}

@-webkit-keyframes move2 {
    ffrom {
        right: -18%;
    }

    to {
        right: 0;
    }
}

.wrappContentBox:nth-child(2) .circleLine {
    border: solid 5px #f19924;
}

.wrappContentBox:nth-child(2) .circleLineIn {
    border: solid 5px #f19924;
}

.wrappContentBox:nth-child(3) .boxDescription {
    right: 15%;
}

.wrappContentBox:nth-child(3) {
    transform: translate(-179px, -478px);
    top: 85%;
    left: 119px;
}

.wrappContentBox:nth-child(3) .Line {
    transform: translate(83px, 78px) rotate(180deg);
    right: 2.5%;
    bottom: 42%;
}

.wrappContentBox:nth-child(3) .boxRound {
    background-color: #f18624;
    right: 0;
    animation: move3 5s;
    -webkit-animation: move3 5s;
}

@keyframes move3 {
    from {
        right: -18%;
    }

    to {
        right: 0;
    }
}

@-webkit-keyframes move3 {
    ffrom {
        right: -18%;
    }

    to {
        right: 0;
    }
}

.wrappContentBox:nth-child(3) .circleLine2 {
    border: solid 5px #f18624;
    transform: translate(163px, 154px);
    right: 56px;

    top: 19%;
}

.wrappContentBox:nth-child(3) .circleLineIn2 {
    border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .circleLine {
    border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .circleLineIn {
    border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .Line {
    background-color: #f18624;
}

.wrappContentBox:nth-child(4) {
    transform: translate(160px, -519px) scalex(-1) rotate(180deg);
    top: 102%;
    left: 10px;
}

.wrappContentBox:nth-child(4) .boxRound {
    background-color: #f16624;
}

.wrappContentBox:nth-child(4) .circleLine2 {
    border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLineIn2 {
    border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLine {
    border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLineIn {
    border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .Line {
    background-color: #f16624;
}

.wrappContentBox:nth-child(4) p {
    transform: scaley(-1);
}

.wrappContentBox:nth-child(5) {
    transform: translate(893px, -441px) rotate(180deg);
    top: 92%;
    left: -51px;
}

.wrappContentBox:nth-child(5) .boxRound {
    background-color: #00599d;
}

.wrappContentBox:nth-child(5) .circleLine2 {
    border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLineIn2 {
    border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLine {
    border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLineIn {
    border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .Line {
    background-color: #00599d;
}

.wrappContentBox:nth-child(5) p {
    transform: rotate(180deg);
}

.wrappContentBox:nth-child(6) {
    transform: translate(1120px, -350px) scaleX(-1);
    top: 75%;
    left: -104px;
}

.wrappContentBox:nth-child(6) .boxRound {
    background-color: #004e96;
    right: 0;
    animation: move4 5s;
    -webkit-animation: move4 5s;
}

@keyframes move4 {
    from {
        right: -18%;
    }

    to {
        right: 0;
    }
}

@-webkit-keyframes move4 {
    ffrom {
        right: -18%;
    }

    to {
        right: 0;
    }
}

.wrappContentBox:nth-child(6) .circleLine2 {
    border: solid 5px #004e96;
    transform: translate(163px, 154px);
    right: 55px;
    top: 19%;
}

.wrappContentBox:nth-child(6) .circleLineIn2 {
    border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLine {
    border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLineIn {
    border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) p {
    transform: scaleX(-1);
}

.wrappContentBox:nth-child(6) .Line {
    transform: translate(83px, 78px) rotate(180deg);
    background-color: #004e96;
    right: 2.5%;
    bottom: 42%;
}

.wrappContentBox:nth-child(7) {
    transform: translate(1035px, -144px) scaleX(-1);
    top: 60%;
    left: -58px;
}

.wrappContentBox:nth-child(7) .boxRound {
    background-color: #03428e;
    animation: move5 5s;
    -webkit-animation: move5 5s;
}

@keyframes move5 {
    from {
        right: -18%;
    }

    to {
        right: 0;
    }
}

@-webkit-keyframes move5 {
    ffrom {
        right: -18%;
    }

    to {
        right: 0;
    }
}

.wrappContentBox:nth-child(7) .circleLine2 {
    border: solid 5px #03428e;
    right: 56px;
    top: 18.5%;
}

.wrappContentBox:nth-child(7) .circleLineIn2 {
    border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLine {
    border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLineIn {
    border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .Line {
    transform: translate(83px, 78px) rotate(180deg);
    background-color: #03428e;
    right: 2.5%;
    bottom: 42%;
}

.wrappContentBox:nth-child(7) p {
    transform: scaleX(-1);
}

.wrappContentBox:nth-child(7) .circleLine2 {
    transform: translate(163px, 154px);
}

.wrappContentBox:nth-child(8) {
    transform: translate(729px, 68px) scaleX(-1);
    left: 31px;
    top: 51%;
}

.wrappContentBox:nth-child(8) .boxRound {
    background-color: #223771;
}

.wrappContentBox:nth-child(8) .circleLine2 {
    border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLineIn2 {
    border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLine {
    border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLineIn {
    border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .Line {
    background-color: #223871;
}

.wrappContentBox:nth-child(8) p {
    transform: scaleX(-1);
}

.boxDescription {
    position: absolute;
    z-index: 1;
    background-color: #f1f1f1;
    width: 350px;
    height: 92px;
    height: 130px;
    bottom: 55px;
    right: 10%;
    padding: 1rem;
    text-align: justify;
}

.boxC {
    max-width: 88%;
}

@media only screen and (min-width: 2560px) {
    .boxC {
        max-width: 88% !important;
    }

    .circle {
        margin-right: 51.9rem;
    }

    .flexContent {
        margin-top: 2%;
    }

    .backgroundchart {
        margin-top: -7.4rem;
    }

    .chart {
        margin-left: 18rem;
    }
}

@media only screen and (max-width: 1400px) {
    .circleFake {
        left: calc(577px - var(--left-fixed));
    }

    .wrappContentBox {
        left: calc(55px - var(--left-fixed));
    }

    .wrappContentBox:nth-child(2) {
        left: calc(123px - var(--left-fixed));
    }

    .wrappContentBox:nth-child(3) {
        left: calc(119px - var(--left-fixed));
    }

    .wrappContentBox:nth-child(4) {
        left: calc(10px - var(--left-fixed));
    }

    .wrappContentBox:nth-child(5) {
        left: calc(-51px - var(--left-fixed));

    }

    .wrappContentBox:nth-child(6) {
        left: calc(-104px - var(--left-fixed));
    }

    .wrappContentBox:nth-child(7) {
        left: calc(-58px - var(--left-fixed));
    }

    .wrappContentBox:nth-child(8) {
        left: calc(31px - var(--left-fixed));
    }

    .backgroundchart {
        transform: scale(0.7);
        margin-top: -12.5rem;
    }

}

@media only screen and (max-width: 768px) {
    .circleFake {
        left: calc(577px - var(--left-fixed-tablet));
    }

    .wrappContentBox {
        left: calc(55px - var(--left-fixed-tablet));
    }

    .wrappContentBox:nth-child(2) {
        left: calc(123px - var(--left-fixed-tablet));
    }

    .wrappContentBox:nth-child(3) {
        left: calc(119px - var(--left-fixed-tablet));
    }

    .wrappContentBox:nth-child(4) {
        left: calc(10px - var(--left-fixed-tablet));
    }

    .wrappContentBox:nth-child(5) {
        left: calc(-51px - var(--left-fixed-tablet));
        width: 430px;
    }

    .wrappContentBox:nth-child(6) {
        left: calc(-104px - var(--left-fixed-tablet));
        width: 385px;
    }

    .wrappContentBox:nth-child(7) {
        left: calc(-58px - var(--left-fixed-tablet));
        width: 424px;
    }

    .wrappContentBox:nth-child(8) {
        left: calc(31px - var(--left-fixed-tablet));
    }

    .backgroundchart {
        transform: scale(0.6);
        margin-top: -16.5rem;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .circleFake {
        left: calc(577px - var(--left-fixed-ipad-pro));
    }

    .wrappContentBox {
        left: calc(55px - var(--left-fixed-ipad-pro));
    }

    .wrappContentBox:nth-child(2) {
        left: calc(123px - var(--left-fixed-ipad-pro));
    }

    .wrappContentBox:nth-child(3) {
        left: calc(119px - var(--left-fixed-ipad-pro));
    }

    .wrappContentBox:nth-child(4) {
        left: calc(10px - var(--left-fixed-ipad-pro));
    }

    .wrappContentBox:nth-child(5) {
        left: calc(-51px - var(--left-fixed-ipad-pro));
        width: 430px;
    }

    .wrappContentBox:nth-child(6) {
        left: calc(-104px - var(--left-fixed-ipad-pro));
        width: 385px;
    }

    .wrappContentBox:nth-child(7) {
        left: calc(-58px - var(--left-fixed-ipad-pro));
        width: 424px;
    }

    .wrappContentBox:nth-child(8) {
        left: calc(31px - var(--left-fixed-ipad-pro));
    }

    .backgroundchart {
        transform: scale(0.6);
        margin-top: -16.5rem;
    }
}

@media only screen and (max-width: 480px) {
    .boxC {
        max-width: 88vw !important;
        padding: 0;
    }

    .backgroundchart {
        margin-top: 0 !important;
        transform: scale(1);
    }

    .circleInner {
        width: 50%;
        height: 15%;
        border-radius: 5%;
        align-items: flex-start;
        margin: auto auto auto 0;
    }

    .textInnerCircle {
        font: bold 14px Helvetica;
    }

    .lineV {
        position: absolute;
        width: 3px;
        height: 45vh;
        background-color: #ddd;
        z-index: 2;
        left: 5%;
        top: 15%;
    }

    .outerCircle1 {
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 20px;
        text-align: center;
        align-items: center;
        padding: 2px;
        border: solid 5px #eead34;
        position: absolute;
        left: -17vw;
        top: 0;
        z-index: 5;
        transform: scale(.8);
    }

    .outerCircle2 {
        /* margin: auto;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: solid 5px #f16624;
        text-align: center;
        align-items: center; */

        position: relative;
        margin: auto .5rem auto auto !important;

        padding: 5px;
        transition: transform 0.2s;
        border-radius: 50%;
        border: 1px solid #f16624;
        display: inline-block;
        left: 0;
        opacity: 1;
        margin: auto 0.5rem auto 0;
    }

    .innerCircle2 {
        content: "";
        background: #f16624;
        width: 8px;
        height: 8px;
        border-radius: 100%;
    }

    .circleLine {
        position: absolute;
        width: 12vw;
        height: 5px;
        background-color: #eead34;
        z-index: 2;
        left: -11vw;
        top: 2vh;
        padding: 0;
        border-radius: 5px;
        border: 1px solid #eead34;
    }

    .roundedBox {
        position: absolute;
        z-index: 4;
        display: flex;
        align-items: center;
        margin: auto;
        background-color: #efad3480;
        width: 60vw;
        height: 5vh;
        border-radius: 40px;
        justify-content: flex-end;
        padding-right: 5px;
        left: 0;
        top: 0;
    }

    .contentRounded {
        width: 300px;
        height: 50px;
        display: flex;
    }

    .textInRounded {
        margin: auto;
    }

    .wrappBoxMobile {
        position: absolute;
        margin-top: 2vh;
        margin-left: 8vw;
        /* height: 100%;
        width: 100%; */
    }

    .contentBoxMobile {
        position: relative;
        margin-top: 0;
        margin-left: 10vw;
        /* height: 100%;
        width: 100%; */
    }

    .wrappBoxMobile:nth-child(2) {
        margin-top: 9vh;
    }

    .wrappBoxMobile:nth-child(3) {
        margin-top: 16vh;
    }

    .wrappBoxMobile:nth-child(4) {
        margin-top: 23vh;
    }

    .wrappBoxMobile:nth-child(5) {
        margin-top: 30vh;
    }

    .wrappBoxMobile:nth-child(6) {
        margin-top: 37vh;
    }

    .wrappBoxMobile:nth-child(7) {
        margin-top: 44vh;
    }

    .wrappBoxMobile:nth-child(8) {
        margin-top: 51vh;
    }

    .wrappBoxMobile:nth-child(2) .outerCircle1,
    .wrappBoxMobile:nth-child(2) .outerCircle2 {
        border: solid 5px #F19624;
    }

    .wrappBoxMobile:nth-child(2) .circleLine,
    .wrappBoxMobile:nth-child(2) .roundedBox {
        background-color: rgb(241 150 36 / 63%);
    }

    .wrappBoxMobile:nth-child(3) .outerCircle1,
    .wrappBoxMobile:nth-child(3) .outerCircle2 {
        border: solid 5px #F18624;
    }

    .wrappBoxMobile:nth-child(3) .circleLine,
    .wrappBoxMobile:nth-child(3) .roundedBox {
        background-color: #efad34cf;
    }

    .wrappBoxMobile:nth-child(4) .outerCircle1,
    .wrappBoxMobile:nth-child(4) .outerCircle2 {
        border: solid 5px #F16624;
    }

    .wrappBoxMobile:nth-child(4) .circleLine,
    .wrappBoxMobile:nth-child(4) .roundedBox {
        background-color: #00699D;
    }

    .wrappBoxMobile:nth-child(5) .outerCircle1,
    .wrappBoxMobile:nth-child(5) .outerCircle2 {
        border: solid 5px #00699D;
    }

    .wrappBoxMobile:nth-child(5) .circleLine,
    .wrappBoxMobile:nth-child(5) .roundedBox {
        background-color: rgb(1 85 127);
    }

    .wrappBoxMobile:nth-child(6) .outerCircle1,
    .wrappBoxMobile:nth-child(6) .outerCircle2 {
        border: solid 5px #00599D;
    }

    .wrappBoxMobile:nth-child(6) .circleLine,
    .wrappBoxMobile:nth-child(6) .roundedBox {
        background-color: #00599D;
    }

    .wrappBoxMobile:nth-child(7) .outerCircle1,
    .wrappBoxMobile:nth-child(7) .outerCircle2 {
        border: solid 5px #004E96;
    }

    .wrappBoxMobile:nth-child(7) .circleLine,
    .wrappBoxMobile:nth-child(7) .roundedBox {
        background-color: #004E96;
    }

    .Section4 {
        padding-top: 3.5rem;
    }

    .boxC {
        max-width: 88vw !important;
        display: flex;
        flex-direction: column;
        height: 88vh;
    }

    .boxTop,
    .boxBottom {
        position: relative;
        /* height: 100%; */
        width: 100%;
    }

    .boxTop {
        margin-bottom: 2vh;
    }

    .boxLinks .outerCircle2 {
        opacity: 1;
        position: relative;
        margin: 0 .5rem 0 auto;
    }


    .boxLinks {
        display: flex;
        flex-direction: row;
    }

    .boxLinks .contentLink {
        margin: auto 0;
        color: black;
        font: 14px Helvetica;
    }

    .contentLink {
        display: flex;
        align-items: center;
    }




}

@media only screen and (max-width: 480px) and (max-height: 600px) {
    .boxC {
        height: 108vh;
    }
}

@media only screen and (max-width: 480px) and (max-height: 700px) {
    .boxC {
        height: 94vh;
    }
}