/* GENERAL */
:root {
    --title-hd: bold 28px Helvetica;
    --subtitle-hd: bold 18px Helvetica;
    --p-hd: normal 14px Helvetica;
    --container-hd: 1062px !important;
    --padding-section-hd: 3.5rem 0;

    --title-fhd: bold 36px Helvetica;
    --subtitle-fhd: bold 26px Helvetica;
    --p-fhd: normal 16px Helvetica;
    --container-fhd: 1520px !important;
    --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */
.posRelative {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    padding: 2rem 0;
    /* padding-top: 5rem; */
    /* padding-bottom: 5rem; */
    /* background-color: #f4f3ef; */
}

.mouseImg {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 50vw;
    bottom: 60px;
    font: normal normal normal 12px Helvetica;
    letter-spacing: -0.12px;
}

.mouseImg p {
    margin: 16px 0 0 0;
}

.customContainer {
    max-width: 1520px;
    padding: 0;
}

.banner {
    position: relative;
    width: 100%;
    height: 100%;
}

.imageFull {
    width: 100%;
    height: 65vh;
    object-fit: cover;
}

.title {
    text-align: left;
    font: var(--title-fhd);
    color: #000000;
    text-transform: uppercase;
    padding: 0;
    margin-bottom: 2rem;
}

.contentDesc {
    text-align: left;
    font: var(--p-fhd);
    line-height: 1.8;
    letter-spacing: 0px;
    color: #000000;
}

.contentDesc p {
    margin: 0;
}

.titleBanner {
    position: relative;
    font: 48px Helvetica;
    color: black;
    /* top: 45%; */
}

.bannerContent {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(50%, 50%);
    width: 10vw;
}

.animate {
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: animate-text;
}

@keyframes animate-text {

    0%,
    30% {
        transform: translateX(-10%);
        opacity: 0;
    }

    30%,
    70% {
        transform: translateX(0);
        opacity: 1;
    }

    70%,
    99% {
        opacity: 1;
    }

    99%,
    100% {
        opacity: 0;
    }
}

.underlineAnimate {
    display: block;
    height: 2px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    background-color: black;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-animate;
}

@keyframes underline-animate {
    0%,
    10% {
        width: 0px;
        transform: translateX(-200px);
    }

    10%,
    20% {
        width: 250px;
        transform: translateX(0);
    }

    20%,
    30% {
        width: 0;
        transform: translateX(200px);
    }

    40%,
    100% {
        width: 0;
        transform: translateX(300px);
    }
}


@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2000px !important;
    }
}

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 1300px !important;
    }
}

@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: var(--container-hd) !important;
    }

    .title {
        font: var(--title-hd);
        margin-bottom: 1.5rem;
    }

    .contentDesc {
        font: var(--p-hd);
        line-height: 1.8;
    }
}

@media only screen and (max-width: 1024px) {
    .customContainer {
        max-width: 88vw !important;
    }
}

@media only screen and (max-width: 768px) {
    .customContainer {
        max-width: 672px !important;
    }

    .title {
        font: bold 18px/32px Helvetica;
    }
}

@media only screen and (max-width: 480px) {
    .customContainer {
        max-width: 88vw !important;
    }

    .contentDesc p {
        text-align: justify;
    }

    .posRelative {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
}