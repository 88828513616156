.section {
    background-color: #f1f1f1;
    padding: 5rem 0;
}

.customContainer {
    max-width: 80vw;
    padding: 0;
}

.title {
    font: bold 36px Helvetica;
    margin-bottom: 2rem;
}

.cardWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    /* justify-content: space-between; */
}

.cardContainer {
    max-width: 2000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.card {
    width: 24% !important;
    position: relative;
    margin: 1rem 1rem 1rem 0;
    background: #d6d6d6;
    padding: 1.5rem;
    border-radius: 20px;
}

.cardTitle {
    font: bold 26px Helvetica;
    margin-bottom: 1rem;
}

.cardContent p {
    font: normal 16px Helvetica;
    line-height: 2rem;
    text-align: justify;
}

.subTitle {
    font: bold 36px Helvetica;
    margin-bottom: 2rem;
}



@media only screen and (max-width: 2560px) {
    .marginLeft {
        margin-left: calc((100vw - 2000px) / 2);
    }
}

@media only screen and (max-width: 1920px) {

    .customContainer {
        max-width: 80vw;
    }

    .cardContainer {
        max-width: 80vw;
    }

    .title {
        font: bold 36px Helvetica;
    }

    .card {
        width: 22% !important;
    }

    .marginLeft {
        margin-left: calc((100vw - 1520px) / 2);
    }

    .subTitle {
        font: bold 32px Helvetica;
    }
}

/* @media only screen and (max-width: 1920px) {
    .customContainer {
        max-width: 1300px;
    }
} */

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 78vw;
    }

    .subTitle {
        font: bold 32px Helvetica;
    }
}

@media only screen and (max-width: 1440px) {
    .section {
        background-color: #f1f1f1;
        padding: 3.5rem 0;
    }

    .title {
        font: bold 28px Helvetica;
        margin-bottom: 1.5rem;
    }

    .customContainer {
        max-width: 78vw;
    }

    /* .card {
        margin-left: 1rem;
    } */

    .marginLeft {
        margin-left: calc((100vw - 1052px) / 2);
    }

    .subTitle {
        font: bold 22px Helvetica;
        margin-bottom: 2rem;
    }

    .cardTitle {
        font: bold 18px Helvetica;
        /* margin-bottom: 2rem; */
    }

    .cardContent p {
        font: normal 14px Helvetica;
        line-height: 28px;
    }
}

@media only screen and (max-width: 1024px) {
    .customContainer {
        max-width: 88vw;
    }

    .marginLeft {
        margin-left: 6vw;
    }


    .card {
        width: 31% !important;
    }
}


@media only screen and (max-width : 900px) {
    .card {
        width: 47% !important;
    }
}