.content {
	width: 100%;
}

.contentt {
	width: 100%;
	display: flex !important;
}

.bxbtm02h {
	width: 100%;
	height: 100%;
	display: flex !important;
	flex-direction: row;
}

.bxct02lft {
	width: 50%;
	max-height: 600px;
	display: flex;
	flex-direction: column;
	padding: 4rem;

	overflow: hidden;
	justify-content: center;
	align-self: center;
}

.bx0vrhdn {
	overflow: auto;
}

.bxct02right {
	width: 50%;
	height: 100%;
}

.boxContainer {
	height: 528px;
	width: 45vw;
	padding: 4rem;
	display: flex;
	/* flex-direction: column; */
	align-items: center;
	justify-content: center;
}

.title {
	position: relative;
	font-size: 26px;
	color: white !important;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 1rem;
}

.paragraph {
	font-size: 16px;
	position: relative;
	color: white !important;
	line-height: 1.8rem;
}

.boxL {
	width: auto;
	height: auto;
}

.boxL p {
	text-align: left;
}

.box {
	width: 100%;
	background-color: #223771;
	color: white;
	font-family: Helvetica;
}

.paragraph p,
.paragraph ul {
	font-size: 16px;
	color: white !important;
	text-align: justify;
	line-height: 2rem;
	margin: 0;
}

.paragraph li {
	text-align: justify;
}

.box_2 {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image {
	object-fit: cover;
	width: 100%;
	height: 600px;
}

.imageDefault {
	object-fit: contain;
	width: 100%;
	height: 528px;
}

.boxAll {
	max-width: 1520px;
	height: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: 5rem;
}

.txmdl1h {
	color: black;
	font: bold 34px Helvetica;
	text-align: center;
	position: relative;
	line-height: 3rem;
	margin-bottom: 3.5rem;
}

.cs01h {
	background-color: #223971;
	/* min-height: 600px; */
	width: 100%;
}

.csbtm02h {
	position: relative;
	top: 35%;
	left: 11%;
	margin-bottom: 10px;
	box-shadow: 0 17px 16px rgba(0, 0, 0, 0.4);
	background-color: #223771;
}

/**macbook pro or full hd++*/
@media only screen and (min-width: 2560px) {
	.boxAll {
		max-width: 2000px !important;
		padding: 7.4rem 0;
	}

	.txmdl1h {
		margin-bottom: 5.5rem;
	}

	.bxct02lft {
		max-height: 700px;
	}
}

/**macbook pro or full hd++*/
@media only screen and (max-width: 2048px) {
	.txmdl1h {
		margin-bottom: 9vh;
	}

	.boxAll {
		padding-top: 9vh;
	}
}

@media only screen and (max-width: 1680px) {
	.boxAll {
		max-width: 1300px !important;
	}
}

/*laptop view*/
@media only screen and (max-width: 1440px) {
	.boxAll {
		max-width: 1062px !important;
	}

	.image {
		height: 400px;
	}

	.bxct02lft {
		max-height: 400px;
		padding-left: 5rem;
	}

	.title {
		position: relative;
		top: 0;
		font-size: 16px;
	}

	.paragraph p,
	.paragraph ul {
		font-size: 14px;
		line-height: 28px;
	}

	.paragraph {
		font-size: 14px;
		font-family: Helvetica;
		line-height: 28px;
	}

	.box {
		width: 100%;
	}

	.boxContainer {
		height: 345px;
		padding: 2rem;
	}

	.txmdl1h {
		font: bold 28px Helvetica;
		margin-bottom: 3.5rem;
	}
}

@media only screen and (max-width: 1366px) {
	.txmdl1h {
		font: bold 21px Helvetica;
	}
}

/*ipad pro*/
@media only screen and (max-width: 1024px) {
	.boxAll {
		padding: 5vh 0 5vh 0;
		max-width: 88vw !important;
	}

	.bxct02lft {
		height: auto;

		/* max-height: 326px; */
		padding: 2.5rem;
	}

	.paragraph {
		margin-bottom: 0;
	}

	.paragraph ul {
		padding-left: 1rem;
	}

	.bx0vrhdn {
		height: auto;
		overflow: unset;
	}
}

/*table view*/
@media only screen and (max-width: 768px) {
	.boxAll {
		max-width: 672px !important;
		padding: 3.5rem 0 5rem 0;
		margin: 0 6.25%;
	}

	.paragraph p,
	.paragraph ul {
		font-size: 14px !important;
	}

	.title {
		font-size: 14px !important;
	}

	.txmdl1h {
		font: bold 18px Helvetica;
		margin-bottom: 4.5rem;
	}

	.cs01h {
		min-height: 400px;
	}

	.bxct02lft {
		height: auto;

		/* max-height: 326px; */
		padding: 2.5rem;
	}

	.paragraph {
		margin-bottom: 0;
	}

	.paragraph ul {
		padding-left: 1rem;
	}

	.bx0vrhdn {
		height: auto;
		overflow: unset;
	}

	.image {
		height: 470px;
	}
}
