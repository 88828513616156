.section {
    padding: 0 0 3.5rem 0;
}

.customContainer {
    padding: 0;
    max-width: 80vw;
    margin: 0 auto;
}

.content1 {
    margin-bottom: 2rem;
}

.content1 p {
    font: normal 16px Helvetica;
    line-height: 2rem;
}

.navBack a {
    text-decoration: none !important;
}

.navBack {
    position: relative;

    padding: 2rem 0 5rem;
    width: 30%;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.textBack {
    font: normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}


.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.backIcon {
    color: #000000;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}

.content2 {
    background-color: #d6d6d6;
    color: black;
    padding: 2rem;
}

.content2 li {
    text-align: justify;
}

.content3 {
    /* background-color: #d6d6d6; */
    color: black;
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem 2rem 0;
    cursor: pointer;
    font-size: 16px;
}




.customContainer a {
    text-decoration: none;
}

.content2 p,
.content2 ul,
.content2 li,
.content2 strong {
    font: normal 16px Helvetica;
    line-height: 3rem;
    /* padding: 4rem 5.5rem; */
}

@media only screen and (max-width: 1920px) {
    .customContainer {
        max-width: 80vw;
    }
}

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 78vw;
    }
}

@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: 78vw;
    }

    .content1 {
        margin-bottom: 1.5rem;
    }

    .content1 p {
        font: normal 14px Helvetica;
        line-height: 1.5rem;
    }

    .content2 {
        color: black;
    }

    .content2 p,
    .content2 ul,
    .content2 li,
    .content2 strong {
        font: normal 14px Helvetica;
        line-height: 2rem;
        /* padding: 4rem 5.5rem; */
    }
}

@media only screen and (max-width: 1024px) {
    .content3 {
        font-size: 14px;
    }

    .customContainer {
        max-width: 88vw;
    }
}