.contentSlider {
    /* margin-top: calc(4rem + 56px); */
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.box {
	position: relative;
	width: 78vw !important;
	height: 272px;
	background: transparent;
	margin: 0 2rem 0 1rem;
	cursor: url('../assets/drag.svg') 20 20, auto;
}

.contentImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contentDefaultImg {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.boxContent {
	width: 100%;
	height: 272px;
	position: absolute;
	top: 0;
	opacity: 1;
	z-index: 1;
	background: hsl(224 54% 29% / 0.5);
	display: flex;
}

.cardCustom {
	height: 50%;
	padding: 2rem;
	margin: auto;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.cardContent .customLink {
	text-decoration: none;
	display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.outerCircle2 {
	padding: 5px;
	transition: transform 0.2s;
	border-radius: 50%;
	border: 1px solid #f16624;
	display: inline-block;
	margin: 0 .5rem 0 auto;
	align-self: left;
	opacity: 0;
}

.innerCircle2 {
	content: '';
	background: #f16624;
	width: 8px;
	height: 8px;
	border-radius: 50%;
}

.posContent {
	font: bold 16px Helvetica;
	line-height: 1.5rem;
	color: white;
	text-align: right;
	padding-top: 0;
	margin-bottom: 0;
	text-transform: uppercase;
	text-align: right;
}

.readMore {
	font: 16px Helvetica;
	color: white;
	margin: 0;
	text-align: right;
}

.box:hover .contentImg, .box:hover .contentDefaultImg {
	filter: blur(2px);
}

.box:hover .outerCircle2 {
	opacity: 1;
}

.boxFirst {
	margin-left: 11vw;
}

@media only screen and (max-width: 900px) and (orientation:landscape) {
	.box{
		width: 40vw !important;
	}
	.boxFirst {
		margin-left: 8vw;
	}
}
