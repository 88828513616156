.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		both;
	animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right-rev {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		reverse both;
	animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse
		both;
}

.content-hamburger {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 56px;
	height: 56px;
	background: #00000080 0% 0% no-repeat padding-box;
}

.burger-item {
	mix-blend-mode: color;
}

.mn-fr {
	background-image: url('../assets/bg-menu.png');
	-backdrop-filter: blur(5px);
	filter: blur(5px);

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	/* background-color: rgb(0,0,0, 0.5); */
	/* opacity: 1; */
}

.mn-fr-op {
	clip-path: circle(100%);
	height: 100vh;
	width: 100vw;
	transition: clip-path 1s, opacity 0.5s;
}

.mn-fr-cl {
	clip-path: circle(0% at calc(100vw - 32px) 32px);
	height: 0;
	width: 0;
	transition: clip-path 1s, opacity 0.5s, width 0s 1s, height 0s 1s;
}
