.customContainer {
    padding-left: 0;
    padding-right: 0;
}

.titleSection {
    position: absolute;
    width: auto;

    top: 20%;
    left: 11%;
}

.titleSection p {
    font: Bold 80px/50px Helvetica;
    width: 100%;
    text-transform: uppercase;
    color: white;
}

.imagesBanner {
    width: auto;
}

.imagesBanner img {
    width: 1510px;
    height: 450px;
    object-fit: cover;
    margin: 3rem 0;
}

.contentWrap {
    padding: 5rem 0 0;
}

.titleAccordion {

    font-size: bold 20px Helvetica;

    .btnSpan {
        transition-timing-function: linear;
        transition: all 1s linear 0.3s;
        font-size: 20px;
    }

}

.bannerTop {
    top: 0;
    left: 0;
    width: 100vw;
    height: 65vh;
}

.bannerTop img {
    width: 100vw;
    position: absolute;
    height: 65vh;
    object-fit: cover;
}

.backLink {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: row;
    font: normal 16px Helvetica;
    align-items: center;
    color: black;
}

.navBack {
    position: relative;
    padding: 2rem 0 5rem;
    width: 30%;
}

.backIcon {
    color: #000000;
    margin-right: 7px;
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.textBack {
    font: normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.customLink {
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.componentViews {

    .groupAccordion {
        font: bold 16px Helvetica;

        .dateArcticle {
            margin: 1rem;
            width: 85px;
        }
    }

    .customAccordion>.customCard .cardHeader {
        border: 1px solid rgba(212, 212, 212, .6);
    }

    .cardHeader {
        font: bold 16px Helvetica;
        display: flex;
    }

    .dateArcticle {
        margin: 1rem;
        width: 85px;
    }

    .selectStyle {
        outline: transparent;
        border: none;
        background-color: transparent;
        font: 26px/20px Helvetica;
        text-transform: uppercase;
        padding-right: 2rem;
        margin-top: -1rem;
        width: auto;
        margin-right: 3rem;
    }

    .selectStyle option {
        font: normal 18px Helvetica;
    }

    .titleArticle {
        font: bold 16px Helvetica;
        margin: 1rem;
        line-height: 1.8rem;
    }

    .highlight {
        position: relative;
        display: flex;
        margin: 3rem 1rem 2rem;
    }

    .year {
        font: 1000 28px/26px Helvetica;
        width: 100%;
        text-transform: uppercase;
        margin-right: 2rem;
        opacity: 0.5;
        cursor: pointer;
    }

    .yearActive {
        opacity: 1 !important;
        font: 1000 32px/26px Helvetica;
        width: 100%;
        text-transform: uppercase;
        margin-right: 2rem;
        opacity: 0.1;
        cursor: pointer;

    }

    .titleTxt {

        font: bold 36px Helvetica;
        text-transform: uppercase;
        margin-bottom: 2rem;

    }

    .contentArtTxt {
        font: normal 16px/1.8rem Helvetica;
        height: 100%;
    }

    .imgLogo {
        margin: 0.5rem;
        border-left: solid 2px #ddd;
        padding-left: 1rem;
        width: 50px;
        height: auto;
    }

    .title1 {
        font: bold 32px Helvetica;
        text-transform: uppercase;
    }

    .catAwards {
        font: bold 16px Helvetica;
        opacity: 0.5;
        margin-bottom: 2rem;
    }

    .titleAccor {
        border: 1px solid #ddd;
        padding: 1.2rem;

        font: 20px Helvetica;

    }

    .titleAccorContent {
        font: bold 16px Helvetica;
        color: black;
    }

    .textAccor {
        padding: 2rem 3rem 2rem 10rem;
        border: solid #f1f1f1;
        font: normal 16px/32px Helvetica;
        text-align: justify;
    }

    .accordion__section {
        display: flex;
        flex-direction: column;
    }

    /* Style the buttons that are used to open and close the accordion panel */
    .accordion {
        background-color: #eee;
        color: #444;
        cursor: pointer;
        padding: 18px;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
    }

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .accordion:hover,
    .active {
        background-color: #ccc;
    }

    /* Style the accordion content title */
    .accordion__title {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
    }

    /* Style the accordion chevron icon */
    .accordion__icon {
        margin-left: auto;
        transition: transform 0.6s ease;
    }

    /* Style to rotate icon when state is active */
    .rotate {
        transform: rotate(90deg);
    }

    /* Style the accordion content panel. Note: hidden by default */
    .accordion__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;
    }

    /* Style the accordion content text */
    .accordion__text {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        padding: 18px;
    }
}

.accParent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accFirst {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.customLink {
    display: flex;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    color: black;
}

.customLink:hover .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    /* background-color: rgb(45, 57, 69, 0.5); */
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2000px !important;
    }
}

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 1100px;
    }

    .dateArcticle {
        min-width: 85px;
    }
}


@media only screen and (max-width:1440px) {

    .backLink {
        font: normal 14px Helvetica;
    }

    .customContainer {
        max-width: 1000px;
    }

    .navBack {
        padding: 2rem 0 5rem;
    }

    .customLink {
        font: normal 14px Helvetica;
    }

    .contentWrap {
        padding: 3.5rem 0 0;

        .componentViews {

            .titleTxt {
                font: bold 28px Helvetica;
                margin-bottom: 1.5rem;
            }

            .year {
                font: 1000 26px Helvetica;
            }

            .selectStyle {
                font: normal 18px helvetica;
            }

            .dateArcticle {
                font: bold 14px Helvetica;
                min-width: 85px;
            }

            .titleArticle {
                font: bold 14px Helvetica;
            }
        }

        .contentArtTxt {
            font: normal 14px Helvetica;
            line-height: 1.8;
        }
    }

}

@media only screen and (max-width:1366px) {
    .customContainer {
        max-width: 950px;
    }
}

@media only screen and (max-width:768px) {
    .bannerTop {
        height: 35vh;

        img {
            height: 35vh;
        }
    }

    .customContainer {
        max-width: 672px !important;
    }

    .contentWrap {
        .componentViews {
            .yearActive {
                font: 1000 32px/30px Helvetica;
            }

            .highlight {
                overflow: scroll;
                width: auto;
            }

            .titleTxt {
                font: bold 18px Helvetica !important;
            }
        }
    }

    .outerCircle {
        display: block;
        opacity: 1;
    }
}

@media only screen and (max-width:480px) {

    .contentArtTxt {
        margin-bottom: 3rem;
    }

    .bannerTop {
        height: 65vh;
        position: relative;
    }

    .bannerTop img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .customContainer {
        max-width: calc(100vw - 12vw) !important;
    }

    .contentWrap {
        padding: 5vh 0 0;
    }

    .highlight {
        margin: 2rem 0 2rem !important;
        overflow: unset !important;
        flex-direction: column;
    }

    .selectStyle {
        font: bold 14px Helvetica !important;
        margin-bottom: 1rem;
    }

    .accParent {
        flex-direction: column-reverse;
        position: relative;
        height: 100%;
        width: 100%;
        align-items: unset;
    }

    .accFirst {
        display: flex;
        flex-direction: row;
        justify-content: unset;
    }

    .imgLogo {
        border-left: none !important;
        padding-left: 0 !important;
        margin: 0 !important;
        height: 35px !important;
        width: 33px !important;
    }

    .cardHeader {
        height: 100%;
        width: 100%;
        padding: 1rem;
    }

    // .groupAccordion {
    //     height: 100%;
    //     width: 100%;
    //     display: flex;
    // }

    .titleArticle {
        margin: 0 1rem 1rem 1rem !important;
        font: bold 14px Helvetica;
    }

    .dateArcticle {
        min-width: 100% !important;
        width: 100% !important;
        margin: 0 0 0 3rem !important;
        font: 14px Helvetica;
    }

    .textAccor {
        padding: 1rem !important;
        font: 14px Helvetica !important;
        text-align: left !important;
    }

    .textBack {
        font: 16px/24px Helvetica;
    }

    .yearActive {
        font: 1000 18px/24px Helvetica !important;
        margin: 0;
    }

    .year {
        font: 1000 16px/24px Helvetica !important;
    }

    .year p,
    .yearActive p {
        margin: 0;
    }

    .customLink {
        margin-top: 1rem;
    }

    .customLink span {
        margin: auto 0;
    }
}