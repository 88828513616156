.gocNhin {
    display: block
}

.leftCol {
    min-height: 30px;
}

.rightCol {
    display: block;
    margin-top: 2rem;
    min-height: 361px;
}

.authorItem {
    width: 33vw !important;
    margin-right: 1rem;
    padding: 1rem .5rem;
    text-align: center;
    background-color: #f4f3ef;
    text-decoration: none;
    color: black;
}

.link {
    color: black;
}

.link:hover {
    color: black;
    text-decoration: none;
}

.contentC {
    display: flex;
}

.contentCLeft {
    width: calc(100% - 75px - 2rem);
}

.contentC:hover {
    background: rgba(0, 0, 0, 0.03);
    cursor: pointer;
}

.contentArtTitle {
    font: bold 16px Helvetica;
    color: #000000;
    line-height: 1.8;
}

.contentSummary {
    font: normal 14px Helvetica;
    line-height: 1.8;
}

.contentAuthorName {
    font: bold 14px Helvetica;
    padding-top: 2rem;
    position: relative;
    bottom: 0;
}

.authorPhoto {
    padding-left: 2rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.authorImg {
    border-radius: 50%;
    height: 75px;
    width: 75px;
}

/* .hrBottom:last-child {
    display: none;
} */

.readMoreBox {
    padding-left: 2rem;
    padding-top: 2rem;
}

.customLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    color: black;
}

.customLink:hover .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}


.readMore, .readMore:hover {
    text-decoration: none;
    color: black;
    font: normal 16px Helvetica;
}