.wrapp {
  height: 100vh;
  width: 100%;
  background-color: #f1f1f1;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.image_box {
  width: 100vw;
  height: 80vh;
  object-fit: cover;
  object-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  overflow: hidden;
  position: relative;
}

.h1 {
  height: 80vh;
}

.h2 {
  height: 20vh;
}

.image_small {
  width: 100vw;
  height: 15vh;
  display: block;
  margin-bottom: 1rem;
  /* flex-direction: row; */
}

.isFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backBoxBtn {
  width: 100vw;
  height: 5vh;
  /* background: white; */
}

.isBottom {
  width: 182px;
  height: 108px;
  /* background: burlywood; */
  /* padding-right: 1rem; */
}

.bxabs1 {
  width: 400px;
  height: 100%;
  position: absolute;
  background-color: rgb(255 255 255 / 0.55);
  /* right: 0; */
  top: 0;
  left: 0;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  padding: 2rem;
  overflow: hidden;
}

.colorbox {
  position: absolute;
  width: 80%;
  height: 22%;
  background-color: #00000040;
  z-index: 6;
  top: 65%;
  margin: auto;
}

.color1 {
  opacity: 0 !important;
}

.box {
  width: 384px;
  /* height: 100vh; */
  object-fit: cover;
  position: relative;
}

.box2 {
  object-fit: cover;
  /* position: relative; */
  width: 182px !important;
  height: 108px;
}

.figureimg {
  width: 100vw;
  height: 100%;
}

.title_mid {
  color: black;
  z-index: 1;
  font-size: 18px;
  font-weight: bold;
  width: auto;
  font-family: Helvetica;
  z-index: 6;
  text-align: justify;
  text-transform: capitalize;
}

.title_mid2 p {
  position: absolute;
  color: white;
  z-index: 1;
  font-size: 16px;
  font-weight: bold;
  width: auto;
  font-family: Helvetica;
  z-index: 6;
  padding: 2rem;
  opacity: 1;
  text-transform: capitalize;
}

.text_fake {
  color: rgb(115 112 112 / 45%);
  /* transform: rotate(90deg); */
  font-family: Helvetica;
  font-size: 100px;
  font-weight: 1000;
  position: relative;
  opacity: 1;
  text-align: center;
}

.bxparent {
  max-height: 300px;
  overflow: auto;
}

.p_text {
  font: normal 16px/1.8rem Helvetica;
  color: black;
  text-align: justify;
  padding-bottom: 1rem;
}

._box3 {
  width: auto;
  height: 20vh;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
}

.padd2 {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.boxImage {
  width: 100%;
  margin-top: 1rem;
}

.ctx003 {
  margin: 5% 0;
}

.isInside {
  width: 182px !important;
  height: 108px;
  background: #978f8f8a;
  position: absolute;
  top: 0;
}

.isYearText {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  width: 100%;
  height: 100%;
}

.isYearText p {
  font: bold 40px Helvetica;
  font-weight: 1000;
  color: #f1f1f1;
  margin-bottom: 0;
}

.activeTextReal p {
  font: bold 66px Helvetica;
  font-weight: 1000;
  color: white;
  margin-bottom: 0;
}

.activeBox {
  background: unset;
}

.active {
  opacity: 0;
  display: none;
}

.bxbtn09 {
  width: 200px;
  height: 5vh;
  /* background: chocolate; */
  position: absolute;
  z-index: 1;
  /* right: 0; */
  bottom: 20.6vh;
  /* right: 78%; */
  margin-left: 72vw;
  display: flex;
  flex-direction: row;
}

.arrowBtn {
  margin: auto;
  display: flex;
  justify-content: center;
}

.arrowBtn2 {
  margin: auto;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
}

.roundBtn {
  width: 7vw;
  height: 5vh;
  border-radius: 50px;
  background: #f4f4f457;
  margin-left: 1vw;
  display: flex;
  justify-content: center;
}

.roundBtn2 {
  width: 7vw;
  height: 5vh;
  border-radius: 50px;
  background: #f4f4f457;
  margin-left: 11vw;
  display: flex;
  justify-content: center;
}

.inActiveImg {
  display: none;
}

.backBoxBtn p {
  font: 16px Helvetica;
  color: black;
}

.backIcon {
  margin-right: 7px;
}

.mL03 {
  margin-left: 3vw;
}