.section {
    padding: 3.5rem 0;
}

.customContainer {
    padding: 0;
    max-width: 88vw;
    margin: 0 auto;
}

.title {
    font: bold 18px Helvetica;
    margin-bottom: 2rem;
}

.content {
    margin-bottom: 2rem;
}

.content p {
    font: normal 14px Helvetica;
    line-height: 1.5rem;
    text-align: justify;
}

.subTitle {
    font: bold 18px Helvetica;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.cardContainer {
    max-width: 100%;
    /* height: 400px; */
}

.card {
    background: #d6d6d6;
    width: 230px !important;
    margin-left: 6vw;
    min-height: 271px;
    border-radius: 20px;

}

.card:nth-child(2) {
    background: #00699D;
}



.cardTitle {
    margin-top: 1rem;
    font: bold 18px Helvetica;
    margin-bottom: 12px;
    text-align: center;
}

.cardContent {
    padding: 0 2rem;
}

.cardContent p {
    font: medium 14px Helvetica;
    line-height: 1.5rem;
    text-align: justify;
}

.marginLeft {
    margin-left: 6vw;
}