#custom-tooltip > .arrow {
    display: none;
	background-color: transparent;
	transform: translate(0px, 4px) !important;
}

#custom-tooltip > .arrow::before {
	background-color: transparent;
	transform: rotate(0deg);
}

#custom-tooltip > .arrow::after {
	background-color: transparent;
}
