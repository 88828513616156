.groupAccordion {
    font: bold 16px Helvetica;

    .dateArcticle {
        margin: 1rem;
        width: 85px;
    }
}

.cardHeader {
    font: bold 16px Helvetica;
    display: flex;
    border: 1px solid rgba(212, 212, 212, .6);
    &:hover {
        cursor: pointer;
    }
}

.accParent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accFirst {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.titleArticle {
    margin: 1rem;
    line-height: 1.8rem;
}

.textAccor {
    padding: 2rem 3rem 2rem 10rem;
    border: solid #f1f1f1;
    font: normal 16px/32px Helvetica;
    text-align: justify;
}

.customLink {
    display: flex;
    text-decoration: none;
    align-items: center;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    font: normal 16px/32px Helvetica;
    color: black;
}

.customLink:hover .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    /* background-color: rgb(45, 57, 69, 0.5); */
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.readMoreBox {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    font: normal 14px Helvetica;
    line-height: 1.8;
    margin-top: 1rem;
}


@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1660px) {

}

@media only screen and (max-width: 1440px) {
    .customLink span {
        font: normal 14px/28px Helvetica;
    }

    .textAccor {
        font: normal 14px/28px Helvetica;
    }
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {

}