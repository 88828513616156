.tabProfile {
    display: flex;
    margin: auto 0;
}

.navbarContent {
    position: relative;
    margin: auto 2rem;
    cursor: pointer;
}

.navbarContent span {
    text-align: left;
    font: 16px/40px Helvetica;
    color: #000000;
}

.navActive:after {
    content: "";
    display: block;
    margin-top: 5px;
    height: 2px;
    background: #223771;
    width: 150%;
    transition: width 0.3s;
    position: absolute;
}

@media only screen and (max-width: 480px) {
    .navActive:after {
        width: 80%;
    }
    .navbarContent {
        width: 50%;
        margin: auto;
    }

}

@media only screen and (max-width: 900px) and (orientation:landscape) {
    .navbarContent:first-child {
        margin: auto 2rem auto 0;
    }   
}