.Section1 {
    background-color: transparent;
    width: auto;
    height: calc(65vh + 86px + 10rem);
}

.imgBackground {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.overlayBackground {
    background: #ffffffd0 0 0 no-repeat padding-box;
    opacity: 0.1;
    position: absolute;
}

.boxImage {
    position: relative;
}

.imgContent {
    width: 100%;
    height: 65vh;

    object-fit: cover;
}

.contentInBox {
    position: relative;
    top: -35.4vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
}

.contentInBox p,
.contentInBox h1 {
    font: bold 48px Helvetica;
    text-transform: uppercase;
    letter-spacing: 0;
    color: black;

    text-align: center !important;
}

.p_0_underline {
    display: block;
    height: 2px;
    width: 700px;
    margin-left: auto;
    margin-right: 26%;
    background-color: black;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-anim;
}

@keyframes underline-anim {

    0%,
    10% {
        width: 0;
        transform: translateX(-750px);
    }

    10%,
    20% {
        width: 700px;
        transform: translateX(0);
    }

    100%,
    30% {

        width: 0;
    }
}

.textInside p {

    text-align: right;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: header-anim;
}

@keyframes header-anim {

    0%,
    30% {
        transform: translateX(-5%);
        opacity: 0;
    }

    30%,
    70% {
        transform: translateX(0);
        opacity: 1;
    }

    70%,
    99% {
        opacity: 1;
    }

    100%,
    99% {
        opacity: 0;
    }
}

.boxTextBottom {
    font: normal 16px Helvetica !important;
    margin: 0 1rem;
}

.boxTextBottom p {
    line-height: 1.8;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
}

.boxC {
    max-width: 1540px;
    position: relative;
    top: -5rem;
    padding: 5rem 0;
}

@keyframes animate {
    0% {
        transform: translateX(500);
    }

    100% {
        transform: translateX(-300%);
    }
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2090px !important;
    }

    .Section1 {
        background-color: transparent;
        width: auto;
        height: calc(65vh + 57px + 10rem);
    }

    .boxTextBottom {
        font: normal 18px Helvetica !important;
    }
}

@media only screen and (max-width: 1680px) {
    .Section1 {
        height: calc(65vh + 98px + 10rem);
    }

    .boxImage {
        height: auto;
    }

    .imgContent {
        height: auto;
    }

    .contentInBox p,
    .contentInBox h1 {
        font: bold 36px Helvetica;
        -webkit-text-stroke: 0.8px rgb(5 54 144 / 70%);
    }

    .contentInBox {
        top: -40.4vh;
    }

    .boxTextBottom {
        font-size: 16px !important;
        max-width: 1300px;
        margin: auto;
    }

    .boxC {
        max-width: 1300px;
    }

    .p_0_underline {
        width: 430px;
        margin-right: 34%;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-name: header-anim;
    }

    @keyframes underline-anim {

        0%,
        10% {
            width: 0;
            transform: translateX(-430px);
        }

        10%,
        20% {
            width: 150px;
            transform: translateX(0);
        }

        100%,
        30% {

            width: 430px;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: 76vw !important;
    }

    .overlayBackground {
        height: 100vh;
    }

    .imgBackground {
        height: 100vh;
    }

    .boxC {
        top: -4rem;
        padding: calc(17.5vh - 75px) 0;
    }

    .boxTextBottom {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1025px) {

    .Section1,
    .overlayBackground,
    .imgBackground {
        min-height: 720px;
    }


}


@media only screen and (max-width: 1366px) and (max-height: 650px) {
    .Section1 {
        height: calc(69vh + 98px + 10rem);
    }

    .imgBackground {
        height: unset;
    }
}

@media only screen and (max-width: 1024px) {
    .Section1 {
        height: 100% !important;
    }

    .boxImage {
        height: 65vh;
    }

    .boxC {
        padding: 3.5rem 0;
    }
}

@media only screen and (max-width: 768px) {
    .Section1 {
        height: calc(65vh + 98px + -13rem) !important;
    }

    .boxC {
        top: -26rem !important;
        max-width: 88%;
    }

    .contentInBox {
        top: -52.4vh !important;
    }

    .contentInBox p,
    .contentInBox h1 {
        font: bold 18px Helvetica !important;


    }

    .p_0_underline {
        margin-right: 34% !important;
        width: 225px !important;
    }

    .boxTextBottom p {
        width: 88% !important;
        margin: auto;
    }

    .imgBackground {
        height: 55vh !important;
    }

    .boxTextBottom {
        max-width: unset;
    }

    .boxTextBottom p {
        width: unset !important;
    }
}

@media only screen and (max-width: 1024px) {
    .Section1 {
        height: calc(63vh + 98px + -13rem) !important;
    }

    .boxC {
        top: -28rem;
        max-width: 88%;
    }

    .contentInBox {
        top: -52.4vh !important;
    }

    .contentInBox p,
    .contentInBox h1 {
        font: bold 18px Helvetica !important;


    }

    .p_0_underline {
        margin-right: 34% !important;
        width: 225px !important;
    }

    .boxTextBottom p {
        width: 88%;
        margin: auto;
    }

    .imgBackground {
        height: 60vh !important;
    }
}