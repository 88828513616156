.groupAccordion {
    font: bold 14px Helvetica;

    .dateArcticle {
        margin: 1rem;
        width: 85px;
        font-size: 14px;
    }
}

.dateArcticle {
    font: bold 14px Helvetica;
}

.cardHeader {
    font: bold 14px Helvetica;
    display: flex;
    border: 1px solid rgba(212, 212, 212, .6);
    padding: 1rem;
    &:hover {
        cursor: pointer;
    }
}

.accParent {
    display: block;
    padding: .75rem 0;
}

.accFirst {
    display: block;
}

.titleArticle {
    line-height: 1.8rem;
    font-size: 14px;
    margin: 0;
}

.textAccor {
    border: solid #f1f1f1;
    font: normal 14px/1.5rem Helvetica;
    text-align: justify;
}

.customLink {
    display: flex;
    text-decoration: none;
    align-items: center;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    font: normal 14px Helvetica;
    color: black;
}

.customLink .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    /* background-color: rgb(45, 57, 69, 0.5); */
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.readMoreBox {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    font: normal 14px Helvetica;
    line-height: 1.8;

    .rightBox {
        margin-top: 1rem;
    }
}