.section {
    padding: 5rem 0 0 0;
}

.customContainer {
    padding: 0;
    max-width: 80vw;
    margin: 0 auto;
}

.card {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.content {
    margin: auto 0;
}

.figure {
    width: 314px;
    height: 274px;
    margin-right: 2rem;
}

.imgLeft {
    width: 314px;
    height: 100%;
    object-fit: cover;
}

.cardTitle {
    font: bold 20px Helvetica;
    margin-bottom: 2rem;
}

.cardContent p {
    font: normal 16px Helvetica;
    line-height: 2rem;
    text-align: justify;
}


@media only screen and (max-width: 1920px) {
    .customContainer {
        max-width: 80vw;
    }

    .marginLeft {
        margin-left: 0;
    }
}

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 78vw;
    }
}

@media only screen and (max-width: 1440px) {
    .section {
        padding: 3.5rem 0 0 0;
    }

    .customContainer {
        max-width: 78vw;
    }

    .cardTitle {
        font: bold 18px Helvetica;
        margin-bottom: 1rem;
    }

    .cardContent p {
        font: normal 14px Helvetica;
        line-height: 1.5rem;
    }
}

@media only screen and (max-width: 1024px) {
    .customContainer {
        max-width: 88vw;
    }

    .card {
        margin-bottom: 4rem;
    }

    .figure {
        width: 263px;
        height: 230px;
        margin-right: 2rem;
    }

    .imgLeft {
        width: 263px;
        height: 100%;
        object-fit: cover;
    }
}