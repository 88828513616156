/* GENERAL */

:root {
    --title-hd: bold 28px Helvetica;
    --subtitle-hd: bold 18px Helvetica;
    --p-hd: normal 14px Helvetica;
    --container-hd: 1062px !important;
    --padding-section-hd: 3.5rem 0;
    --title-fhd: bold 36px Helvetica;
    --subtitle-fhd: bold 26px Helvetica;
    --p-fhd: normal 16px Helvetica;
    --container-fhd: 1540px !important;
    --padding-section-fhd: 5rem 0;
    --title-tablet: bold 18px Helvetica;
    --subtitle-tablet: bold 14px Helvetica;
    --p-tablet: normal 14px Helvetica;
    --container-tablet: 88vw;
}

/* SPECIFIC */

.row_1 {
    margin: auto;
    width: 100%;
    height: auto;
    background-color: #f7f7f7;
    display: flex;
    position: relative;
    padding-bottom: 5rem;
    padding-top: 5rem;
}

.title_history {
    padding: 20px 0 20px 280px;
}

.content {
    width: 1440px;
    height: 592px;
    background-color: white;
    margin: auto;
    display: flex;
}

.margin {
    margin: auto 2rem;
    opacity: 1 !important;
}

.joinPad {
    max-width: 1540px;
}

.contentBoxTitle {
    margin: auto auto auto 1.5rem;
    color: #f26522;
    font: bold 50px Helvetica;
}

.boxslide {
    position: relative;
    width: 920px;
    padding: 120px 10px 10px 58px;
}

.title_box {
    font-size: 36px;
    font-family: Helvetica;
    font-weight: bolder;
}

.text_box1 {
    font-size: 16px;
    font-family: Helvetica;
}

.text2_box1 {
    font-size: 20px;
    color: blue;
}

.button_box1 {
    border-radius: 100px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding: 20px 70px;
    border: none;
    margin-top: 30px;
    font-family: Helvetica;
}

.boxslide2 {
    width: 520px;
}

.year {
    width: 100%;
}

.Link {
    color: white !important;
}

.Link:hover {
    text-transform: none;
    text-decoration: none;
}

.card_group {
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
    border-color: white;
    padding-top: 50px;
}

.card_body {
    padding: 0;
    width: 720px;
}

.card_row_1_1 {
    border-color: white;
    width: 720px;
    height: 550px;
}

.card_title_row_1 {
    font-size: 32px;
    font-weight: bold;
    font-family: Helvetica;
    color: black !important;
}

.img_row_1 {
    height: 440px;
    width: 720px;
    object-fit: fill;
}

.footer_row_1 {
    background-color: #223771 !important;
    position: absolute;
    bottom: 0;
    width: 37.5vw;
    font-size: 16px;
    color: white;
}

.card_text_row_1_1 {
    font-size: 18px;
    padding-bottom: 10px;
    font-family: Helvetica;
    margin-top: 5px;
}

.card_text_row_1_2 {
    position: absolute;
    bottom: 9%;
    font-family: Helvetica;
    font-size: 22px;
    width: 47vw;
    padding-left: 10px;
    background-color: white;
    left: -25%;
    height: 15vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    display: flex;
}



.card_row_1_2 {
    border-color: white;
    width: 720px;
    height: 550px;
    background-color: white;
}

.row_2 {
    height: 50vvh;
    position: relative;
    margin: auto;
}

.card_img_2 {
    height: 488px;
    filter: grayscale(100%);
    background-color: white;
    background-image: linear-gradient(white, rgb(86, 43, 800));
    background-position: center;
    object-fit: cover;
    opacity: 0.7;
}

.text_h2 {
    font-size: 52px;
    font-weight: bold;
    color: #f16624;
}

.text_normal {
    padding: 20px 75px 20px 20px;
    font-weight: bold;
}

.title_row_2 {
    background-color: #223771;
    border-radius: 10px;
    color: white;
    padding: 10px;
    font-size: 24px;
    border-radius: 30px;
    width: 10vw;
    text-align: center;
    font-family: Herlvetica;
}

.arrow {
    font-size: 60px;
    position: relative;
    color: #f26522;
    right: -85%;
    top: 5%;
    width: 30px;
    bottom: 45px;
}

.title_map {
    font-weight: bold;
    position: absolute;
    left: 15%;
    font-size: 32px;
    font-family: Helvetica;
    top: 30%;
    text-transform: uppercase;
    z-index: 2;
}

.box_1 {
    width: 480px;
    height: 176px;
    background-color: #223771;
    border: 1px solid white;
    display: flex;
}

.box_2 {
    width: 480px;
    height: 176px;
    background-color: #223771;
    border: 1px solid white;
    display: flex;
}

.box_3 {
    width: 480px;
    height: 176px;
    background-color: #223771;
    border: 1px solid white;
    display: flex;
}

.boxText {
    width: 191px;
    height: 66px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    align-self: center;
    margin: auto;
}

.box_text_1 {
    font-size: 55px;
    color: white;
    margin: auto 0;
}

.box_text_2 {
    font-size: 26px;
    color: white;
    margin: auto 0 10px 10px;
}

.box_text_3 {
    font-size: 20px;
    color: white;
    margin: 10px 0 0;
    align-self: center;
}

.box_3_text_1 {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 55px;
}

.row_3 {
    position: relative;
    margin: 10px auto auto;
    justify-content: center;
    height: 327px;
    display: flex;
}

.col_1 {
    width: 50%;
}

.col_2 {
    width: 50%;
}

._boxSlider {
    width: 900px;
    height: 70px;
    display: flex !important;
    flex-direction: row;
}

._boxYear {
    width: 200px;
    margin: auto 1rem;
    text-align: center;
}

.absBtm {
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: row;
    margin-left: -280px;
}

._box4 {
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #223771;
}

._box4 p {
    text-decoration: none !important;
}

._box1 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.boxImage {
    height: 408px;
    position: relative;
}

.boxBottom {
    width: 200px;
    height: 192px;
    position: relative;
    background-color: rgb(34 55 113 / 75%);
    transition: width 0.5s ease-in-out;
}

.contentInsideBox {
    position: absolute;
    bottom: 0;
    transition: height 0.5s;
    opacity: 0.7;
}

.insideBox {
    display: flex;
    margin: 4.5rem 1.5rem 4rem;
}

.inside_image {
    width: 1510px;
    height: 440px;
    position: absolute;
    background-color: rgb(6 6 6 / 23%);
    opacity: 0;
    color: rgb(0, 0, 0);
    top: 14.5%;
}

.boxYear {
    font: bold 22px Helvetica;
    color: black;
}

.img_col_1 {
    width: 755px;
    height: 408px;
    object-fit: cover;
    float: right;
}

.card_2020 {
    width: 1048px;
    height: 192px;
    background-color: rgb(34 55 113 / 75%);
    padding: 4rem 3.5rem 4rem 1.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
}

._boxTitle {
    width: 700px;
    align-self: center;
    /* justify-content: center; */
    display: flex !important;
    height: 100px;
    position: relative;
}

.boxLinks {
    position: relative;
    margin: auto 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    text-decoration: none !important;
}

.readMore {
    cursor: pointer;
    color: white;
    font: normal 16px Helvetica;
}

.outerCircle2 {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    margin: 0 0 0.3rem 3rem;
    align-self: left;
    opacity: 0;
    position: absolute;
    top: 27%;
}

.innerCircle2 {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.boxLinks:hover .outerCircle2 {
    opacity: 1;
}

.boxLinks:hover .foot {
    margin-left: 1.5rem;
    font-weight: bold;
}

.boxLinks:hover .foot p {
    text-decoration: none !important;
}

.contentTl {
    font: 16px Helvetica;
    text-align: left;
    color: white;
    margin: auto 1.5rem;
    line-height: 1.8rem !important;
}

p._boxTitle {
    margin: auto auto auto 1rem;
}

._bTextYear {
    width: 206px;
    height: auto;
    position: relative;
    justify-content: center;
    /* margin-top: 1rem; */
    margin-right: 5rem;
    align-self: center;
}

._bTextEvents {
    width: 617px;
    height: auto;
    position: relative;
    justify-content: center;
    /* margin-top: 1rem; */
    align-self: center;
}

._bImg {
    width: auto;
    height: auto;
    position: relative;
    justify-content: center;
    /* margin-top: 1rem; */
    margin-right: .5rem;
    align-self: center;
}

._bImgR {
    width: auto;
    height: auto;
    position: relative;
    justify-content: center;
    margin-top: 1rem;
    margin-left: 2rem;
    align-self: center;
}

.foot {
    position: relative;
    text-align: left;
    color: white;
    padding-left: 3rem;
    padding-top: 15px;
    font: 16px Helvetica !important;
}

.absBtm2 {
    position: absolute;
    bottom: 0;
}

.p_col_2 {
    height: 100px;
    width: 640px;
}

.arrow_2 {
    width: 24px;
    cursor: pointer;
}

.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.judul {
    color: black;
    font-size: 36px;
    font-family: Helvetica;
    font-weight: bold;
    margin-bottom: 2rem;
    line-height: 1.8rem;
}

.text {
    padding-left: 15rem;
}

.spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
    position: absolute;
    bottom: -39%;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@media only screen and (min-width: 2560px) {
    .joinPad {
        max-width: 2090px !important;
    }

    .img_col_1 {
        width: 1030px;
        height: 560px;
    }

    .card_2020 {
        width: 1330px;
        height: 220px;
        padding: 4rem 3.5rem;
    }

    ._bTextYear {
        width: 170px;
        margin-right: 7rem;
    }

    ._bTextEvents {
        width: 855px;
    }

    .contentBoxTitle {
        margin: auto auto auto 1rem;
    }

    .box4 {
        height: 60px;
    }

    .foot {
        padding-left: 5.6rem;
    }

    .boxLinks:hover .foot {
        margin-left: 0;
    }

    .judul {
        margin-bottom: 2rem;
    }

    .boxLinks {
        position: relative;
        margin: auto 0;
        text-align: left;
        display: flex;
        flex-direction: row;
        text-decoration: none !important;
    }

    .readMore {
        cursor: pointer;
        color: white;
        font: normal 16px Helvetica;
    }

    .outerCircle2 {
        padding: 5px;
        transition: transform 0.2s;
        border-radius: 50%;
        border: 1px solid #f16624;
        display: inline-block;
        margin: 0 0 0.3rem 5.3rem;
        align-self: left;
        opacity: 0;
        position: absolute;
        top: 27%;
    }

    .innerCircle2 {
        content: "";
        background: #f16624;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    .boxLinks:hover .outerCircle2 {
        opacity: 1;
    }

    .boxLinks:hover .foot {
        margin-left: 1.5rem;
        font-weight: bold;
    }

    .boxLinks:hover .foot p {
        text-decoration: none !important;
    }
}

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 78vw;
        padding: 0;
    }

    .img_col_1 {
        width: 654px;
    }

    .card_2020 {
        width: 903px;
    }

    .contentTl {
        /* margin: 0 auto 0 50px */
    }

    ._bTextEvents {
        width: 477px;
    }
}

@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: 1200px;
    }

    .outerCircle2 {
        margin-left: 2rem;
    }

    ._boxYear {
        width: 100px;
        margin: auto 1rem auto 0;
    }

    .card_2020 {
        width: 696px;
        height: 100px;
        padding: 0;
    }

    ._bTextYear {
        width: 134px;
        margin-right: 1rem;
    }

    ._bTextEvents {
        width: 465px;
    }

    ._boxTitle {
        margin: 0;
    }

    ._bImg {
        /* margin-top: .5rem; */
        margin-left: 18px;
    }

    ._bImgR {
        margin-top: .5rem;
        margin-right: 18px;
        margin-left: 0;
    }

    .col_1 {
        height: 279px;
    }

    .img_col_1 {
        width: 532px;
        height: 268px;
        position: absolute;
        right: 0;
        top: 11px;
    }

    .joinPad {
        max-width: 1200px;
        padding: unset;
    }

    .boxBottom {
        height: 100px;
        width: 116px;
    }

    .insideBox {
        margin: 0;
        bottom: 0;
        position: relative;
        padding-top: 2rem;
    }

    .contentBoxTitle {
        margin: 0 auto auto 1rem;
        font: bold 30px Helvetica;
    }

    .contentTl {
        font: normal 14px Helvetica;
        /* margin: 0 1.5rem; */
    }

    .spinner {
        bottom: -64%;
    }

    .foot {
        position: relative;
        width: 500px;
        padding-left: 2rem;
        font: normal 14px Helvetica !important;
    }

    .p_col_2 {
        height: 300px;
        width: 400px;
        font-size: 14px;
    }

    .absBtm {
        margin-left: -146px;
    }

    .arrow_2 {
        right: 6%;
        top: 40%;
        width: 18px;
    }

    .judul {
        color: black;
        font: bold 28px Helvetica;
        /* margin-left: 9.5rem; */
    }

    .card_img_2 {
        height: 314px;
        object-fit: contain;
        opacity: 0.7;
        width: 100vw;
    }

    .title_map {
        font-size: 22px;
        left: 22%;
    }

    .text {
        padding-left: 12rem;
    }

    .row_1 {
        padding-bottom: 3.5rem;
        padding-top: 3.5rem;
    }

    .year {
        position: absolute;
        top: 8%;
    }
}

@media only screen and (max-width: 1366px) {
    .customContainer {
        max-width: 1062px;
    }

    .judul {
        margin-left: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .row_1 {
        padding-bottom: 48px;
        padding-top: 48px;
    }

    .customContainer {
        max-width: 88vw;
        margin: 0 auto;
    }

    .judul {
        margin: 0 0 22px 0;
        font: var(--title-tablet);
    }

    ._bTextEvents {
        width: 364px;
        margin-top: 0;
    }

    ._boxTitle {
        display: flex !important;
        height: 84px;
    }

    .absBtm2 {
        width: 50%;
    }

    .card_2020,
    ._box4 {
        width: 100%;
    }

    .img_col_1 {
        width: 100%;
    }

    .boxLinks .outerCircle2 {
        opacity: 1;
        margin: unset;
        margin-left: 1.5rem;
    }

    .boxLinks .foot {
        margin-left: 1.5rem;
        font-weight: bold;
    }

    .boxLinks .foot p {
        text-decoration: none !important;
    }

    .contentTl {
        margin: auto .7rem;
        height: auto;
        line-height: 1.5rem !important;
    }

    ._bTextYear {
        margin-top: 0;
    }
}

@media only screen and (max-width: 900px) {
    ._bTextEvents {
        width: 254px;
    }

    ._boxTitle {
        height: 135px;
    }

    ._bImgR {
        margin-left: .5rem;
    }


    ._bTextYear {
        margin-top: 0;
    }

    ._bTextEvents {
        height: 100%;
        margin: auto;
    }

    ._bImg {
        margin-left: .5rem;
        padding-right: .5rem;
    }

    .contentTl {
        margin: auto;
        height: auto;
        line-height: 1.5rem !important;
    }

    .card_2020 {
        height: 13vh;
    }

    ._boxTitle {
        margin: auto;
        display: flex !important;
    }
}