.--m-subtitle-chart {
    font: bold 14px Helvetica;
}
.--m-chart-content {
    font: 14px Helvetica;
}
.m-1-1 {
    width: 1px;
    height: 140px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
    /* animation: left-1 2s; */
}

.m-1-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

.m-1-2 {
    width: 5%;
    height: 1px;
    top: 20px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-1-x1-title {
    width: 100%;
    height: 1px;
    top: 12px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 14px Helvetica;
    text-align: left;
}

.m-1-3 {
    width: 1px;
    height: 75px;
    top: 30px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-4 {
    width: 5%;
    height: 1px;
    top: 45px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x1-content {
    width: 100%;
    height: 1px;
    top: 38px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-1-5 {
    width: 5%;
    height: 1px;
    top: 65px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x2-content {
    width: 100%;
    height: 1px;
    top: 58px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-1-6 {
    width: 5%;
    height: 1px;
    top: 85px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x3-content {
    width: 100%;
    height: 1px;
    top: 78px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-1-7 {
    width: 5%;
    height: 1px;
    top: 105px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x4-content {
    width: 100%;
    height: 1px;
    top: 98px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

/* 2 */
.m-1-8 {
    width: 5%;
    height: 1px;
    top: 140px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-1-x2-title {
    width: 100%;
    height: 1px;
    top: 132px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 14px Helvetica;
    text-align: left;
}

.m-1-9 {
    width: 1px;
    height: 75px;
    top: 152px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-10 {
    width: 5%;
    height: 1px;
    top: 165px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x5-content {
    width: 100%;
    height: 1px;
    top: 158px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-1-11 {
    width: 5%;
    height: 1px;
    top: 185px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x6-content {
    width: 100%;
    height: 1px;
    top: 178px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-1-12 {
    width: 5%;
    height: 1px;
    top: 205px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x7-content {
    width: 100%;
    height: 1px;
    top: 198px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-1-13 {
    width: 5%;
    height: 1px;
    top: 225px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-1-x8-content {
    width: 100%;
    height: 1px;
    top: 218px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

/* 3 */
.m-2-1 {
    width: 1px;
    height: 220px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
    /* animation: left-1 2s; */
}

.m-2-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

.m-2-2 {
    width: 5%;
    height: 1px;
    top: 20px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x1-content {
    width: 100%;
    height: 1px;
    top: 12px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-3 {
    width: 5%;
    height: 1px;
    top: 40px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x2-content {
    width: 100%;
    height: 1px;
    top: 32px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-4 {
    width: 5%;
    height: 1px;
    top: 60px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x3-content {
    width: 100%;
    height: 1px;
    top: 52px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-5 {
    width: 5%;
    height: 1px;
    top: 80px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x4-content {
    width: 100%;
    height: 1px;
    top: 72px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}


.m-2-6 {
    width: 5%;
    height: 1px;
    top: 100px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x5-content {
    width: 100%;
    height: 1px;
    top: 92px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-7 {
    width: 5%;
    height: 1px;
    top: 120px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x6-content {
    width: 100%;
    height: 1px;
    top: 112px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-8 {
    width: 5%;
    height: 1px;
    top: 140px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x7-content {
    width: 100%;
    height: 1px;
    top: 132px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-9 {
    width: 5%;
    height: 1px;
    top: 160px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x8-content {
    width: 100%;
    height: 1px;
    top: 152px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-2-10 {
    width: 5%;
    height: 1px;
    top: 180px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x9-content {
    width: 70%;
    height: 1px;
    top: 172px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}
.m-2-11 {
    width: 5%;
    height: 1px;
    top: 220px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-2-x10-content {
    width: 100%;
    height: 1px;
    top: 211px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

/* 4 */

.m-4-1 {
    width: 1px;
    height: 100px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
    /* animation: left-1 2s; */
}

.m-4-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

.m-4-2 {
    width: 5%;
    height: 1px;
    top: 20px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-4-x1-title {
    width: 100%;
    height: 1px;
    top: 12px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 14px Helvetica;
    text-align: left;
}

.m-4-3 {
    width: 1px;
    height: 35px;
    top: 30px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-4-4 {
    width: 5%;
    height: 1px;
    top: 45px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-4-x1-content {
    width: 100%;
    height: 1px;
    top: 38px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-4-5 {
    width: 5%;
    height: 1px;
    top: 65px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-4-x2-content {
    width: 100%;
    height: 1px;
    top: 58px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-4-6 {
    width: 5%;
    height: 1px;
    top: 100px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-4-x2-title {
    width: 100%;
    height: 1px;
    top: 92px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 14px Helvetica;
    text-align: left;
}

/* 5 */

.m-5-1 {
    width: 1px;
    height: 261px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
    /* animation: left-1 2s; */
}

.m-5-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

.m-5-2 {
    width: 5%;
    height: 1px;
    top: 20px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x1-content {
    width: 100%;
    height: 1px;
    top: 12px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-3 {
    width: 5%;
    height: 1px;
    top: 45px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x2-content {
    width: 100%;
    height: 1px;
    top: 37px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-4 {
    width: 5%;
    height: 1px;
    top: 70px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x3-content {
    width: 100%;
    height: 1px;
    top: 62px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-5 {
    width: 5%;
    height: 1px;
    top: 95px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x4-content {
    width: 100%;
    height: 1px;
    top: 87px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}


.m-5-6 {
    width: 5%;
    height: 1px;
    top: 120px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x5-content {
    width: 85%;
    height: 1px;
    top: 112px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-7 {
    width: 5%;
    height: 1px;
    top: 160px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x6-content {
    width: 100%;
    height: 1px;
    top: 152px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-8 {
    width: 5%;
    height: 1px;
    top: 185px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x7-content {
    width: 100%;
    height: 1px;
    top: 177px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-9 {
    width: 5%;
    height: 1px;
    top: 210px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x8-content {
    width: 100%;
    height: 1px;
    top: 202px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-10 {
    width: 5%;
    height: 1px;
    top: 235px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x9-content {
    width: 100%;
    height: 1px;
    top: 227px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-11 {
    width: 5%;
    height: 1px;
    top: 260px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x10-content {
    width: 100%;
    height: 1px;
    top: 252px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-12 {
    width: 5%;
    height: 1px;
    top: 285px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x11-content {
    width: 100%;
    height: 1px;
    top: 277px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-13 {
    width: 5%;
    height: 1px;
    top: 310px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x12-content {
    width: 100%;
    height: 1px;
    top: 302px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-14 {
    width: 5%;
    height: 1px;
    top: 320px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x13-content {
    width: 100%;
    height: 1px;
    top: 312px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-5-15 {
    width: 5%;
    height: 1px;
    top: 345px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-5-x14-content {
    width: 100%;
    height: 1px;
    top: 337px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

/* 7 */

.m-7-1 {
    width: 1px;
    height: 140px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-7-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

.m-7-2 {
    width: 5%;
    height: 1px;
    top: 20px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-7-x1-title {
    width: 100%;
    height: 1px;
    top: 12px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 14px Helvetica;
    text-align: left;
}

.m-7-3 {
    width: 1px;
    height: 75px;
    top: 30px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-7-4 {
    width: 5%;
    height: 1px;
    top: 45px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-7-x1-content {
    width: 100%;
    height: 1px;
    top: 38px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-7-5 {
    width: 5%;
    height: 1px;
    top: 65px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-7-x2-content {
    width: 100%;
    height: 1px;
    top: 58px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-7-6 {
    width: 5%;
    height: 1px;
    top: 85px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-7-x3-content {
    width: 100%;
    height: 1px;
    top: 78px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-7-7 {
    width: 5%;
    height: 1px;
    top: 105px;
    left: 20%;
    display: block;
    opacity: 1;
}

.m-7-x4-content {
    width: 100%;
    height: 1px;
    top: 98px;
    left: 27%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 14px Helvetica;
    text-align: left;
}

.m-7-8 {
    width: 5%;
    height: 1px;
    top: 140px;
    left: 5%;
    display: block;
    opacity: 1;
}

.m-7-x2-title {
    width: 100%;
    height: 1px;
    top: 132px;
    left: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 14px Helvetica;
    text-align: left;
}