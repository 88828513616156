.customContainer {
    padding: 2rem 0;
    padding-left: 0mm;
    padding-right: 0mm;
  }

  .wrapHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    /* height: 60px; */
    background-color: #d4d4d4;
  }
  
  .firstColumn {
    width: 100%;
    height: 100%;
  }
  .secondColumn {
    width: 100%;
    height: 100%;
  }
  .thirdColumn {
    width: 100%;
    height: 100%;
  }
  .fourthColumn {
    width: 100%;
    height: 100%;
  }
  
  .wrapHeader span {
    font: 14px Helvetica;
    text-align: left;
    color: black;
    margin: auto;
  }
  
  .wrapHeader div {
    display: flex;
    justify-content: center;
  }
  
  .customRow {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
    font: 14px/28px Helvetica;
    text-align: justify;
  }
  
  .customRow ul > li {
    padding-bottom: 0.5rem;
  }
  
  .wrapTable {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 100%;
    height: 100%;
  }
  
  .wrapTable .customRow > div {
    padding:2rem;
  }
  
  .boxIcon {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120px;
  }
  
  .firstColumnT {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .secondColumnT {
    width: 100%;
    height: 100%;
  }
  .thirdColumnT {
    width: 100%;
    height: 100%;
  }
  .fourthColumnT {
    width: 100%;
    height: 100%;
  }
  
  .titleIcon {
    font: bold 16px/32px Helvetica;
  }
  
  .iconTable {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin: auto;
    padding: 0 0 0.5rem;
    transform: scale(1.5);
  }
  
  /*tablet only*/
  
