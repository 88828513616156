.sectionFooter {
    padding: 2rem 0;
}

.gridContainer {
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: auto auto auto;
    background-color: #2196f3;
    padding: 10px;
}

.gridItem {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: 14px;
    text-align: center;
}

.flexContainerFooter {
    display: flex;
    flex-wrap: nowrap;
    background-color: transparent;
}

.flexContainerFooter>div {
    background-color: #fff;
    width: 100%;
    margin: 10px;
    text-align: left;
}

/* .flexContainerFooter p {
  font-family: "Helvetica";
} */

p.titleFooter {
    font-family: "Helvetica";
    text-transform: uppercase;
    color: #f16b26;
    font: bold 14px/18px Helvetica;
}

.contentFooter {
    position: sticky;
    bottom: 0;
    left: 0;
    /* width: 100%; */
    background: white;
    /* display: grid; */
    place-items: center;
    /* padding: 50px; */
}

p.contentFooter {
    display: list-item;
    font: bold 12px/16px Helvetica;
}

.contentFooter:hover {
    color: #000;
}

.contentFooter:hover:after {
    color: #f16b26;
}

/* p.contentFooter:after {
    content: " \2192";
    color: black;
} */

.contentIconLeft {
    font: bold 12px/16px Helvetica;
    display: list-item;
}

.boxFooter {
    height: 100;
}

.customLink {
    color: black;
    text-decoration: none;
}

.customLink:hover,
.customLinkHeader:hover {
    text-decoration: none;
}

.active {
    color: #f16b26;
}

.dropDownLang {
    position: relative;
}

.dropDownBtn {
    color: #707070;
    font-size: 1.4rem;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background: 0 0;
}

.dropdownMenu {
    position: absolute;
    width: 25rem;
    min-width: 100%;
    background: #f8f8f8;
    top: 25%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, 0.08);
    box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, 0.08);

    width: 16rem;
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.slNav {
    display: block;
    font: bold 12px/16px Helvetica;
    margin-top: -2px;
}

.slNav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
}

.slNav li {
    cursor: pointer;
    padding-bottom: 10px;
}

.slNav li ul {
    display: none;
}

.slNav li:hover ul {
    position: absolute;
    top: 29px;
    right: -15px;
    display: block;
    background: #fff;
    width: 150px;
    padding-top: 0px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li:hover .triangle {
    position: absolute;
    top: 15px;
    right: -10px;
    z-index: 10;
    height: 14px;
    overflow: hidden;
    width: 30px;
    background: transparent;
}

.slNav li:hover .triangle:after {
    content: "";
    display: block;
    z-index: 20;
    width: 15px;
    transform: rotate(45deg) translateY(0px) translatex(10px);
    height: 15px;
    background: #fff;
    border-radius: 2px 0px 0px 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li ul li {
    position: relative;
    text-align: left;
    background: transparent;
    padding: 15px 15px;
    padding-bottom: 0;
    z-index: 2;
    font-size: 15px;
    color: #000;
}

.slNav li ul li:last-of-type {
    padding-bottom: 15px;
}

.slNav li ul li span {
    padding-left: 5px;
}

.slNav li ul li span:hover,
.slNav li ul li span.active {
    color: #f16b26;
}

.slFlag {
    display: inline-block;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    width: 15px;
    height: 15px;
    background: transparent;
    border-radius: 50%;
    position: relative;
    /* top: 2px; */
    overflow: hidden;
}

.imgFlag {
    height: 15px;
    width: 15px;
    object-fit: cover;
    margin-top: -2px;
}

.footerBackground {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.previewLang {
    margin: 0 .5rem;
    height: 20px;
    width: 20px;
}

.footerSocialMedia {
    display: flex;
}
.footerSocialMedia a {
    margin-right: 1rem;
    color: unset;
}
.footerSocialMedia svg {
    transform: scale(1.4);
}

@media only screen and (min-width: 2560px) and (min-height: 1680px) {
    .cusT {
        max-width: 2090px !important;
        padding: 4rem 0 3rem 0;
    }

    p.titleFooter {
        font-size: 14px;
    }

    p.contentFooter {
        font: bold 16px Helvetica;
        margin-bottom: 1.5rem;
    }

    .contentIconLeft {
        font: bold 16px Helvetica;
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 1680px) {
    .cusT {
        max-width: 1300px !important;
        /* padding: 4rem 0 3rem 0; */
    }

    .imgFlag {
        margin-top: -3px;
    }

    .slNav li:hover .triangle:after {
        box-shadow: none;
    }
}

@media only screen and (max-width: 1440px) {
    .cusT {
        max-width: 1200px !important;
        /* padding: 4rem 0 3rem 0; */
    }

    .imgFlag {
        margin-top: -4px;
    }

    .slNav li:hover .triangle:after {
        box-shadow: none;
    }
}

@media only screen and (max-width: 1400px) {
    .cusT {
        max-width: 1062px !important;
        /* padding: 4rem 0 3rem 0; */
    }
}

@media only screen and (max-width: 1024px) {
    .cusT {
        max-width: 88vw !important;
    }

    .mobileFooter {
        margin-top: 1vh;
        display: flex;
        justify-content: flex-end;
    }

    .slNav li:hover ul {
        top: -90px;
    }
}

@media only screen and (max-width: 768px) {
    .cusT {
        max-width: 672px !important;
    }

    .customFlexContainer.child.last {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .cusT {
        max-width: calc(100vw - 12vw) !important;
    }

    .footerBackground {
        position: absolute;
        opacity: 1;
        left: 20vw;
        bottom: 20%;
        z-index: 0;
        height: 35vh;
        width: auto;
    }

    .flexContainerFooter {
        flex-direction: column;
    }

    .contentFooter {
        bottom: 0;
        background: transparent;
    }

    .contentFooter p {
        margin-bottom: 0.5rem;
        font: bold 14px Helvetica;
    }

    .flexContainerFooter>div {
        margin: 5px 10px;
    }

    p.titleFooter {
        font: 10px Helvetica;
    }

    .slNav {
        top: 2vh;
        position: relative;
    }
    .slNav li:hover ul {
        top: -100px !important;
    }
    .footerSocialMedia {
        padding-left: .5rem;
    }
}

@media only screen and (max-width: 360px) {
    .flexContainerFooter {
        flex-direction: column;
    }

    .contentFooter {
        bottom: 0;
    }

    .contentFooter p {
        margin-bottom: 0.5rem;
    }

    .flexContainerFooter>div {
        margin: 5px 10px;
    }
}