.outerContent2 {
    position: relative;
    margin: 153px auto auto;
    border: 3px dotted rgba(0, 0, 0, 0.363);
    width: 700px;
    height: 700px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.three {
    .circleBig {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
        transform: translate(269px, -343px);
        padding: 2rem;
    }
    
    .circleBig img {
        width: 80px;
        transform: translate(4px, 6px);
    }

    .circleBig:nth-child(4) .textBox {
        transform: translate(-86px, 665px);
    }
    
    .circleBig:nth-child(5) {
        transform: translate(-38px, 164px);
    }
    
    .circleBig:nth-child(5) .textBox {
        transform: translate(702px, -393px);
    }
    
    .circleBig:nth-child(6) {
        transform: translate(581px, 164px);
    }
    
    .circleBig:nth-child(6) .textBox {
        transform: translate(-852px, -427px);
    }
    
    .circle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(55, 44, 124, 0.9);
        border-radius: 10px;
        color: transparent;
        top: 61%;
        left: 0;
        -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    }

    .circle:nth-child(1) {
        transform: translate(17px, -224px);
    }
    
    .circle:nth-child(2) {
        transform: translate(657px, -224px);
    }
    
    .circle:nth-child(3) {
        transform: translate(336px, 262px);
    }
}

.four {
    .circleBig {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
        transform: translate(269px, -343px);
        padding: 2rem;
    }
    
    .circleBig img {
        width: 80px;
        transform: translate(4px, 6px);
    }

    .circleBig:nth-child(5) .textBox {
        transform: translate(-473px, 543px);
    }
    
    .circleBig:nth-child(6) {
        transform: translate(-78px, 0px);
    }
    
    .circleBig:nth-child(6) .textBox {
        transform: translate(672px, 167px);
    }
    
    .circleBig:nth-child(7) {
        transform: translate(269px, 346px);
    }
    
    .circleBig:nth-child(7) .textBox {
        transform: translate(338px, -677px);
    }
    
    .circleBig:nth-child(8) {
        transform: translate(613px, 0px);
    }
    
    .circleBig:nth-child(8) .textBox {
        transform: translate(-824px, -347px);
    }
    
    .circle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(55, 44, 124, 0.9);
        border-radius: 10px;
        color: transparent;
        top: 61%;
        left: 0;
        -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    }

    .circle:nth-child(1) {
        transform: translate(70px, -310px);
    }
    
    .circle:nth-child(2) {
        transform: translate(604px, 140px);
    }
    
    .circle:nth-child(3) {
        transform: translate(70px, 140px);
    }
    
    .circle:nth-child(4) {
        transform: translate(604px, -310px);
    }

}


.five {
    .circleBig {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
        transform: translate(269px, -343px);
        padding: 2rem;
    }
    
    .circleBig img {
        width: 80px;
        transform: translate(4px, 6px);
    }
    
    .circleBig:nth-child(6) {
        transform: translate(-50px, -127px);
    }
    
    .circleBig:nth-child(6) .textBox {
        width: 300px;
        transform: translate(712px, 127px);
    }
    
    .circleBig:nth-child(7) {
        transform: translate(38px, 246px);
    }
    
    .circleBig:nth-child(7) .textBox {
        transform: translate(478px, -657px);
    }
    
    .circleBig:nth-child(8) {
        transform: translate(499px, 245px);
    }
    
    .circleBig:nth-child(8) .textBox {
        width: 430px;
        transform: translate(-754px, -657px);
    }
    
    .circleBig:nth-child(9) {
        transform: translate(586px, -133px);
    }
    
    .circleBig:nth-child(9) .textBox {
        transform: translate(-867px, 141px);
    }
    
    .circleBig:nth-child(10) .textBox {
        width: 345px;
        transform: translate(-123px, 663px);
    }
    
    .circle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(55, 44, 124, 0.9);
        border-radius: 10px;
        color: transparent;
        top: 61%;
        left: 0;
        -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    }
    
    .circle:nth-child(2) {
        transform: translate(675px, 0);
    }
    
    .circle:nth-child(3) {
        transform: translate(336px, 264px);
    }
    
    .circle:nth-child(4) {
        transform: translate(536px, -373px);
    }
    
    .circle:nth-child(5) {
        transform: translate(138px, -372px);
    }

}

.six {
    .circleBig {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
        transform: translate(269px, -343px);
        padding: 2rem;
    }
    
    .circleBig img {
        width: 80px;
        transform: translate(4px, 6px);
    }

    .circleBig .textBox {
        width: 300px;
    }
    
    .circleBig:nth-child(7) {
        transform: translate(-9px, -197px);
    }
    
    .circleBig:nth-child(7) .textBox {
        transform: translate(680px, 118px);
    }
    
    .circleBig:nth-child(8) {
        transform: translate(-9px, 197px);
    }
    
    .circleBig:nth-child(8) .textBox {
        transform: translate(486px, -617px);
    }
    
    .circleBig:nth-child(9) {
        transform: translate(269px, 343px);
    }
    
    .circleBig:nth-child(9) .textBox {
        transform: translate(-424px, -757px);
    }
    
    .circleBig:nth-child(10) {
        transform: translate(555px, 197px);
    }
    
    .circleBig:nth-child(10) .textBox {
        transform: translate(-881px, -270px);
    }

    .circleBig:nth-child(11) {
        transform: translate(555px, -197px);
    }
    
    .circleBig:nth-child(11) .textBox {
        width: 345px;
        transform: translate(-743px, 473px);
    }

    .circleBig:nth-child(12) .textBox {
        transform: translate(193px, 593px);
    }
    
    .circle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(55, 44, 124, 0.9);
        border-radius: 10px;
        color: transparent;
        top: 61%;
        left: 0;
        -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    }
    
    .circle:nth-child(1) {
        transform: translate(686px, -85px);
    }
    
    .circle:nth-child(2) {
        transform: translate(498px, 224px);
    }
    
    .circle:nth-child(3) {
        transform: translate(175px, 224px);
    }
    
    .circle:nth-child(4) {
        transform: translate(-12px, -85px);
    }
    
    .circle:nth-child(5) {
        transform: translate(168px, -390px);
    }

    .circle:nth-child(6) {
        transform: translate(508px, -390px);
    }
}

.seven {
    .circleBig {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
        transform: translate(269px, -343px);
        padding: 2rem;
    }
    
    .circleBig img {
        width: 80px;
        transform: translate(4px, 6px);
    }

    .circleBig .textBox {
        width: 300px;
    }
    
    .circleBig:nth-child(8) {
        transform: translate(-9px, -217px);
    }
    
    .circleBig:nth-child(8) .textBox {
        transform: translate(610px, 368px);
    }
    
    .circleBig:nth-child(9) {
        transform: translate(-71px, 75px);
    }
    
    .circleBig:nth-child(9) .textBox {
        transform: translate(733px, -237px);
    }
    
    .circleBig:nth-child(10) {
        transform: translate(109px, 311px);
    }
    
    .circleBig:nth-child(10) .textBox {
        transform: translate(362px, -750px);
    }
    
    .circleBig:nth-child(11) {
        transform: translate(469px, 311px);
    }
    
    .circleBig:nth-child(11) .textBox {
        transform: translate(-601px, -740px);
    }

    .circleBig:nth-child(12) {
        transform: translate(625px, 75px);
    }
    
    .circleBig:nth-child(12) .textBox {
        transform: translate(-960px, -247px);
    }

    .circleBig:nth-child(13) {
        transform: translate(550px, -217px);
    }
    
    .circleBig:nth-child(13) .textBox {
        transform: translate(-817px, 372px);
    }

    .circleBig:nth-child(14) .textBox {
        transform: translate(-83px, 658px);
    }
    
    .circle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(55, 44, 124, 0.9);
        border-radius: 10px;
        color: transparent;
        top: 61%;
        left: 0;
        -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    }
    
    .circle:nth-child(1) {
        transform: translate(618px, 124px);
    }
    
    .circle:nth-child(2) {
        transform: translate(358px, 264px);
    }
    
    .circle:nth-child(3) {
        transform: translate(58px, 124px);
    }
    
    .circle:nth-child(4) {
        transform: translate(-3px, -162px);
    }
    
    .circle:nth-child(5) {
        transform: translate(181px, -398px);
    }

    .circle:nth-child(6) {
        transform: translate(496px, -397px);
    }

    .circle:nth-child(7) {
        transform: translate(678px, -162px);
    }
}

.eight {
    .circleBig {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
        transform: translate(275px, -343px);
        padding: 2rem;
    }
    
    .circleBig img {
        width: 80px;
        transform: translate(4px, 6px);
    }

    .circleBig .textBox {
        width: 300px;
    }
    
    .circleBig:nth-child(9) {
        transform: translate(20px, -247px);
    }
    
    .circleBig:nth-child(9) .textBox {
        transform: translate(620px, 318px);
    }
    
    .circleBig:nth-child(10) {
        transform: translate(-80px, 0px);
    }
    
    .circleBig:nth-child(10) .textBox {
        transform: translate(733px, -227px);
    }
    
    .circleBig:nth-child(11) {
        transform: translate(20px, 247px);
    }
    
    .circleBig:nth-child(11) .textBox {
        transform: translate(422px, -710px);
    }
    
    .circleBig:nth-child(12) {
        transform: translate(275px, 350px);
    }
    
    .circleBig:nth-child(12) .textBox {
        transform: translate(-391px, -815px);
    }

    .circleBig:nth-child(13) {
        transform: translate(540px, 247px);
    }
    
    .circleBig:nth-child(13) .textBox {
        transform: translate(-850px, -488px);
    }

    .circleBig:nth-child(14) {
        transform: translate(625px, 0px);
    }
    
    .circleBig:nth-child(14) .textBox {
        transform: translate(-943px, 39px);
    }

    .circleBig:nth-child(15) {
        transform: translate(530px, -247px);
    }
    
    .circleBig:nth-child(15) .textBox {
        transform: translate(-617px, 530px);
    }

    .circleBig:nth-child(16) .textBox {
        transform: translate(160px, 613px);
    }
    
    .circle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(55, 44, 124, 0.9);
        border-radius: 10px;
        color: transparent;
        top: 61%;
        left: 0;
        -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
        box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    }
    
    .circle:nth-child(1) {
        transform: translate(482px, 232px);
    }
    
    .circle:nth-child(2) {
        transform: translate(204px, 236px);
    }
    
    .circle:nth-child(3) {
        transform: translate(14px, 46px);
    }
    
    .circle:nth-child(4) {
        transform: translate(14px, -218px);
    }
    
    .circle:nth-child(5) {
        transform: translate(198px, -408px);
    }

    .circle:nth-child(6) {
        transform: translate(478px, -405px);
    }

    .circle:nth-child(7) {
        transform: translate(663px, -212px);
    }

    .circle:nth-child(8) {
        transform: translate(664px, 42px);
    }
}


.textBox {
    width: 260px;
    transform: translate(-78px, 663px);
    color: black;
    text-align: center;
    height: auto;
    padding: 1rem;
    font: normal 20px/22px Helvetica;
    text-transform: inherit;
}

.textBox p {
    opacity: 1;
}

.activeText {
    opacity: 1 !important;
}
