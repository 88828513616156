.navBack {
    position: relative;
    padding: 2rem 0 5rem;
    width: 30%;
}

.customLink {
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.customMargin {
    margin: auto 1rem auto auto;
}

.customLink span {
    color: black;
}

@media only screen and (max-width:1440px) {
    .navBack {
        padding: 2rem 0 5rem;
    }
}