.modalContent {
    padding: 2rem;
}

.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modalTitle1 {
    font: 14px Helvetica;
    color: black;
}

.modalTitle {
    font: 14px Helvetica;
    color: #980000;
}

.boxContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f1f1f1;
    position: relative;
}

.boxContent:not(:first-child) {
    margin-top: 1rem;
}

.titleContent {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #b0b0b0;
}

.inputModal {
    border: 0;
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000000;
    margin-top: 0.5rem;
}

.inputModal:focus {
    border: none;
    outline: unset;
}

.validError {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #980000;
    opacity: 1;
}

.errorActive {
    text-align: left;
    opacity: 1 !important;
}

.contentAction {
    display: flex;
    margin-top: 1rem;
    margin-left: auto;
}

.cancel {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dddddd;
    border-radius: 16px;
    padding: 0 0.5rem;
    margin-right: 1rem;
    width: 110px;
    display: flex;
    cursor: pointer;
}

.cancel span {
    margin: auto;
}

.boxAction {
    width: auto;
    height: 32px;
    background: #00599d 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 16px;
    margin: auto 0 auto 0.5rem;
    cursor: pointer;
}

.boxAction span {
    font: 16px/8px Helvetica;
    color: #ffffff;
    margin: auto;
    cursor: pointer;
}