.banner {
  height: 65vh;
  width: 100vw;
  position: relative;
}

.imgBanner {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contentRunning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -35.4vh;
}

.textRunning {
  font: 48px Helvetica;
  font-weight: bold;
  margin: auto auto 1rem;
  text-align: center;
  color: white;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: header-anim;
}

.customContainer {
  margin: 5rem 12.5rem;
  width: 100%;
}

.titleSection {
  font: bold 36px Helvetica;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.textIn {
  font: normal 16px/1.8rem Helvetica;
  width: 100%;
}

span.content {
  font: 16px/1.8rem Helvetica;
  padding-bottom: 1rem;
  text-align: justify;
}

.span.content ul {
  margin-top: 1rem;
}

.span.content ul>li {
  margin-bottom: 1rem;
}

.navBack {
  position: relative;

  padding: 2rem 0 5rem;
  width: 30%;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.textBack {
  font: normal 14px/48px Helvetica;
  color: black;
  margin: 0;
}

.customMargin {
  margin: auto 1rem auto auto;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.wrapGallery {
  display: flex;
  padding: 2rem 0;
  justify-content: center;
}

.boxCulture {
  width: 303px;
  height: 360px;
  position: relative;
  justify-content: center;
}

.boxCulture img {
  position: relative;
  width: 100%;
  height: 360px;
  object-fit: cover;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.boxCulture figure {
  overflow: hidden;
  height: 100%;
}

.boxCulture .boxMask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.boxCulture:hover .boxMask {
  opacity: 0;
}

.boxCulture:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.customLink {
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

@keyframes header-anim {

  0%,
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }

  30%,
  70% {
    transform: translateX(0);
    opacity: 1;
  }

  70%,
  99% {
    opacity: 1;
  }

  100%,
  99% {
    opacity: 0;
  }
}

.underlineAnimate {
  display: block;
  height: 2px;
  width: 900px;
  margin-left: auto;
  margin-right: 28%;
  background-color: white;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-iteration-count: alternate;
  animation-name: underline-anim;
}

@keyframes underline-anim {

  0%,
  10% {
    width: 0;
    transform: translateX(-900px);
  }

  10%,
  20% {
    width: 900px;
    transform: translateX(0);
  }

  20%,
  30% {
    width: 0;
    transform: translateX(100px);
  }

  100%,
  30% {
    transform: translateX(0);
    width: 0;
  }
}

.boxGallery {
  width: 100px;
  height: 374px;
  transition: width 2s;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
}

.boxGallery figure {
  margin: 0;
  overflow: hidden;
}

.boxGallery figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.activeImg {
  width: 720px;
}

.boxGallery:first-child {
  border-left: 7px solid rgba(214, 214, 214, 0.6);
}

.boxGallery:last-child {
  border-right: 7px solid rgba(214, 214, 214, 0.6);
}

.boxGallery:not(:first-child),
.boxGallery:not(:last-child) {
  border-bottom: 7px solid rgba(214, 214, 214, 0.6);
  border-top: 7px solid rgba(214, 214, 214, 0.6);
  border-left: 1px solid rgba(214, 214, 214, 0.6);
}

@media only screen and (min-width: 2560px) {
  .textRunning {
    font: bold 70px Helvetica;
  }

  .customContainer {
    max-width: 2000px !important;
    margin: 5rem 17.5rem;
  }
}


@media only screen and (max-width: 1680px) {

  .textBack {
    font: normal 16px Helvetica;
  }

  .customContainer {
    margin: 3.5rem auto;
    max-width: 78vw;
  }

  .titleSection {
    font: bold 28px Helvetica;
  }

  span.content {
    font: 16px/1.8rem Helvetica;
  }

  .textIn {
    width: 100% !important;
    font: 16px/1.8rem Helvetica;
  }

  .boxCulture {
    width: 213px;
    height: 260px;
  }

  .boxCulture img {
    width: 213px;
    height: 260px;
  }

  .textRunning {
    font: bold 36px Helvetica;
  }

  .underlineAnimate {
    width: 600px;
  }

  @keyframes underline-anim {

    0%,
    10% {
      width: 0;
      transform: translateX(-600px);
    }

    10%,
    20% {
      width: 600px;
      transform: translateX(0);
    }

    20%,
    30% {
      width: 0;
      transform: translateX(100px);
    }

    100%,
    30% {
      transform: translateX(0);
      width: 0;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .customContainer {
    margin: 3.5rem auto;
    max-width: 1200px;
  }

    .textBack {
        font: normal 14px Helvetica;
    }

  .titleSection {
    font: bold 28px Helvetica;
  }

  span.content {
    font: normal 14px Helvetica;
    line-height: 1.8;
  }

  .textIn {
    /* width: 1062px !important; */
    font: normal 14px Helvetica;
    line-height: 1.8;
  }

  .boxCulture {
    width: 213px;
    height: 260px;
  }

  .boxCulture img {
    width: 213px;
    height: 260px;
  }

  .textRunning {
    font: bold 36px Helvetica;
  }

  .underlineAnimate {
    width: 600px;
  }

  @keyframes underline-anim {

    0%,
    10% {
      width: 0;
      transform: translateX(-600px);
    }

    10%,
    20% {
      width: 600px;
      transform: translateX(0);
    }

    20%,
    30% {
      width: 0;
      transform: translateX(100px);
    }

    100%,
    30% {
      transform: translateX(0);
      width: 0;
    }
  }
}

@media only screen and (max-width: 1370px) {
  .customContainer {
    max-width: 1062px;
  }
}

@media only screen and (max-width: 1024px) {
  .boxGallery figure {
    width: 100% !important;
  }

  .customContainer {
    margin: 3.5rem 2.5rem;
  }


}

@media only screen and (max-width: 768px) {
  .customContainer {
    max-width: 672px;
    margin: 3.5rem 48px;
    padding: 0;
  }

  .textIn {
    padding: 0 !important;
  }

  .titleSection {
    font: bold 18px Helvetica;
  }

  .textRunning {
    font: bold 28px Helvetica;
  }

  .underlineAnimate {
    margin-right: 15%;
  }
}