.searchContainer {
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
    background-color: rgba(255,255,255, .2);
    border-radius: 50%;
}

.searchContainer img {
    height: 16px;
    width: 16px;
    margin: 0 auto;
}

.active {
    opacity: 1 !important;
}