#slidermobile .slick-dots {
    position: absolute;
    top: 40vh !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    height: 2vh;
}


#slidermobile .slick-dots ul {
    padding: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
}