.titleIcon {
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
}

.iconL {
  margin-left: auto;
}

.titleIcon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 480px) {
  .titleIcon {
    height: 2.5vh;
    width: 5vw;
    margin-right: 3vw;
    margin-bottom: unset;
    display: flex;
  }
  .titleIcon img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin: auto;
  }
  .iconL {
    margin-left: unset;
  }
}

