.readMoreBox {
    padding-top: 2rem;
    display: inline-block;
}

.customLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    color: black;
}

.customLink:hover .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.readMore, .readMore:hover {
    text-decoration: none;
    color: black;
    font: normal 16px/1.5 Helvetica;
}

@media screen and (max-width: 1440px) {
    .readMore, .readMore:hover {
        font: normal 14px/30px Helvetica;
    }
}

@media screen and (max-width: 480px) {
    .readMoreBox {
        padding-top: 2rem;
    }
    .outerCircle {
        display: block;
        opacity: 1;
    }
    .readMore, .readMore:hover {
        font: normal 14px/1.5 Helvetica;
    }
}