.imgLogo {
  height: 100%;
  width: 100%;
  color: white;
  z-index: 100;
  margin: 1rem 0 0 2rem;
}

.imgLogo_2 {
  height: 200px;
  width: 40px;
  color: white;
  z-index: 100;
  margin: 1rem 0 0 2rem;
}

.navLogo {
  z-index: 100;
}

.Logo {
  top: 0rem;
  z-index: 99;
  width: 7%;
}

@media only screen and (max-width: 1440px) {
  .imgNav {
    width: 75px;
  }

  .imgLogo {
    width: 90px;
    height: 90px;
    margin: -0.5rem 0 0 1rem;
  }
}

@media only screen and (max-width: 1024px) {
  .imgLogo {
    margin: 0 0 0 1.5rem;
  }

  .customLogo {
    transition: top 0.6s;
  }

  .navbarHidden {
    top: -120px !important;
  }
}

@media only screen and (max-width: 480px) {
  .imgLogo {
    width: 57px !important;
    height: 57px !important;
    margin: .5rem 0 0 1rem;
  }

  .navbarHidden {
    top: -120px !important;
  }
}

@media only screen and (max-width: 360px) {
  .imgLogo {
    width: 50px !important;
    height: 50px !important;
    margin: 1rem 0 0 1rem;

  }
}


/**landscape view*/
@media only screen and (orientation: landscape) and (max-width: 897px) {

  .imgLogo {
    width: 50px !important;
    height: 50px !important;
    margin: 15px 0 0 1.5rem;
  }

}


@media only screen and (orientation: landscape) and (max-width: 568px) {
  .imgLogo {
    width: 38px;
    height: 38px;
    margin: .5rem 0 0 0;
  }
}