.h-line,
.v-line {
    position: absolute;
    background-color: #223971;
}

.chart-content-animate:hover {
    font-weight: bold;
}

.title-chart {
    height: 100%;
    width: 100%;
    text-transform: uppercase;
}

.chart-icon-1 img,
.chart-icon-2 img,
.chart-icon-3 img,
.chart-icon-4 img,
.chart-icon-5 img,
.chart-icon-6 img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.chart-icon-0 img {
    height: 180px;
    width: 180px;
    object-fit: contain;
}

.chart-icon-0 {
    width: 15%;
    height: 1px;
    top: -20px;
    left: 45.8%;
    display: block;
    opacity: 1;
    position: absolute;
}

.chart-icon-1 {
    width: 15%;
    height: 1px;
    top: 145px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;

}

.chart-icon-2 {
    width: 15%;
    height: 1px;
    top: 400px;
    left: 38%;
    display: block;
    position: absolute;
}

.chart-icon-3 {
    width: 15%;
    height: 1px;
    top: 690px;
    left: 38%;
    display: block;
    position: absolute;
}

.chart-icon-4 {
    width: 15%;
    height: 1px;
    top: 285px;
    right: 26%;
    display: block;
    position: absolute;
}

.chart-icon-5 {
    width: 15%;
    height: 1px;
    top: 525px;
    right: 26%;
    display: block;
    position: absolute;
}

.chart-icon-6 {
    width: 15%;
    height: 1px;
    top: 620px;
    right: 26%;
    display: block;
    position: absolute;
}

.title-chart:hover {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

/* Left */
.left-1 {
    width: 1px;
    height: 620px;
    top: 0;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-1 2s;
}

@keyframes left-1 {
    0% {
        height: 0;
    }

    100% {
        height: 550px;
    }
}

.left-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

/* start kiri item 1 */

.left-2 {
    width: 8%;
    height: 1px;
    top: 80px;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-2 2s;
}

@keyframes left-2 {
    0% {
        width: 0;
    }

    100% {
        width: 8%;
    }
}

.left-2:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -0;
}

.left-x1-title {
    width: 15%;
    height: 1px;
    top: 60px;
    right: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-21 {
    width: 22%;
    height: 1px;
    top: 80px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-21 2s;
}

@keyframes left-21 {
    0% {
        width: 0;
    }

    100% {
        width: 22%;
    }
}

.left-21:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}

.left-22 {
    width: 1px;
    height: 40px;
    top: 110px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-22 2s;
}

@keyframes left-22 {
    0% {
        height: 0;
    }

    100% {
        height: 30px;
    }
}

.left-22:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-x2-title {
    width: 15%;
    height: 1px;
    top: 160px;
    right: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-23 {
    width: 8%;
    height: 1px;
    top: 170px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-23 2s;
}

@keyframes left-23 {
    0% {
        width: 0;
    }

    100% {
        width: 8%;
    }
}

.left-23:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}

.left-x3-content {
    width: 15%;
    height: 1px;
    top: 160px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-24 {
    width: 1px;
    height: 111px;
    top: 170px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-24 2s;
}

@keyframes left-24 {
    0% {
        height: 0;
    }

    100% {
        height: 111px;
    }
}

.left-25 {
    width: 4%;
    height: 1px;
    top: 200px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-25 2s;
}

@keyframes left-25 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x4-content {
    width: 15%;
    height: 1px;
    top: 190px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-26 {
    width: 4%;
    height: 1px;
    top: 240px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-26 2s;
}

@keyframes left-26 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x5-content {
    width: 15%;
    height: 1px;
    top: 230px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-27 {
    width: 4%;
    height: 1px;
    top: 280px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-27 2s;
}

@keyframes left-27 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x6-content {
    width: 15%;
    height: 1px;
    top: 270px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

/* end kiri item 1 */

/* start kiri item 2 */

.left-x3-title {
    width: 20%;
    height: 1px;
    top: 35px;
    right: 56%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-28 {
    width: 1px;
    height: 150px;
    top: 110px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-28 2s;
}

@keyframes left-28 {
    0% {
        height: 0;
    }

    100% {
        height: 150px;
    }
}

.left-28:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-29 {
    width: 4%;
    height: 1px;
    top: 140px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-29 2s;
}

@keyframes left-29 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x7-content {
    width: 15%;
    height: 1px;
    top: 122px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-30 {
    width: 4%;
    height: 1px;
    top: 180px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-30 2s;
}

@keyframes left-30 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x8-content {
    width: 15%;
    height: 1px;
    top: 172px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-31 {
    width: 4%;
    height: 1px;
    top: 220px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-31 2s;
}

@keyframes left-31 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x9-content {
    width: 15%;
    height: 1px;
    top: 212px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-32 {
    width: 4%;
    height: 1px;
    top: 260px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-32 2s;
}

@keyframes left-32 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.left-x10-content {
    width: 15%;
    height: 1px;
    top: 252px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

/* end kiri item 2 */

/* start kiri item 3 */

.left-3 {
    width: 8%;
    height: 1px;
    top: 330px;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-3 2s;
}

@keyframes left-3 {
    0% {
        width: 0;
    }

    100% {
        width: 8%;
    }
}

.left-3:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -0;
}

.left-x4-title {
    width: 40%;
    height: 1px;
    top: 320px;
    right: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: right;
}

.left-33 {
    width: 1px;
    height: 30px;
    top: 350px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

@keyframes left-33 {
    0% {
        height: 0;
    }

    100% {
        height: 30px;
    }
}

.left-33:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-34 {
    width: 72%;
    height: 1px;
    top: 380px;
    right: 15%;
    display: block;
    opacity: 1;
    animation: left-34 2s;
}

@keyframes left-34 {
    0% {
        width: 0;
    }

    100% {
        width: 60%;
    }
}

.left-35 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 15%;
    display: block;
    opacity: 1;
    animation: left-35 2s;
}

@keyframes left-35 {
    0% {
        height: 0;
    }

    100% {
        height: 30px;
    }
}

.left-x11-content {
    width: 15%;
    height: 1px;
    top: 420px;
    right: 8%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-36 {
    width: 1px;
    height: 100px;
    top: 380px;
    right: 25%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x12-content {
    width: 12%;
    height: 1px;
    top: 487px;
    right: 19%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-37 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 34%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x13-content {
    width: 15%;
    height: 1px;
    top: 420px;
    right: 26%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-38 {
    width: 1px;
    height: 100px;
    top: 380px;
    right: 42%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x14-content {
    width: 10%;
    height: 1px;
    top: 487px;
    right: 37%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-39 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 47%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x15-content {
    width: 10%;
    height: 1px;
    top: 420px;
    right: 42%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-40 {
    width: 1px;
    height: 100px;
    top: 380px;
    right: 53%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x16-content {
    width: 10%;
    height: 1px;
    top: 487px;
    right: 48%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-41 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 59%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x17-content {
    width: 10%;
    height: 1px;
    top: 420px;
    right: 54%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-50 {
    width: 1px;
    height: 100px;
    top: 380px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x20-content {
    width: 10%;
    height: 1px;
    top: 487px;
    right: 60%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-51 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 75%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x21-content {
    width: 18%;
    height: 1px;
    top: 420px;
    right: 67%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-52 {
    width: 1px;
    height: 100px;
    top: 380px;
    right: 87%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x22-content {
    width: 10%;
    height: 1px;
    top: 487px;
    right: 82%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}


/* end kiri item 3 */

/* start kiri item 4 */
.left-42 {
    width: 8%;
    height: 1px;
    top: 620px;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-42 2s;
}

@keyframes left-42 {
    0% {
        width: 0;
    }

    100% {
        width: 13%;
    }
}

.left-42:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-x5-title {
    width: 20%;
    height: 1px;
    top: 610px;
    right: 12%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-43 {
    width: 8%;
    height: 1px;
    top: 620px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-43 2s;
}

@keyframes left-43 {
    0% {
        width: 0;
    }

    100% {
        width: 8%;
    }
}

.left-43:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: -0;
}

.left-x6-title {
    width: 40%;
    height: 1px;
    top: 610px;
    right: 42%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: right;
}

.left-44 {
    width: 1px;
    height: 30px;
    top: 640px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-44 2s;
}

@keyframes left-44 {
    0% {
        height: 0;
    }

    100% {
        height: 50px;
    }
}

.left-44:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-x7-title {
    width: 15%;
    height: 1px;
    top: 675px;
    right: 14%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-45 {
    width: 1px;
    height: 30px;
    top: 710px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-45 2s;
}

@keyframes left-45 {
    0% {
        height: 0;
    }

    100% {
        height: 20px;
    }
}

.left-45:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-46 {
    width: 18%;
    height: 1px;
    top: 740px;
    right: 14%;
    display: block;
    opacity: 1;
    animation: left-46 2s;
}

@keyframes left-46 {
    0% {
        width: 0;
    }

    100% {
        width: 20%;
    }
}

.left-47 {
    width: 1px;
    height: 20px;
    top: 740px;
    right: 14%;
    display: block;
    opacity: 1;
    animation: left-47 2s;
}

@keyframes left-47 {
    0% {
        height: 0;
    }

    100% {
        height: 20px;
    }
}

.left-x18-content {
    width: 15%;
    height: 1px;
    top: 770px;
    right: 7%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-48 {
    width: 1px;
    height: 20px;
    top: 740px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-47 2s;
}

.left-x19-content {
    width: 15%;
    height: 1px;
    top: 770px;
    right: 25.5%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

/* end kiri item 4 */

/* Right */

.r-1 {
    width: 1px;
    height: 549px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-1 2s;
}

@keyframes r-1 {
    0% {
        height: 0;
    }

    100% {
        height: 520px;
    }
}

.r-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

/* start kanan item 1 */

.r-2 {
    width: 8%;
    height: 1px;
    top: 215px;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-2 2s;
}

@keyframes r-2 {
    0% {
        width: 0;
    }

    100% {
        width: 8%;
    }
}

.r-2:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}

.r-y1-title {
    width: 20%;
    height: 1px;
    top: 205px;
    left: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

.r-3 {
    width: 8%;
    height: 1px;
    top: 215px;
    left: 28%;
    display: block;
    opacity: 1;
    animation: r-2 2s;
}

.r-3:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: 0;
}

.r-4 {
    width: 1px;
    height: 316px;
    top: 20px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-4 2s;
}

@keyframes r-4 {
    0% {
        height: 0;
    }

    100% {
        height: 316px;
    }
}

.r-5 {
    width: 8%;
    height: 1px;
    top: 20px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

@keyframes r-5 {
    0% {
        width: 0;
    }

    100% {
        width: 8%;
    }
}

.r-y1-content {
    width: 40%;
    height: 1px;
    top: 10px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-6 {
    width: 23%;
    height: 1px;
    top: 55px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

@keyframes r-6 {
    0% {
        width: 0;
    }

    100% {
        width: 23%;
    }
}

.r-y2-content {
    width: 40%;
    height: 1px;
    top: 40px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-7 {
    width: 8%;
    height: 1px;
    top: 90px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y3-content {
    width: 40%;
    height: 1px;
    top: 80px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-8 {
    width: 23%;
    height: 1px;
    top: 125px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y4-content {
    width: 40%;
    height: 1px;
    top: 110px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-9 {
    width: 8%;
    height: 1px;
    top: 160px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y5-content {
    width: 40%;
    height: 1px;
    top: 150px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-10 {
    width: 23%;
    height: 1px;
    top: 195px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y6-content {
    width: 40%;
    height: 1px;
    top: 185px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-11 {
    width: 8%;
    height: 1px;
    top: 230px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y7-content {
    width: 40%;
    height: 1px;
    top: 220px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-12 {
    width: 23%;
    height: 1px;
    top: 265px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y8-content {
    width: 40%;
    height: 1px;
    top: 250px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-13 {
    width: 8%;
    height: 1px;
    top: 300px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y9-content {
    width: 40%;
    height: 1px;
    top: 290px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-14 {
    width: 23%;
    height: 1px;
    top: 335px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y10-content {
    width: 40%;
    height: 1px;
    top: 320px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-15 {
    width: 8%;
    height: 1px;
    top: 365px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y11-content {
    width: 40%;
    height: 1px;
    top: 355px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-16 {
    width: 23%;
    height: 1px;
    top: 400px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y12-content {
    width: 40%;
    height: 1px;
    top: 385px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

/* end kanan item 1 */

/* start kanan item 2 */

.r-17 {
    width: 8%;
    height: 1px;
    top: 455px;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-17:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}

.r-y2-title {
    width: 30%;
    height: 1px;
    top: 445px;
    left: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

/* end kanan item 2 */

/* start kanan item 3 */

.r-18 {
    width: 8%;
    height: 1px;
    top: 548px;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-18:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}

.r-y3-title {
    width: 30%;
    height: 1px;
    top: 537px;
    left: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

.r-19 {
    width: 8%;
    height: 1px;
    top: 549px;
    left: 30%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-19:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: 0;
}

.r-y4-title {
    width: 20%;
    height: 1px;
    top: 537px;
    left: 40%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

.r-20 {
    width: 1px;
    height: 40px;
    top: 570px;
    left: 21%;
    display: block;
    opacity: 1;
    animation: r-20 2s;
}

@keyframes r-20 {
    0% {
        height: 0;
    }

    100% {
        height: 60px;
    }
}

.r-20:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: -2px;
}

.r-y5-title {
    width: 15%;
    height: 1px;
    top: 620px;
    left: 13%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

/* .r-y12-content {
    width: 20%;
    height: 1px;
    top: 385px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
} */

.r-21 {
    width: 8%;
    height: 1px;
    top: 630px;
    left: 28%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-21:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: 0;
}

.r-22 {
    width: 1px;
    height: 120px;
    top: 630px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-22 2s;
}

@keyframes r-22 {
    0% {
        height: 0;
    }

    100% {
        height: 90px;
    }
}

.r-y13-content {
    width: 25%;
    height: 1px;
    top: 620px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-23 {
    width: 4%;
    height: 1px;
    top: 670px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-23 2s;
}

@keyframes r-23 {
    0% {
        width: 0;
    }

    100% {
        width: 4%;
    }
}

.r-y14-content {
    width: 25%;
    height: 1px;
    top: 660px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-24 {
    width: 4%;
    height: 1px;
    top: 710px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-23 2s;
}

.r-y15-content {
    width: 30%;
    height: 1px;
    top: 700px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-25 {
    width: 4%;
    height: 1px;
    top: 750px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-23 2s;
}

.r-y16-content {
    width: 30%;
    height: 1px;
    top: 740px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

@media only screen and (max-width: 1680px) {
    .chart-icon-0 {
        left: 44.5%
    }

    .left-x1-title {
        width: 18%;
        right: 13%;
    }

    .left-x1En-title {
        top: 65px;
        width: 20%;
        right: 12%;
    }
}

/* end kanan item 3 */
@media only screen and (max-width: 1440px) {
    .title-chart {
        font: bold 16px Helvetica;
    }

    .--subtitle-chart {
        font: bold 16px Helvetica;
    }

    .--chart-content {
        font: 14px Helvetica;
    }

    .chart-icon-1 img,
    .chart-icon-2 img,
    .chart-icon-3 img,
    .chart-icon-4 img,
    .chart-icon-5 img,
    .chart-icon-6 img {
        height: 40px;
        width: 40px;
        object-fit: contain;
    }

    .chart-icon-0 img {
        height: 120px;
        width: 120px;
        object-fit: contain;
    }

    .chart-icon-0 {
        width: 15%;
        height: 1px;
        top: -100px;
        left: 46%;
        display: block;
        opacity: 1;
        position: absolute;
    }

    .chart-icon-1 {
        width: 15%;
        height: 1px;
        top: -5px;
        left: 38%;
        display: block;
        opacity: 1;
        position: absolute;
    }

    .chart-icon-2 {
        width: 15%;
        height: 1px;
        top: 270px;
        left: 38%;
        display: block;
        position: absolute;
    }

    .chart-icon-3 {
        width: 15%;
        height: 1px;
        top: 540px;
        left: 38%;
        display: block;
        position: absolute;
    }

    .chart-icon-4 {
        width: 15%;
        height: 1px;
        top: 155px;
        right: 26%;
        display: block;
        position: absolute;
    }

    .chart-icon-5 {
        width: 15%;
        height: 1px;
        top: 395px;
        right: 26%;
        display: block;
        position: absolute;
    }

    .chart-icon-6 {
        width: 15%;
        height: 1px;
        top: 490px;
        right: 26%;
        display: block;
        position: absolute;
    }

    .left-1 {
        height: 600px;
    }

    .left-x1-title {
        top: 45px;
        width: 15%;
        right: 15%;
    }

    .left-x1En-title {
        top: 67px;
        width: 20%;
        right: 12%;
    }

    .left-x3-title {
        top: 45px;
    }


    /* Kanan 3 */
    .left-34 {
        width: 82%;
    }
    .left-39 {
        right: 48%;
    }

    .left-x15-content {
        width: 9%;
        right: 44%;
    }

    .left-40 {
        right: 55%;
    }

    .left-x16-content {
        right: 50%;
    }

    .left-41 {
        right: 63%;
    }

    .left-x17-content {
        right: 58%;
    }

    .left-50 {
        right: 71%;
    }

    .left-x20-content {
        right: 66%;
    }

    .left-51 {
        right: 83%;
    }

    .left-x21-content {
        right: 75%;
    }

    .left-52 {
        right: 97%;
    }

    .left-x22-content {
        right: 92%;
    }

    /* End Kanan 3 */

    .left-42,
    .left-43 {
        top: 600px;
    }

    .left-x5-title,
    .left-x6-title {
        top: 590px;
    }

    .left-44 {
        top: 620px;
    }

    .left-x7-title {
        top: 660px;
        text-align: center;
        right: 15%;
    }

    .left-45 {
        top: 690px;
    }

    .left-46 {
        top: 720px;
    }

    .left-47,
    .left-48 {
        top: 720px;
    }

    .left-x18-content,
    .left-x19-content {
        top: 750px;
    }

    .r-19 {
        left: 34%;
    }

    .r-y4-title {
        left: 44%;
    }
}

@media only screen and (max-width: 1024px) {
    .chart-icon-0 {
        left: 44%;
        top: -120px;
    }

    .left-x1-title {
        top: 45px;
        width: 18%;
        right: 13%;
    }

    .left-x1En-title {
        top: 64px;
        width: 25%;
        right: 10%;
    }

    .left-x7-content,
    .left-x8-content,
    .left-x9-content,
    .left-x10-content {
        width: 30%;
    }

    /* start item 3 */
    .left-34 {
        width: 85%;
    }

    .left-36 {
        height: 120px;
    }

    .left-x12-content {
        width: 15%;
        right: 18%;
        top: 507px;
    }

    .left-38 {
        height: 120px;
        right: 44%;
    }

    .left-x14-content {
        width: 12%;
        right: 39%;
        top: 507px;
    }

    .left-39 {
        right: 51%;
    }

    .left-x15-content {
        right: 46%;
    }

    .left-40 {
        right: 58%;
        height: 120px;
    }

    .left-x16-content {
        width: 12%;
        right: 53%;
        top: 507px;
    }

    .left-41 {
        right: 66%;
    }

    .left-x17-content {
        right: 61%;
    }

    .left-50 {
        right: 74%;
        height: 120px;
    }

    .left-x20-content {
        width: 15%;
        top: 507px;
        right: 67%;
    }

    .left-51 {
        right: 87%;
    }

    .left-x21-content {
        width: 20%;
        right: 77%;
    }

    .left-52 {
        right: 100%;
        height: 120px;
    }

    .left-x22-content {
        width: 15%;
        top: 507px;
        right: 93%;
    }

    /* start item 4 */
    .chart-icon-3 {
        top: 560px;
    }

    .left-42,
    .left-43 {
        top: 620px;
    }

    .left-x5-title {
        top: 610px;
        right: 13%;
    }

    .left-x6-title {
        top: 610px;
    }

    .left-44 {
        top: 640px;
    }

    .left-x7-title {
        width: 40%;
        top: 680px;
        right: 15%;
    }

    .left-45 {
        top: 710px;
    }

    .left-46 {
        top: 740px;
    }

    .left-47,
    .left-48 {
        top: 740px;
    }

    .left-x18-content,
    .left-x19-content {
        top: 770px;
    }

    /* start kanan 3 */
    .r-19 {
        left: 38%;
    }

    .r-y4-title {
        left: 48%;
    }
}

@media only screen and (max-width: 768px) {
    .chart-icon-0 img {
        height: 120px;
        width: 120px;
        object-fit: contain;
    }

    .chart-icon-0 {
        left: 42%;
    }

    .chart-icon-2 {
        top: 290px;
    }

    .left-x1-title {
        width: 25%;
        right: 12%;
        top: 50px;
    }

    .left-x1En-title {
        top: 45px;
        width: 26%;
        right: 12%;
        word-break: break-word;
    }

    .left-22 {
        top: 115px;
        right: 24%;
    }

    .left-x2-title {
        width: 22%;
        top: 165px;
    }

    .left-23 {
        right: 38%;
        top: 190px;
    }

    .left-x3-content {
        right: 48%;
        top: 180px;
    }

    .left-24 {
        top: 190px;
        right: 42%;
    }

    .left-25 {
        top: 225px;
        right: 42%;
    }

    .left-x4-content {
        top: 205px;
        right: 48%;
    }

    .left-26 {
        top: 265px;
        right: 42%;
    }

    .left-x5-content {
        top: 255px;
        right: 51%;
    }

    .left-27 {
        top: 300px;
        right: 42%;
    }

    .left-x6-content {
        top: 285px;
        right: 48%;
    }

    /* left 2 */

    .left-21 {
        right: 38%;
    }

    .left-x3-title {
        right: 60%;
        width: 35%;
    }

    .left-28 {
        right: 78%;
    }

    .left-29 {
        right: 78%;
    }

    .left-x7-content {
        right: 84%;
        width: 25%;
        top: 115px;
    }

    .left-30 {
        right: 78%;

    }

    .left-x8-content {
        right: 84%;
        width: 20%;
    }

    .left-31 {
        right: 78%;

    }

    .left-x9-content {
        right: 84%;
        width: 20%;
    }

    .left-32 {
        right: 78%;

    }

    .left-x10-content {
        right: 84%;
        width: 20%;
    }

    /* left 3 */
    .left-3 {
        top: 350px;
    }

    .left-x4-title {
        width: 55%;
        top: 340px;
    }

    .left-33 {
        top: 370px;
        right: 24%;
    }

    .left-34 {
        top: 400px;
        width: 91%;
        right: 19%;
    }

    .left-35 {
        top: 400px;
        right: 19%;
    }

    .left-x11-content {
        top: 435px;
        width: 20%;
    }

    .left-36 {
        top: 400px;
        right: 30%;
    }

    .left-x12-content {
        top: 535px;
        right: 22%;
        width: 18%;
    }

    .left-37 {
        top: 400px;
        right: 41%;
    }

    .left-x13-content {
        top: 435px;
        right: 34%;
        width: 15%;
    }

    .left-38 {
        top: 400px;
        right: 52%;
    }

    .left-x14-content {
        top: 535px;
        right: 45%;
        width: 15%;
    }

    .left-39 {
        top: 400px;
        right: 60%;
    }

    .left-x15-content {
        top: 435px;
        right: 54%;
        width: 12%;
    }

    .left-40 {
        top: 400px;
        right: 67%;
    }

    .left-x16-content {
        top: 535px;
        right: 60%;
        width: 12%;
    }

    .left-41 {
        top: 400px;
        right: 75%;
    }

    .left-x17-content {
        top: 435px;
        right: 68%;
        width: 15%;
    }

    .left-50 {
        top: 400px;
        right: 84%;
    }

    .left-x20-content {
        width: 15%;
        top: 535px;
        right: 75%;
    }

    .left-51 {
        top: 400px;
        right: 96%;
    }

    .left-x21-content {
        top: 435px;
        width: 20%;
        right: 86%;
    }

    .left-52 {
        right: 110%;
        top: 400px;
    }

    .left-x22-content {
        width: 15%;
        top: 535px;
        right: 100%;
    }

    /* left 4 */

    .chart-icon-3 {
        top: 600px;
        left: 35.5%;
    }

    .left-42 {
        top: 660px;
    }

    .left-x5-title {
        top: 650px;
        right: 14%;
        width: 25%;
        text-align: center;
    }

    .left-43 {
        top: 660px;
        right: 40%;
    }

    .left-x6-title {
        top: 650px;
        right: 50%;
    }

    .left-44 {
        top: 680px;
        right: 24%;
    }

    .left-x7-title {
        top: 720px;
        width: 22%;
        right: 15%;
    }

    .left-45 {
        top: 750px;
        right: 24%;
    }

    .left-46 {
        top: 780px;
        right: 16%;
    }

    .left-47 {
        top: 780px;
        right: 16%;
    }

    .left-x18-content {
        top: 810px;
        right: 9%;
    }

    .left-48 {
        top: 780px;
        right: 34%;
    }

    .left-x19-content {
        top: 810px;
        right: 27%;
    }

    /* right 1 */

    .r-y2-content {
        top: 45px;
    }

    .r-y3-content {
        width: 50%;
    }

    .r-y4-content {
        top: 115px;
    }

    .r-y5-content {
        top: 145px;
        width: 45%;
    }

    .r-y10-content {
        top: 325px;
    }

    /* right 2 */
    .r-y2-title {
        width: 55%;
    }

    /* right 3 */
    .r-y3-title {
        top: 538px;
        width: 40%;
    }

    .r-19 {
        left: 45%;
    }

    .r-y4-title {
        left: 55%;
        width: 30%;
    }

    /* right 4 */

    .r-y13-content {
        top: 626px;
    }

    .r-y14-content {
        top: 666px;
        width: 30%;
    }

    .r-y15-content {
        top: 705px;
    }

    .r-y16-content {
        top: 745px;
    }

    /* right 6 */
    .r-y5-title {
        width: 20%;
    }

    .r-21 {
        left: 35%;
    }

    .r-22 {
        left: 39%;
    }

    .r-23,
    .r-24,
    .r-25 {
        left: 39%;
    }

    .r-y13-content,
    .r-y14-content,
    .r-y15-content,
    .r-y16-content {
        left: 45%;
    }
}