.gallery {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.contentGallery {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.boxTitle {
    position: absolute;
    left: calc(50% - 800px / 2);
    /* 800 = text Width   */
    top: calc(50vh - 35px);
    /* 25px = textHeight/2   */
    width: 800px;
    z-index: 10;
}

.title {
    position: relative;
    display: block;
    text-align: center;
    font: bold 60px Helvetica;
    color: #ffffff;
}

.box {
    position: relative;
    height: calc(100vh / 3);
    width: calc(100vw / 6);
}

.backgroundOverflow {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh / 3);
    width: calc(100vw / 6);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.box figure {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
}

.box figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    filter: grayscale(1);
}

.box:hover .backgroundOverflow {
    z-index: -1;
}

.box:hover figure img {
    filter: none;
}

.box figure:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.viewMore {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: calc(100vh - 3rem - 56px);
    left: calc(50% - 108px);
}

.contentViewMore {
    position: relative;
    display: flex;
    justify-content: center;
    width: 216px;
    height: 56px;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff;
    border-radius: 28px;
    opacity: 1;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    opacity: 1;
    margin: auto 0.2rem auto auto;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 4px;
    height: 4px;
    border-radius: 100%;
}

.contentViewMore span {
    margin: auto auto auto 0.2rem;
    color: #ffffff;
    font: 16px Helvetica;
}

@media only screen and (min-width: 2449px) {}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1024px) {
    .boxTitle {
        left: calc(50% - 600px / 2);
        /* 800 = text Width   */
        top: calc(50vh - 50px / 2);
        width: 600px;
    }

    .title {
        font: bold 50px Helvetica;
    }

    .box {
        position: relative;
        height: calc(100vh / 5);
        width: calc(100vw / 4);
    }

    .backgroundOverflow {
        height: calc(100vh / 5);
        width: calc(100vw / 4);
    }

    .contentViewMore span {
        font: 14px Helvetica;
    }
}

@media only screen and (max-width: 768px) {
    .boxTitle {
        top: calc(50vh - 40px / 2);
    }

    .title {
        font: bold 40px Helvetica;
    }
}

@media only screen and (max-width: 480px) {
    .boxTitle {
        left: calc(50% - 310px / 2);
        /* 800 = text Width   */
        top: calc(50vh - 35px / 2);
        width: 310px;
    }

    .title {
        font: bold 24px Helvetica;
    }

    .box {
        position: relative;
        height: calc(100vh / 4);
        width: calc(100vw / 2);
    }

    .backgroundOverflow {
        height: calc(100vh / 4);
        width: calc(100vw / 2);
    }

    .viewMore {
        position: absolute;
        cursor: pointer;
        z-index: 10;
        top: calc(100vh - 2.5rem - 45px);
        left: calc(50% - 90px);
    }

    .contentViewMore {
        width: 180px;
        height: 45px;
    }
}


@media only screen and (max-width: 360px) {


    .title {
        font: bold 20px Helvetica;
    }
}