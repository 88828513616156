.modalHeader button {
    font-size: 2.5rem;
    width: auto;
    height: auto;
    padding: 1rem 2rem 1rem 1rem !important;
    opacity: 1;
}
.modalHeader button:focus-visible, .modalHeader button:focus {
    border: 0;
    outline: none;
}
.title {
    font: bold 36px/48px Helvetica;
    color: white;
    margin-bottom: 2rem;
}

.subTitle {
    font: bold 26px Helvetica;
    color: white;
    margin-bottom: 2rem;
    font-style: italic;
    // text-transform: lowercase;
}

.contentSignature {
    font: 20px/32px Helvetica;
    color: white;
}

.contentSub {
    text-align: left;
    font: bold 18px/32px Helvetica;
    color: white;
    margin-bottom: 2rem;
}

.content {
    text-align: left;
    font: 16px/32px Helvetica;
    color: white;
}

.signature {
    padding: 1rem 0 0;
    position: relative;
    float: right;
    text-align: center;

    .imgSignature {
        height: 100px;
        width: auto;
        mix-blend-mode: exclusion;
    }
}

.boxImage {
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.backgroundImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    // transform: scale(1.1);
}

.letterContent {
    // height: 100vh;
    width: 100vw;
    background-color: #223771;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    padding-bottom: 68px;

    .boxLetter {
        padding: 2rem 0 0;

        .contentLetter {
            column-count: 2;
            column-span: all;
            color: white;
            text-align: justify;
            line-height: 28px;
            column-gap: 50px;

            .leftContent {
                padding: 0 3rem 0 0;
                width: 50%;
                overflow-wrap: break-word;
                text-align: justify;
            }

            .rightContent {
                padding: 0 0 0 3rem;
                width: 50%;
                overflow-wrap: break-word;
                text-align: justify;
            }
        }
    }

    .imageLetter {
        position: absolute;
        bottom: 3.5rem;
        left: 2.8rem;
        z-index: -1;
        transform: scale(0.9);
    }

    .backgroundImage {
        position: absolute;
        bottom: -7rem;
        left: -5rem;
        z-index: -2;
        transform: scale(0.9);
        mix-blend-mode: luminosity;
    }
}

.letterIcon svg {
    position: relative;
}

#circleLine {
    position: relative;
}

.customG {
    transform: translate(18px, 18px);
}

.svgAnimate0 {
    transform-origin: 120px 120px;
    transform-box: fill-box;
    position: relative;
    animation: rotate-group 10s linear infinite -8s both;
}

.svgAnimate {
    transform-origin: 120px 120px;
    transform-box: fill-box;
    position: relative;
    animation: rotate-group 10s linear infinite;
}

.svgAnimate1 {
    transform-origin: 120px 120px;
    transform-box: fill-box;
    position: relative;
    animation-delay: 1s;
    animation: rotate-group 10s linear infinite -2s both;
}

.svgAnimate2 {
    transform-origin: 120px 120px;
    transform-box: fill-box;
    position: relative;
    animation-delay: 2s;
    animation: rotate-group 10s linear infinite -4s both;
}

.svgAnimate3 {
    transform-origin: 120px 120px;
    transform-box: fill-box;
    position: relative;
    animation: rotate-group 10s linear infinite -6s both;
}

.svgAnimateSafari0 {
    --factor: 1;
    transform-origin: calc(120px * var(--factor)) calc(120px * var(--factor));
    transform-box: fill-box;
    position: relative;
    animation: rotate-group 10s linear infinite;
}

.svgAnimateSafari {
    --factor: 1;
    transform-origin: calc(120px * var(--factor)) calc(120px * var(--factor));
    transform-box: fill-box;
    position: relative;
    animation: rotate-group 10s linear infinite -4s both;
}

.svgAnimateSafari1 {
    --factor: 1;
    transform-origin: calc(120px * var(--factor)) calc(120px * var(--factor));
    transform-box: fill-box;
    position: relative;
    animation-delay: 1s;
    animation: rotate-group 10s linear infinite -2s both;
}

.svgAnimateSafari2 {
    --factor: 1;
    transform-origin: calc(120px * var(--factor)) calc(120px * var(--factor));
    transform-box: fill-box;
    position: relative;
    animation-delay: 2s;
    animation: rotate-group 10s linear infinite -8s both;
}

.svgAnimateSafari3 {
    --factor: 1;
    transform-origin: calc(120px * var(--factor)) calc(120px * var(--factor));
    transform-box: fill-box;
    position: relative;
    animation: rotate-group 10s linear infinite -6s both;
}

@keyframes rotate-group {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(-360deg);
    }
}

.fadeInBack {
    -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    z-index: 100;
}

@-webkit-keyframes fade-in-bck {
    0% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes fade-in-bck {
    0% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.fadeOutFwd {
    -webkit-animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-fwd {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
        z-index: -1;
    }
}

@keyframes fade-out-fwd {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
        z-index: -1;
    }
}


.customContainer {
    margin-bottom: 3rem;
}


.letterContent button {
    color: white !important;
}


.modalHeader {
    border-bottom: unset;
}

@media only screen and (min-width: 2560px) {
    .subTitle {
        font: bold 26px Helvetica;
    }

    .letterContent {

        height: 100vh !important;

        .imageLetter {
            bottom: 6rem;
        }

        .backgroundImage {
            bottom: -4.6rem;
        }

        .boxLetter {
            padding: 6rem 0 0;
        }
    }
}


@media only screen and (min-width:2047px) and (max-width: 2048px) {
    .letterContent {
        height: 100vh;
    }
}

@media only screen and (min-width:1800px) and (max-width:1920px) {
    .letterContent {
        height: 100vh;
    }
}


@media only screen and (max-width: 1680px) {
    .title {
        font: bold 28px/28px Helvetica;
        margin-bottom: 1rem;
    }



    .letterContent {

        .boxLetter {
            height: auto !important;
        }
    }
}


// @media only screen and (max-width: 1600px) {
//     .letterContent button {
//         color: white !important;
//     }


//     .modalHeader {
//         border-bottom: unset;
//     }
// }


@media only screen and (max-width: 1440px) {
    .title {
        font: bold 28px/28px Helvetica;
        margin-bottom: 1rem;
    }

    .subTitle {
        font: bold 18px Helvetica;
        margin-bottom: 1rem;
    }

    .content {
        font: 14px/1.8rem Helvetica;
    }

    .imageLetter {
        top: unset !important;
        bottom: -4rem;

        img {
            height: 60vh;
        }
    }

    .signature {
        padding: 1rem 0 0;

        img {
            transform: scale(0.7);
        }
    }

    .contentSignature {
        font: 14px Helvetica;
        margin: 0;
    }

    .letterContent {

        .imageLetter {
            transform: scale(0.5);
            bottom: -3.3rem;
            left: -2.8rem;
        }

        .backgroundImage {
            transform: scale(0.5);
            bottom: -16.6rem;
            left: -18rem;
        }

        .boxLetter {
            padding: 1rem 3.5rem;
        }
    }

    .leftContent {
        padding: 0 1.5rem 0 0 !important;
    }

    .rightContent {
        padding: 0 0 0 3rem !important;
    }
}


@media only screen and (min-width:1000px) and (max-width: 1024px) {
    .letterContent {
        height: 100vh !important;
    }
}

@media only screen and (max-width: 768px) {


    .customContainer {
        max-width: 672px;
    }

    .boxLetter {
        padding: 2rem 0 !important;
    }

    .rightContent {
        padding: 0 0 0 1rem !important;
    }
}