.authorBox {
    width: 100%;
    text-align: center;
}

.authorImg {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.authorName {
    font: bold 16px/1.8rem Helvetica;
    margin: 1rem 0 0 0;
}

.authorTitle {
    margin: 0;
    font: normal 16px Helvetica;
}

@media only screen and (max-width: 1920px) {}

@media only screen and (max-width: 1600px) {}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 480px) {
    .authorBox {
        width: 100%;
        display: flex;
    }
    
    .authorImg {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
    }
    
    .authorName {
        font: bold 18px/2.5rem Helvetica;
        margin: 0 0 0 1rem;
    }
    
    .authorTitle {
        margin: 0;
        font: normal 14px Helvetica;
    }
}
