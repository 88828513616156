.Section3 {
    height: 100%;
    width: auto;
    position: relative;
    background-color: white;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.contentRight {
    position: absolute;
    right: 0;
}

.contentImgRight {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.boxTextSec3 {
    width: 920px;
    height: 440px;
}

.ContentTitle {
    width: 346px;
    height: 60px;
    font-size: 46px/55px Helvetica;
}

.ContentTitle h2 {
    font-size: 46px/55px Helvetica;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
}

p.title,
h2.title {
    font: bold 36px Helvetica;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 2rem;
}

p.content {
    font: 16px/32px Helvetica;
    text-align: justify;
    margin-bottom: 2rem;
}

.boxTitle {
    height: 62px;
    position: absolute;
    align-self: center;
    top: 14%;
}

p.contentLink {
    font: 16px/24px Helvetica;
    color: black !important;
    margin-left: -4%;
    margin-top: 14px;
}

.contentLink:hover p {
    opacity: 1;
    margin-left: 0;

}

.contentLink:hover .outerCircle {
    opacity: 1;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

h2.titleContent3 {
    font-size: 36px Helvetica;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
}

p.textContent3 {
    font-size: 16px/32px Helvetica;
}

.contentLeft {
    width: 562px;
    height: 341px;
    position: absolute;
    font-size: 16px !important;
    top: 25%;
}

.readMore {
    height: 100px;
    bottom: 25%;
}

.Link {
    color: transparent !important;
    cursor: pointer;
}

.Link:hover {
    text-transform: none;
    text-decoration: none;
}

.disp {
    display: flex;
    flex-direction: row;
}

.content {
    font: 16px/32px Helvetica;
    color: #000000;
}

.box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.boxLeft {
    width: 50%;
    height: 424px;
    background-color: #fff;
    padding-right: 7rem;
}

.boxRight {
    width: 50%;
    height: 424px;
}

.titleChart {
    text-align: left;
    font: 22px/27px Helvetica;
    color: #ffffff;
    padding: 2rem 2rem 0;
}

.lineChart {
    animation: fadeIn 600ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
    opacity: 0;
    max-width: 640px;
    width: 100%;
    margin: auto 0;
    padding: 0 2rem;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.boxLinks {
    position: relative;
    margin: auto 0;
}

.boxLink {
    position: relative;
    margin-top: 2rem;
    padding-bottom: 5rem;
}

.linkAll {
    text-align: left;
    font: 14px/24px Helvetica Neue LT Std;
    color: #000000;
    cursor: pointer;
    transition: all 1;
    margin: auto 0;
}

.boxLink:hover .outerCircle {
    opacity: 1;
}

.boxLink:hover .linkAll {
    margin-left: 1.5rem;
}

.boxC {
    max-width: 1540px;
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2090px !important;
    }

    .contentImgRight {
        width: 1029px;
    }

    p.title,
    h2.title {
        margin-bottom: 2rem;
    }

    p.title,
    h2.title {
        font: bold 36px Helvetica;
    }
}

@media only screen and (max-width: 1680px) {
    .customContainer {
        max-width: 76vw;
        padding: 0;
    }

    .Section3 {
        height: auto;
        padding-bottom: 3.5rem;
    }

    .contentImgRight {
        width: 100%;
        height: 350px;
    }

    .boxLeft {
        padding-right: 5rem;
        height: auto;
    }

    .boxRight {
        height: 100%;
    }

    .readMore {
        height: auto;
    }

    .readMore p {
        font: 16px Helvetica;
        line-height: 1.8;
    }

    p.title,
    h2.title {
        font: bold 28px Helvetica;
    }

    p.content {
        font: normal 16px Helvetica;
        line-height: 1.8;
    }
}

@media only screen and (max-width: 1440px) {
    p.content {
        font: normal 14px Helvetica;
        line-height: 1.8;
    }

    .readMore p {
        font: normal 14px Helvetica;
        line-height: 1.8;
    }
}

@media only screen and (max-width: 1024px) {
    .boxC {
        max-width: 88vw;
    }

    .contentLink p {
        opacity: 1;
        margin-left: 0;

    }

    .contentLink .outerCircle {
        opacity: 1;
    }
}


@media only screen and (max-width: 768px) {
    .customContainer {
        max-width: 88% !important;
    }

    p.title,
    h2.title {
        font: bold 18px Helvetica !important;
    }

    .boxLeft {
        padding-right: 2.5rem !important;
    }

    .Section3 {
        padding-top: 3.5rem !important;
    }
}


@media only screen and (max-width: 480px) {
    .customContainer {
        max-width: calc(100vw - 12vw) !important;
    }

    .box {
        flex-direction: column-reverse;
    }

    .boxLeft,
    .boxRight {
        width: 100%;
    }

    .boxLeft {
        padding-right: 0 !important;
        padding-top: 2vh;
    }

    .contentImgRight {
        height: 30vh;
    }

    .contentLink p {
        opacity: 1;
        margin-left: 0;

    }

    .contentLink .outerCircle {
        opacity: 1;
    }

}