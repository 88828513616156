.section {
    padding: 3.5rem 0 0 0;
}

.customContainer {
    padding: 0;
    max-width: 88vw;
    margin: 0 auto;
}

.card {
    margin-bottom: 2.5rem;
}

.figure {
    width: 100%;
    height: 274px;
    margin-bottom: 1rem;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardTitle {
    font: bold 14px Helvetica;
    line-height: 2.5rem;
}

.cardContent p {
    font: normal 14px Helvetica;
    line-height: 1.5rem;
    text-align: justify;
}