.Link {
  color: white !important;
}

/* .Link:hover {
  text-transform: none;
  text-decoration: none;
} */

.itemLink {
  padding: 0 !important;
  letter-spacing: 0 !important;
}

.titleB_1 {
  color: #d6d6d6;
  text-transform: uppercase;
  font: 14px Helvetica;
  margin-bottom: 0.5rem;
}

.titleB_2 {
  color: white;
  text-transform: uppercase;
  font: 14px Helvetica;
  margin-bottom: 0.5rem;
}

.mainBox {
  width: 50%;
  height: 100%;
}

.boxMainNews {
  width: auto;
  height: 100%;
}

.custom {
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 85px;
  max-width: 300px;
  padding-top: 1rem;
}

.textTitleNews {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.textTitleNews,
.textTitleNews p {
  margin: 0 !important;
  text-transform: capitalize;
  font-size: small;
  letter-spacing: 0;
  color: white;
}

.dateTitleNews {
  font-size: small;
  color: #f16b26;
  letter-spacing: 0;
}

.contentImg {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.contentImgNull {
  height: 300px;
  width: 300px;
  object-fit: contain;
}

.card01 {
  width: 17vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cardi01 {
  width: 100%;
  height: 100%;
}

.card01 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* filter: blur(2px); */
}

.title01 {
  font: bold 14px Helvetica;
  color: white;
  margin-top: 1rem;
}

.title02 {
  font: bold 14px Helvetica;
  color: #f16b26;
}

.card01 a {
  padding: 0 !important;
  height: 25vh;
}

.boxL {
  height: 100%;
  width: 20vw;
}

.picBox {
  width: 100%;
  height: 20vh;
}

.picBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleB {
  color: #d6d6d6;
  text-transform: uppercase;
  font: 14px Helvetica;
  margin-bottom: 0.5rem;
}

.litleP {
  color: white;
  font: bold 14px Helvetica;
}

/* .titleB:hover::after {
  content: " \2192";
  color: #f16b26;
} */

.titleC {
  color: #f16b26;
  font: 16px Helvetica;
  text-transform: uppercase;
  width: 100%;
}

/* .boxL p {
  color: white;
  font: 16px Helvetica;
  text-transform: uppercase;
  height: 20%;
} */

.boxR {
  background: violet;
  height: 100%;
}

.linkPic {
  width: 100%;
  height: 100%;
}

.blurNeed {
  background: transparent;
  filter: blur(5px);
  position: relative;
  width: 100%;
}

/* .blurNeed p {
  color: white;
} */

.boxSignIn {
  width: 15vw;
  height: 4vh;
  /* background: #f16b26; */
  position: absolute;
  margin: auto;
  display: flex;
  /* padding: 12% 42%; */
  top: 50%;
}

.loginTrue {
  display: none;
}

.isFilter {
  filter: none;
}

.boxSignIn p {
  font: bold 14px Helvetica;
  color: white;
  margin: auto;
}

.boxSign {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #223971;
  border-radius: 30px;
}

.boxSign a {
  text-decoration: none !important;
  font-size: 14px !important;
  color: white;
  letter-spacing: 0 !important;
  text-transform: none !important;
  font-family: Helvetica;
  padding: 0 !important;
  margin: auto;
}

@media only screen and (max-width: 1680px) {

  .contentImg,
  .contentImgNull {
    height: 219px;
    width: 254px;
  }
}

@media only screen and (max-width: 1440px) {

  .contentImg,
  .contentImgNull {
    height: 200px;
    width: 200px;
  }



  .boxL p {
    font: 14px Helvetica;
  }

  .custom {
    height: 76px;
    max-width: 200px;
  }

  .custom p {
    font: 14px Helvetica;
  }
}