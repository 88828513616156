.banner {
    height: 65vh;
    width: 100vw;
    position: relative;
}

.imgBanner {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* .contentRunning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -35.4vh;
}

.textRunning {
    font: 48px Helvetica;
    font-weight: bold;
    margin: auto auto 1rem;
    text-align: center;
    color: white;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: header-anim;
}

@keyframes header-anim {
    0%, 30% {
        transform: translateX(-5%);
        opacity: 0;
    }
    30%, 70% {
        transform: translateX(0);
        opacity: 1;
    }
    70%, 99% {
        opacity: 1;
    }
    100%, 99% {
        opacity: 0;
    }
}

.underlineAnimate {
    display: block;
    height: 2px;
    width: 900px;
    margin-left: auto;
    margin-right: 28%;
    background-color: white;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-anim;
}

@keyframes underline-anim {
    0%, 10% {
        width: 0;
        transform: translateX(-900px);
    }
    10%, 20% {
        width: 900px;
        transform: translateX(0);
    }
    20%, 30% {
        width: 0;
        transform: translateX(100px);
    }
    100%, 30% {
        transform: translateX(0);
        width: 0;
    }
} */