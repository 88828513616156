body {
    overflow-x: hidden;
}

.TransformComponent-module_container__3NwNd {
    position: relative;
    width: 100% !important;
    height: fit-content;
    overflow: hidden;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    margin: 0;
    padding: 0;
}

.TransformComponent-module_content__TZU5O {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    height: fit-content;
    margin: 0;
    padding: 0;
    transform-origin: 0 0;
}

.TransformComponent-module_content__TZU5O img {
    pointer-events: none;
}
path#au:hover,
path#br:hover,
path#ca:hover,
path#gb:hover,
path#id:hover,
path#ru:hover,
path#us:hover,
path#us:hover,
path#za:hover {
    fill: #F26522;
}
path#vn:hover {
    fill: #F26522;
}

path#node-light {
    filter: blur(100px) !important;
}
