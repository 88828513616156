.customContainer {
    max-width: 88vw;
}

.filterList {
    /* margin-bottom: 2rem; */
}

.filterList {
    display: flex;
}

.filterContent {
    width: auto;
    height: 32px;
    background: #707070 0% 0% no-repeat padding-box;
    border-radius: 16px;
    display: flex;
    padding: 0 1rem;
    margin-right: .5rem;
    cursor: pointer;
}

.filterActive {
    background: #000000 0% 0% no-repeat padding-box;
}

.filterContent span {
    color: white;
    text-align: center;
    font: 16px/32px Helvetica;
}

.jobs {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.box {
    width: 366px;
    height: 376px;
    background: #223771 0% 0% no-repeat padding-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.boxApply {
    background: #707070 0% 0% no-repeat padding-box;
}

.boxLink:hover  {
    text-decoration: none;
}

.category {
    text-align: left;
    font: 18px/32px Helvetica;
    letter-spacing: -0.18px;
    color: #F0F0F0;
}

.boxContent {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 100%;
}

.boxContent span {
    color: white;
}

.innerContent {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.title {
    text-align: left;
    font: bold 20px/32px Helvetica;
    color: #000;
}

.content {
    text-align: left;
    font: 16px Helvetica;
    letter-spacing: -0.16px;
    color: #F0F0F0;
}

.boxDetail {
    margin-top: auto;
    display: flex;
}

.Link {
    margin-left: auto;
    width: 40px;
    height: 40px;
    border: 2px solid #FFFFFF;
    opacity: 1;
    border-radius: 50%;
    display: flex;
}

.Link img {
    margin: auto;
    height: 100%;
    width: 100%;
}

.customTitle {
    padding-top: calc(5rem + 56px);
    background: #F9F9F9;
}

@media only screen and (max-width: 480px) {
    .boxLink {
        width: 100%;
        height: 100%;
    }
    .box {
        width: 100%;
        height: 35vh;
    }
    .filterContent {
        width: 100%;
        height: 32px;
        background: #707070 0% 0% no-repeat padding-box;
        border-radius: 16px;
        display: flex !important;
        padding: 0 1rem;
        cursor: pointer;
    }
    .filterContent span {
        margin: auto;
    }
}

@media only screen and (max-width: 900px) and (orientation:landscape) {
    .customTitle {
        padding-top: calc(2rem + 56px);
        background: #F9F9F9;
    }

    .filterContent {
        display: flex !important;
    }
    .filterContent span {
        margin: auto;
    }
    .box{
        height: 340px;
    }
}