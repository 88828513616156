.customContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: relative;
}

.c_err {
    display: flex;
    margin: auto;
}

.boxLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
}

.boxRight {
    height: 352px;
    width: 352px;
    margin-left: 2rem;
}

.errorIcon {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.boxNum {
    height: auto;
    width: 100%;
}

.num {
    text-align: center;
    font: 180px Roboto;
    letter-spacing: 0px;
    color: #B0B0B0;
    margin: 0;
}

.text {
    text-align: center;
    font: 26px/32px Helvetica;
    letter-spacing: 0px;
    color: #484848;
    margin-bottom: 1rem;
}

.detail {
    text-align: center;
    font: 16px/32px Helvetica;
    letter-spacing: 0px;
    color: #484848;
}

.btnCustom {
    width: 312px;
    height: 48px;
    background: #223771 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    position: relative;
    display: flex;
}

.btnCustom span {
    margin: auto;
    text-align: center;
    font: bold 16px/18px Helvetica;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.customLink:hover {
    text-decoration: none;
}

@media only screen and (max-width: 1024px) {
    .c_err {
        display: flex;
        flex-direction: column-reverse;
    }
    .boxLeft {
        margin-top: 2rem;
        margin-right: 0;
    }

    .boxRight {
        width: 50%;
        height: 50%;
        display: flex;
        margin: 0 auto;
    }

    .errorIcon {
        margin: auto;
        height: 60%;
        width: 60%;
    }
}

@media only screen and (max-width: 480px) {
    .num {
        font: 100px Roboto;
    }
    .text {
        font: 18px/32px Helvetica;
    }
    .customLink {
        width: 100%;
    }

    .btnCustom {
        width: 60%;
        display: flex;
        margin: auto;
    }
}