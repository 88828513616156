.disableScroll {
    overflow: hidden !important;
}

.iconItems {
    position: fixed;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 4rem);
}

.contentMenu {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
}

.frCkstm {
    background-color: rgba(0, 0, 0, .9) !important;
}

.footerSocialMedia {
    display: flex;
}
.footerSocialMedia a {
    font-size: unset !important;
    margin-right: 1rem;
    color: unset !important;
}
.footerSocialMedia svg {
    width: 32px;
    height: 32px;
    /* transform: scale(1.4); */
}


@media only screen and (min-height: 800px) {
    .iconItems {
        bottom: 6vh;
        width: 70vw;
    }
}

@media only screen and (max-width: 1024px) and (min-height: 800px){
    .iconItems {
        width: 70vw;
        margin-left: 10vw;
    }
}

@media only screen and (max-height: 725px) {
    .iconItems {
        bottom: 10vh;
    }
}

@media only screen and (max-width: 360px){
    .iconItems {
        display: none;
    }
}