/* @media (min-width: 576px) {
    .modal-dialog {
        max-width: none;
        margin: 0;
        border-radius: 0;
    }
} */

#bod-modal > .modal-content {
    margin-left: 10%;
    display: inline-block;
    border-radius: 0;
    background-color: #223771;
}
