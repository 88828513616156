/* GENERAL */

:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;
  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */

.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 100px;
}

.customContainer {
  max-width: 88vw;
  margin: 0 auto 0 auto;
}

.topContainer {
  min-height: 70vh;
  display: block;
}

.col1 {
  display: flex;
}

.col2 {
  display: block;
}

.authorName {
  padding: 1rem 0 0 2rem;
  font: bold 20px Helvetica;
}

.title {
  font: bold 16px Helvetica;
  line-height: 1.8;
}

.summary {
  font: normal 14px Helvetica;
  line-height: 1.8;
}

.date {
  font: normal 12px Helvetica;
  color: gray;
  line-height: 1.8;
}

.boxName {
  width: 25%;
  font: normal 16px Helvetica;
  padding-right: 2%;
  min-height: 250px;
}

.boxName:hover {
  background-color: lightcoral;
}

.imgCont {
  text-align: center;
}

.authorImg {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.link, .link:hover {
  color: black;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.03);
}

.backBtn, .backBtn:hover {
  color: black;
  text-decoration: none;
  font: var(--p-hd);
}

.arrow {
  color: black;
  margin-right: 1rem;
}

.authorText {
  font: bold 18px/2.5rem Helvetica;
  text-transform: uppercase;
  margin-bottom: 1rem;
}