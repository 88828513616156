.contentSection {
    height: auto;
    width: 100%;
    background-color: #F1F1F1;
}

.customContainer {
    padding-bottom: 5rem;
}

@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: 1062px !important;
    }
}

@media only screen and (max-width: 1366px) {
    .customContainer {
        max-width: 1062px !important;
    }
}

@media only screen and (max-width: 835px) and (min-width: 415px) {
    .customContainer {
        max-width: 672px !important;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3.5rem;
    }
}

@media only screen and (max-width: 414px) {
    .customContainer {
        max-width: 366px !important;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3.5rem;
    }
}