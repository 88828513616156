.sliderGallery .slick-dots li.slick-active button:before {
    background-color: #f16b26;
    opacity: 1;
}

.sliderGallery li button {
    width: 10px;
    height: 10px;
}

.sliderGallery .slick-dots li button:before {
    width: 10px;
    height: 10px;
    font-size: 0;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid rgb(214, 214, 214);
    opacity: 1;
}

.sliderGallery li:last-of-type {
    width: 10px;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .sliderGallery .slick-dots {
        bottom: 33px !important;
    }
}