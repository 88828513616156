.box {
    .thumbC {
        width: auto;
    }

    .thumbImg {
        width: 100%;
    }
}

.title {
    margin-top: 1rem;
    font: bold 14px Helvetica;
    line-height: 1.5rem;
}

.summary {
    padding-top: 1rem;
    font: normal 14px Helvetica;
    line-height: 1.5rem;
}

.customLink {
    margin: 26px 0 2rem 0;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    font: normal 14px Helvetica;
    color: black;
}

.customLink .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.readMore {
    font: normal 14px Helvetica;
    color: black;
    text-decoration: none;
}