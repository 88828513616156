.jobs {
    /* padding-top: 2rem; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.box {
    width: 416px;
    height: 376px;
    background: #223771 0% 0% no-repeat padding-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.boxBookmarks {
    background: #707070 0% 0% no-repeat padding-box;
}

.customLink:hover {
    text-decoration: none;
}

.category {
    text-align: left;
    font: 18px/32px Helvetica;
    letter-spacing: -0.18px;
    color: #F0F0F0;
}

.boxContent {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 100%;
}

.innerContent {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.title {
    text-align: left;
    font: bold 20px/32px Helvetica;
    letter-spacing: -0.28px;
    color: #F0F0F0;
    margin-bottom: 1rem;
}

.content {
    text-align: left;
    font: 14px Helvetica;
    letter-spacing: -0.16px;
    color: #F0F0F0;
}

.boxDetail {
    margin-top: auto;
    display: flex;
}

.Link {
    margin-left: auto;
    width: 40px;
    height: 40px;
    border: 2px solid #FFFFFF;
    opacity: 1;
    border-radius: 50%;
    display: flex;
}

.Link img {
    margin: auto;
    height: 100%;
    width: 100%;
}

.LinkB {
    cursor: pointer;
}

@media only screen and (max-width: 1400px) { 
    .box {
        width: 356px;
        height: 326px;
        padding: 2rem;
    }
}

@media only screen and (max-width: 768px) {
     .box {
         width: 38vw;
         height: 30vh;
     }
     .title {
        font: bold 18px/32px Helvetica; 
    }
}

@media only screen and (max-width: 480px) {
    .box {
        width: 100%;
        height: 40vh;
    }
}

@media only screen and (max-width: 900px) and (orientation:landscape) {
    .box {
        height: 340px;
    }
}
