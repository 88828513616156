.contentLang {
    display: flex;
    flex-direction: row;
}

.contentLang img {
    height: 32px;
    width: 32px;
    opacity: .5;
}

.contentLang img:first-child {
    margin-right: 1rem;
}

.active {
    opacity: 1 !important;
}