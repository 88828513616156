.Section2 {
    width: auto;
    background-color: #faf9f5;
    position: relative;
    padding-top: 5rem;
    padding-bottom: 7.4rem;
    margin-top: 5rem;
}

.contentRight {
    position: absolute;
    right: -15rem;
    top: 3rem;
}

.boxRight {
    width: 571px;
    height: 100%;
    position: relative;
}

.contentImgRight {
    width: auto;
    height: 752px;
    object-fit: cover;
}

.contentBox {
    width: 50vw;
    height: 55vh;
    position: absolute;
    left: 20%;
    margin-bottom: 0;
    margin-top: 10px 0 10px 0;
}

.boldText {
    font-weight: bold;
}

.content2 {
    width: auto;
    height: auto;
}

.containerBox {
    position: relative;
    display: flex;
    align-items: center;
    height: 565px;
}

.containerBox2 {
    position: relative;
    display: flex;
    align-items: center;
    height: 972px;
}

.outerContent1 {
    margin: 153px auto auto;
    border: 3px solid black;
    width: 450px;
    height: 450px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.outerContent2 {
    position: relative;
    margin: 153px auto auto;
    border: 3px dotted rgba(0, 0, 0, 0.363);
    width: 700px;
    height: 700px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.wrapBox {
    width: 143px;
    height: 82px;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.innerText {
    font: bold 26px Helvetica;
    color: black;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(140, 140, 140, 0.9);
    width: 300px;
    text-transform: uppercase;
}

.Content1 {
    margin: auto;
    width: 400px;
    height: 400px;
    display: flex;
    text-align: center;
    background-color: #ececec;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    -webkit-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    -moz-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    box-shadow: -15px 13px 24px -8px rgba(179, 90, 90, 0.9);
}

.Content1:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
}

.Content2 {
    margin: auto;
    width: 450px;
    height: 450px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: white;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    justify-content: center;
    -webkit-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    -moz-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
}

.Content2:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
}

.circleBig {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
    transform: translate(269px, -343px);
    padding: 2rem;
}

.circleBig img {
    width: 80px;
    transform: translate(4px, 6px);
}

.circleBig:nth-child(6) {
    transform: translate(-50px, -127px);
}

.circleBig:nth-child(6) .textBox {
    width: 300px;
    transform: translate(712px, 127px);
}

.circleBig:nth-child(7) {
    transform: translate(38px, 246px);
}

.circleBig:nth-child(7) .textBox {
    transform: translate(478px, -657px);
}

.circleBig:nth-child(8) {
    transform: translate(499px, 245px);
}

.circleBig:nth-child(8) .textBox {
    width: 430px;
    transform: translate(-754px, -657px);
}

.circleBig:nth-child(9) {
    transform: translate(586px, -133px);
}

.circleBig:nth-child(9) .textBox {
    transform: translate(-867px, 141px);
}

.circleBig:nth-child(10) .textBox {
    width: 345px;
    transform: translate(-123px, 663px);
}

.textBox {
    width: 260px;
    transform: translate(-78px, 663px);
    color: black;
    text-align: center;
    height: auto;
    padding: 1rem;
    font: normal 20px/22px Helvetica;
    text-transform: inherit;
}

.textBox p {
    opacity: 1;
}

.circle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(55, 44, 124, 0.9);
    border-radius: 10px;
    color: transparent;
    top: 61%;
    left: 0;
    -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
}

.circle:nth-child(2) {
    transform: translate(675px, 0);
}

.circle:nth-child(3) {
    transform: translate(336px, 264px);
}

.circle:nth-child(4) {
    transform: translate(536px, -373px);
}

.circle:nth-child(5) {
    transform: translate(138px, -372px);
}

p.titleContent1 {
    margin: auto;
    width: 200px;
    font: bold 20px Helvetica;
    font-weight: bold;
}

.satelliteBox {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    margin: auto;
    left: 397px;
}

.satelliteBox:nth-child(2) {
    transform: translate(85px, -138px) rotate(45deg);
}

.satelliteBox:nth-child(3) {
    transform: translate(275px, -208px) rotate(90deg);
}

.satelliteBox:nth-child(4) {
    transform: translate(490px, -134px) rotate(135deg);
}

.satelliteBox:nth-child(5) {
    transform: translate(570px, 0px) rotate(180deg);
}

.satelliteBox:nth-child(1) .smallBox {
    transform: rotate(-45deg);
}

.satelliteBox:nth-child(2) .smallBox {
    transform: rotate(-45deg);
}

.satelliteBox:nth-child(3) .smallBox {
    transform: rotate(-90deg);
}

.satelliteBox:nth-child(4) .smallBox {
    transform: rotate(-135deg);
}

.satelliteBox:nth-child(5) .smallBox {
    transform: rotate(-180deg);
}

.satelliteBox:nth-child(2) .outerSmallBox {
    background: rgb(254, 216, 0);
    background: linear-gradient(90deg, rgba(254, 216, 0, 1) 0%, rgba(243, 154, 0, 1) 100%);
    transition: 0.3s;
}

.satelliteBox:nth-child(2) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(243, 154, 0, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(243, 154, 0, 1);
    box-shadow: 1px 2px 20px 11px rgba(243, 154, 0, 1);
}

.satelliteBox:nth-child(3) .outerSmallBox {
    background: rgb(0, 168, 199);
    background: linear-gradient(90deg, rgba(0, 168, 199, 1) 0%, rgba(0, 139, 210, 1) 100%);
    transition: 0.3s;
}

.satelliteBox:nth-child(3) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(0, 139, 210, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(0, 139, 210, 1);
    box-shadow: 1px 2px 20px 11px rgba(0, 139, 210, 1);
}

.satelliteBox:nth-child(3) .outerSmallBox {
    background: rgb(0, 168, 199);
    background: linear-gradient(90deg, rgba(0, 168, 199, 1) 0%, rgba(0, 139, 210, 1) 100%);
}

.satelliteBox:nth-child(4) .outerSmallBox {
    background: rgb(6, 125, 193);
    background: linear-gradient(90deg, rgba(6, 125, 193, 1) 0%, rgba(34, 55, 113, 1) 100%);
    transition: 0.3s;
}

.satelliteBox:nth-child(4) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(34, 55, 113, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(34, 55, 113, 1);
    box-shadow: 1px 2px 20px 11px rgba(34, 55, 113, 1);
}

.satelliteBox:nth-child(5) .outerSmallBox {
    background: rgb(19, 81, 149);
    background: linear-gradient(90deg, rgba(19, 81, 149, 1) 0%, rgba(55, 44, 124, 1) 100%);
    transition: 0.3s;
}

.satelliteBox .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(234, 98, 11, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(234, 98, 11, 1);
    box-shadow: 1px 2px 20px 11px rgba(234, 98, 11, 1);
}

.satelliteBox:nth-child(5) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(55, 44, 124, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(55, 44, 124, 1);
    box-shadow: 1px 2px 20px 11px rgba(55, 44, 124, 1);
}

.satelliteBox:nth-child(2) .circleSmallBox, .satelliteBox:nth-child(2) .lineSmallBox {
    background: #f39a00;
}

.satelliteBox:nth-child(3) .circleSmallBox, .satelliteBox:nth-child(3) .lineSmallBox {
    background: #008bd2;
}

.satelliteBox:nth-child(4) .circleSmallBox, .satelliteBox:nth-child(4) .lineSmallBox {
    background: #223771;
}

.satelliteBox:nth-child(5) .circleSmallBox, .satelliteBox:nth-child(5) .lineSmallBox {
    background: #372c7c;
}

.activeText {
    opacity: 1 !important;
}

.satelliteBox .outerSmallBox p {
    position: absolute;
    transform: translate(-220px, 0px);
    width: 200px;
    opacity: 0;
}

.satelliteBox:nth-child(2) .outerSmallBox p {
    position: absolute;
    transform: translate(-100px, -90px);
    width: 200px;
}

.satelliteBox:nth-child(3) .outerSmallBox p {
    position: absolute;
    transform: translate(-70px, -90px);
    width: 200px;
}

.satelliteBox:nth-child(4) .outerSmallBox p {
    position: absolute;
    transform: translate(-30px, -90px);
    width: 200px;
}

.satelliteBox:nth-child(5) .outerSmallBox p {
    position: absolute;
    transform: translate(100px, 0px);
    width: 200px;
}

.outerSmallBox {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    background: rgb(244, 153, 0);
    background: linear-gradient(90deg, rgba(244, 153, 0, 1) 0%, rgba(234, 98, 11, 1) 100%);
    transition: 0.3s;
}

.smallBox {
    margin: auto;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    background-color: #f1f1f1;
    -webkit-box-shadow: 15px 13px 17px -8px rgba(0, 0, 0, 1);
    -moz-box-shadow: 15px 13px 17px -8px rgba(0, 0, 0, 1);
    box-shadow: 15px 13px 17px -8px rgba(0, 0, 0, 1);
}

.smallBox p {
    display: flex;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: auto;
}

.lineSmallBox {
    height: 2px;
    background-color: #ea620b;
    width: 30px;
}

.circleSmallBox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ea620b;
}

.boxContentOverflow {
    padding-top: 4%;
    width: 370px;
    height: 752px;
    overflow-y: hidden;
}

.contentOverflow {
    height: 100%;
    overflow-y: auto;
}

.boxContentOverflow li, .boxContentOverflow p, .boxContentOverflow span {
    font: 20px Helvetica;
    color: rgb(16, 6, 73);
    text-align: left;
    font-weight: bold;
}

.overlay {
    background: transparent;
    opacity: 1;
    padding-top: 10rem;
    padding-bottom: 30rem;
}

.boxHighlight {
    width: 700px;
    height: 200px;
    top: 1%;
    z-index: 1;
    position: absolute;
    background-color: #dfddd9de;
    opacity: 0.7;
}

.boxHighlight2 {
    width: 700px;
    height: 400px;
    bottom: 0;
    z-index: 1;
    position: absolute;
    background-color: #dfddd9de;
    opacity: 0.7;
}

.boxOverflow {
    position: relative;
    left: 15%;
}

.lowercase {
    font: 20px Helvetica;
}

.Circuit {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30rem;
    opacity: 1;
    z-index: 0;
}

.backgroundLogo {
    position: absolute;
    top: 10%;
    right: 3rem;
    height: 100%;
    width: 30rem;
    z-index: 0;
}

.st0 {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.boxC {
    max-width: 1540px;
}

.box_1_0 {
    width: 356px;
    height: 100px;
}

.boxHexa {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: transparent;
}

.hexagon {
    position: relative;
    width: 50px;
    height: 28.87px;
    background-color: transparent;
    margin: 11.43px 0;
    transform: rotate(90deg);
    outline: none;
}

.hexagon img {
    height: 50px;
    width: 50px;
    margin: -0.5rem auto auto;
    position: relative;
    transform: rotate(-90deg);
    display: block;
}

.outerSmallBox img {
    height: 50px;
    width: 50px;
    position: absolute;
    z-index: 1;
    transform: translate(23px, 0px) rotate(0deg);
    display: block;
}

.satelliteBox:nth-child(2) .outerSmallBox img {
    transform: translate(24px, 0px) rotate(-45deg);
}

.satelliteBox:nth-child(3) .outerSmallBox img {
    transform: translate(24px, -2px) rotate(-90deg);
}

.satelliteBox:nth-child(4) .outerSmallBox img {
    transform: translate(24px, 0px) rotate(-135deg);
}

.satelliteBox:nth-child(5) .outerSmallBox img {
    transform: translate(25px, 0px) rotate(180deg);
}

.hexagon:hover {
    background: none !important;
}

.hexagon img:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(128, 128, 128, 0.65);
    -moz-box-shadow: 1px 2px 20px 11px rgba(128, 128, 128, 0.65);
    box-shadow: 1px 2px 20px 11px rgba(128, 128, 128, 0.65);
}

.hexagon:after, .hexagon:before {
    content: "";
    position: absolute;
    width: 0;
    right: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    outline: none;
    background-color: transparent;
}

.hexagon:before {
    bottom: 100%;
    border-bottom: 14.43px solid transparent;
    outline: none;
}

.hexagon:after {
    top: 100%;
    width: 0;
    border-top: 14.43px solid transparent;
    outline: none;
}

@media only screen and (min-width: 2560px) {
    .containerBox2 {
        height: 960px;
        transform: scale(1.2);
        padding-bottom: 5rem;
    }
    .innerText {
        font: bold 28px Helvetica;
    }
    .textBox {
        font: normal 20px/24px Helvetica;
    }
    .Section2 {
        padding-top: 7rem;
        padding-bottom: 12.4rem;
    }
}

@media only screen and (max-width: 1650px) {
    .satelliteBox {
        left: 172px;
    }
    .boxOverflow {
        left: 10%;
    }
    .boxRight {
        width: 530px;
    }
    .boxC {
        max-width: 1062px !important;
        padding: 0;
        margin: auto;
    }
    .containerBox2 {
        transform: scale(0.82) translateY(-130px);
        height: 720px;
    }
    .Section2 {
        padding-bottom: 2rem;
    }
    .innerText {
        font: bold 18px/26px Helvetica;
    }
    .textBox {
        font: normal 20px/26px Helvetica;
    }
    .satelliteBox {
        left: 172px;
    }
    .boxOverflow {
        left: 10%;
    }
    .boxRight {
        width: 530px;
    }
    .boxC {
        max-width: 1062px !important;
        padding: 0;
        margin: auto;
    }
    .Section2 {
        padding-bottom: 5.5rem;
    }

}

@media only screen and (max-width: 1440px) {
    .containerBox2 {
        transform: scale(0.7) translateY(-180px);
        height: 630px;
    }
    .Section2 {
        margin-top: unset;
    }

    .innerText {
        font: bold 18px/26px Helvetica;
    }

}

@media only screen and (max-width:1024px) {
    .containerBox2 {
        height: 52vh;
    }
}

@media only screen and (max-width:768px) {
    .containerBox2 {
        transform: scale(0.6) translateY(-180px) !important;
        height: 48vh !important;
    }
    .Section2 {
        padding-bottom: 130px !important;
        padding-top: 40px !important;
    }
}