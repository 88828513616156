.customNavbar1 {
  padding-left: 2.2rem;
  margin-top: 4rem;
  /* background-color: #223771; */
}

.customSelect {
  border: none;
  background-color: transparent;
  margin: 15px 0 0 20px;
  display: flex;
  height: 100%;
  width: 10%;
  position: relative;
  font: 16px Helvetica;
  border-radius: 1rem;
  line-height: 1.8rem;
}

.customSelect option {
  background-color: white;
  border-radius: 10px;
  width: 200px;
}

.customSelect option:hover {
  background-color: #223771;
}

.wrap_option {
  display: flex;
  position: absolute;
  left: 35%;
}

.box_search {
  width: 20%;
  height: 35px;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  border-radius: 20px;
  border: hidden;
  margin: 8px;
  position: absolute;
  right: 30%;
}

.box_search p {
  text-align: left;
  font: 19px Helvetica;
  line-height: 1.8rem;
}

.box_search img {
  position: absolute;
  right: 3%;
  width: 20px;
  height: 20px;
  top: 23%;
}

.input_search {
  width: 250px;
  height: 31px;
  border: none;
  font-size: 18px;
  line-height: 1.8rem;
  position: absolute;
  top: 10%;
}

.input_search:focus {
  border: hidden !important;
  outline: none;
}

@media only screen and (max-width: 1400px) {
  .customSelect {
    margin: 17px 0 0 -162px;
    font: normal 14px Helvetica;
  }

  .wrap_option {
    left: 35%;
  }

  .box_search {
    right: 33%;
    width: 285px;
  }

  .box_search p {
    font: 14px Helvetica;
  }

  .input_search {
    width: 245px;
    font-size: 14px;
  }
}

/*tablet only*/
@media only screen and (max-width: 768px) {
  select.customSelect {
    width: 35%;
    margin: 17px 0 0 0;
  }

  .date2 {
    padding-left: 0;
  }

  .box_search {
    right: 48px;
  }
}
