.Section2 {
    width: auto;
    background-color: #faf9f5;
    position: relative;
    /* padding-top: 5rem; */
    /* padding-bottom: 7.4rem; */
    height: 50vh;
}

.contentRight {
    position: absolute;
    right: -15rem;
    top: 3rem;
}

.boxRight {
    width: 571px;
    height: 100%;
    position: relative;
}

.contentImgRight {
    width: auto;
    height: 752px;
    object-fit: cover;
}

.contentBox {
    width: 50vw;
    height: 55vh;
    position: absolute;
    left: 20%;
    margin-bottom: 0;
    margin-top: 10px 0 10px 0;
}

.boldText {
    font-weight: bold;
}

.content2 {
    width: auto;
    height: auto;
}

.containerBox {
    position: relative;
    display: flex;
    align-items: center;
    height: 565px;
}

.containerBox2 {
    position: relative;
    display: flex;
    align-items: center;
    height: 22vh;
    transform: scale(0.5);
}

.outerContent1 {
    margin: 153px auto auto;
    border: 3px solid black;
    width: 450px;
    height: 450px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.outerContent2 {
    position: relative;
    margin: 90px auto auto;
    border: 3px dotted rgba(0, 0, 0, 0.363);
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.wrapBox {
    width: 143px;
    height: 82px;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.innerText {
    font: bold 18px Helvetica;
    color: black;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(140, 140, 140, 0.9);
    width: 230px;
    text-transform: uppercase;
}

.Content1 {
    margin: auto;
    width: 400px;
    height: 400px;
    display: flex;
    text-align: center;
    background-color: #ececec;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    -webkit-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    -moz-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    box-shadow: -15px 13px 24px -8px rgba(179, 90, 90, 0.9);
}

.Content1:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
}

.Content2 {
    margin: auto;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: white;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    justify-content: center;
    -webkit-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    -moz-box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
    box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
}

.Content2:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.65);
}

.circleBig {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 1px 2px 7px 5px rgba(0, 0, 0, 0.294);
    transform: translate(130px, -174px);
    padding: 2rem;
}

.circleBig img {
    width: 46px;
    transform: translate(-13px, -16px);
}

.circleBig:nth-child(6) {
    transform: translate(-29px, -62px);
}

.circleBig:nth-child(6) .textBox {
    transform: translate(344px, 5px);
}

.circleBig:nth-child(7) {
    transform: translate(10px, 123px);
}

.circleBig:nth-child(7) .textBox {
    transform: translate(248px, -385px);
}

.circleBig:nth-child(8) {
    transform: translate(256px, 122px);
}

.circleBig:nth-child(8) .textBox {
    transform: translate(-481px, -388px);
}

.circleBig:nth-child(9) {
    transform: translate(292px, -66px);
}

.circleBig:nth-child(9) .textBox {
    transform: translate(-538px, 45px);
}

.textBox {
    width: 215px;
    transform: translate(-86px, 335px);
    color: black;
    text-align: center;
    height: auto;
    font: normal 26px Helvetica;
    text-transform: inherit;
}

.textBox p {
    opacity: 1;
}

.circle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(55, 44, 124, 0.9);
    border-radius: 10px;
    color: transparent;
    top: 59%;
    left: -6px;
    -webkit-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    -moz-box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
    box-shadow: 1px 2px 8px 5px rgba(19, 81, 149, 0.2);
}

.circle:nth-child(2) {
    transform: translate(339px, -7px);
}

.circle:nth-child(3) {
    transform: translate(169px, 133px);
}

.circle:nth-child(4) {
    transform: translate(270px, -181px);
}

.circle:nth-child(5) {
    transform: translate(69px, -183px);
}

p.titleContent1 {
    margin: auto;
    width: 200px;
    font: bold 20px Helvetica;
    font-weight: bold;
}

.satelliteBox {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    margin: auto;
    left: 397px;
}

.satelliteBox:nth-child(2) {
    transform: translate(85px, -138px) rotate(45deg);
}

.satelliteBox:nth-child(3) {
    transform: translate(275px, -208px) rotate(90deg);
}

.satelliteBox:nth-child(4) {
    transform: translate(490px, -134px) rotate(135deg);
}

.satelliteBox:nth-child(5) {
    transform: translate(570px, 0px) rotate(180deg);
}

.satelliteBox:nth-child(1) .smallBox {
    transform: rotate(-45deg);
}

.satelliteBox:nth-child(2) .smallBox {
    transform: rotate(-45deg);
}

.satelliteBox:nth-child(3) .smallBox {
    transform: rotate(-90deg);
}

.satelliteBox:nth-child(4) .smallBox {
    transform: rotate(-135deg);
}

.satelliteBox:nth-child(5) .smallBox {
    transform: rotate(-180deg);
}

.satelliteBox:nth-child(2) .outerSmallBox {
    background: rgb(254, 216, 0);
    background: linear-gradient(90deg, rgba(254, 216, 0, 1) 0%, rgba(243, 154, 0, 1) 100%);
    transition: 0.3s;
}

.satelliteBox:nth-child(2) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(243, 154, 0, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(243, 154, 0, 1);
    box-shadow: 1px 2px 20px 11px rgba(243, 154, 0, 1);
}

.satelliteBox:nth-child(3) .outerSmallBox {
    background: rgb(0, 168, 199);
    background: linear-gradient(90deg, rgba(0, 168, 199, 1) 0%, rgba(0, 139, 210, 1) 100%);
    transition: 0.3s;
}

.satelliteBox:nth-child(3) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(0, 139, 210, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(0, 139, 210, 1);
    box-shadow: 1px 2px 20px 11px rgba(0, 139, 210, 1);
}

.satelliteBox:nth-child(3) .outerSmallBox {
    background: rgb(0, 168, 199);
    background: linear-gradient(90deg, rgba(0, 168, 199, 1) 0%, rgba(0, 139, 210, 1) 100%);
}

.satelliteBox:nth-child(4) .outerSmallBox {
    background: rgb(6, 125, 193);
    background: linear-gradient(90deg, rgba(6, 125, 193, 1) 0%, rgba(34, 55, 113, 1) 100%);
    transition: 0.3s;
}

.satelliteBox:nth-child(4) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(34, 55, 113, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(34, 55, 113, 1);
    box-shadow: 1px 2px 20px 11px rgba(34, 55, 113, 1);
}

.satelliteBox:nth-child(5) .outerSmallBox {
    background: rgb(19, 81, 149);
    background: linear-gradient(90deg, rgba(19, 81, 149, 1) 0%, rgba(55, 44, 124, 1) 100%);
    transition: 0.3s;
}

.satelliteBox .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(234, 98, 11, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(234, 98, 11, 1);
    box-shadow: 1px 2px 20px 11px rgba(234, 98, 11, 1);
}

.satelliteBox:nth-child(5) .outerSmallBox:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(55, 44, 124, 1);
    -moz-box-shadow: 1px 2px 20px 11px rgba(55, 44, 124, 1);
    box-shadow: 1px 2px 20px 11px rgba(55, 44, 124, 1);
}

.satelliteBox:nth-child(2) .circleSmallBox, .satelliteBox:nth-child(2) .lineSmallBox {
    background: #f39a00;
}

.satelliteBox:nth-child(3) .circleSmallBox, .satelliteBox:nth-child(3) .lineSmallBox {
    background: #008bd2;
}

.satelliteBox:nth-child(4) .circleSmallBox, .satelliteBox:nth-child(4) .lineSmallBox {
    background: #223771;
}

.satelliteBox:nth-child(5) .circleSmallBox, .satelliteBox:nth-child(5) .lineSmallBox {
    background: #372c7c;
}

.activeText {
    opacity: 1 !important;
}

.satelliteBox .outerSmallBox p {
    position: absolute;
    transform: translate(-220px, 0px);
    width: 200px;
    opacity: 0;
}

.satelliteBox:nth-child(2) .outerSmallBox p {
    position: absolute;
    transform: translate(-100px, -90px);
    width: 200px;
}

.satelliteBox:nth-child(3) .outerSmallBox p {
    position: absolute;
    transform: translate(-70px, -90px);
    width: 200px;
}

.satelliteBox:nth-child(4) .outerSmallBox p {
    position: absolute;
    transform: translate(-30px, -90px);
    width: 200px;
}

.satelliteBox:nth-child(5) .outerSmallBox p {
    position: absolute;
    transform: translate(100px, 0px);
    width: 200px;
}

.outerSmallBox {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    background: rgb(244, 153, 0);
    background: linear-gradient(90deg, rgba(244, 153, 0, 1) 0%, rgba(234, 98, 11, 1) 100%);
    transition: 0.3s;
}

.smallBox {
    margin: auto;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    background-color: #f1f1f1;
    -webkit-box-shadow: 15px 13px 17px -8px rgba(0, 0, 0, 1);
    -moz-box-shadow: 15px 13px 17px -8px rgba(0, 0, 0, 1);
    box-shadow: 15px 13px 17px -8px rgba(0, 0, 0, 1);
}

.smallBox p {
    display: flex;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: auto;
}

.lineSmallBox {
    height: 2px;
    background-color: #ea620b;
    width: 30px;
}

.circleSmallBox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ea620b;
}

.boxContentOverflow {
    padding-top: 4%;
    width: 370px;
    height: 752px;
    overflow-y: hidden;
}

.contentOverflow {
    height: 100%;
    overflow-y: auto;
}

.boxContentOverflow li, .boxContentOverflow p, .boxContentOverflow span {
    font: 20px Helvetica;
    color: rgb(16, 6, 73);
    text-align: left;
    font-weight: bold;
}

.overlay {
    background: transparent;
    opacity: 1;
    padding-top: 10rem;
    padding-bottom: 30rem;
}

.boxHighlight {
    width: 700px;
    height: 200px;
    top: 1%;
    z-index: 1;
    position: absolute;
    background-color: #dfddd9de;
    opacity: 0.7;
}

.boxHighlight2 {
    width: 700px;
    height: 400px;
    bottom: 0;
    z-index: 1;
    position: absolute;
    background-color: #dfddd9de;
    opacity: 0.7;
}

.boxOverflow {
    position: relative;
    left: 15%;
}

.lowercase {
    font: 20px Helvetica;
}

.Circuit {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30rem;
    opacity: 1;
    z-index: 0;
}

.backgroundLogo {
    position: absolute;
    top: 10%;
    right: 3rem;
    height: 100%;
    width: 30rem;
    z-index: 0;
}

.st0 {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.boxC {
    max-width: 1540px;
}

.box_1_0 {
    width: 356px;
    height: 100px;
}

.boxHexa {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: transparent;
}

.hexagon {
    position: relative;
    width: 50px;
    height: 28.87px;
    background-color: transparent;
    margin: 11.43px 0;
    transform: rotate(90deg);
    outline: none;
}

.hexagon img {
    height: 50px;
    width: 50px;
    margin: -0.5rem auto auto;
    position: relative;
    transform: rotate(-90deg);
    display: block;
}

.outerSmallBox img {
    height: 50px;
    width: 50px;
    position: absolute;
    z-index: 1;
    transform: translate(23px, 0px) rotate(0deg);
    display: block;
}

.satelliteBox:nth-child(2) .outerSmallBox img {
    transform: translate(24px, 0px) rotate(-45deg);
}

.satelliteBox:nth-child(3) .outerSmallBox img {
    transform: translate(24px, -2px) rotate(-90deg);
}

.satelliteBox:nth-child(4) .outerSmallBox img {
    transform: translate(24px, 0px) rotate(-135deg);
}

.satelliteBox:nth-child(5) .outerSmallBox img {
    transform: translate(25px, 0px) rotate(180deg);
}

.hexagon:hover {
    background: none !important;
}

.hexagon img:hover {
    -webkit-box-shadow: 1px 2px 20px 11px rgba(128, 128, 128, 0.65);
    -moz-box-shadow: 1px 2px 20px 11px rgba(128, 128, 128, 0.65);
    box-shadow: 1px 2px 20px 11px rgba(128, 128, 128, 0.65);
}

.hexagon:after, .hexagon:before {
    content: "";
    position: absolute;
    width: 0;
    right: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    outline: none;
    background-color: transparent;
}

.hexagon:before {
    bottom: 100%;
    border-bottom: 14.43px solid transparent;
    outline: none;
}

.hexagon:after {
    top: 100%;
    width: 0;
    border-top: 14.43px solid transparent;
    outline: none;
}

@media only screen and (max-width: 480px) {
    .Section2 {
        height: 375px;
    }
    .containerBox2 {
        transform: scale(0.55);
    }
    .outerContent2 {
        width: 300px;
        height: 300px;
    }
    .circleBig {
        width: 60px;
        height: 60px;
    }
    .circleBig img {
        width: 38px;
        transform: translate(-18px, -16px);
    }
    .Content2 {
        width: 230px;
        height: 230px;
    }
    .circle {
        top: 57%;
        left: -3%;
    }
    .innerText {
        font: bold 14px Helvetica;
    }
    .circleBig {
        transform: translate(110px, -153px);
    }
    .textBox {
        transform: translate(-97px, 295px);
    }

    @mixin circle-elements($size, $transform-circles, $transform-circle-big, $transform-textbox) {
        @for $i from 1 through $size {
            .circle:nth-child(#{$i}) {
                transform: nth($transform-circles, $i);
                // display: none;
            }
    
            .circleBig:nth-child(#{$size + $i}) {
                transform: nth($transform-circle-big, $i);
    
                .textBox {
                    // display: none;
                    transform: nth($transform-textbox, $i);
                }
            }
        }
    }

    .three {
        $transform-circles: (
            translate(13px, -97px),
            translate(271px, -97px),
            translate(142px, 118px),
        );
        $transform-circle-big: (
            translate(114px, -155px), 
            translate(-25px, 79px), 
            translate(247px, 79px), 
        );
        $transform-textbox: (
            translate(-110px, 288px), 
            translate(292px, -236px), 
            translate(-504px, -263px), 
        );

        @include circle-elements(3, $transform-circles, $transform-circle-big, $transform-textbox)
    }

    .four {
        $transform-circles: (
            translate(33px, -127px),
            translate(251px, 68px),
            translate(33px, 68px),
            translate(251px, -127px),
        );
        $transform-circle-big: (
            translate(114px, -155px), 
            translate(-39px, 0px), 
            translate(114px, 157px), 
            translate(272px, 0px),
        );
        $transform-textbox: (
            translate(120px, 226px), 
            translate(291px, -202px), 
            translate(-348px, -387px), 
            translate(-488px, 76px), 
        );

        @include circle-elements(4, $transform-circles, $transform-circle-big, $transform-textbox)
    }

    .five {
        $transform-circles: (
            none,
            translate(293px, -7px),
            translate(142px, 118px),
            translate(219px, -156px),
            translate(60px, -154px)
        );
        $transform-circle-big: (
            translate(-29px, -62px), 
            translate(2px, 109px), 
            translate(225px, 103px), 
            translate(244px, -66px),
            translate(110px, -153px)
        );
        $transform-textbox: (
            translate(300px, 26px), 
            translate(201px, -372px), 
            translate(-427px, -414px), 
            translate(-508px, 40px), 
            translate(-105px, 280px)
        );

        @include circle-elements(5, $transform-circles, $transform-circle-big, $transform-textbox)
    }

    .six {
        $transform-circles: (
            translate(75px, 100px),
            translate(295px, -29px),
            translate(216px, 102px),
            translate(216px, -163px),
            translate(69px, -159px),
            translate(-4px, -29px)
        );
        $transform-circle-big: (
            translate(-18px, -81px), 
            translate(-18px, 81px), 
            translate(110px, 151px), 
            translate(248px, 81px),
            translate(248px, -81px),
            translate(110px, -153px)
        );
        $transform-textbox: (
            translate(291px, 7px), 
            translate(201px, -389px), 
            translate(-292px, -477px), 
            translate(-507px, -155px), 
            translate(-420px, 186px),
            translate(65px, 250px)
        );

        @include circle-elements(6, $transform-circles, $transform-circle-big, $transform-textbox)
    }

    .seven {
        $transform-circles: (
            translate(145px, 118px),
            translate(290px, -67px),
            translate(266px, 58px),
            translate(212px, -164px),
            translate(79px, -165px),
            translate(2px, -68px),
            translate(26px, 57px)
        );
        $transform-circle-big: (
            translate(-10px, -103px), 
            translate(-38px, 31px), 
            translate(37px, 135px), 
            translate(190px, 135px),
            translate(270px, 31px),
            translate(242px, -103px),
            translate(110px, -153px)
        );
        $transform-textbox: (
            translate(254px, 157px), 
            translate(321px, -171px), 
            translate(141px, -469px), 
            translate(-330px, -459px), 
            translate(-546px, -184px),
            translate(-475px, 150px),
            translate(-99px, 290px)
        );

        @include circle-elements(7, $transform-circles, $transform-circle-big, $transform-textbox)
    }

    .eight {
        $transform-circles: (
            translate(207px, 106px),
            translate(286px, -83px),
            translate(285px, 22px),
            translate(210px, -166px),
            translate(81px, -165px),
            translate(7px, -83px),
            translate(7px, 24px),
            translate(83px, 104px)
        );
        $transform-circle-big: (
            translate(-1px, -103px), 
            translate(-40px, 0px), 
            translate(-1px, 103px), 
            translate(110px, 154px),
            translate(235px, 103px),
            translate(273px, 0px),
            translate(235px, -103px),
            translate(110px, -153px)
        );
        $transform-textbox: (
            translate(276px, 112px), 
            translate(315px, -169px), 
            translate(169px, -448px), 
            translate(-230px, -489px), 
            translate(-489px, -294px),
            translate(-528px, -10px),
            translate(-369px, 240px),
            translate(55px, 289px)
        );

        @include circle-elements(8, $transform-circles, $transform-circle-big, $transform-textbox)
    }
}

@media only screen and (max-width: 420px) {
    .containerBox2 {
        transform: scale(0.55);
    }
    // .circleBig:nth-child(6) .textBox {
    //     width: 180px;
    // }
    // .circleBig:nth-child(8) .textBox {
    //     transform: translate(-454px, -385px);
    // }
    // .circleBig:nth-child(9) .textBox {
    //     width: 180px;
    //     transform: translate(-459px, 40px);
    // }
}

@media only screen and (max-width: 380px) {
    .Section2 {
        height: 360px;
    }
    .containerBox2 {
        transform: scale(0.5);
    }
    .textBox {
        font: normal 25px Helvetica;
    }
}

@media only screen and (max-width: 320px) {
    .Section2 {
        height: 345px;
    }
    .containerBox2 {
        transform: scale(.4);
        height: 205px;
    }


    // .textBox {
    //     font: normal 35px Helvetica;
    // }
    // .circleBig:nth-child(6) .textBox {
    //     transform: translate(277px, 8px);
    //     width: 260px;
    // }
    // .circleBig:nth-child(7) .textBox {
    //     transform: translate(203px, -423px);
    // }
    // .circleBig:nth-child(8) .textBox {
    //     transform: translate(-477px, -463px);
    //     width: 285px;
    // }
    // .circleBig:nth-child(9) .textBox {
    //     transform: translate(-495px, 26px);
    // }
    // .circleBig:nth-child(10) .textBox {
    //     transform: translate(-130px, 286px);
    //     width: 270px;
    // }
}

@media only screen and (orientation: landscape) and (max-width:896px) {
    .Section2 {
        height: 90vh;
    }
}

@media only screen and (orientation: landscape) and (max-width:570px) {
    .Section2 {
        height: 83vh;
    }
}