.modalContent {
    padding: 2rem;
}

.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.isDis {
    display: none !important;
}

.boxContent {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
    position: relative;
}

.boxContent:not(:first-child) {
    margin-top: 1rem;
}

.titleContent {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #b0b0b0;
}

.inputModal {
    border: 0;
    width: 100%;
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000000;
    height: 100%;
}

.inputModal:focus {
    border: none;
    outline: unset;
}

.validError {
    text-align: left;
    font: 12px/24px Helvetica;
    color: #980000;
    opacity: 1;
}

.tableRowContent {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    /* background: saddlebrown; */
}

.inputCurrent {
    width: 60%;
    height: auto;
    text-align: right;
    margin: auto;
}

.line {
    margin: 0 2rem;
}

.errorActive {
    text-align: left;
    opacity: 1 !important;
}

.contentAction {
    display: flex;
    margin-top: 1rem;
    margin-left: auto;
}

.contentAction2 {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.cancel2 {
    background: #28a745 0% 0% no-repeat padding-box;
    border: 1px solid #dddddd;
    border-radius: 16px;
    width: 110px;
    display: flex;
    cursor: pointer;
    justify-content: center;
}



.cancel2 span {
    color: white;
}


.modalAction3 {
    display: none;
}

.isInline {
    display: inline;
}



.cancel {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dddddd;
    border-radius: 16px;
    padding: 0 0.5rem;
    margin-right: 1rem;
    width: 110px;
    display: flex;
    cursor: pointer;
}

.cancel span {
    margin: auto;
}

.boxAction {
    width: auto;
    height: 32px;
    background: #00599d 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 16px;
    margin: auto 0 auto 0.5rem;
    cursor: pointer;
}

.boxAction span {
    font: 14px Helvetica;
    color: #ffffff;
    margin: auto;
    cursor: pointer;
}


.boxAction5 {
    width: 20%;
    height: 32px;
    background: #00599d 0% 0% no-repeat padding-box;
    float: right;
    display: flex;
    border-radius: 16px;
    cursor: pointer;
}

.boxAction5 span {
    font: 14px Helvetica;
    color: #ffffff;
    margin: auto;
    cursor: pointer;
}