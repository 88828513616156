/* GENERAL */

:root {
    --left-fixed: 224px !important;
    --hd-fixed: 6.5vw !important;
    --hdp-fixed: 48px !important;
    --hdpZ150-fixed: 10vw !important;
    --left-fixed-tablet: 420px !important;
    --left-fixed-ipad-pro: 319px !important;
}

/* SPECIFIC */

.backgroundchart {
    width: 100%;
    margin-top: -7.4rem;
    transform: scale(0.9);
}

.wrappContent {
    margin-top: 0;
    width: 100%;
    height: 567px;
    position: relative;
}

.circleFake {
    position: absolute;
    border: 2px solid #ddd;
    width: 356px;
    height: 360px;
    align-items: center;
    border-radius: 50%;
    top: 8px;
    left: 577px;
    z-index: 0;
}

.circle {
    margin: 18rem auto auto;
    border: 20px dotted #ddd;
    width: 375px;
    height: 375px;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.circleInner {
    margin: auto;
    width: 300px;
    height: 300px;
    display: flex;
    text-align: center;
    background-color: #ececec;
    align-items: center;
    border-radius: 50%;
    transition: 0.3s;
    box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
}

.textInnerCircle {
    font: bold 20px Helvetica;
    margin: auto;
    text-transform: uppercase;
}

.chart {
    position: relative;
}

.boxRound {
    position: absolute;
    z-index: 4;
    display: flex;
    align-items: center;
    margin: auto;
    background-color: #eead34;
    width: 300px;
    height: 60px;
    border-radius: 40px;
    justify-content: flex-end;
    padding-right: 5px;
    right: 0;
    bottom: 0;
    animation: move 5s;
}

@keyframes move {
    from {
        bottom: 22%;
    }
    to {
        bottom: 0;
    }
}

@-webkit-keyframes move {
    from {
        bottom: 22%;
    }
    to {
        bottom: 0;
    }
}

@keyframes move2 {
    from {
        right: -18%;
    }
    to {
        right: 0;
    }
}

@-webkit-keyframes move2 {
    from {
        right: -18%;
    }
    to {
        right: 0;
    }
}

.textInBox {
    font: normal 16px Helvetica;
}

.textInboxRound {
    font: normal 18px Helvetica;
    color: white;
    margin: auto;
    text-align: center;
    width: 190px;
}

.Line {
    position: absolute;
    width: 70px;
    height: 5px;
    background-color: #eead34;
    z-index: 2;
    transform: rotate(90deg);
    right: -1.5%;
    bottom: 36%;
}

.circleLine {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    padding: 5px;
    border: solid 5px #eead34;
}

.circleLineIn {
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    border: solid 5px #eead34;
    text-align: center;
    align-items: center;
}

.circleLine2 {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    padding: 5px;
    border: solid 5px #eead34;
    position: absolute;
    right: 6px;
    top: 34%;
}

.circleLineIn2 {
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    border: solid 5px #eead34;
    text-align: center;
    align-items: center;
}

.wrappContentBox {
    position: absolute;
    width: 600px;
    height: 250px;
    left: 55px;
    top: 57%;
    z-index: 1;
}

@mixin boxes($size, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves) {
    @for $i from 2 through $size {
        .wrappContentBox:nth-child(#{$i}) {
            transform: nth($base-transform, $i - 1);
            top: nth($base-top, $i - 1);
            left: nth($base-left, $i - 1);
    
            .Line {
                transform: nth($transform-lines, $i - 1);
                background-color: nth($colors, $i - 1);
                right: nth($right-lines, $i - 1);
                bottom: nth($bottom-lines, $i - 1);
            }
    
            .circleLine2 {
                transform: nth($transform-cl2, $i - 1);;
                border: solid 5px nth($colors, $i - 1);
                right: nth($right-cl2, $i - 1);
                top: nth($top-cl2, $i - 1);
            }
    
            .circleLineIn2, .circleLineIn, .circleLine {
                border: solid 5px nth($colors, $i - 1);
            }
    
            p {
                transform: nth($transform-p, $i - 1);;
            }
    
            .boxRound {
                background-color: nth($colors, $i - 1);
                right: 0;
                animation: nth($moves, $i - 1) 5s infinite;
                -webkit-animation: nth($moves, $i - 1) 5s;
            }
        }
    }
}

.six {
    $base-transform: (
        translate(-228px, -376px), 
        translate(160px, -519px) scalex(-1) rotate(180deg), 
        translate(893px, -441px) rotate(180deg),
        translate(1122px, -310px) scaleX(-1),
        translate(859px, 59px) scaleX(-1)
    );
    $base-top: 76%, 102%, 92%, 75%, 51%;
    $base-left: 123px, 119px, 10px, -51px, -104px, 31px;
    $transform-lines: (
        translate(83px, 78px) rotate(180deg), 
        rotate(90deg), 
        rotate(90deg),
        translate(83px, 78px) rotate(180deg),
        rotate(90deg)
    );
    $right-lines: 2.5%, -1.5%, -1.5%, 2.5%, -1.5%;
    $bottom-lines: 42%, 36%, 36%, 42%, 36%;
    $transform-cl2: (
        translate(163px, 154px), 
        none, 
        none, 
        translate(163px, 154px),
        none
    );
    $right-cl2: 55px, 6px, 6px, 55px, 6px;
    $top-cl2: 19%, 34%, 34%, 19%, 34%;
    $colors:  #f18624, #f16624, #00599d, #004e96, #03428e;
    $transform-p: inherit, scaley(-1), rotate(180deg), scaleX(-1), scaleX(-1);
    $moves: move2, move, move, move2, move;

    @include boxes(6, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves)
}

.seven {
    $base-transform: (
        translate(-232px, -290px), 
        translate(-179px, -478px), 
        translate(160px, -519px) scalex(-1) rotate(180deg), 
        translate(893px, -441px) rotate(180deg),
        translate(1122px, -310px) scaleX(-1),
        translate(859px, 59px) scaleX(-1)
    );
    $base-top: 76%, 85%, 102%, 92%, 75%, 51%;
    $base-left: 123px, 119px, 10px, -51px, -104px, 31px;
    $transform-lines: (
        translate(83px, 78px) rotate(180deg), 
        translate(83px, 78px) rotate(180deg), 
        rotate(90deg), 
        rotate(90deg),
        translate(83px, 78px) rotate(180deg),
        rotate(90deg)
    );
    $right-lines: 2.5%, 2.5%, -1.5%, -1.5%, 2.5%, -1.5%;
    $bottom-lines: 42%, 42%, 36%, 36%, 42%, 36%;
    $transform-cl2: (
        translate(163px, 154px), 
        translate(163px, 154px), 
        none, 
        none, 
        translate(163px, 154px),
        none
    );
    $right-cl2: 55px, 56px, 6px, 6px, 55px, 6px;
    $top-cl2: 19%, 19%, 34%, 34%, 19%, 34%;
    $colors: #f19924, #f18624, #f16624, #00599d, #004e96, #223771;
    $transform-p: inherit, inherit, scaley(-1), rotate(180deg), scaleX(-1), scaleX(-1);
    $moves: move2, move2, move, move, move2, move;

    @include boxes(7, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves)
}

.eight {
    $base-transform: (
        translate(-232px, -290px), 
        translate(-179px, -478px), 
        translate(160px, -519px) scalex(-1) rotate(180deg), 
        translate(893px, -441px) rotate(180deg),
        translate(1120px, -350px) scaleX(-1),
        translate(1035px, -144px) scaleX(-1),
        translate(729px, 68px) scaleX(-1)
    );
    $base-top: 76%, 85%, 102%, 92%, 75%, 60%, 51%;
    $base-left: 123px, 119px, 10px, -51px, -104px, -58px, 31px;
    $transform-lines: (
        translate(83px, 78px) rotate(180deg), 
        translate(83px, 78px) rotate(180deg), 
        rotate(90deg), 
        rotate(90deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        rotate(90deg)
    );
    $right-lines: 2.5%, 2.5%, -1.5%, -1.5%, 2.5%, 2.5%, -1.5%;
    $bottom-lines: 42%, 42%, 36%, 36%, 42%, 42%, 36%;
    $transform-cl2: (
        translate(163px, 154px), 
        translate(163px, 154px), 
        none, 
        none, 
        translate(163px, 154px),
        translate(163px, 154px),
        none
    );
    $right-cl2: 55px, 56px, 6px, 6px, 55px, 55px, 6px;
    $top-cl2: 19%, 19%, 34%, 34%, 19%, 18.5%, 34%;
    $colors: #f19924, #f18624, #f16624, #00599d, #004e96, #03428e, #223771;
    $transform-p: inherit, inherit, scaley(-1), rotate(180deg), scaleX(-1), scaleX(-1), scaleX(-1);
    $moves: move2, move2, move, move, move2, move2, move;

    @include boxes(8, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves)
}

.nine {
    .wrappContentBox {
        transform: translate(75px, 42px);
    }

    $base-transform: (
        translate(-236px, -290px), 
        translate(-179px, -478px), 
        translate(160px, -519px) scalex(-1) rotate(180deg), 
        translate(893px, -441px) rotate(180deg),
        translate(1120px, -350px) scaleX(-1),
        translate(1060px, -174px) scaleX(-1),
        translate(765px, 57px) scaleX(-1),
        translate(-236px, -290px)
    );
    $base-top: 63%, 85%, 102%, 92%, 75%, 60%, 51%, 95%;
    $base-left: 123px, 119px, 10px, -51px, -104px, -58px, 31px, 152px;
    $transform-lines: (
        translate(83px, 78px) rotate(180deg), 
        translate(83px, 78px) rotate(180deg), 
        rotate(90deg), 
        rotate(90deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        rotate(90deg),
        translate(83px, 78px) rotate(180deg)
    );
    $right-lines: 2.5%, 2.5%, -1.5%, -1.5%, 2.5%, 2.5%, -1.5%, 2.5%;
    $bottom-lines: 42%, 42%, 36%, 36%, 42%, 42%, 36%, 42%;
    $transform-cl2: (
        translate(163px, 154px), 
        translate(163px, 154px), 
        none, 
        none, 
        translate(163px, 154px),
        translate(163px, 154px),
        none,
        translate(163px, 154px)
    );
    $right-cl2: 55px, 56px, 6px, 6px, 55px, 55px, 6px, 55px;
    $top-cl2: 19%, 19%, 34%, 34%, 19%, 18.5%, 34%, 19%;
    $colors: #f18624, #f16624, #E6530F, #00599d, #004e96, #03428e, #223771, #f19924;
    $transform-p: inherit, inherit, scaley(-1), rotate(180deg), scaleX(-1), scaleX(-1), scaleX(-1), inherit;
    $moves: move2, move2, move, move, move2, move2, move, move2;
    
    @include boxes(9, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves)
}

.ten {
    .wrappContentBox {
        transform: translate(75px, 42px);
    }

    $base-transform: (
        translate(-183px, -399px), 
        translate(51px, -458px) scaleY(-1), 
        translate(798px, -519px) rotate(180deg), 
        translate(1040px, -441px) rotate(180deg),
        translate(1129px, -350px) scaleX(-1),
        translate(1041px, -174px) scaleX(-1),
        translate(765px, 57px) scaleX(-1),
        translate(-262px, -407px),
        translate(-233px, -290px)
    );
    $base-top: 63%, 85%, 107%, 131%, 85%, 69%, 51%, 95%, 95%;
    $base-left: 123px, 119px, 10px, -51px, -104px, -58px, 31px, 152px, 152px;
    $transform-lines: (
        translate(83px, 78px) rotate(180deg), 
        rotate(90deg), 
        rotate(90deg), 
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        rotate(90deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg)
    );
    $right-lines: 2.5%, -1.5%, -1.5%, 2.5%, 2.5%, 2.5%, -1.5%, 2.5%, 2.5%;
    $bottom-lines: 42%, 36%, 36%, 42%, 42%, 42%, 36%, 42%, 42%;
    $transform-cl2: (
        translate(163px, 154px), 
        none,
        none, 
        translate(163px, 154px), 
        translate(163px, 154px),
        translate(163px, 154px),
        none,
        translate(163px, 154px),
        translate(163px, 154px)
    );
    $right-cl2: 55px, 6px, 6px, 55px, 55px, 55px, 6px, 55px, 55px;
    $top-cl2: 19%, 34%, 34%, 19%, 19%, 18.5%, 34%, 19%, 19%;
    $colors: #f16624, #E6530F, #00599d, #004e96, #03428e, #223771, #21356E, #f18624, #f19924;
    $transform-p: inherit, scaleY(-1), scaley(-1) scalex(-1), rotate(180deg), scaleX(-1), scaleX(-1), scaleX(-1), inherit, inherit;
    $moves: move2, move, move, move2, move2, move2, move, move2, move2;
    
    @include boxes(10, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves)

}

.eleven {
    .wrappContentBox {
        transform: translate(115px, 49px);
    }

    $base-transform: (
        translate(-183px, -399px), 
        translate(51px, -458px) scaleY(-1), 
        translate(798px, -519px) rotate(180deg), 
        translate(1040px, -441px) rotate(180deg),
        translate(1129px, -350px) scaleX(-1),
        translate(1041px, -174px) scaleX(-1),
        translate(765px, 57px) scaleX(-1),
        translate(-262px, -407px),
        translate(-249px, -320px),
        translate(-173px, -230px)
    );
    $base-top: 63%, 85%, 107%, 131%, 85%, 69%, 51%, 95%, 95%, 95%;
    $base-left: 123px, 119px, 10px, -51px, -104px, -58px, 31px, 152px, 152px, 152px;
    $transform-lines: (
        translate(83px, 78px) rotate(180deg), 
        rotate(90deg), 
        rotate(90deg), 
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        rotate(90deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg),
        translate(83px, 78px) rotate(180deg)
    );
    $right-lines: 2.5%, -1.5%, -1.5%, 2.5%, 2.5%, 2.5%, -1.5%, 2.5%, 2.5%, 2.5%;
    $bottom-lines: 42%, 36%, 36%, 42%, 42%, 42%, 36%, 42%, 42%, 42%;
    $transform-cl2: (
        translate(163px, 154px), 
        none,
        none, 
        translate(163px, 154px), 
        translate(163px, 154px),
        translate(163px, 154px),
        none,
        translate(163px, 154px),
        translate(163px, 154px),
        translate(163px, 154px)
    );
    $right-cl2: 55px, 6px, 6px, 55px, 55px, 55px, 6px, 55px, 55px, 55px;
    $top-cl2: 19%, 34%, 34%, 19%, 19%, 18.5%, 34%, 19%, 19%, 19%;
    $colors: #f05d19, #E6530F, #00599d, #004e96, #03428e, #223771, #21356E, #f16624, #f18624, #f19924;
    $transform-p: inherit, scaleY(-1), scaley(-1) scalex(-1), rotate(180deg), scaleX(-1), scaleX(-1), scaleX(-1), inherit, inherit, inherit;
    $moves: move2, move, move, move2, move2, move2, move, move2, move2, move2;
    
    @include boxes(11, $base-transform, $base-top, $base-left, $transform-lines, $right-lines, $bottom-lines, $transform-cl2, $right-cl2, $top-cl2, $colors, $transform-p, $moves)

}


.boxDescription {
    position: absolute;
    z-index: 1;
    background-color: #f1f1f1;
    width: 350px;
    height: 92px;
    height: 130px;
    bottom: 55px;
    right: 10%;
    padding: 1rem;
    text-align: justify;
}

.boxC {
    max-width: 88%;
}

$left-wrapContentBox-six: 55px, 123px, 10px, -51px, -104px, -58px, 31px, 150px, 150px, 150px;

@mixin adjust-left-WrapContentBox($size, $container, $left-wrapContentBox: (55px, 123px, 119px, 10px, -51px, -104px, -58px, 31px, 150px, 150px, 150px)) {
    .wrappContentBox {
        left: calc(55px - #{$container});
    }
    @for $i from 2 through $size {
        .wrappContentBox:nth-child(#{$i}) {
            left: calc(#{nth($left-wrapContentBox, $i)} - #{$container});
        }
    }
}


@media only screen and (min-width: 2560px) {
    .boxC {
        max-width: 88% !important;
    }
    .circle {
        margin-right: 51.9rem;
    }
    .flexContent {
        margin-top: 2%;
    }
    .backgroundchart {
        margin-top: -7.4rem;
    }
    .chart {
        margin-left: 18rem;
    }
}

@media only screen and (max-width: 1440px) {
    .circleFake {
        left: calc(577px - var(--hdp-fixed));
    }
    .six {
        @include adjust-left-WrapContentBox(6, var(--hdp-fixed), $left-wrapContentBox-six)
    }
    .seven {
        @include adjust-left-WrapContentBox(7, var(--hdp-fixed))
    }
    .eight {
        @include adjust-left-WrapContentBox(8, var(--hdp-fixed))
    }
    .nine {
        @include adjust-left-WrapContentBox(9, var(--hdp-fixed))
    }
    .ten {
        @include adjust-left-WrapContentBox(10, var(--hdp-fixed))
    }
    .eleven {
        @include adjust-left-WrapContentBox(11, var(--hdp-fixed))
    }
    .backgroundchart {
        transform: scale(0.7);
        margin-top: -12.5rem;
    }
}

@media only screen and (max-width: 1366px) {
    .circleFake {
        left: calc(577px - var(--hd-fixed));
    }
    .wrappContentBox {
        left: calc(55px - var(--hd-fixed));
    }
    .six {
        @include adjust-left-WrapContentBox(6, var(--hd-fixed), $left-wrapContentBox-six)
    }
    .seven {
        @include adjust-left-WrapContentBox(7, var(--hd-fixed))
    }
    .eight {
        @include adjust-left-WrapContentBox(8, var(--hd-fixed))
    }
    .nine {
        @include adjust-left-WrapContentBox(9, var(--hd-fixed))
    }
    .ten {
        @include adjust-left-WrapContentBox(10, var(--hd-fixed))
    }
    .eleven {
        @include adjust-left-WrapContentBox(11, var(--hd-fixed))
    }
    .backgroundchart {
        transform: scale(0.7);
        margin-top: -12.5rem;
    }
}

@media only screen and (max-width: 1280px) {
    .circleFake {
        left: calc(577px - var(--hdpZ150-fixed));
    }
    .six {
        @include adjust-left-WrapContentBox(6, var(--hdpZ150-fixed), $left-wrapContentBox-six)
    }
    .seven {
        @include adjust-left-WrapContentBox(7, var(--hdpZ150-fixed))
    }
    .eight {
        @include adjust-left-WrapContentBox(8, var(--hdpZ150-fixed))
    }
    .nine {
        @include adjust-left-WrapContentBox(9, var(--hdpZ150-fixed))
    }
    .ten {
        @include adjust-left-WrapContentBox(10, var(--hdpZ150-fixed))
    }
    .eleven {
        @include adjust-left-WrapContentBox(11, var(--hdpZ150-fixed))
    }
    .backgroundchart {
        transform: scale(0.7);
        margin-top: -12.5rem;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .circleFake {
        left: calc(577px - var(--left-fixed-ipad-pro));
    }
    .six {
        @include adjust-left-WrapContentBox(6, var(--left-fixed-ipad-pro), $left-wrapContentBox-six)
    }
    .seven {
        @include adjust-left-WrapContentBox(7, var(--left-fixed-ipad-pro))
    }
    .eight {
        @include adjust-left-WrapContentBox(8, var(--left-fixed-ipad-pro))
    }
    .nine {
        @include adjust-left-WrapContentBox(9, var(--left-fixed-ipad-pro))
    }
    .ten {
        @include adjust-left-WrapContentBox(10, var(--left-fixed-ipad-pro))
    }
    .eleven {
        @include adjust-left-WrapContentBox(11, var(--left-fixed-ipad-pro))
    }
    .backgroundchart {
        transform: scale(0.6);
        margin-top: -16.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .circleFake {
        left: calc(577px - var(--left-fixed-tablet));
    }
    .six {
        @include adjust-left-WrapContentBox(6, var(--left-fixed-tablet), $left-wrapContentBox-six)
    }
    .seven {
        @include adjust-left-WrapContentBox(7, var(--left-fixed-tablet))
    }
    .eight {
        @include adjust-left-WrapContentBox(8, var(--left-fixed-tablet))
    }
    .nine {
        @include adjust-left-WrapContentBox(9, var(--left-fixed-tablet))
    }
    .ten {
        @include adjust-left-WrapContentBox(10, var(--left-fixed-tablet))
    }
    .eleven {
        @include adjust-left-WrapContentBox(11, var(--left-fixed-tablet))
    }
    .backgroundchart {
        transform: scale(0.6);
        margin-top: -16.5rem;
    }
}

@media only screen and (max-width: 480px) {
    .boxC {
        max-width: 88vw !important;
    }
    .backgroundchart {
        margin-top: 0 !important;
        transform: scale(1);
    }
    .circleInner {
        width: 50%;
        height: 50px;
        border-radius: 5%;
        align-items: flex-start;
        margin: auto auto auto 0;
    }
    .textInnerCircle {
        font: bold 14px Helvetica;
    }
    .lineV {
        position: absolute;
        width: 3px;
        height: 45vh;
        background-color: #ddd;
        z-index: 2;
        left: 5%;
        top: 139%;
    }
    .outerCircle1 {
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 20px;
        text-align: center;
        align-items: center;
        padding: 2px;
        border: solid 5px #eead34;
        position: absolute;
        left: -17vw;
        top: 0;
        z-index: 5;
        transform: scale(.8);
    }
    .outerCircle2 {
        margin: auto;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: solid 5px #eead34;
        text-align: center;
        align-items: center;
    }
    .circleLine {
        position: absolute;
        width: 12vw;
        height: 5px;
        background-color: #eead34;
        z-index: 2;
        left: -11vw;
        top: 2vh;
        padding: 0;
        border-radius: 5px;
        border: 1px solid #eead34;
    }
    .roundedBox {
        position: absolute;
        z-index: 4;
        display: flex;
        align-items: center;
        margin: auto;
        background-color: #eead34;
        width: 60vw;
        height: 5vh;
        border-radius: 40px;
        justify-content: flex-end;
        padding-right: 5px;
        left: 0;
        top: 0;
    }
    .contentRounded {
        width: 300px;
        height: 50px;
        display: flex;
    }
    .textInRounded {
        margin: auto;
    }
    .wrappBoxMobile {
        position: absolute;
        margin-top: 2vh;
        margin-left: 8vw;
        /* height: 100%;
        width: 100%; */
    }
    .contentBoxMobile {
        position: relative;
        margin-top: 0;
        margin-left: 10vw;
        /* height: 100%;
        width: 100%; */
    }

    @mixin boxes-mobile($size, $colors) {
        $line-size: 32vh, 38vh, 44vh, 50vh, 56vh, 62vh;
        .lineV {
            height: nth($line-size, $size - 5);
        }

        @for $i from 2 through $size {
            .wrappBoxMobile:nth-child(#{$i}) {
                margin-top: #{($i - 2) * 6 + 8}vh;

                .outerCircle1, .outerCircle2 {
                    border: solid 5px nth($colors, $i - 1);
                }

                .circleLine, .roundedBox {
                    background-color: nth($colors, $i - 1);
                }
            }
        }
    }

    .six {
        $colors:  #F19624, #F18624, #F16624, #00699D, #00599D;
        @include boxes-mobile(6, $colors)
    }
    .seven {
        $colors:  #F19624, #F18624, #F16624, #00699D, #00599D, #004E96;
        @include boxes-mobile(7, $colors)
    }
    .eight {
        $colors:  #eead34, #F19624, #F18624, #F16624, #00699D, #00599D, #004E96;
        @include boxes-mobile(8, $colors)
    }
    .nine {
        $colors:  #eead34, #eead34, #F19624, #F18624, #F16624, #00699D, #00599D, #004E96;
        @include boxes-mobile(9, $colors)
    }
    .ten {
        $colors: #eead34, #eead34, #eead34, #F19624, #F18624, #F16624, #00699D, #00599D, #004E96;
        @include boxes-mobile(10, $colors)
    }
    .eleven {
        $colors:  #eead34, #eead34, #eead34, #eead34, #F19624, #F18624, #F16624, #00699D, #00599D, #004E96;
        @include boxes-mobile(11, $colors)
    }
}

@media only screen and (max-width: 320px) {
    .lineV {
        left: 6.5%;
        top: 160%;
    }
    .textInRounded {
        font-size: 12px;
    }
}

@media only screen and (orientation: landscape) and (max-width: 890px) {
    .boxC {
        max-width: 88vw !important;
    }
    .backgroundchart {
        margin-top: 0 !important;
        transform: scale(1);
        height: 100vh;
    }
    .circleInner {
        width: 50%;
        height: 15%;
        border-radius: 5%;
        align-items: flex-start;
        margin: auto auto auto 0;
    }
    .textInnerCircle {
        font: bold 14px Helvetica;
    }
    .lineV {
        position: absolute;
        width: 3px;
        height: 76vh;
        background-color: #ddd;
        z-index: 2;
        left: 5%;
        top: 15%;
    }
    .outerCircle1 {
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 20px;
        text-align: center;
        align-items: center;
        padding: 2px;
        border: solid 5px #eead34;
        position: absolute;
        left: -16vw;
        top: 0;
        z-index: 5;
        transform: scale(.8);
    }
    .outerCircle2 {
        margin: auto;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: solid 5px #eead34;
        text-align: center;
        align-items: center;
    }
    .circleLine {
        position: absolute;
        width: 12vw;
        height: 5px;
        background-color: #eead34;
        z-index: 2;
        left: -12vw;
        top: 3vh;
        padding: 0;
        border-radius: 5px;
        border: 1px solid #eead34;
    }
    .roundedBox {
        position: absolute;
        z-index: 4;
        display: flex;
        align-items: center;
        margin: auto;
        background-color: #eead34;
        width: 60vw;
        height: 8vh;
        border-radius: 40px;
        justify-content: flex-end;
        padding-right: 5px;
        left: -2px;
        top: 0;
    }
    .contentRounded {
        width: 385px;
        height: 50px;
        display: flex;
    }
    .textInRounded {
        margin: auto;
    }
    .wrappBoxMobile {
        position: absolute;
        margin-top: 3vh;
        margin-left: 8vw;
        /* height: 100%;
        width: 100%; */
    }
    .contentBoxMobile {
        position: relative;
        margin-top: 0;
        margin-left: 10vw;
        /* height: 100%;
        width: 100%; */
    }
    .wrappBoxMobile:nth-child(2) {
        margin-top: 24vh;
    }
    .wrappBoxMobile:nth-child(3) {
        margin-top: 34vh;
    }
    .wrappBoxMobile:nth-child(4) {
        margin-top: 44vh;
    }
    .wrappBoxMobile:nth-child(5) {
        margin-top: 54vh;
    }
    .wrappBoxMobile:nth-child(6) {
        margin-top: 64vh;
    }
    .wrappBoxMobile:nth-child(7) {
        margin-top: 74vh;
    }
    .wrappBoxMobile:nth-child(8) {
        margin-top: 13vh;
    }
    .wrappBoxMobile:nth-child(2) .outerCircle1, .wrappBoxMobile:nth-child(2) .outerCircle2 {
        border: solid 5px #F19624;
    }
    .wrappBoxMobile:nth-child(2) .circleLine, .wrappBoxMobile:nth-child(2) .roundedBox {
        background-color: #F19624;
    }
    .wrappBoxMobile:nth-child(3) .outerCircle1, .wrappBoxMobile:nth-child(3) .outerCircle2 {
        border: solid 5px #F18624;
    }
    .wrappBoxMobile:nth-child(3) .circleLine, .wrappBoxMobile:nth-child(3) .roundedBox {
        background-color: #F18624;
    }
    .wrappBoxMobile:nth-child(4) .outerCircle1, .wrappBoxMobile:nth-child(4) .outerCircle2 {
        border: solid 5px #F16624;
    }
    .wrappBoxMobile:nth-child(4) .circleLine, .wrappBoxMobile:nth-child(4) .roundedBox {
        background-color: #F16624;
    }
    .wrappBoxMobile:nth-child(5) .outerCircle1, .wrappBoxMobile:nth-child(5) .outerCircle2 {
        border: solid 5px #00699D;
    }
    .wrappBoxMobile:nth-child(5) .circleLine, .wrappBoxMobile:nth-child(5) .roundedBox {
        background-color: #00699D;
    }
    .wrappBoxMobile:nth-child(6) .outerCircle1, .wrappBoxMobile:nth-child(6) .outerCircle2 {
        border: solid 5px #00599D;
    }
    .wrappBoxMobile:nth-child(6) .circleLine, .wrappBoxMobile:nth-child(6) .roundedBox {
        background-color: #00599D;
    }
    .wrappBoxMobile:nth-child(7) .outerCircle1, .wrappBoxMobile:nth-child(7) .outerCircle2 {
        border: solid 5px #004E96;
    }
    .wrappBoxMobile:nth-child(7) .circleLine, .wrappBoxMobile:nth-child(7) .roundedBox {
        background-color: #004E96;
    }
}