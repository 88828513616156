/* HEADER */
.headMapContainer {
    height: 400px;
    width: 100%;
}

.frontMap {
    height: 400px;
    width: 100%;
    position: absolute;
    /* z-index: 1;
    background-color: black;
    opacity: 0.67; */
}

.headMapImg {
    position: absolute;
    z-index: 0;
    height: 400px;
    width: 100%;
    object-fit: cover;
}

/* ROW SECTION */
.heading {
    font: bold 36px Helvetica;
    margin-bottom: 0 !important;
    color: black;
    text-transform: uppercase;
}

/* SEND MESSAGE SECTION */
.sendMessageSection {
    max-width: 625px;
}

.input1 {
    display: block;
    width: 100%;
    height: 5vh;
    margin-top: 2rem;
    padding-left: 24px;
    font: 18px Helvetica;
    border: 1px solid #c8c8c8;
}

.input {
    display: block;
    width: 100%;
    height: 5vh;
    margin-top: 16px;
    padding-left: 24px;
    font: 18px Helvetica;
    border: 1px solid #c8c8c8;
}

.bigInput {
    display: block;
    width: 100%;
    /* margin-top: 16px; */
    height: 168px;
    padding: 16px 24px;
    font: 18px Helvetica;
    border: 1px solid #c8c8c8;
}

.alertBox {
    display: flex;
    height: 40px;
    background-color: #ca0000;
    margin-top: 8px;
}

.alertBoxText {
    color: white;
    font: 16px Helvetica;
    align-self: center;
    justify-content: center;
    text-align: center;
    margin: auto auto auto 1.6rem;
}

/* .btnSend {
    color: white;
    background-color: #223870;
    height: 56px;
    font: 26px Helvetica;
    padding: 0 80px;
    margin-top: 24px;
    float: right;
    border-radius: 50px;
    display: flex;
    cursor: pointer;
} */

/* .btnSend span {
    margin: auto;
} */

/* CONTACT SECTION */
.companyName {
    font: bold 30px Helvetica;
    color: #223771;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 0 !important;
}

.officeDiv {
    margin-top: 1rem;
}

.factoryDiv {
    margin-top: 1rem;
}

.smallAnchor {
    font: 20px Helvetica;
    color: #f16b26;
    text-transform: uppercase;
    margin: 2rem 0 0 0 !important;
}

.icon {
    margin-left: 11px;
}

.address {
    margin: 1rem 0 0 0 !important;
    font: bold 16px Helvetica;
}

.thirdDiv {
    display: flex;
    margin-top: 3.2rem;
}

.QRCode {
    height: 170px;
    width: 170px;
    /* border: 1px solid #707070; */
    /* background-color: #afafaf; */
}

.imgQR {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 1px solid #c8c8c8;
}

.emailPhoneFaxContainer {
    height: 170px;
    width: 170px;
    margin-left: 3rem;
}

.label {
    font: 16px Helvetica;
    margin-bottom: 0;
    height: 50%;
    /* margin: 62px 0 0 0; */
    color: #696969;
}

.label.top {
    margin: 0;
}

.text {
    color: black;
    font: 16px Helvetica;
    margin-bottom: 0;
    /* margin: 1rem 0; */
}

.text.email {
    color: #223771;
}

.mWid {
    max-width: 1520px !important;
    padding: 5rem 0;
}

.prBox {
    height: 100%;
    width: 100vw;
}

/* Error */
.errorVisible {
    display: block !important;
}

.contentError {
    text-align: left;
    font: 12px Helvetica;
    color: #980000;
    display: none;
}

.sweetLoading {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.phenikaaNotif {
    background-color: #f16b26;
    border-left: 8px solid #f16b26;
}

.phenikaaNotif>div>div {
    color: black;
    font-family: Helvetica;
}

.phenikaaNotif>div>div>div {
    background: black;
}


.cardBottom {
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    /* background-color: tomato; */
    display: flex;
    flex-direction: column;
}


.cardTopInfo {
    height: 70%;
    width: 100%;
    /* background-color: thistle; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.cardLeft {
    width: 50%;
    height: 100%;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;

}

.cardRight {
    width: 50%;
    height: 100%;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    margin-left: 2rem;

}


.cardRight2 {
    width: 50%;
    min-height: 100%;
    /* background-color: brown; */
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
}




.cardBottomInfo {
    height: 30%;
    width: 100%;
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

}


.imgQRCode {
    width: 170px;
    height: 100%;
    /* background-color: powderblue; */
}

.imgQRCode img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #c8c8c8;

}

.emailPhoneCode {
    width: 200px;
    height: 100%;
    /* background-color: red; */
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
}

.footerSocialMedia {
    display: flex;
}
.footerSocialMedia a {
    margin-right: 1rem;
    color: unset;
}
.footerSocialMedia svg {
    transform: scale(1.4);
}



.boxEmail {
    height: 50%;
}


.boxPhone {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}


.cardSend {
    height: 5vh;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    /* background-color: rosybrown; */
}


.leftBtn {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    /* background-color: seagreen; */
}


.rightNull {
    width: 50%;
    height: 100%;
    margin-left: 2rem;
    background-color: transparent;
}


.boxButtonSend {
    width: 45%;
    height: 100%;
    background-color: #223870;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;

}

.boxButtonSend span {
    color: white;
    font: 26px Helvetica;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: auto;
}

@media only screen and (min-width: 2560px) {
    .mWid {
        max-width: 2090px !important;
    }

    .sendMessageSection {
        max-width: 885px;
    }

    .input1 {
        border: 1px solid #afafaf !important;
    }

    .input {
        margin-top: 2rem;
        border: 1px solid #afafaf !important;
    }

    .bigInput {
        /* margin-top: 2rem; */
        border: 1px solid #afafaf !important;
    }

    .alertBox {
        height: 5vh;
    }

    /* .btnSend {
        height: 5vh;
    } */

    .thirdDiv {
        margin-top: 4.8rem;
    }

    .smallAnchor {
        margin: 5rem 0 0 0 !important;
    }

    .address {
        margin: 3rem 0 0 0 !important;
    }


}


@media only screen and (max-width: 2048px) {
    .thirdDiv {
        margin-top: 10.2vh;
    }
}


@media only screen and (max-width : 1680px) {
    .mWid {
        max-width: 78vw !important;
    }


    .boxButtonSend span {
        font: 20px Helvetica;
    }

    /* .text {
        margin: 1.7rem 0;
    } */

    .emailPhoneFaxContainer {
        padding: .5rem;
    }

    .smallAnchor {
        margin: 1.5rem 0 0 0 !important
    }


    .thirdDiv {
        margin-top: 3rem;
    }

    /* .label {
        margin: 0;
    } */


    .isVie {
        margin: unset;
    }
}

@media only screen and (max-width: 1440px) {
    .mWid {
        max-width: 88vw !important;
        padding: 3.5rem 2.5vw;
    }




    .row {
        margin-left: 3vw !important;
    }

    .heading {
        font: bold 28px Helvetica;
    }

    .input1 {
        margin-top: 1.5rem;
    }

    .input1,
    .input,
    .bigInput {
        font: 14px Helvetica;
    }

    .companyName {
        font: bold 22px Helvetica;
        margin-top: 1.5rem;
    }

    .smallAnchor {
        font: 16px Helvetica;
        margin: 1rem 0 0 0 !important;
    }

    .address {
        font: bold 14px Helvetica;
    }

    .label,
    .text {
        font: 14px Helvetica;
    }


    .boxButtonSend span {
        font: 18px Helvetica;
    }

    /* .btnSend {
        font: 18px Helvetica;
        height: 45px;
        margin-top: 1rem;
    } */


    .imgQR {
        width: 100%;
        height: 100%;

    }

    /* .text {
        margin: 2rem 0;
    } */

    .thirdDiv {
        margin-top: 5.3rem;
    }

    .emailPhoneFaxContainer {
        padding: .7rem;
        /* margin-top: 1rem; */
    }
}

@media only screen and (max-width: 1366px) {



    .thirdDiv {
        margin-top: 3.7rem;
    }

}




@media only screen and (max-width: 1024px) {
    .mWid {
        max-width: 88vw !important;
    }

    .heading {
        font: bold 18px Helvetica;
    }

    .companyName {
        font: bold 18px Helvetica;
    }

    .smallAnchor {
        font: 14px Helvetica;
    }

    .thirdDiv {
        margin-top: 3.7rem !important;
    }

    .imgQRCode {
        width: 170px;
    }

    .boxButtonSend span {
        font: 16px Helvetica;
    }



}




@media only screen and (max-width: 768px) {
    .mWid {
        max-width: 88vw !important;
        margin: auto 6vw;
    }

    .heading {
        font: bold 18px Helvetica;
    }

    .companyName {
        font: bold 18px Helvetica;
    }


    .imgQRCode {
        width: 50%;
    }


    .label,
    .text {
        font: 12px Helvetica;
    }

    .emailPhoneCode {
        width: 50%;
        padding: 0 0 0 1rem;
        margin-left: 0;
    }

    .boxButtonSend span {
        font: 14px Helvetica;
    }

}

@media only screen and (max-width: 480px) {
    .joinCss {
        padding-top: 3.5rem;
    }
}