.customSlick > .slick-slider > .slick-list > .slick-track > :not(.slick-slide) {
  width: 0 !important;
}

.customSlick
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide:nth-child(--slicks) {
  width: 100% !important;
}

.wrapp > .customSlick > .scroll-horizontal {
  overflow: unset !important;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

/* .slick-dots {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
} */

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 1px;
  padding: 0;
  cursor: pointer;
}

#sliderHistory .slick-dots {
  position: absolute;
  display: block;
  top: 91%;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  height: 2vh;
}

/*ipad pro view*/
@media only screen and (min-width: 769px) and(max-width: 1024px) {
  #sliderHistory .slick-dots {
    top: 107%;
  }
}
