#slider2 .slick-dots {
  position: absolute;
  bottom: -45%;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#slider2 .slick-dots ul {
  padding: 0 !important;
}

@media only screen and (min-height: 714px) and (max-height: 715px) {
  #slider2 .slick-dots {
    bottom: -54%;
  }
}

@media only screen and (min-height: 732px) and (max-height: 738px) {
  #slider2 .slick-dots {
    bottom: -58%;
    padding-bottom: 0 !important;
  }
}

/*nokia N9*/
@media only screen and (min-width: 480px) and (orientation: portrait) {
  #slider2 .slick-dots {
    bottom: -65%;
    padding-bottom: 0 !important;
  }
}

/*iPhone*/
@media only screen and (max-width: 438px) and (orientation: portrait) {
  #slider2 .slick-dots {
    bottom: -18%;
  }
}

/*iPhone*/
@media only screen and (max-width: 400px) and (orientation: portrait) {
  #slider2 .slick-dots {
    bottom: -33%;
  }
}

/*galaxy S III*/
@media only screen and (max-width: 375px) and (orientation: portrait) {
  #slider2 .slick-dots {
    bottom: -27%;
  }
}

/*galaxy S III*/
@media only screen and (max-width: 360px) and (orientation: portrait) {
  #slider2 .slick-dots {
    bottom: -28%;
    padding-bottom: 0 !important;
  }
}

/*iphone 5*/
@media only screen and (max-width: 320px) and (orientation: portrait) {
  #slider2 .slick-dots {
    bottom: -38%;
    padding-bottom: 0 !important;
  }
}


/*landscape view*/

@media only screen and (orientation: landscape) and (max-width: 890px) {
  #slider2 .slick-dots {
    bottom: -38%;
    right: unset;
  }
}

@media only screen and (orientation: landscape) and (max-width: 570px) {
  #slider2 .slick-dots {
    bottom: -18%;
  }
}