/* GENERAL */
:root {
    --title-hd: bold 28px Helvetica;
    --subtitle-hd: bold 18px Helvetica;
    --p-hd: normal 14px Helvetica;
    --container-hd: 1062px !important;
    --padding-section-hd: 3.5rem 0;

    --title-fhd: bold 36px Helvetica;
    --subtitle-fhd: bold 26px Helvetica;
    --p-fhd: normal 16px Helvetica;
    --container-fhd: 1540px !important;
    --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */
.newNavbar {
    height: 72px;
    width: 100vw;
    background-color: white;
    transition: all 1s ease;
    z-index: 2;
    position: relative;
    transition: all 1s linear 0.3s;
}

.sectionWrapping {
    background-color: white;
    position: relative;
}

/* .box:after {
    content: '';
    display: block;
    margin-top: 20px;
    width: 0;
    height: 2px;
    background: #000;
    border-bottom: 3px solid #F16B26;
    transition: width .3s;
}

.box:hover::after {
    width: 100%;
} */

.isActiveB span {
    color: #223771 !important;
    text-transform: uppercase;
}

.isActiveB::after {
    /* border-bottom: 3px solid #223771; */
    content: "" !important;
    display: block !important;
    margin-top: 19px !important;
    width: 0 !important;
    height: 2px !important;
    background: #223771 !important;
    width: 100% !important;
    transition: width 0.3s;
}

.isActiveO span {
    color: #223771 !important;
    text-transform: uppercase;
}

.isActiveO::after {
    content: "" !important;
    display: block !important;
    margin-top: 19px !important;
    width: 0 !important;
    height: 2px !important;
    background: #f16b26 !important;
    width: 100% !important;
    transition: width 0.3s !important;
}

.imgIcon {
    height: 25px;
    width: 25px;
    position: relative;
    margin: auto 0.5rem auto 0;
}

.boxsO,
.boxsB {
    height: 72px;
    padding: 1.4rem;
}

.boxsO span,
.boxsB span {
    color: #000;
    font: bold 18px/28px Helvetica;
}

.boxsO:after {
    content: "";
    display: block;
    margin-top: 19px;
    width: 0;
    height: 2px;
    background: #f16b26;
    transition: width 0.3s;
}

.boxsB:after {
    content: "";
    display: block;
    margin-top: 19px;
    width: 0;
    height: 2px;
    background: #223771;
    transition: width 0.3s;
}

.boxsO:hover::after,
.boxsB:hover::after {
    width: 0%;
}

.boxsO span:hover,
.boxsB span:hover {
    cursor: pointer;
    color: #223771;
}

.boxsB:hover .customSpan,
.boxsO:hover .customSpan {
    /* -webkit-transform: rotateX(90deg) translateY(-22px);
    transform: rotateX(90deg) translateY(-22px); */
    -webkit-transform: rotateX(90deg) translateY(-10px);
    transform: rotateX(90deg) translateY(-10px);
}

.customSpan {
    position: relative;
    display: inline-block;
    padding: 0;
    transition: transform 0.3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
}

.customSpan:before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    content: attr(data-hover);
    transition: color 0.3s;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2000px !important;
        padding: 0;
    }

}

@media only screen and (max-width: 1680px) {
    .customContainer {
        /*max-width: var(--container-hd) !important;*/
        max-width: 1300px !important;
        padding-left: 0;
        padding-right: 0;
    }

    .boxsO span,
    .boxsB span {
        color: #000;
        font: bold 16px/28px Helvetica;
    }
}

@media only screen and (max-width: 1440px) {
    .customContainer {
        max-width: 1200px !important;
        padding-left: 0;
        padding-right: 0;
    }

    .boxsO,
    .boxsB {
        height: 72px;
        padding: 0.4rem 0.4rem 0 0.4rem;
    }

    .boxsO span,
    .boxsB span {
        font: bold 13px/28px Helvetica;
    }

    .navEn span,
    .navEn span {
        font: bold 13px Helvetica !important;
        margin: auto;
    }

    .isActiveB::after,
    .isActiveO::after,
    .boxsB:after,
    .boxsO:after {
        margin-top: 10px !important;
    }
}

@media only screen and (max-width: 1400px) {
    .customContainer {
        max-width: var(--container-hd) !important;
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .customContainer {
        max-width: 88vw !important;
    }
}

@media only screen and (max-width: 768px) {
    .customContainer {
        max-width: 672px !important;
    }
    
    .customBox {
        width: 25px;
        height: 100%;
        display: flex;
    }

    .customBoxA {
        width: 100%;
        height: 100%;
        display: flex;
        /* transition: width .1s; */
    }

    .customBoxA span {
        margin: auto;
    }

    .isActiveB::after,
    .isActiveO::after,
    .boxsB:after,
    .boxsO:after {
        margin-top: -2px !important;
    }

}