.card-img .image_zoom_2 {
  opacity: 0;
  height: 300px;
  width: auto;
  margin-top: -260px;
  margin-left: 3rem;
}

#modal_section2 {
  margin: 0;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  /* background-color: #fff; */
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 0.3rem;
  outline: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.AuSec2 .slick-dots li.slick-active button:before {
  background-color: #f16b26;
  opacity: 1;
}

.AuSec2 li button {
  width: 10px;
  height: 10px;
}

.AuSec2 li button:before {
  width: 10px;
  height: 10px;
  font-size: 0;
  background-color: transparent;
  opacity: 1;
  border-radius: 50%;
  border: 1px solid rgb(214, 214, 214);
}

.AuSec2 li:last-of-type {
  width: 10px;
}
