#advanced-article img.full-width {
	width: 100vw !important;
	height: 100vh;
	position: absolute;
}

#advanced-article {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 16px;
}

#advanced-article p {
	font: 16px/32px Helvetica;
	text-align: justify;
}

#advanced-article iframe {
	margin-top: 0 !important;
	margin-bottom: 1rem !important;
}

#advanced-article img.regular-width {
	margin-top: 0 !important;
	margin-bottom: 1rem !important;
}

@media (max-width: 992px) {
	#advanced-article iframe {
		width: 100% !important;
		height: 100% !important;
	}
	#advanced-article img.regular-width {
		width: 100% !important;
	}
}

@media (max-width: 768px) {
	#advanced-article iframe {
		width: 100% !important;
		height: 380px !important;
	}
}

@media (max-width: 576px) {
	#advanced-article iframe {
		width: 100% !important;
		height: 100% !important;
	}
}

@media (min-width: 1200px) {
	#advanced-article div.container {
		max-width: 1140px;
	}
}
