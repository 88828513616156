.Map {
    height: 100%;
    width: 100vw;
    position: relative;

}

.imageMask {
    height: 100%;
    width: 100vw;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.boxPreview {
    width: 100%;
    height: 368px;
    background: var(--unnamed-color-f1f1f1) 0 0 no-repeat padding-box;
    background: #F1F1F1 0 0 no-repeat padding-box;
}

.fullWidth {
    padding: 3rem 0;
    height: 65vh;
    width: 100vw !important;
    position: relative;
    transform: scale(1.2);
}

.detailMaps {
    position: absolute;
    bottom: 0;
    left: 0;
}

.tools {
    position: absolute;
    bottom: 10%;
    right: 12%;
    z-index: 4;
}

.button_map {
    width: 40px;
    height: 40px;
    border: none;
    margin-right: 10px;
    font-size: 20px;
}

.boxImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
}

.backgroundImg {
    height: 100%;
    width: 100%;
    position: relative;
}

.boxDot {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 45vw;
}

.boxDot img {
    /* transform: scale(.1) translate(114.974748%, 294.7204%); */
    /* transform: scale(.3) translate(-100px, 0px); */
    transform: scale(.3) translate(-33%, 2%);
}

svg.dot {
    width: 100%;
    height: 100%;
    /* transform: scale(.1) translate(114.974748%, 294.7204%); */
}

.shuriken {
    /* transform: scale(1) translate(114.974748%, 294.7204%); */
    height: 100%;
    width: 100%;
}

.nodeCA {
    transform: scale(.6) translate(-995px, 240px);
    animation: blinker 3s linear infinite;
}

.nodeUSA {
    transform: scale(.6) translate(-990px, 390px);
    animation: blinker 3s linear infinite;
    animation-delay: -3s;
}

.nodeBR {
    transform: scale(.6) translate(-785px, 610px);
    animation: blinker 3s linear infinite;
    animation-delay: -4s;
}

.nodeSA {
    transform: scale(.6) translate(-430px, 722px);
    animation: blinker 3s linear infinite;
    animation-delay: -5s;
}

.nodeAUS {
    transform: scale(.6) translate(-0px, 710px);
    animation: blinker 3s linear infinite;
    animation-delay: -3s;
}

.nodeID {
    transform: scale(.6) translate(-85px, 620px);
    animation: blinker 3s linear infinite;
    animation-delay: -4s;
}

.nodeRU {
    transform: scale(.6) translate(-170px, 235px);
    animation: blinker 3s linear infinite;
    animation-delay: -5s;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}


@media only screen and (min-width: 2048px) and (max-height: 1536px) {
    .fullWidth {
        height: unset;
    }

    .Map {
        height: unset;
    }


}



@media only screen and (max-width:1440px) and (height:900px) {
    .fullWidth {
        height: 30vh;
    }
}

@media only screen and (max-width:1440px) {
    .Map {
        overflow: hidden;
    }
}

@media only screen and (max-width:1366px) {
    .Map {
        overflow: unset;
    }

    .tools {
        top: 51%;
        right: 15%;
    }
}

@media only screen and (max-width: 480px) {
    .button_map {
        display: none;
    }

}

/* @media only screen and (max-width:360px) {
    .button_map {
        display: none;
    }
} */

@media only screen and (max-width: 1024px) {
    .boxDot {
        width: 100%;
    }

    .fullWidth {
        height: auto;
        transform: scale(1);
        padding: 0 0 3.5rem 0;
    }
}

@media only screen and (max-width: 480px) {
    .fullWidth {
        padding: 0 0 1rem 0;
    }
}