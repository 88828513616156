.no_src, .is_src {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bxf1r:hover .is_src {
    filter: blur(2px);
}

.bxf1r {
    display: block !important;
    position: relative;
    width: 304px !important;
    height: 272px;
    /* background: #223771; */
    background: transparent;
    margin: 0 16px 0 0;
    cursor: url("../assets/drag.svg") 20 20, auto;
}

.isMarginLeft {
    margin-left: calc((100vw - 2000px) / 2);
}

.shdw0 {
    width: 304px;
    height: 272px;
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: 1;
    background: hsl(224 54% 29% / 0.5);
    display: flex;
}

.cardFd1 {
    height: 50%;
    padding: 1.5rem;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.cardcontent a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.kpos {
    font: bold 16px Helvetica;
    line-height: 1.5rem;
    color: white;
    text-align: right;
    padding-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: right;
}

.date {
    font: 16px Helvetica;
    color: white;
    text-align: right;
}

.outerCircle2 {
    padding: 5px;
    /* background-color: rgb(45, 57, 69, 0.5); */
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    margin: 0 0rem 0 auto;
    align-self: left;
    opacity: 0;
    /*position: absolute;*/
    /* top: 1px; */
    /* left: 42%; */
}

.innerCircle2 {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.rdMoreP {
    font: 16px Helvetica;
    color: white;
    margin: 0;
    text-align: right;
    padding-left: 1rem;
}

.contentWrapping {
    padding: 0;
}

.bxf1r:hover .outerCircle2 {
    opacity: 1;
}

@media only screen and (max-width: 1920px) {
    .isMarginLeft {
        margin-left: calc((100vw - 1540px) / 2);
    }
}

@media only screen and (max-width: 1660px) {
    .isMarginLeft {
        margin-left: calc((100vw - 1300px) / 2);
    }
}

@media only screen and (max-width: 1440px) {
    .isMarginLeft {
        margin-left: calc((100vw - 1162px) / 2);
    }
}

@media only screen and (max-width: 1366px) {
    .isMarginLeft {
        margin-left: calc((100vw - 1062px) / 2);
    }
}

@media only screen and (max-width: 1024px) {
    .isMarginLeft {
        margin-left: 6vw;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 480px) {
    .kpos {
        font: bold 14px Helvetica;
        line-height: 1.5rem;
    }
    .rdMoreP {
        font: 14px Helvetica;
        padding-left: .5rem;
    }
    .bxf1r .outerCircle2 {
        opacity: 1;
    }
    .bxf1r .is_src {
        filter: blur(2px);
    }
    .isMarginLeft {
        margin-left: 6vw;
    }
}