.editProfile {
    margin-top: 1rem;
    padding: 2rem;
}

.profile {
    display: flex;
}

.boxImage {
    width: 128px;
    height: 128px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 2rem;
}

.boxRounded {
    width: 120px;
    height: 120px;
    border: 1px solid #f1f1f1;
    border-radius: 50%;
    overflow: hidden;
}

.imgProfile {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.boxProfile {
    display: flex;
    margin-bottom: .5rem;
}

.title {
    text-align: left;
    font: bold 16px/32px Helvetica;
    color: #000000;
    margin: 0;
}

.content {
    text-align: left;
    font: 14px/32px Helvetica;
    color: #000000;
    margin: 0;
}

.titleContent {
    text-align: left;
    font: bold 16px/32px Helvetica;
    color: #000000;
}

.iconEdit {
    margin: auto 2rem auto 2rem;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    position: relative;
    display: flex;
}

.iconEditAva {
    margin: auto;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    position: relative;
    display: flex;
    background: #ffffff;
    bottom: 26px;
}

.iconEdit img, .iconEditAva img {
    margin: auto;
    height: 65%;
    width: 65%;
    object-fit: cover;
}

.boxContact {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 480px) {
    .editProfile {
        padding: 1rem 0;
    }
    .profile {
        flex-direction: column;
    }
    .boxImage {
        margin: 0 auto 1rem auto;
    }
}