.isBlue {
    background: #00599d 0% 0% no-repeat padding-box !important;

}


.tableColumn {

    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;
    margin-right: 1rem;
}


.isGrey {
    background: #d6d6dd 0% 0% no-repeat padding-box !important;
}

.statusNego {
    color: #28a745 !important;
}

.changeColor {
    color: white !important;
    display: flex;
    justify-content: center;
    margin: auto;
}

.changeColorApprove {
    color: white !important;
    display: flex;
    justify-content: center;
    margin: auto;
}

.changeColorGreen {
    color: white !important;
    display: flex;
    justify-content: center;
    margin: auto;
}


.boxStatus {
    width: auto;
    height: 30px;
    background: #00599d 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
}


.boxStatusClosed {
    width: auto;
    height: 30px;
    background: #c50707 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
}


.boxStatusOpened {
    width: auto;
    height: 30px;
    background: #28a745 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
}

.validError {
    text-align: left;
    font: 12px Helvetica;
    color: #980000;
    opacity: 1;
}




.isDisabled {
    display: none !important;
}

.validError2 {
    text-align: left;
    font: 10px Helvetica;
    color: #980000;
    opacity: 1;
}

.btnApprove {
    width: auto;
    height: 30px;
    background: #28a745 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
}


.btnApproveDisabled {
    width: auto;
    height: 30px;
    background: #28a745 0% 0% no-repeat padding-box;
    padding: 0 1rem;
    display: flex;
    border-radius: 5px;
}

.modalSelect {
    width: 100%;
    height: 100%;
    border: 0;
}

/* .modalSelect:focus {
    border: none;
    outline: none;
} */

.createSal {
    width: 30%;
    height: auto;
    background: #00599d;
    display: flex;
    cursor: pointer;
}


.createSal:hover {
    background: #28a745;
}

.createSal p {
    color: white;
    text-align: right;
    margin: auto;
    font: 14px Helvetica;
}


.wrap2 {
    flex-direction: column;
    width: 100%;
}

.wrap3 {
    flex-direction: column;
    width: 20%;
}

.boxNewOffer {
    width: 100%;
    height: 30px;
    /* background: cornflowerblue; */
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
}

.btnApprove span {
    color: white !important;
    justify-content: center;
    margin: auto;
}


.btnApproveDisabled span {
    color: white !important;
    justify-content: center;
    margin: auto;
}

.dataTable span {
    font: 14px Helvetica;
    color: black;
}

@media only screen and (max-width: 480px) {
    .boxStatus {
        width: unset;
        min-width: 92px;
    }


    .tableColumn {
        min-width: 92px;
    }
}