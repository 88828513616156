/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 76%;

  --title-tablet: bold 18px Helvetica;
  --subtitle-tablet: bold 14px Helvetica;
  --p-tablet: normal 14px Helvetica;
  --container-tablet: 672px;
}

/* SPECIFIC */
.Section5 {
  width: auto;
  background-color: white;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.flexContent {
  margin: auto;
}

.boxTitle {
  height: 62px;
  position: absolute;
  align-self: center;
  left: 14%;
  top: 14%;
}

.title {
  font: 36px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2rem;
}

p.contentTextBottom {
  font-size: 16px/32px Helvetica;
  letter-spacing: 0;
  text-align: justify;
}

.Box5 {
  padding: 7rem 2rem 5rem;
}

.boxImage1 {
  width: 100%;
  height: 440px;
  position: relative;
}

figure.wrapp_image {
  width: 100%;
  height: 440px;
  overflow: hidden;
}

.img_content {
  width: 100%;
  height: 440px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.inside_image {
  width: 1510px;
  height: 440px;
  position: absolute;
  background-color: rgb(6 6 6 / 23%);
  opacity: 0;
  color: rgb(0, 0, 0);
  top: 0;
}

.iconhover {
  position: absolute;
  width: 200px;
  height: 100px;
  opacity: 1;
  bottom: 2px;
  left: 82%;
  object-fit: contain;
}

figure.wrapp_image:hover .inside_image {
  opacity: 1;
}

figure.wrapp_image:hover .img_content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 1s ease-in-out;
}

.Box5_1 {
  width: 1440px;
  height: 440px;
}

.flexContent2 {
  padding-top: 4%;
}

.miniBox1 {
  width: 448px;
  height: 242px;
  padding: 0 2rem 0 0;
}

.miniBox2 {
  width: 448px;
  height: 242px;
  padding: 0;
  margin-right: 30px;
  margin-left: 30px;
}

.miniBox3 {
  width: 448px;
  height: 242px;
  padding: 0 0 0 2rem;
}

.image2 {
  width: 100%;
  height: 232px;
  object-fit: cover;
}

.box_title {
  width: 448px;
  height: 56px;
  background-color: transparent;
  filter: blur(10px);
}

p.text {
  position: relative;
  font-size: 20px Helvetica;
}

.boxC {
  max-width: 100%;
  padding-right: 11vw;
}

.boxBottom {
  width: 448px;
  height: 56px;
  position: absolute;
  background-color: rgb(100 20 20 / 10%);
  bottom: 0;
  backdrop-filter: blur(10px);
  z-index: 0;
  opacity: 1;
  transition: height 0.5s;
}

.contentInsideBox {
  position: absolute;
  bottom: 0;
  z-index: 2;
  transition: height 0.5s;
  opacity: 1;
}

.insideBox {
  width: 448px;
  height: 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
}

p.contentBoxTitle {
  font: Bold 20px Helvetica;
  color: white;
  opacity: 1;
}

.box {
  width: 448px;
  height: 232px;
  position: relative;
}

.margin {
  margin: 1rem 2rem auto;
  opacity: 1;
}

.box_hover {
  position: relative;
  width: auto;
  height: auto;
  background-color: transparent;
}

.content_hover {
  background-color: rgb(241 229 229 / 15%);
  width: 448px;
  height: 232px;
  position: absolute;
  opacity: 0;
  backdrop-filter: blur(15px);
}

.text_hover {
  width: 448px;

  opacity: 1;
  color: white;
}

h2.text_hover {
  font: Bold 20px Helvetica;
  opacity: 1;
}

p.contentLink {
  font: 16px/24px Helvetica;
  margin: auto 0;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.outerCircle {
  padding: 5px;

  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0;
  opacity: 1;
  margin: auto 0.5rem auto 0;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.box_hover:hover .content_hover,
.box_hover:hover .content_hover .text_hover,
.box_hover:hover .content_hover .text_hover .outerCircle,
.box_hover:hover .content_hover .text_hover .outerCircle .innerCircle {
  opacity: 1;
}

.box_hover:hover .boxBottom {
  opacity: 0;
}

.padd {
  padding: 0 auto;
  display: flex;
  flex-direction: row;
  padding-top: 20%;
  padding-left: 20%;
}

.link {
  color: transparent !important;
  cursor: pointer;
}

.link1 {
  text-decoration: none !important;
  margin: auto auto auto 0;
}

.link2 {
  text-decoration: none !important;
  margin: auto;
}

.link3 {
  text-decoration: none !important;
  margin: auto 0 auto auto;
}

.flexDir {
  display: flex;
  flex-direction: row;
}

.boxFirst {
  margin-left: calc((100vw - 80vw) / 2);
}

@media only screen and (min-width: 2560px) {
  .customContainer {
    max-width: 2090px !important;
  }

  .title {
    font: bold 36px Helvetica;
  }
}

@media only screen and (max-width: 1680px) {
  .customContainer {
    max-width: 76% !important;
    padding-right: 0;
    padding-left: 0;
  }

  .title {
    font: var(--title-hd);
  }

  .inside_image {
    width: 1090px;
    height: 441px;
    top: 19%;
  }

  .box {
    width: 354px;
  }

  .content_hover {
    width: 354px;
  }

  .boxBottom {
    width: 354px;
  }

  .boxC {
    max-width: 100%;
    padding-right: unset;
    padding-left: unset;
  }

  .insideBox {
    width: 354px;
  }

  .iconhover {
    left: 80%;
  }

  .Section5 {
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;
  }

  .boxFirst {
    margin-left: calc((100vw - 76vw) / 2);
  }
}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1024px) {
  .customContainer {
    max-width: 88vw !important;
  }

  .title {
    font: var(--title-tablet);
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .customContainer {
    max-width: var(--container-tablet) !important;
  }



  .title {
    font: var(--title-tablet);
  }
}

@media only screen and (max-width: 480px) {
  .title {
    font: bold 18px Helvetica;
  }

  .customContainer {
    max-width: 88vw !important;
  }

  .customDots {
    background-color: #ddd;
    border-radius: 10px;
    padding: 10px;
  }

  .customPaggination {
    width: 10px;
    border: 1px #f1f1f1 solid;
    height: 10px;
    border-radius: 50%;
  }
}

@media only screen and (orientation: landscape) and (max-width: 890px) {

  .Section5 {
    width: 88%;
    margin: auto;
  }
}