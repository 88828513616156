/* GENERAL */
:root {
	--title-hd: bold 28px Helvetica;
	--subtitle-hd: bold 18px Helvetica;
	--p-hd: normal 14px Helvetica;
	--container-hd: 1062px !important;
	--padding-section-hd: 3.5rem 0;
}

/* SPECIFIC */
.banner {
	width: 100vw;
	height: 65vh;
	display: flex;
	justify-content: center;
	align-self: center;
	position: relative;
}

.banner img {
	width: 100vw;
	height: 65vh;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
}

/* .banner img {
  width: 100%;
  height: 65vh;
  object-fit: cover;
} */

.runningBanner {
	position: relative;
	font: 50px Helvetica;
	color: white;
}

.titleBanner {
	position: relative;
	font: bold 48px Helvetica;
	color: white;
	/* top: 45%; */
}

.bannerContent {
	/* display: flex; */
	margin: auto;
}

.animate {
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-name: animate-text;
}

.underlineAnimate {
	display: block;
	height: 2px;
	width: 400px;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-iteration-count: alternate;
	animation-name: underline-animate;
}

.customContainer {
	padding-top: 5rem;
	padding-bottom: 2rem;
	position: relative;
	padding-left: 0px;
}

.circleLink {
	width: 96px;
	height: 96px;
	background: #223771 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 50%;
	position: absolute;
	top: -3rem;
	right: 0;
}

.circleLink div {
	width: 96px;
	height: 96px;
	position: relative;
	display: flex;
}

.circleLink span {
	color: white;
	margin: auto;
	text-align: center;
	font: 16px/55px Helvetica;
	letter-spacing: 0px;
}

.title {
	text-align: left;
	font: bold 36px Helvetica;
	color: #000000;
	text-transform: uppercase;
	margin-bottom: 2rem;
}

.subTitle {
	text-align: left;
	font: bold 26px/55px Helvetica;
	color: #000000;
}

.desc {
	text-align: justify;
	font: 16px/32px Helvetica;
	color: #000000;
}

.titleSection {
	font: bold 26px/55px Helvetica;
	color: #000000;
	text-transform: uppercase;
}

.box {
	width: 448px;
	height: 192px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	position: relative;
}

.box:nth-child(3n + 2) {
	background-color: transparent;
	margin: 0 1rem 1rem 1rem;
}

.box img {
	position: relative;
	width: 448px;
	height: 192px;
	object-fit: cover;
	/* filter: blur(5px); */
}

.box:hover .boxContent {
	background-color: rgb(34, 55, 113, 0.6);
}

.box:hover img {
	filter: blur(5px);
}

.boxMask {
	background-color: rgb(0, 0, 0, 0.4);
	position: absolute;
	top: 0;
	height: 192px;
	width: 448px;
}

.boxContent {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.content {
	height: 192px;
	width: 448px;
	display: flex;
	align-self: center;
	padding: 0 2rem;
}

.content .boxTitle {
	position: absolute;
	top: 50%;
	text-align: left;
	font: bold 20px/48px Helvetica;
	color: #ffffff;
	text-transform: uppercase;
}

.boxTitle span {
	background: linear-gradient(
		rgba(242, 107, 37, 0.5) 0%,
		rgba(242, 107, 37, 0.5) 50%,
		rgba(242, 107, 37, 0.5) 100%
	);
	background-size: 0 50%;
	background-repeat: no-repeat;
	display: inline;
	transition: 0.5s ease;
}

.content:hover .boxTitle span {
	background-size: 100% 50%;
	margin-top: 10px;
}

.content:hover .boxLink {
	opacity: 1;
}

.boxLink {
	position: absolute;
	top: 70%;
	opacity: 0;
}

.boxLink > div {
	position: relative;
	display: flex;
	margin: auto;
}

.outerCircle {
	padding: 5px;
	transition: transform 0.2s;
	border-radius: 50%;
	border: 1px solid #fff;
	display: inline-block;
	bottom: -1rem;
	left: 0rem;
	opacity: 1;
	margin: auto 0.5rem auto 0;
}

.innerCircle {
	content: '';
	background: #fff;
	width: 8px;
	height: 8px;
	border-radius: 100%;
}

.boxLink span {
	color: #fff;
	font: 14px Helvetica;
	align-self: center;
}

.boxCulture {
	width: 100%;
	height: 360px;
	position: relative;
}

.boxCulture img {
	position: relative;
	width: 100%;
	height: 360px;
	object-fit: cover;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	/* filter: blur(5px); */
}

.boxCulture figure {
	overflow: hidden;
}

.boxCulture .boxMask {
	/* background-color: rgb(0, 0, 0, .4); */
	position: absolute;
	top: 0;
	width: 100%;
	height: 360px;
}

.cultureWrap {
	width: 100vw;
	display: flex;
}

.boxCulture:hover .boxMask {
	opacity: 0;
}

.boxCulture:hover img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
	-webkit-transform: scale(1);
	transform: scale(1);
}

.active {
	color: #f16b26;
}

.dropDownLang {
	position: relative;
}

.dropDownBtn {
	color: #707070;
	font-size: 1.4rem;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background: 0 0;
}

.dropdownMenu {
	position: absolute;
	width: 25rem;
	min-width: 100%;
	background: #f8f8f8;
	top: 25%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	-webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
	-webkit-box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, 0.08);
	box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, 0.08);

	width: 16rem;
	left: 0;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.slNav {
	display: inline;
}

.slNav ul {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	display: inline-block;
}

.slNav li {
	cursor: pointer;
	padding-bottom: 10px;
	color: #f16b26;
}

.slNav li ul {
	display: none;
}

.slNav li:hover ul {
	position: absolute;
	top: 29px;
	right: -15px;
	display: block;
	background: #fff;
	width: 150px;
	padding-top: 0px;
	z-index: 1;
	border-radius: 5px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li:hover .triangle {
	position: absolute;
	top: 15px;
	right: -10px;
	z-index: 10;
	height: 14px;
	overflow: hidden;
	width: 30px;
	background: transparent;
}

.slNav li:hover .triangle:after {
	content: '';
	display: block;
	z-index: 20;
	width: 15px;
	transform: rotate(45deg) translateY(0px) translatex(10px);
	height: 15px;
	background: #fff;
	border-radius: 2px 0px 0px 0px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li ul li {
	position: relative;
	text-align: left;
	background: transparent;
	padding: 15px 15px;
	padding-bottom: 0;
	z-index: 2;
	font-size: 15px;
	color: #000;
}

.slNav li ul li:last-of-type {
	padding-bottom: 15px;
}

.slNav li ul li span {
	padding-left: 5px;
}

.slNav li ul li span:hover,
.slNav li ul li span.active {
	color: #f16b26;
}

.boxImgFull {
	height: 392px;
	width: 100%;
}

.boxImgFull img {
	height: 392px;
	width: 100%;
	object-fit: cover;
}

@keyframes underline-animate {
	0%,
	10% {
		width: 0px;
		transform: translateX(-350px);
	}

	10%,
	20% {
		width: 300px;
		transform: translateX(0);
	}

	20%,
	30% {
		width: 0px;
		transform: translateX(300px);
	}

	30%,
	100% {
		transform: translateX(0);
		width: 0px;
	}
}

@keyframes animate-text {
	0%,
	30% {
		transform: translateX(-15%);
		opacity: 0;
	}

	30%,
	70% {
		transform: translateX(0);
		opacity: 1;
	}

	70%,
	99% {
		opacity: 1;
	}

	99%,
	100% {
		opacity: 0;
	}
}

@media only screen and (min-width: 2560px) {
	.customContainer {
		max-width: 2090px !important;
	}

	.banner {
		height: 45vh;
	}

	.banner img {
		height: 45vh;
	}

	.titleBanner {
		font: bold 70px Helvetica;
	}
}

@media only screen and (max-width: 1680px) {
	.customContainer {
		max-width: 78vw;
	}
}

@media only screen and (max-width: 1440px) {
	.customContainer {
		max-width: var(--container-hd);
		padding-bottom: 1.5rem;
		padding-top: 3.5rem;
	}

	.title {
		font: bold 28px Helvetica;
		margin-bottom: 1.5rem;
	}

	.desc {
		font: var(--p-hd);
		line-height: 1.8;
	}

	.titleBanner {
		font: bold 36px Helvetica;
	}
}

/*ipad pro only*/
@media only screen and (max-width: 1024px) {
	.customContainer {
		max-width: 100vw;
		padding: 3.5rem 10vw 3.5rem 6vw;
	}

	.circleLink {
		right: 4vw;
	}
	.title {
		font: bold 18px Helvetica;
	}

	.titleBanner {
		font: bold 28px Helvetica;
	}
}

/*tablet only*/
@media only screen and (max-width: 768px) {
	.customContainer {
		max-width: 100vw;
		padding: 3.5rem 6vw;
	}
	.circleLink {
		right: 1.5rem;
	}
	.title {
		font: bold 18px Helvetica;
	}

	.titleBanner {
		font: bold 28px Helvetica;
	}
}
