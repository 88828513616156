.pages {
    width: 100vw;
    height: 100%;
    background: #f1f1f1;
}


.pagesLitle {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.textBack {
    font: normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.cardImage {
    height: 38vh;
    width: 100%;
}

.cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.cardTitle {
    font: bold 16px Helvetica;
    color: black;
    line-height: 28px;
    text-transform: capitalize;
}

.cardText {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 2rem 2rem;
    min-height: 62vh;
}


.cardText a {
    justify-content: flex-start;
    display: flex;
    margin-top: auto;
}

.cardText span {
    font: 14px Helvetica;
    color: black;
    text-align: justify;
    line-height: 28px;
}


.iconDiv {
    color: black;
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem 2rem 0;
}

.iconFont {
    color: #000000;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}