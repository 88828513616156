.gocNhin {
    display: flex;
}

.leftCol {
    flex: 30%;
    min-height: 30px;
}

.rightCol {
    flex: 70%;
    padding-left: .5rem;
    display: block;
}

.authorC {
    width: 70%;
    border-right: solid 1px rgba(0, 0, 0, 0.03);
}

.authorItem {
    color: black;
    display: block;
    text-decoration: none;
    padding-left: 2rem;
    width: 140%;
    font: normal 16px/1.8 Helvetica;
}

.authors {
    font: normal 16px/1.8 Helvetica;
}

.authorItem:hover, .selected {
    color: black;
    text-decoration: none;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    clip-path: polygon(0% 0%, 97% 0, 100% 50%, 97% 100%, 0% 100%);
}

.selected {
    background: lightgrey
}

.link {
    color: black;
}

.link:hover {
    color: black;
    text-decoration: none;
}

.contentC {
    padding: 1rem;
    display: flex;
}

.contentCLeft {
    width: calc(100% - 100px - 2rem);
}

.contentC:hover {
    background: rgba(0, 0, 0, 0.03);
    cursor: pointer;
}

.contentArtTitle {
    font: bold 16px Helvetica;
    color: #000000;
    line-height: 1.8;
}

.contentSummary {
    font: normal 16px/1.8 Helvetica;
    margin-top: 1rem;
    min-height: 50px;
}

.contentAuthorName {
    font: bold 16px Helvetica;
    padding-top: 2rem;
    position: relative;
    bottom: 0;
}

.authorPhoto {
    padding-left: 2rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.authorImg {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.hrBottom:last-child {
    display: none;
}

.readMoreBox {
    padding-left: 2rem;
    padding-top: 2rem;
}

.customLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.customLink span {
    color: black;
}

.customLink:hover .outerCircle {
    opacity: 1;
    display: block;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    display: none;
    opacity: 0;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.readMore, .readMore:hover {
    text-decoration: none;
    color: black;
    font: normal 16px Helvetica;
}

@media only screen and (max-width: 1920px) {}

@media only screen and (max-width: 1660px) {}

@media only screen and (max-width: 1440px) {
    .contentArtTitle {
        font: bold 16px/1.8 Helvetica;
    }
    .contentSummary {
        font: normal 14px/1.8 Helvetica;
    }
    .contentAuthorName {
        font: bold 14px/1.8 Helvetica;
    }
    .authors {
        font: normal 14px/1.8 Helvetica;
    }
    .readMore, .readMore:hover {
        font: normal 14px/1.8 Helvetica;
    }
}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 768px) {}