.nego {
    /* margin-left: 2rem; */
    background: #004E96;
    padding: 0 2rem;
    height: 30px;
    justify-content: center;
    display: flex;
    border-radius: 3px;
    margin-bottom: 1rem;
}

.nego p {
    color: white !important;
}


.nego span {
    display: flex;
    font: 14px Helvetica;
    justify-content: center;
    margin: auto;
    padding: 0 !important;
    color: white;
}