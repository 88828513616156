/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;

  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;

  --title-tablet: bold 18px Helvetica;
  --subtitle-tablet: bold 14px Helvetica;
  --p-tablet: normal 14px Helvetica;
  --container-tablet: 672px;
}

/* SPECIFIC */
.Section6 {
  width: auto;
  height: auto;
  background-color: white;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.flexContent {
  margin: auto;
}

.boxTitle {
  height: 62px;
  position: absolute;
  align-self: center;
  left: 14%;
  top: 14%;
}

p.title,
h2.title {
  font: 36px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2rem;
}

p.contentTextBottom {
  font-size: 16px/32px Helvetica;
  letter-spacing: 0;
  text-align: left;
}

.Box5 {
  padding: 7rem 2rem 5rem;
}

.boxImage1 {
  width: 100%;
  height: 440px;
}

figure.wrapp_image {
  width: 100%;
  height: 440px;
  overflow: hidden;
}

.img_content {
  width: 100%;
  height: 440px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.inside_image {
  width: 1510px;
  height: 440px;
  position: absolute;
  background-color: rgb(6 6 6 / 23%);
  opacity: 0;
  color: rgb(0, 0, 0);
  top: 14.5%;
}

.iconhover {
  position: absolute;
  width: 200px;
  height: 100px;
  opacity: 1;
  bottom: 2px;
  left: 82%;
  object-fit: contain;
}

figure.wrapp_image:hover .inside_image {
  opacity: 1;
}

figure.wrapp_image:hover .img_content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 1s ease-in-out;
}

.Box5_1 {
  width: 1440px;
  height: 440px;
}

.flexContent2 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.miniBox1 {
  width: 448px;
  height: 242px;
  padding: 0 2rem 0 0;
}

.miniBox2 {
  width: 448px;
  height: 242px;
  padding: 0;
  margin-right: 30px;
  margin-left: 30px;
}

.miniBox3 {
  width: 448px;
  height: 242px;
  padding: 0 0 0 2rem;
}

.image2 {
  width: 100%;
  height: 232px;
  object-fit: cover;
}

.box_title {
  width: 448px;
  height: 56px;
  background-color: transparent;
  filter: blur(10px);
}

p.text {
  position: relative;
  font-size: 20px Helvetica;
}

.boxBottom {
  width: 448px;
  height: 56px;
  position: absolute;
  background-color: rgb(100 20 20 / 10%);
  bottom: 0;
  backdrop-filter: blur(10px);
  z-index: 0;
  opacity: 1;
  transition: height 0.5s;
}

.contentInsideBox {
  position: absolute;
  bottom: 0;
  z-index: 2;
  transition: height 0.5s;
  opacity: 1;
}

.insideBox {
  width: 448px;
  height: 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
}

p.contentBoxTitle {
  font: Bold 20px Helvetica;
  color: white;
  opacity: 1;
}

.box {
  width: 448px;
  height: 232px;

  position: relative;
}

.margin {
  margin: 1rem 2rem auto;
  opacity: 1;
}

.box_hover {
  position: relative;
  width: auto;
  height: auto;
  background-color: transparent;
}

.content_hover {
  background-color: rgb(241 229 229 / 15%);
  width: 448px;
  height: 232px;
  position: absolute;
  opacity: 0;
  backdrop-filter: blur(15px);
}

.text_hover {
  opacity: 1;
  color: white;
}

h2.text_hover {
  font: Bold 20px Helvetica;
  opacity: 1;
  margin: 0;
}

p.contentLink {
  font: 16px/24px Helvetica;
  margin: auto 0;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.outerCircle {
  padding: 5px;

  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0;
  opacity: 1;
  margin: auto 0.5rem auto 0;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.box_hover:hover .content_hover,
.box_hover:hover .content_hover .text_hover,
.box_hover:hover .content_hover .text_hover .outerCircle,
.box_hover:hover .content_hover .text_hover .outerCircle .innerCircle {
  opacity: 1;
}

.box_hover:hover .boxBottom {
  opacity: 0;
}

.padd {
  padding: 20% 0 0 20%;

  display: flex;
  flex-direction: row;
}

.link {
  color: transparent !important;
  cursor: pointer;
}

.link1 {
  text-decoration: none !important;
  margin: auto auto auto 0;
}

.link2 {
  text-decoration: none !important;
  margin: auto;
}

.link3 {
  text-decoration: none !important;
  margin: auto 0 auto auto;
}

.box_1 {
  width: 410px;
  height: 600px;
  background-color: #f1f1f1;
  padding: 2rem 2rem 0;
  display: flex !important;
  flex-direction: column;
  box-shadow: 6px 7px 6px #00000029;
}

.box_hover {
  position: relative;
  width: auto;
  height: auto;
  background-color: transparent;
}

.img_box {
  width: 346px;
  height: 398px;
  object-fit: cover;
  position: relative;
  transform: scale(1);
  transition: 1s ease-in-out;
  overflow: hidden;
}

.boxBtm {
  width: 346px;
  height: 100px !important;
  position: relative;
  background-color: #d6d6d6;
  margin-top: 21%;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  flex-direction: column;
  padding: 1rem;
}

._box {
  width: auto;
  position: relative;
  height: 20px;
  align-self: center;
}

.tx {
  font: bold 20px Helvetica;
  color: black;
  text-align: center;
  text-transform: uppercase;
}

.flexDir {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.boxC {
  max-width: 1540px;
}

.box1 {
  width: 448px;
  height: 232px;
  margin: auto;
  position: relative;
}

.box2 {
  width: 448px;
  height: 232px;
  position: relative;
}

.box3 {
  width: 448px;
  height: 232px;
  margin: auto 0 auto auto;
  position: relative;
}

._boxTxt {
  width: 250px;
  height: auto;
}

@media only screen and (max-width: 1680px) {
  .customContainer {
    padding-left: 0;
    padding-right: 0;
    max-width: 76vw !important;
  }

  p.title,
  h2.title {
    font: var(--title-hd);
  }

  .inside_image {
    width: 1090px;
    height: 441px;
    top: 11%;
  }

  .box {
    width: 354px;
  }

  .content_hover {
    width: 354px;
  }

  .boxBottom {
    width: 354px;
  }

  .boxC {
    max-width: 1120px;
  }

  .insideBox {
    width: 354px;
  }

  .iconhover {
    left: 80%;
  }

  .flexDir {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1440px) {}

@media only screen and (min-width: 2560px) {
  .customContainer {
    max-width: 2090px !important;
  }

  p.title,
  h2.title {
    margin-bottom: 2rem;
    font: bold 36px Helvetica;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 481px) {
  .customContainer {
    max-width: 88vw !important;
    margin: 0 6vw;
  }

  .flexDir {
    justify-content: space-between;
  }

  .Section6 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  /* .flexDir {
    transform: scale(0.67);
    justify-content: space-evenly;
  } */

  p.title,
  h2.title {
    font: var(--title-tablet);
    /* margin-bottom: -2rem; */
  }


}

@media only screen and (max-width: 480px) {
  .customContainer {
    max-width: 88vw !important;
  }

  .customDots {
    background-color: #ddd;
    border-radius: 10px;
    padding: 10px;
  }

  .customPaggination {
    width: 10px;
    border: 1px #f1f1f1 solid;
    height: 10px;
    border-radius: 50%;
  }

  .Section6 {
    padding: 3.5rem 0;
  }

  p.title,
  h2.title {
    font: bold 18px Helvetica;
  }

  .customSlider {
    justify-content: center;
  }
}