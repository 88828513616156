/* GENERAL */

:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;
  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */

.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 80px 0;
}

.customContainer {
  max-width: 88vw;
  margin: 0 auto 0 auto;
}

.topContainer {
  min-height: 70vh;
}

.backBtn, .backBtn:hover {
  color: black;
  text-decoration: none;
  font: var(--p-hd);
}

.arrow {
  color: black;
  margin-right: 1rem;
}

.title {
  font: bold 18px Helvetica;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

.date {
  font: normal 12px Helvetica;
  margin: 2rem 0 1rem 0;
}