/* GENERAL */
:root {
	--title-hd: bold 28px Helvetica;
	--subtitle-hd: bold 18px Helvetica;
	--p-hd: normal 14px Helvetica;
	--container-hd: 1062px !important;
	--padding-section-hd: 3.5rem 0;

	--title-fhd: bold 36px Helvetica;
	--subtitle-fhd: bold 26px Helvetica;
	--p-fhd: normal 16px Helvetica;
	--container-fhd: 1540px !important;
	--padding-section-fhd: 5rem 0;
}

/* SPECIFIC */
.customContainer {
	padding-left: 0;
	padding-right: 0;
	margin-left: auto;
	margin-right: auto;
	max-width: 1540px;
}

.topContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
	height: 90px;
}

.bgGrey {
	background-color: #d4d4d4;
}

.stickyBar {
	position: sticky;
	top: 0;
	z-index: 100;
}

.boxFilter {
	width: 19%;
	margin-right: 1rem;
	padding: 1rem 0.5rem;
	text-align: center;
	background-color: #f4f3ef;
	cursor: pointer;
	display: flex;
}

.boxFilter:last-child {
	margin-right: 0;
}

.filterActive {
	background-color: #d4d4d4;
}

.boxFilter span {
	font: bold 16px Helvetica;
	margin: auto;
}

.flexContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.posRelative {
	background-color: white;
	/* padding-bottom: 120px; */
}

.boxPreview {
	margin: 0 0 2rem 0;
	position: relative;
	width: 100%;
	height: 100%;
	/* transition: height 2s; */
	animation: transitionHeight 1s ease-in-out;
}

/* .boxPreview .active {
    animation: transitionHeight 1s;
} */

.boxPreviews .inActive {
	animation: transitionHeightRev 1s;
}

@-webkit-keyframes transitionHeight {
	0% {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
		height: 0;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		height: 100%;
		opacity: 1;
	}
}

@keyframes transitionHeight {
	0% {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
		height: 0;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		height: 100%;
		opacity: 1;
	}
}

@-webkit-keyframes transitionHeightRev {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		height: 100%;
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
		height: 0;
		opacity: 0;
	}
}

@keyframes transitionHeightRev {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		height: 100%;
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
		height: 0;
		opacity: 0;
	}
}

.boxPreview pre {
	color: #000;
}

.boxPreview p, .boxPreview pre {
	font: var(--p-fhd);
	line-height: 1.8;
	margin-bottom: 1.5rem;
	text-align: justify;
}

.boxPreview ul > li {
	font: var(--p-fhd);
	line-height: 1.8;
	margin-bottom: 0;
}

.navBack {
	position: relative;
	/* margin-left: -200px; */
	/* margin-top: -45px; */
	padding: 2rem 0 5rem 0;
	width: 30%;
}

.arrow {
	border: solid black;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
}

.textBack {
	font: normal 16px/32px Helvetica;
	color: black;
	margin: 0;
}

.customMargin {
	margin: auto 1rem auto auto;
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.wrapGallery {
	display: flex;
	padding: 2rem 0;
}

.boxCulture {
	width: 300px;
	height: 360px;
	position: relative;
	justify-content: center;
}

.boxCulture img {
	position: relative;
	width: 100%;
	height: 360px;
	object-fit: cover;
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	/* filter: blur(5px); */
}

.boxCulture figure {
	overflow: hidden;
}

.boxCulture .boxMask {
	/* background-color: rgb(0, 0, 0, .4); */
	position: absolute;
	top: 0;
	width: 100%;
	height: 360px;
}

.boxCulture:hover .boxMask {
	opacity: 0;
}

.boxCulture:hover img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transform: scale(1);
	transform: scale(1);
}

.customLink {
	text-decoration: none;
}

.customLink:hover {
	text-decoration: none;
}

.isActiveB span {
	color: #223771 !important;
	text-transform: uppercase;
}

.isActiveB::after {
	/* border-bottom: 3px solid #223771; */
	content: '' !important;
	display: block !important;
	margin-top: 19px !important;
	width: 0 !important;
	height: 2px !important;
	background: #223771 !important;
	width: 100% !important;
	transition: width 0.3s;
	position: relative;
	bottom: -4px;
}

.isActiveO span {
	color: #223771 !important;
	text-transform: uppercase;
}

.isActiveO::after {
	content: '' !important;
	display: block !important;
	margin-top: 19px !important;
	width: 0 !important;
	height: 2px !important;
	background: #f16b26 !important;
	width: 100% !important;
	transition: width 0.3s !important;
	position: relative;
	bottom: -4px;
}

.boxSlider {
	height: 100%;
	width: 100%;
	display: flex;
	margin-right: 2vw;
}

.boxSlider span {
	margin: auto;
	font: 14px Helvetica;
	text-transform: unset;
}

.imgIcon {
	height: 34px;
	width: 34px;
	position: relative;
	/* margin: auto 1vw auto 0; */
	margin: auto;
}

.figureContent {
	width: 100%;
	height: auto;
}

.contentImage {
	height: 800px;
	width: 100%;
	object-fit: cover;
}

.closeIcon {
	font-size: 30px;
	color: white;
	position: absolute;
	right: 30px;
	top: 25px;
	cursor: pointer;
	filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.6));
	/* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
	
}

.textContent {
	columns: 2;
	column-gap: 5%;
}

.companyDetailContainer {
	margin-top: 1rem;
}

.mapContainer {
	width: 70%;
}

.companyIdentityContainer {
	color: white;
	width: 30%;
	background: #223771 ;
	display: flex;
	align-items: center;
}

.companyIdentityContainer > div {
	margin-left: 2rem;
	margin-right: 2rem;
}

.companyIdentityContainer > div > div {
	margin-bottom: 1rem;
}

.companyIdentityContainer > div > div > div {
	margin-left: 1.5rem;
}	

.companyIdentityContainer p, .companyIdentityContainer pre {
	margin-bottom: 0 !important;
	white-space: pre-line;
}

.companyDetailContainer h4 {
	font: normal 26px Helvetica;
}

.companyDetailContainer p, .companyDetailContainer p {
	font: normal 16px/1.8 Helvetica;
}

.icon {
	font-size: 28px;
	width: 20px !important;
}

@media only screen and (min-width: 2560px) {
	.customContainer {
		max-width: 2000px !important;
	}
}

@media only screen and (max-width: 1650px) {
	.customContainer {
		max-width: 1300px !important;
	}
}

@media only screen and (max-width: 1440px) {
	.customContainer {
		max-width: var(--container-hd) !important;
	}

	.topContainer {
		padding-top: 0;
		margin-bottom: 1.5rem;
	}

	.textBack {
		font: normal 14px/28px Helvetica;
		color: black;
		margin: 0;
	}

	.topTitle {
		font: var(--title-hd);
	}

	.topHorizontalLine {
		margin-top: 1.5rem;
	}

	.navBack {
		padding: 1.5rem 0 3.5rem 0;
	}

	.boxPreview p, .boxPreview pre {
		font: var(--p-hd);
		line-height: 1.8;
		margin-bottom: 1rem;
	}

	.boxPreview ul > li {
		font: var(--p-hd);
		line-height: 1.8;
	}
	.isActiveB::after,
	.isActiveO::after {
		margin-top: 10px !important;
	}

	.contentImage {
		height: 570px;
	}

	.companyDetailContainer h4 {
		font: normal 18px Helvetica;
	}
	
	.companyDetailContainer p, .companyDetailContainer pre {
		font: normal 14px/1.5 Helvetica;
	}
}

@media only screen and (max-width: 1024px) {
	.customContainer {
		justify-content: flex-start;
		max-width: 88vw !important;
	}

	.companyDetailContainer h4 {
		font: normal 14px Helvetica;
	}
	
	.companyDetailContainer p, .companyDetailContainer pre {
		font: normal 14px/1.5 Helvetica;
	}
}

@media only screen and (max-width: 768px) {
	.customContainer {
		max-width: 672px !important;
	}

	.mapContainer {
		width: 50%;
	}

	.companyIdentityContainer {
		width: 50%;
	}

	.topTitle {
		font: bold 18px/32px Helvetica;
	}

	.isActiveB::after,
	.isActiveO::after {
		margin-top: -2px !important;
	}

	.contentImage {
        height: 35vh;
	}
}

@media only screen and (max-width: 480px) {
	.customBorder {
		border-top: 2px solid #b0b0b0;
	}
	.customBorderDefault {
		margin-bottom: 8vh;
	}
	.flexContainer {
		justify-content: center;
	}
	.customContainer {
		max-width: calc(100vw - 12vw) !important;
	}

	.topContainer {
		padding-top: 0;
		overflow-x: hidden;
		margin-bottom: 0;
	}

	.boxPreview {
		border-top: 1px solid black;
	}

	.textContent {
		columns: 1;
	}

	.companyIdentityContainer{
		width: 100%;
		height: auto;
	}

	.companyIdentityContainer > div {
		padding: 24px;
		margin: 0;
	}

	.companyDetailContainer h4 {
		font: normal 14px Helvetica;
	}

	.companyDetailContainer p, .companyDetailContainer pre {
		font: normal 14px/1.5 Helvetica;
	}

	.mapContainer {
		width: 100%;
	}

	.boxPreview p,
	.boxPreview pre,
	.boxPreview ul > li {
		font: 14px/24px Helvetica;
	}

	.navBack {
		padding: 3rem 0 0 0;
	}

	.boxFilter {
		padding: 1rem;
	}

	.boxsO,
	.boxsB {
		height: 10vh;
		padding: 0.5rem;
		margin-top: -2px !important;
	}

	.closeIcon {
		font-size: 20px;
		right: 15px;
		top: 15px;
	}

	.icon {
		font-size: 24px;
	}
}

@media screen and (max-width: 900px) and (orientation: landscape) {
	.imgIcon {
		margin: 0.5rem auto;
	}
}
