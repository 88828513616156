#data_table_nego {
    padding-bottom: 1rem;
    margin-top: 2rem;
}

#data_table_nego>.data-table-extensions {
    display: none !important;
}

#data_table_nego>.data-table-extensions>.data-table-extensions-filter {
    display: none !important;
}

/* #data_table_nego>.data-table-extensions>.data-table-extensions-filter>label {
    top: 50%;
   
    right: 0;
} */

/* #data_table_nego>.data-table-extensions>.data-table-extensions-filter>input {
    margin-left: .5rem;
} */

#data_table_nego>div:nth-child(3n)>nav {
    background-color: transparent;
}

.statusNego {
    color: #28a745 !important;
}

.dataTable span {
    font: 14px Helvetica;
    color: black;
}



@media only screen and (max-width: 1024px) {
    #data_table_nego {
        padding-bottom: 3rem;
    }
}