body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(207, 204, 204);
  border-radius: 20px;
  border: 1px solid transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
 
.modal-content {
  border: none;
}

.section-content-full {
  height: 100vh;
  width: 100vw;
  padding: 20px;
  display: block;
}

.card, .card-img {
  background-color: transparent;
  background-clip: border-box;
  border: 0;
  border-radius: 0;
}

@import "./assets/css/bootstrap.css";
