/* GENERAL */

:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;
  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */

.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 0 5rem 0;
}

.customContainer {
  max-width: 2000px;
  margin: 5rem auto 0 auto;
}

.title {
  font: bold 36px Helvetica;
  color: #000000;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 1920px) {
  .customContainer {
    max-width: 1540px;
  }
}

@media only screen and (max-width: 1600px) {
  .customContainer {
    max-width: 1300px;
  }
}

@media only screen and (max-width: 1440px) {
  .customContainer {
    max-width: 1162px;
  }
  .contentSection {
    padding: 0 0 3.5rem 0;
  }
}

@media only screen and (max-width: 1366px) {
  .customContainer {
    max-width: 1062px;
  }
}

@media only screen and (max-width: 1024px) {
  .customContainer {
    max-width: 88vw;
  }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 480px) {
  .title {
    font: bold 18px Helvetica;
    margin-bottom: 2rem;
  }
  .contentSection {
    padding: 0 0 5vh 0;
  }
  .customContainer {
    margin: 5vh auto 0 auto;
  }
}