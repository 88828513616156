.customContainer {
    padding-top: calc(2rem + 56px);
    padding-bottom: 5rem;
}

.titleContent {
    text-align: left;
    font: bold 20px/32px Helvetica;
    color: #000000;
    opacity: 1;
}

.titleContentB {
    text-align: left;
    font: bold 20px/32px Helvetica;
    color: #000000;
    opacity: 1;
    margin-bottom: 0;
}

.contentBookmarks {
    margin-top: 2rem;
}

@media only screen and (max-width: 1400px) {
    .customContainer {
        max-width: 82vw;
    }
}
@media only screen and (max-width: 480px) {
    .customContainer {
        padding-top: calc(2rem + 102px)
    }
}