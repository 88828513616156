.customContainer {
    max-width: calc(100vw - 12vw);
    height: 100%;
}

.boxImage {
    width: 100%;
    height: 30vh;
}

.imgContent {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentTop {
    margin-top: 3vh;
}

.tabTitle {
    font: bold 18px Helvetica;
}

.isNull {
    display: none !important;
}

.title {
    font: bold 16px Helvetica;
}

.content,
.content p {
    font: 14px/24px Helvetica;
    text-align: justify;
}

.contentDesc {
    background-color: #f4f4f4;
    padding: 1rem;
}

.contentDesc ul {
    padding: 0 0 0 2rem;
}

.contentDesc,
.contentDesc ul>li {
    font: 14px/24px Helvetica;
    text-align: justify;
}

.contentLink {
    font: 14px Helvetica;
    margin: auto 0;
    cursor: pointer;
    padding: 2vh 0;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    bottom: -1rem;
    left: 0rem;
    opacity: 1;
    margin: auto 0.5rem auto 0;
}

.innerCircle {
    content: "";
    background: #f16624;
    width: 4px;
    height: 4px;
    border-radius: 100%;
}

/* Detail */
.detailContent p {
    font: 14px/24px Helvetica;
}

.detailContent ul>li {
    font: 14px/24px Helvetica;
}

.detailContent strong {
    font-weight: bold;
}

.titleContent p {
    font: bold 18px/24px Helvetica !important;
}

.boxTop {
    height: 30vh;
    width: 100%;
}

.imgTop {
    height: 100%;
    width: 100%;
}

.boxBottom {}

.box {
    width: 80vw !important;
    height: 176px;
    margin-right: 1rem;
    position: relative;
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentFront {
    width: 100%;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
}

.contentFront {
    opacity: 1;
}

.boxMask {
    width: 100%;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
    background: #223771 0% 0% no-repeat padding-box;
    opacity: 0.5;
}

.boxContentIn {
    width: 100%;
    height: 176px;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    position: relative;
}

.boxContentIn p, .boxContentIn span {
    text-align: right;
    font: 14px Helvetica !important;
    color: #ffffff !important;
    margin-bottom: .5rem;
    display: block;
}

.boxContentIn .viewMore {
    text-align: right;
    font: 14px Helvetica !important;
    color: #ffffff;
    margin: 0;
    display: block;
}

.customLink {
    display: flex;
}

.link {
    text-decoration: none !important;
    color: black;
}

.customDots {
    background-color: #ddd;
    border-radius: 10px;
    padding: 10px;
}

.customPaggination {
    width: 8px;
    border: 1px #f1f1f1 solid;
    height: 8px;
    border-radius: 50%;
}

.fadeInBottom {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 900px) and (orientation:landscape) {

    .boxTop,
    .boxImage {
        width: 50%;
        margin: auto;
    }

    .imgTop {
        object-fit: cover;
    }

}